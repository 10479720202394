import { observer } from 'mobx-react';
import { ContactModelBase } from 'Models/ContactModel';
import { Contact } from 'Models/Contacts';
import React from 'react';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { PersonModel } from '../../models';
import { UiStore } from '../../stores';
import { UserAvatar } from '../shared/UserAvatar';

class PrintMessageHeader extends React.Component<{
  extrContactPhone?: string;
  persons: (PersonModel | ContactModelBase)[];
  extrContact: Contact;
  ui: UiStore;
  loggedInPerson;
  hasMessages: boolean;
}> {
  mergeContactsPersons = () => {
    const { extrContact, persons, loggedInPerson } = this.props;
    const filteredPersonNoLoggedIn = persons
      ?.filter((person) => person?.id !== loggedInPerson)
      .filter(Boolean);
    const firstInList =
      filteredPersonNoLoggedIn?.length > 0 && filteredPersonNoLoggedIn[0];
    if (
      (firstInList instanceof ContactModelBase || !firstInList) &&
      extrContact
    ) {
      return [extrContact];
    }
    return filteredPersonNoLoggedIn || [];
  };

  render() {
    const { ui, hasMessages, extrContactPhone } = this.props;
    const groupedContacts = this.mergeContactsPersons();
    const isAdHoc = window.location.pathname === '/chat/adHocGroup';
    return (
      <div className="first-mess">
        <div className="flex-row">
          {groupedContacts?.map((person, index) => {
            const name =
              person instanceof Contact
                ? `${person.DisplayName()}`
                : person instanceof PersonModel ||
                  person instanceof ContactModelBase
                ? person.DisplayName
                : '';
            const key =
              person instanceof Contact
                ? `${person.DisplayName()}`
                : person instanceof PersonModel ||
                  person instanceof ContactModelBase
                ? person.DisplayName
                : '';
            const presence = ui.selectPersonPresenceStatus(person?.id)
            return (
              <div key={key} className={index > 0 ? 'left-12px' : ''}>
                {!isNaN(person?.id) ? (
                  person instanceof Contact && person.pictureUrl ? (
                    <img className="contact-prof-img" src={person.pictureUrl} />
                  ) : (
                    <UserAvatar
                      key={`message-header-avatar-${person.id}-${presence?.state}`}
                      name={name}
                      selectUnreadCounts={ui.selectConversationUnreadCounts}
                      presence={
                        !(person instanceof Contact) &&
                        presence
                      }
                    />
                  )
                ) : (
                  <UserAvatar
                    key={`message-header-avatar-${presence?.state}`}
                    selectUnreadCounts={ui.selectConversationUnreadCounts}
                    presence={presence}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex-row flex-wrap">
          {groupedContacts?.map((person, index) => {
            const name =
              person instanceof Contact
                ? person.DisplayName()
                : person instanceof PersonModel ||
                  person instanceof ContactModelBase
                ? person.DisplayName
                : '';
            const key =
              person instanceof Contact
                ? person.DisplayName()
                : person instanceof PersonModel ||
                  person instanceof ContactModelBase
                ? person.DisplayName
                : '';
            return (
              <div key={key} className="first-mess-wrapper">
                <span className="first-mess-names">
                  {index !== 0 && ', '}
                  {name}
                </span>
                {person instanceof Contact && person?.phoneNumbers && (
                  <div className="extr-phone">
                    <div>{formatNumberWithNationalCode(extrContactPhone)}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <span className="first-mess-welcome">
          {' '}
          {isAdHoc
            ? 'Write a message to create a group chat.'
            : hasMessages
            ? 'This is the very beginning of your conversation.'
            : 'Write a message to create a chat.'}
        </span>
      </div>
    );
  }
}

export default observer(PrintMessageHeader);
