import type { BodyCopyProps } from './types';
import React from 'react';
import { Styled } from './index.styles';

export const BodyCopy: React.FC<BodyCopyProps> = ({
  children,
  ellipsis = false,
  noMargin = false,
  variant = 'paragraph',
  ...props
}) => (
  <Styled.BodyCopy
    $ellipsis={ellipsis}
    $noMargin={noMargin}
    $variant={variant}
    {...props}
  >
    {children}
  </Styled.BodyCopy>
);
