import styled from 'styled-components';

const HistoryVideoAttendeesStyled = styled.div`
  width: 50%;
  max-height: 25vh;
  -ms-overflow-style: 4px;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #ccc transparent;
  scroll-behavior: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const PersonNameStyled = styled.span`
  margin-left: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
`;

const NumAttendeesStyled = styled.span`
  position: relative;
  top: -9px;
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  border-radius: 50%;
  font-size: 9px;
  padding-top: 2px;
  width: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
`;

const SubtitleStyled = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding-bottom: 19px;
  padding-top: 10px;
`;

export const Styled = {
  HistoryVideoAttendeesStyled,
  NumAttendeesStyled,
  PersonNameStyled,
  SubtitleStyled,
};
