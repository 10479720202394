import { API_ENDPOINTS } from 'Constants/env';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import { action, observable, ObservableMap, makeObservable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import {
  ContactModel,
  ContactTypeGuards,
  FromContactResponseDto,
  IContactDto,
} from 'Models/ContactModel';
import API from '../api';
import { formatNumberNoPlusIfUS } from '../utils/phoneUtil';
import { BaseStore } from './BaseStore';
import { RootStore } from './RootStore';

export class ContactStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /** Map of the contacts, where the key is the phone number. Used only on the `ContactStore` */
  @observable private contactsByPhone: ObservableMap<
    string,
    AxiosResponseT<ContactModel>
  > = observable.map();

  /** Status of the initial loadContacts promise. Used only on the `ContactStore` */
  @observable private loadContactsStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<IContactDto>>
  > = null;

  /** Fetches a single contact from the BE, based on it's phone number. Used only on the `ContactStore` */
  private fetchContactByPhoneNumber = (
    phoneNumber: string
  ): Promise<AxiosResponseT<ContactModel>> =>
    API.post<ContactModel>(API_ENDPOINTS.Contacts, { phoneNumber }).then(
      (resp) => {
        const contactInst = FromContactResponseDto(resp.data);
        if (ContactTypeGuards.isContactExtendedOrMerged(contactInst)) {
          const resolvedMulti: AxiosResponseT<ContactModel>[] = [];
          contactInst.phoneNumbers?.forEach((phNum) => {
            this.contactsByPhone.set(phNum, { ...resp, data: contactInst });
            resolvedMulti.push({ ...resp, data: contactInst });
          });
          // Return the first entry from a Merged Contact, since they are all the same.
          return resolvedMulti[0];
        }

        this.contactsByPhone.set(contactInst.phoneNumber.toString(), {
          ...resp,
          data: contactInst,
        });
        return { ...resp, data: contactInst };
      },
      (response) => {
        this.rootStore.notificationStore.addAxiosErrorNotification(
          response,
          'Error Loading Contact'
        );
        return Promise.reject(response);
      }
    );

  /** Checks if phone number exists in cache. If not, return `null` without trying to fetch it. */
  @action public getContactByPhoneNumber = (
    phoneNumber: string
  ): AxiosResponseT<ContactModel> | null => {
    phoneNumber = formatNumberNoPlusIfUS(phoneNumber);

    if (this.contactsByPhone.has(phoneNumber))
      return this.contactsByPhone.get(phoneNumber);

    if (this.contactsByPhone.has('+' + phoneNumber))
      return this.contactsByPhone.get('+' + phoneNumber);

    return null;
  };

  /** Checks if phone number exists in cache. If not, try to fetch it from BE. */
  @action public loadContactByPhoneNumber = (
    phoneNumber: string
  ): IPromiseBasedObservable<AxiosResponseT<ContactModel>> => {
    phoneNumber = formatNumberNoPlusIfUS(phoneNumber);

    const storedContactByPhone = this.getContactByPhoneNumber(phoneNumber);
    if (storedContactByPhone) return fromPromise.resolve(storedContactByPhone);

    // If main list is loading, wait...
    return this.loadContactsStatus.case({
      fulfilled: () => {
        // Once the main list is done loading, check if `phoneNumber` matches...
        if (this.contactsByPhone.has(phoneNumber))
          return fromPromise.resolve(this.contactsByPhone.get(phoneNumber));

        // Otherwise, try load the Contact
        return fromPromise(this.fetchContactByPhoneNumber(phoneNumber));
      },
      rejected: (response) => {
        this.rootStore.notificationStore.addAxiosErrorNotification(
          response,
          'Error Loading Contacts'
        );
        return fromPromise.reject(response);
      },
    });
  };

  /** Only used for initial load of the contacts, on login */
  @action public async loadContacts() {
    this.loadContactsStatus = fromPromise(API.get(API_ENDPOINTS.Contacts));
    await this.loadContactsStatus.then(
      (response: AxiosResponseT<ResultsCollection<IContactDto>>) => {
        response.data.results.forEach((item) => {
          const contactInst = FromContactResponseDto(item);
          if (
            ContactTypeGuards.isContactExtended(contactInst) ||
            ContactTypeGuards.isContactMerged(contactInst)
          ) {
            // TODO: If there are multiple Contacts that aren't Merged for some reason, prioritize by type and/or updated (RP 2018-04-18)
            // Currently, this behavior is acceptable, because if there is an existing Base Contact, we want to overwrite it
            contactInst.phoneNumbers.forEach((itm) =>
              this.contactsByPhone.set(itm, { ...response, data: contactInst })
            );
            return;
          }

          if (!contactInst.phoneNumber) return;

          // "Base" Contacts are the lowest priority, so don't overwrite a higher level Contact
          if (this.contactsByPhone.has(contactInst.phoneNumber)) {
            const exCt = this.contactsByPhone.get(contactInst.phoneNumber).data;
            const lmPrfx = `Base Contact with phoneNumber ${contactInst.phoneNumber} (id: ${contactInst.id})`;
            const lmSufx = `existing ${exCt.contactType} Contact (id: ${exCt.id})`;
            ContactTypeGuards.isContactExtendedOrMerged(exCt)
              ? console.debug(`Skipping ${lmPrfx} in favor of ${lmSufx}`)
              : console.warn(
                  `Encountered duplicate ${lmPrfx}, which conflicts with ${lmSufx}`
                );
            return;
          }

          // This is a TEMPORARY solution. Huy says that we do need to handle contacts that have neither a
          // Communicator account nor a phone number, I.E. Caller ID blocked numbers. That is a much bigger
          // fix though, so right now we just won't show them in history to prevent the app getting stuck
          // on loading.
          this.contactsByPhone.set(contactInst.phoneNumber, {
            ...response,
            data: contactInst,
          });
        });
      }
    );
  }

  @action clearAllData = () => this.contactsByPhone.clear();
}

export default ContactStore;
