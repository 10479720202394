import type { SipConnectionStatusProps } from './types';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';

export const SipConnectionStatus: React.FC<SipConnectionStatusProps> = observer(
  ({ testid }) => {
    const {
      phoneCall: {
        reconnectSip,
        isWebRTCConnected,
        isWebRTCReconnecting,
      },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    return (
      <Styled.IconButton
        active={isWebRTCConnected && !isWebRTCReconnecting}
        content={
          isWebRTCConnected && !isWebRTCReconnecting
            ? 'Sip Connected'
            : 'Sip Not Connected, click for reconnection'
        }
        icon={'sip-connection'}
        onClick={() => !isWebRTCConnected && !isWebRTCReconnecting && reconnectSip()}
        popupPosition="left center"
        size="tiny"
        testid={`${testid}-buttonSipConnectionStatus`}
      />
    );
  }
);
