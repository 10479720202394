import { computed, observable, makeObservable } from 'mobx';
import { PHONE_TYPE } from '../constants/enums';

export interface IPhoneNumberModel {
  id: number;
  phone: string;
  type: PHONE_TYPE;
  description?: string;
}

export class PhoneNumberModel implements IPhoneNumberModel {
  constructor(
    id: number,
    phone: string,
    type: PHONE_TYPE,
    description?: string
  ) {
    makeObservable(this);
    this.id = id;
    this.phone = phone;
    this.type = type;
    this.description = description;
  }

  static FromResponseDto = (responseDto: IPhoneNumberModel) =>
    new PhoneNumberModel(
      responseDto.id,
      responseDto.phone,
      responseDto.type,
      responseDto.description
    );

  public id: number;
  @observable
  public phone: string;

  @observable
  public timer: string;

  @observable
  public type: PHONE_TYPE;

  @observable
  public description?: string;

  @computed get PhoneIcon() {
    let iconType = 'h';
    switch (this.type) {
      case PHONE_TYPE.Work:
        {
          iconType = 'w';
        }
        break;
      case PHONE_TYPE.Cell:
        {
          iconType = 'c';
        }
        break;
      case PHONE_TYPE.Other:
        {
          iconType = 'o';
        }
        break;
      case PHONE_TYPE.Home:
      default:
        {
          iconType = 'h';
        }
        break;
    }
    return iconType;
  }
}

export default PhoneNumberModel;
