import type { PopOverMenuProps } from './types';
import React from 'react';
import { Styled } from './index.styles';

export const PopOverMenu: React.FC<PopOverMenuProps> = ({
  content,
  hideOnScroll = false,
  offset,
  onOutsideClick,
  position = 'bottom center',
  show,
  trigger,
}) => (
  <Styled.Popup
    {...{ trigger, position, offset, hideOnScroll }}
    open={show}
    on="click"
    content={<Styled.PopupContent>{content}</Styled.PopupContent>}
    onClose={onOutsideClick}
  />
);
