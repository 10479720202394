import type { ChatContentItemProps } from './types';
import cx from 'classnames';
import {
  SystemMessageAddUser,
  SystemMessageCall,
  SystemMessageGroupCreated,
  SystemMessageRemoveUser,
  SystemMessageVideo,
} from 'Components/Chat/ChatLayout/SystemMessage';
import { parseMessageDate } from 'Components/Chat/utils';
import { MessageBubble } from 'Components/shared/MessageBubble';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import type { Contact } from 'Models/Contacts';
import React from 'react';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { Avatar } from './Avatar';
import { Styled } from './index.styles';

export const ChatContentItem: React.FC<ChatContentItemProps> = observer(
  ({
    conversationId,
    getPerson,
    selectUnreadCounts,
    message,
    selectPersonPresenceStatus,
    getExtrContactByPhoneNumber,
    messageGroup,
    loggedInPersonId,
  }) => {
    const [person, setPerson] = React.useState(null);
    const [contact, setContact] = React.useState<Contact>(null);
    const fetchPerson = async (data) => {
      const result = await getPerson(data);
      setPerson(result);
    };

    const fetchContact = (data: string) => {
      const result = getExtrContactByPhoneNumber(data);
      setContact(result);
    };

    const event = message.systemEvent?.eventType;
    const shouldRender =
      ([
        'Conversation.Created',
        'Conversation.Participants.Removed',
        'Conversation.Participants.Added',
      ].includes(event) ||
        !event) &&
      message?.conference?.provider !== 'Voxeet';

    React.useEffect(() => {
      const fetchPersonOrContact = async () => {
        if (message.personId > 0) {
          await fetchPerson(
            message?.systemEvent?.payload?.personId || message.personId
          );
        } else if (message.phone) {
          fetchContact(message.phone);
        }
      };

      fetchPersonOrContact();
    }, [message.personId, message.phone]);

    if (!shouldRender) {
      return null;
    }

    const parsedMessageDate = parseMessageDate(message.created);
    const presence =
      person?.data?.id && selectPersonPresenceStatus(person.data.id);

    return (
      <Styled.FeedEvent id={message.id}>
        <div
          className={cx('content', {
            'content-grouped': !(message.isFirstIn5Mins || event),
            'flex-row-reverse': message.conference || message.call,
          })}
        >
          {(!isEmpty(message.chat) ||
            !isEmpty(message.call) ||
            message.conference ||
            (message.documents && isEmpty(message.sms)) ||
            message.isDeleted) &&
            person && (
              <MessageBubble
                data-testid="messageBubble-message"
                AvatarComponent={
                  <Avatar
                    key={`message-bubble-avatar-${person?.data?.id}-${presence?.state}`}
                    message={message}
                    person={person}
                    conversationId={conversationId}
                    selectUnreadCounts={selectUnreadCounts}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                  />
                }
                messageDate={parsedMessageDate}
                isOwnMessage={loggedInPersonId === message.personId}
                content={message.chat?.text}
                displayName={`${person?.data?.firstName} ${person?.data?.lastName}`}
                {...{ message, conversationId }}
              />
            )}
          {message?.sms && (
            <MessageBubble
              data-testid="messageBubble-sms"
              AvatarComponent={
                <Avatar
                  {...{ message, person, conversationId, selectUnreadCounts }}
                  {...(!person && { variant: 'phone' })}
                  selectPersonPresenceStatus={selectPersonPresenceStatus}
                />
              }
              messageDate={parsedMessageDate}
              isOwnMessage={loggedInPersonId === message.personId}
              content={message.sms.text || ''}
              displayName={
                person
                  ? `${person.data.firstName} ${person.data.lastName}`
                  : contact?.DisplayName() ||
                    formatNumberWithNationalCode(message.phone)
              }
              {...{ message, conversationId }}
            />
          )}
          {(person || contact) && (
            <>
              {message.systemEvent?.eventType === 'Conversation.Created' && (
                <SystemMessageGroupCreated {...{ message }} />
              )}
              {message.systemEvent?.eventType ===
                'Conversation.Participants.Removed' && (
                <SystemMessageRemoveUser {...{ message }} />
              )}
              {message.systemEvent?.eventType ===
                'Conversation.Participants.Added' && (
                <SystemMessageAddUser {...{ message }} />
              )}
              {message.conference && <SystemMessageVideo {...{ message }} />}
            </>
          )}
          {message.call?.disposition && <SystemMessageCall {...{ message }} />}
        </div>
      </Styled.FeedEvent>
    );
  }
);
