import React from 'react';

import { Styled } from './index.styles';
import { ContentAreaProps } from './types';

export const ContentArea: React.FC<ContentAreaProps> = ({
  id,
  children,
  inlineTopBanner,
}) => {
  return (
    <Styled.ContentAreaWrapper {...{ id }}>
      {inlineTopBanner}
      <Styled.ContentArea $inlineTopBanner={!!inlineTopBanner}>
        <Styled.Content>{children}</Styled.Content>
      </Styled.ContentArea>
    </Styled.ContentAreaWrapper>
  );
};
