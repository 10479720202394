import { Icon } from 'Components/shared/Icon';
import { Feed } from 'semantic-ui-react';
import styled from 'styled-components';

const FeedEvent = styled(Feed.Event)`
  &:hover {
    background: none !important;
  }
`;

const AvatarContainer = styled.div`
  margin-right: 5px;
  width: 30px;
`;

const PhoneIcon = styled(Icon)`
  color: white !important;
`;

const PhoneContainer = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const Styled = {
  AvatarContainer,
  PhoneContainer,
  FeedEvent,
  PhoneIcon,
};
