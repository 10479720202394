import TurndownService from 'turndown';

/** Do not escape any Markdown within HTML */
TurndownService.prototype.escape = (str: string) => str;

const turndownSvc = new TurndownService()
  .addRule('rich', {
    filter: ['i', 'strong', 'b', 'u'],
    replacement: (content, node, options) => {
      return content;
    },
  })
  .addRule('br', {
    filter: ['br'],
    replacement: (content, node, options) => {
      return ' \n';
    },
  })
  /*.addRule('p', {
        filter: ['p'],
        replacement: (content, node, options) => {
            return content + '\n';
        }
    })*/
  .addRule('mention', {
    filter: ['div'],
    replacement: (content, node, options) => {
      if (
        node.classList.contains('input-mention') &&
        node.hasAttribute('mention-target')
      ) {
        return node.getAttribute('mention-target');
      }
      return content;
    },
  });

export { turndownSvc };
