import type { TopBarDropdownContextProps } from 'Components/TopBar/contexts/TopBarDropdownContext';
import { DropDownItemTopBar } from 'Components/TopBar/DropDownItemTopBar';
import React from 'react';
import { getBvCode } from 'Utils/callValidation/getBvCode';

export type bindNumberToOptionsProps = (
  searchQuery: string,
  isNumericSearch: boolean
) => TopBarDropdownContextProps['options'];

export const bindNumberToOptions: bindNumberToOptionsProps = (
  searchQuery,
  isNumericSearch
) => {
  const broadVoiceCode = getBvCode(searchQuery);
  const normalizedSearchQuery = searchQuery.replace(/[^0-9+]/g, '');

  const phoneNumber = broadVoiceCode
    ? broadVoiceCode + normalizedSearchQuery
    : normalizedSearchQuery;

  return isNumericSearch
    ? [
        {
          key: phoneNumber,
          value: phoneNumber,
          text: searchQuery,
          variant: 'adhoc-phone-number',
          phoneNumber,
          label: (
            <DropDownItemTopBar
              ignoreName
              userInfo={{ phoneNumbers: [phoneNumber], keyValue: phoneNumber }}
              name={phoneNumber}
              variant="adhoc-phone-number"
            />
          ),
        },
      ]
    : [];
};
