import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';

/**
 * @param buildLinkPreview An `action` which should load the link preview for the Message model, given the URI.
 */
export const buildRefLinkText = (): ParserRule & ReactOutputRule => ({
  ...SimpleMarkdown.defaultRules.reflink,
  order: 0,
  match: (matchSrc) =>
    /^\[([^\]]+)\]\[(?!\s*\]|\s*\[)\s*([^\]]+)\]/.exec(matchSrc),
  parse: (capture) => ({
    first: capture[1],
    second: capture[2],
  }),
  react: (node, _output, state) => {
    const { first, second } = node;

    return (
      <span
        key={state.key}
        data-testid={`markdownCompiler-reflinkText-${state.key}`}
      >
        {`[${first}][${second}]`}
      </span>
    );
  },
});
