import styled from 'styled-components';

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Styled = {
  ActionButtonsWrapper,
};
