import type { RemoveEventModalProps } from './types';
import { ConfirmationModal } from 'Components/shared/ConfirmationModal';
import React from 'react';

export const RemoveEventModal = ({
  calendar,
  isRemoveEventModalOpened,
  eventForRemoval,
  handleRemoveEventModalClose,
  testId: parentTestId,
}: RemoveEventModalProps) => {
  const handleCalendarRemoveEvent = async () => {
    const isDeleted = await calendar.deleteEventById(eventForRemoval.id);

    isDeleted && handleRemoveEventModalClose();
  };

  return (
    <ConfirmationModal
      show={isRemoveEventModalOpened}
      onClose={handleRemoveEventModalClose}
      onConfirm={handleCalendarRemoveEvent}
      textConfirm="Yes"
      textCancel="No"
      testid={`${parentTestId}-removeEventModal`}
    >
      <div>
        You are about to delete the event <b>{eventForRemoval.title}</b> sync.
      </div>

      <div>Are you sure?</div>
    </ConfirmationModal>
  );
};
