import closeBtn from 'Assets/images/close-btn.svg';
import uploadIcon from 'Assets/images/upload.svg';
import { ChatFooter } from 'Components/Chat/ChatFooter';
import { ChatLayout } from 'Components/Chat/ChatLayout';
import { useAttachments } from 'Components/Chat/hooks/attachments/useAttachments';
import { ConfirmationModalContextWrapper } from 'Components/ChatLayoutHeader/contexts/ConfirmationModalContextWrapper';
import { ContentArea } from 'Components/shared/ContentArea';
import { EMPTY_TIMEUUID, SHOW_FILE_UPLOAD } from 'Constants/env';
import AdHocGroup from 'Containers/AdHocGroup';
import { usePreferences } from 'Hooks/preferences/usePreferences';
import { IUnreadMessageAndMentionCountsByConversationId } from 'Interfaces/components';
import { isEmpty } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import type { ConversationModel } from 'Models/index';
import React from 'react';
import { FileDrop } from 'react-file-drop';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Button, Grid, Modal } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const ContextPanel: React.FC = observer(() => {
  const {
    conversation: {
      RecentConversations,
      CurrentConversation,
      adHocGroupParticipants,
      ownerOfTheChat,
      setChannelInfoDetails,
      clearNameTaken,
    },
    message: { createMessagePost, editMessage },
    phoneCall: { AnyPhoneConnectionActive },
    ui: {
      activePinnedConversationId,
      fileDeleteModal,
      openTopbarDialpad,
      removeFromOpenedRightSidebarsOrder,
      selectConversationUnreadCounts,
      setEmojiPickerState,
      setFileDeletePopup,
      setMoreActionOptions,
      setSelectedParticipants,
      setShowDropDownBox,
      showDropDownBox,
      wantedUrl,
    },
    person: { IsLoggedIn, showPersonDetails, setShowPersonDetails },
  } = React.useContext<RootStoreProps>(MobXProviderContext);
  const { conversationId } = useParams();

  const { addAttachments, resetAttachments, getUploadedS3ObjectsMap } =
    useAttachments();

  const {
    preferences: { listUnreadFirst },
  } = usePreferences();

  const [shiftPressed, setShiftPressed] = React.useState(false);
  const [UnreadCounts, setUnreadCounts] =
    React.useState<IUnreadMessageAndMentionCountsByConversationId>({});

  React.useEffect(() => {
    setUnreadCounts(
      RecentConversations.reduce<IUnreadMessageAndMentionCountsByConversationId>(
        (prev, next) => {
          prev[next.id] = selectConversationUnreadCounts(next.id);
          return prev;
        },
        {}
      )
    );
  }, [RecentConversations]);

  const isGroupConversation = React.useRef<boolean>();

  React.useEffect(() => {
    isGroupConversation.current =
      CurrentConversation?.grouping === 'Group' ||
      CurrentConversation?.grouping === 'Channel';
  }, [CurrentConversation?.grouping]);

  React.useEffect(
    () => () => {
      if (isGroupConversation.current) {
        setChannelInfoDetails('0', 'new');
        clearNameTaken();
      } else {
        setShowPersonDetails(null, null);
      }
      removeFromOpenedRightSidebarsOrder('sidebar-info');
    },
    []
  );

  const handleCloseModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEmojiPickerState({ open: false, editing: false });
    setSelectedParticipants(null);
    setMoreActionOptions({ conversationId: '', show: false });
  };

  const getFirstFromHistory = (): ConversationModel =>
    RecentConversations.slice().sort((a, b) => {
      const aDate = a.lastMessageDate || a.created;
      const bDate = b.lastMessageDate || b.created;
      let sortResult = aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
      if (listUnreadFirst) {
        const unreadCountsA = UnreadCounts[a.id];
        const unreadCountsB = UnreadCounts[b.id];
        const aHasUnreads =
          !isEmpty(unreadCountsA) && unreadCountsA.unreadMessages > 0;
        const bHasUnreads =
          !isEmpty(unreadCountsB) && unreadCountsB.unreadMessages > 0;
        if (aHasUnreads && !bHasUnreads) {
          sortResult = -1; // A before B
        } else if (!aHasUnreads && bHasUnreads) {
          sortResult = 1; // B before A
        } // ... Otherwise, leave the sort result alone
      }

      return sortResult;
    })[0];

  const handleShiftDown = (e) => {
    (e.keyCode === 16 || e.charCode === 16) && setShiftPressed(true);
  };

  const handleShiftUp = (e, state?: boolean) => {
    (e.keyCode === 16 || e.charCode === 16 || state) && setShiftPressed(false);
  };

  const handleOnDragOver = (e) => {
    !showDropDownBox.show &&
      setShowDropDownBox({ show: true, draggedOn: true });
    document.addEventListener('keydown', handleShiftDown);
    document.addEventListener('keyup', handleShiftUp);
  };

  const handleOnDragLeave = () => {
    showDropDownBox.draggedOn &&
      setShowDropDownBox({ show: false, draggedOn: false });
    document.removeEventListener('keydown', handleShiftDown);
    document.removeEventListener('keyup', handleShiftUp);
    setShiftPressed(false);
  };

  const handleOnDrop = async (files: FileList, event) => {
    setShowDropDownBox({ show: false, draggedOn: false });
    await addAttachments(files);
    const uploadedS3Objects = getUploadedS3ObjectsMap();

    if (shiftPressed) {
      handleShiftUp(event, true);
      setTimeout(() => {
        void createMessagePost(
          CurrentConversation.id,
          null,
          null,
          CurrentConversation.grouping,
          uploadedS3Objects
        );
        resetAttachments();
      }, 1000);
    }

    document.removeEventListener('keydown', handleShiftDown);
    document.removeEventListener('keyup', handleShiftUp);
    return;
  };

  const handleCloseFileDrop = () => {
    setShowDropDownBox({ show: false, draggedOn: false });
    document.removeEventListener('keydown', handleShiftDown);
    document.removeEventListener('keyup', handleShiftUp);
  };

  const closeFileDeletePopup = () =>
    setFileDeletePopup({ show: false, externalId: '', messageId: '' });

  const handleDeleteConfirm = () =>
    editMessage(CurrentConversation.id, fileDeleteModal.messageId, null);

  const isRightSidebarOpened = () =>
    !AnyPhoneConnectionActive &&
    !openTopbarDialpad &&
    !showPersonDetails?.id &&
    !activePinnedConversationId;

  const largeColumns = isRightSidebarOpened() ? 8 : null;
  const smallColumns = isRightSidebarOpened() ? 4 : null;

  const renderContextContent =
    ['/chat', '/chat/menu'].some((path) => window.location.pathname === path) &&
    !!RecentConversations;

  const firstFromRecentHistroy = getFirstFromHistory();
  const RedirectTo =
    wantedUrl && !wantedUrl.includes('login') && wantedUrl !== '/' ? (
      <Navigate to={wantedUrl} replace />
    ) : (
      <Navigate
        replace
        to={`/chat/conversations/${firstFromRecentHistroy?.id}/menu`}
      />
    );

  if (window.location.pathname === '/directory') return;

  return (
    <Grid.Column
      id="context-panel"
      onClick={handleCloseModal}
      widescreen={largeColumns}
      computer={largeColumns}
      desktop={largeColumns}
      tablet={smallColumns}
      mobile={smallColumns}
      className={`flex-column maxheightpercent ${
        openTopbarDialpad &&
        activePinnedConversationId &&
        'sidebar-right-margin'
      }`}
    >
      <ContentArea>
        {IsLoggedIn && (
          <>
            {window.location.pathname.includes('conversations') &&
              SHOW_FILE_UPLOAD && (
                //@ts-ignore
                <FileDrop
                  className={showDropDownBox.show ? 'z-index-file-target' : ''}
                  onFrameDragEnter={handleOnDragOver}
                  onDragLeave={handleOnDragLeave}
                  onDrop={handleOnDrop}
                  onTargetClick={handleCloseFileDrop}
                >
                  {showDropDownBox.show && (
                    <div
                      className={
                        showDropDownBox.draggedOn ? 'drag-drop-file' : ''
                      }
                    >
                      <img src={uploadIcon} />
                      <span className="receiver">{ownerOfTheChat}</span>
                      <span>You can add comments before uploading.</span>
                      <span>Hold shift to share immediately.</span>
                    </div>
                  )}
                </FileDrop>
              )}
            {fileDeleteModal && (
              <Modal
                open={fileDeleteModal.show}
                size="small"
                id="confirmation-delete-modal"
                dimmer="blurring"
                closeOnDimmerClick
                centered
                onClose={closeFileDeletePopup}
                className="confirmation-modal"
              >
                <img
                  className="close-btn"
                  src={closeBtn}
                  onClick={closeFileDeletePopup}
                />
                <div className="flex-row">
                  Are you sure you want to delete this file?
                </div>
                <div className="flex-row">
                  <Button
                    primary
                    content="CANCEL"
                    onClick={closeFileDeletePopup}
                  />
                  <Button
                    secondary
                    content="DELETE"
                    onClick={handleDeleteConfirm}
                  />
                </div>
              </Modal>
            )}

            {renderContextContent && firstFromRecentHistroy ? (
              RedirectTo
            ) : window.location.pathname.includes('adHocGroup') &&
              adHocGroupParticipants ? (
              <>
                <AdHocGroup />
                <ChatFooter />
              </>
            ) : (
              <ConfirmationModalContextWrapper>
                {conversationId === EMPTY_TIMEUUID && !wantedUrl && (
                  <Navigate to="/chat/*" />
                )}
                <Routes>
                  <Route
                    path="/conversations/:conversationId/*"
                    element={<ChatLayout />}
                  />
                </Routes>
              </ConfirmationModalContextWrapper>
            )}
          </>
        )}
      </ContentArea>
    </Grid.Column>
  );
});
