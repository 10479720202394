import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const SidebarMarginStyled = styled(Grid.Column)<{
  $marginWidth: number;
}>`
  z-index: 0;
  width: 0 !important;
  padding: 0 !important;
  height: calc(100% - 70px);

  @media (min-width: 1024px) {
    ${({ $marginWidth }) =>
      $marginWidth &&
      `
        width: ${$marginWidth}px !important;
      `};
  }
`;

export const Styled = {
  SidebarMarginStyled,
};
