import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ConversationStore, ConversationStoreState } from './index.types';

const initialState: ConversationStoreState = {
  optedOutPhoneNumbers: [],
  mentionUsers: new Map(),
};

const conversationStore = create(
  devtools<ConversationStore>((set, get) => ({
    ...initialState,
    setOptedOutPhoneNumbers: (optedOutPhoneNumbers) =>
      set({ optedOutPhoneNumbers }),
    updateOptedOutPhoneNumbers: (numberToUpdate, optsOut) => {
      const optedOutPhoneNumbers = get().optedOutPhoneNumbers;
      const optedOutPhoneNumberIndex =
        optedOutPhoneNumbers.indexOf(numberToUpdate);

      if (optsOut) {
        if (optedOutPhoneNumberIndex === -1) {
          get().setOptedOutPhoneNumbers([
            ...optedOutPhoneNumbers,
            numberToUpdate,
          ]);
        }
      } else {
        if (optedOutPhoneNumberIndex >= 0) {
          const optedOutPhoneNumbersAux = [...optedOutPhoneNumbers];
          optedOutPhoneNumbersAux.splice(optedOutPhoneNumberIndex, 1);

          get().setOptedOutPhoneNumbers(optedOutPhoneNumbersAux);
        }
      }
    },
    addMentionUser: (mentionUser) => {
      const mentionUsersAux = new Map(get().mentionUsers);

      mentionUsersAux.set(mentionUser.id, mentionUser);

      set({ mentionUsers: mentionUsersAux });
    },
    // Reset all data except data that is not specific to the current conversation
    resetCurrentConversation: () =>
      set({ ...initialState, mentionUsers: get().mentionUsers }),
  }))
);

export const useConversationStore = conversationStore;
