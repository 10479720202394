import styled from 'styled-components';

const BetaLogoText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 2px;
  margin-left: -5px;
  margin-right: 10px;
`;

export const Styled = {
  BetaLogoText,
};
