import { ErrorBanner } from 'Components/shared/ErrorBanner';
import { TextButton } from 'Components/shared/TextButton';
import { Channel as ChannelContainer } from 'Containers/Channel';
import { ContextPanelProps } from 'Containers/ContextPanel/interfaces';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { ADDRESS_BOOK_SUBPATHS } from '../../constants/routing';
import { RootStoreProps } from '../../stores/RootStore.types';
import AddNewContact from '../AddNewContact';
import AllContacts from '../AllContacts';
import ContactsAddressBook from '../ContactsAddressBook';
import { ContentArea } from '../shared/ContentArea';
import { SourcesAddressBook } from '../SourcesAddressBook';

export default observer((props: ContextPanelProps) => {
  const { person, ui } = React.useContext<RootStoreProps>(MobXProviderContext);

  const handleCloseError = () =>
    person.setErrorNewContact({
      show: false,
      message: '',
      field: '',
    });

  useEffect(() => {
    const {
      allSources: sourceList,
      allContacts: contactList,
      loadingContacts,
      getAllContacts,
      getSourcesIfMissing,
    } = person;

    if (contactList.length === 0 && !loadingContacts) {
      void getAllContacts();
    }
    if (sourceList.length === 0) {
      void getSourcesIfMissing();
    }
  }, []);

  useEffect(() => {
    person.newContactErrors.show &&
      document.getElementById('addressBook-container').scrollTo(0, 0);
  });

  const testid = `${props.testid}-addressBookPanel`;

  return (
    <ContentArea>
      <ErrorBanner
        show={person.newContactErrors.show}
        action={
          <TextButton
            variant="inverted"
            testid={`${testid}-errorBanner-buttonClose`}
            onClick={handleCloseError}
          >
            Close
          </TextButton>
        }
        content={person.newContactErrors.message}
      />
      <Grid.Column className="addressBook-container" id="addressBook-container">
        <ChannelContainer
          openGroupModal={ui.groupModalOpen}
          {...props}
          {...props.browserHistory}
        />
        <Routes>
          {/** path = /addressBook/* */}
          <Route path="contact" element={<AddNewContact />} />
          <Route
            path="contacts"
            element={
              person.hasContacts ? <AllContacts /> : <ContactsAddressBook />
            }
          />
          <Route
            path="sources"
            element={
              <SourcesAddressBook
                {...props}
                titleFor="contacts"
                testId={`${testid}`}
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to={ADDRESS_BOOK_SUBPATHS.CONTACTS} replace />}
          />
        </Routes>
      </Grid.Column>
    </ContentArea>
  );
});
