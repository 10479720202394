import { INTERCOM_SETTINGS } from "../constants/env";
import { IPersonSignInTokenResponse } from "../models/PersonModel";

export const startSurvey = (surveyId: number) => {
  // @ts-ignore
  window?.Intercom?.('startSurvey', surveyId);
}

export const boot = (person: IPersonSignInTokenResponse, jwt: any) => {
  // @ts-ignore
  window?.Intercom?.('boot', {
    app_id: INTERCOM_SETTINGS.appId,
    account_id: person.account.id, // "account_id" in JWT
    location_id: jwt.location_id, // "location_id" in JWT
    person_id: person.id, // "person_id" in JWT
    email: person.email, // User's email
    name: `${person.firstName} ${person.lastName}`, // User's name
    seat_type: person.package, // "acctp" in JWT
    role: jwt.acl, // "acl" in JWT
    app_name: 'communicator' //app description
  });
}

export const update = () => {
  // @ts-ignore
  window?.Intercom?.('update');
}

export const shutdown = () => {
  // @ts-ignore
  window?.Intercom?.('shutdown')
}
