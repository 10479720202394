import styled from 'styled-components';

const CodeSnippet = styled.pre`
  display: inline-block;
  vertical-align: text-top;
  width: auto;
  padding: 1px 5px !important;
  margin: 0px !important;
`;

export const Styled = {
  CodeSnippet,
};
