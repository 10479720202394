import type { UserDropdownMenuProps } from './UserDropdownMenu.types';
import { Auth0Context } from '@auth0/auth0-react';
import { FeatureFlag } from 'Components/shared/FeatureFlag';
import { PopOverMenuOption } from 'Components/shared/PopOverMenuOption';
import { HELP_URL } from 'Constants/env';
import { REFRESH_TOKEN } from 'Constants/featureFlags';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { bugsnagClient } from 'Utils/logUtils';

export const UserDropdownMenu: React.FC<UserDropdownMenuProps> = ({
  setShowHeaderDropdown,
  setShowMessageStatusModal,
  testid,
}) => {
  const { person } = React.useContext<RootStoreProps>(MobXProviderContext);
  const auth0 = React.useContext(Auth0Context);
  const navigate = useNavigate();

  const closeOnOptionClick = async (callback?: () => void | Promise<void>) => {
    await callback?.();
    setShowHeaderDropdown(false);
  };

  const handleLogoutClick = async () => {
    await person.logout();
  };

  const handleRefreshTokenClick = async () => {
    try {
      person?.setRefreshingToken(true);
      await auth0.getAccessTokenSilently({ ignoreCache: true });
    } catch (error) {
      bugsnagClient.notify(error, (event) => {
        event.severity = 'error';
        event.context = 'handleRefreshTokenClick';
        event.addMetadata('custom', {
          function: 'getAccessTokenSilently',
        });
      });
      auth0.logout({ returnTo: window.location.origin });
    }
    person?.setRefreshingToken(false);
  };

  return (
    <React.Fragment>
      <PopOverMenuOption
        testid={`${testid}-dropdownOptionSettings`}
        onClick={() => closeOnOptionClick(() => navigate('/settings'))}
      >
        Settings
      </PopOverMenuOption>
      <PopOverMenuOption
        onClick={() =>
          closeOnOptionClick(() => setShowMessageStatusModal(true))
        }
        testid={`${testid}-dropdownOptionAvailability`}
      >
        Status & Availability
      </PopOverMenuOption>
      {HELP_URL && (
        <PopOverMenuOption
          testid={`${testid}-dropdownOptionHelp`}
          href={HELP_URL}
          onClick={closeOnOptionClick}
        >
          Help
        </PopOverMenuOption>
      )}
      <PopOverMenuOption
        testid={`${testid}-dropdownOptionLogout`}
        onClick={() => closeOnOptionClick(handleLogoutClick)}
        destructive
      >
        Logout
      </PopOverMenuOption>
      <FeatureFlag flag={REFRESH_TOKEN}>
        <PopOverMenuOption
          testid={`${testid}-dropdownOptionRefreshToken`}
          onClick={() => closeOnOptionClick(handleRefreshTokenClick)}
        >
          Refresh Token
        </PopOverMenuOption>
      </FeatureFlag>
    </React.Fragment>
  );
};
