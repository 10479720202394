import type { TopBarUserProps } from '../interfaces';
import { ModalMessageStatus } from 'Components/ModalMessageStatus';
import { IconSvg } from 'Components/shared/Icon';
import { PopOverMenu } from 'Components/shared/PopOverMenu';
import { SipConnectionStatus } from 'Components/TopBar/SipConnectionStatus';
import { IS_BETA_ENV, COMMUNICATOR_CURRENT_VERSION_URL } from 'Constants/env';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { Grid, Message } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';
import { TopBarProfileStatusButton } from './TopBarProfileStatusButton';
import { UserDropdownMenu } from './UserDropdownMenu';

export const TopBarUser: React.FC<TopBarUserProps> = observer(
  ({ testid: parentTestid }) => {
    const { ui, person, message } =
      React.useContext<RootStoreProps>(MobXProviderContext);

    const [showMessageStatusModal, setShowMessageStatusModal] =
      React.useState(false);
    const [showHeaderDropdown, setShowHeaderDropdown] = React.useState(false);

    const testid = `${parentTestid}-topBarUser`;

    return (
      <Grid.Column className="profile-menu-wrapper">
        <Grid.Column className="app-header-user">
          <Styled.RightHeaderWrapper>
            {IS_BETA_ENV && (
              <Styled.BetaRef
                href={COMMUNICATOR_CURRENT_VERSION_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.BetaButton>
                  <Styled.BetaIcon>{IconSvg['resync']}</Styled.BetaIcon>
                  <Styled.BetaText>Switch to current version</Styled.BetaText>
                </Styled.BetaButton>
              </Styled.BetaRef>
            )}
            {person.selectPersonById(person.loggedInPersonId)?.case({
              pending: () => <strong>Loading...</strong>,
              rejected: () => (
                <Message visible error content="Failed to load Person " />
              ),
              fulfilled: ({ data }) => (
                <>
                  <SipConnectionStatus {...{ testid }} />
                  <PopOverMenu
                    trigger={
                      <TopBarProfileStatusButton
                        {...{ testid }}
                        handleToggleHeader={() =>
                          setShowHeaderDropdown(!showHeaderDropdown)
                        }
                        displayName={data.DisplayName}
                      />
                    }
                    content={
                      <UserDropdownMenu
                        {...{
                          setShowHeaderDropdown,
                          setShowMessageStatusModal,
                          testid,
                        }}
                      />
                    }
                    show={showHeaderDropdown}
                    onOutsideClick={() => setShowHeaderDropdown(false)}
                    position="bottom right"
                  />
                </>
              ),
            })}
          </Styled.RightHeaderWrapper>
          <ModalMessageStatus
            personStore={person}
            message={message}
            uiStore={ui}
            open={showMessageStatusModal}
            onClose={() => setShowMessageStatusModal(!showMessageStatusModal)}
            size="tiny"
          />
        </Grid.Column>
      </Grid.Column>
    );
  }
);
