import { bugsnagClient } from './logUtils';

export const logError = (
  error: any,
  errorMessage: string,
  context: 'PhoneStore' | 'PhoneCallModel' | 'PusherStore',
  whereItHappened: string,
  severity: 'info' | 'warning' | 'error' = 'error',
) => {
  bugsnagClient.notify(errorMessage, (event) => {
    event.severity = severity;
    event.context = context;
    event.addMetadata('custom', { function: whereItHappened });
    event.request = error;
  });
};

