import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const DirectoryRowWrapper = styled(Grid.Row)`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  align-items: center !important;
`;

export const Styled = {
  DirectoryRowWrapper,
};
