import type { StartVideoCallButtonProps } from './StartVideoCallButton.types';
import * as React from 'react';
import { selectProperVideoTooltipMessageForOneOnOnes } from 'Utils/videoConferenceUtils';

import { IconButton } from '../shared/IconButton';

export const StartVideoCallButton: React.FC<StartVideoCallButtonProps> = ({
  activeConference,
  darkDisabledVidIcon,
  isUserInActiveVideoConference,
  loggedInPersonVideoFeature,
  placeVideoCallConference,
  popupPosition,
  size,
  testid,
}) => (
  <IconButton
    onClick={placeVideoCallConference}
    content={selectProperVideoTooltipMessageForOneOnOnes(
      !!activeConference,
      null
    )}
    disabled={{
      title: 'Video conference unavailable',
      condition:
        darkDisabledVidIcon ||
        isUserInActiveVideoConference ||
        !loggedInPersonVideoFeature.enabled,
    }}
    icon="video"
    testid={`${testid}-buttonStartVideoCall`}
    {...{ popupPosition, size }}
  />
);
