import closeBtn from 'Assets/images/close-btn.svg';
import pinnedIcon from 'Assets/images/pinned-icon.svg';
import { UserAvatar } from 'Components/shared/UserAvatar';
import {
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_UI,
} from 'Constants/stores';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';

import moment from 'moment-timezone';
import React from 'react';
import { isNullOrUndefined } from 'util';
import { Styled } from './index.styles';
import { IPinnedMessagesProps } from './interfaces';

class PinnedMessages extends React.Component<IPinnedMessagesProps> {
  handleClose = () => {
    const { ui } = this.props;
    ui.setActiveConversationIdPinnMess('');
    ui.removeFromOpenedRightSidebarsOrder('pinned-messages');
  };

  handleDeletePinnedMess = (messageId) => () => {
    const { conversation } = this.props;
    conversation.deletePinnedMessages(
      conversation.CurrentConversation.id,
      messageId
    );
  };

  handleFocusMessage = (messageId) => () =>
    document
      .getElementById(messageId)
      .scrollIntoView({ block: 'center', behavior: 'smooth' });

  render() {
    const {
      participant: { updateMyLastReadMessage },
      ui: {
        listOfPinnedMessages,
        activePinnedConversationId,
        selectPersonPresenceStatus,
        setConversationAndTotalUnreadCount,
        selectConversationUnreadCounts,
        openedRightSidebarsOrder,
      },
      person,
    } = this.props;
    const pinnedMessages = listOfPinnedMessages.get(activePinnedConversationId);
    return (
      <Styled.PinnedMessages
        $zIndex={openedRightSidebarsOrder.get('pinned-messages')}
        className={`nogrow pinned-sidebar ${
          activePinnedConversationId ? 'visible' : 'hidden'
        }`}
      >
        {activePinnedConversationId && (
          <>
            <div className="header">
              <div>
                <span>Pinned</span>
                <span>{pinnedMessages?.length}</span>
              </div>
              <img src={closeBtn} onClick={this.handleClose} />
            </div>
            <div className="content">
              {!isEmpty(pinnedMessages) &&
              !isNullOrUndefined(pinnedMessages) ? (
                pinnedMessages?.map((message, index) => {
                  const personPbo = person.loadPersonByIdGetIfMissingGet(
                    message.pinnedByPersonId
                  );
                  const date = moment(message.pinnedAt).format('hh:mm A');
                  let callText = message.call ? '' : null;
                  const callPreText =
                    message.call && !message.call.text
                      ? message.call?.direction === 'Incoming'
                        ? 'Call from:'
                        : 'Called'
                      : null;
                  if (message.call?.to?.phone) {
                    callText = `${callPreText} ${message.call.to.phone}`;
                  } else if (message.call?.text) {
                    callText = message.call.text;
                  } else if (message.call?.to?.personId) {
                    const personPbo =
                      message.call?.direction === 'Incoming'
                        ? person.selectPersonById(message.call.from.personId)
                        : person.selectPersonById(message.call.to.personId);
                    personPbo.case({
                      fulfilled: (resp) =>
                        (callText = `${callPreText} ${resp.data.DisplayName}`),
                    });
                  }
                  const pinnedDocuments = message.documents
                    ? message.documents
                        ?.map((document) => document.description)
                        .join('\n')
                    : '';
                  const createdConv = message.systemEvent?.eventType
                    ?.split('.')
                    .join(' ');
                  const properMessage =
                    message.chat?.text ||
                    message.sms?.text ||
                    message.conference?.displayName ||
                    callText ||
                    pinnedDocuments ||
                    createdConv;
                  return personPbo.case({
                    fulfilled: (resp) => {
                      return (
                        <div className="pinned-mess-box" key={message.id}>
                          <div className="flex-row flex-justify-space-between flex-align-items-center">
                            <UserAvatar
                              name={resp.data.DisplayName}
                              showUnread={false}
                              conversationId="0"
                              selectUnreadCounts={
                                selectConversationUnreadCounts
                              }
                              presence={selectPersonPresenceStatus(
                                message.pinnedByPersonId
                              )}
                              setConversationAndTotalUnreadCount={
                                setConversationAndTotalUnreadCount
                              }
                              updateMyLastReadMessage={updateMyLastReadMessage}
                            />
                            <span className="date">{date}</span>
                            <img
                              className="close-btn pointer"
                              src={closeBtn}
                              onClick={this.handleDeletePinnedMess(message.id)}
                            />
                          </div>
                          <div
                            data-private
                            className={`flex-row message ${
                              pinnedDocuments ? 'single-line-string' : ''
                            }`}
                          >
                            {properMessage}
                          </div>
                          <div
                            className="link pointer"
                            onClick={this.handleFocusMessage(message.id)}
                          >
                            View Message
                          </div>
                        </div>
                      );
                    },
                  });
                })
              ) : (
                <div className="flex-row no-pinned-mess">
                  No messages have been pinned yet! <img src={pinnedIcon} />
                </div>
              )}
            </div>
          </>
        )}
      </Styled.PinnedMessages>
    );
  }
}

export default inject(
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONVERSATION,
  STORE_PERSON
)(observer(PinnedMessages));
