import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';
import { CallButtonsProps } from './interfaces';

const endCallIcon = require('../../assets/images/icon-phonecalls.svg');

/**
 * Call Buttons component
 * Will handle both Incoming/Outgoing cases
 * @export
 * @class CallButtons
 */
export class CallButtons extends React.Component<CallButtonsProps> {
  render() {
    const { inbound, lineActions } = this.props;
    return inbound ? (
      <Grid.Column className="call-buttons" textAlign="center">
        <div className="btn-wrapper">
          <Button
            onClick={lineActions.pickup}
            circular
            className="answer-call-btn"
          >
            <Button.Content visible>
              <Image
                src={endCallIcon}
                style={{ transform: 'rotateZ(-90deg)' }}
              />
            </Button.Content>
          </Button>
          <span>Accept</span>
        </div>
        <div className="btn-wrapper">
          <Button onClick={lineActions.reject} circular>
            <Button.Content visible>
              <Image src={endCallIcon} />
            </Button.Content>
          </Button>
          <span>Reject</span>
        </div>
      </Grid.Column>
    ) : (
      <Grid.Column className="call-buttons" textAlign="center">
        <div className="btn-wrapper">
          {
            <>
              <Button
                onClick={lineActions.cancel}
                circular
                className="end-call"
              >
                <Button.Content visible>
                  <Image src={endCallIcon} />
                </Button.Content>
              </Button>
              <span className="btn-text">Cancel</span>
            </>
          }
        </div>
      </Grid.Column>
    );
  }
}

export default observer(CallButtons);
