import { isEmpty } from 'lodash';

export const CONVERSATION_TOPIC_REGEXP = /^[a-zA-Z][a-zA-Z0-9_\-]*$/;
export const CONFERENCE_ID_REGEXP = /^[a-zA-Z][a-zA-Z0-9_.\-]*$/;
export const CONVERSATION_TOPIC_MAXLENGTH = 50;
export const CONVERSATION_DESCRIPTION_MAXLENGTH = 200;

export const UUID_REGEXP =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/** Given a full `location.pathname`, determine if it starts with `/conversations/{valid UUID}` */
export const TEST_CONVERSATION_PATH_START = (locationPathname: string) => {
  // If null/empty/undefined or the last `/` is at the start (or not present), this path fails
  if (isEmpty(locationPathname) || locationPathname.lastIndexOf('/') <= 0) {
    return false;
  }
  const pathSplit = locationPathname.trim().split('/');
  return (
    pathSplit.length >= 4 &&
    pathSplit[2] === 'conversations' &&
    UUID_REGEXP.test(pathSplit[3])
  );
};

export const MENTION_REGEXP = /@\w*$/i;
