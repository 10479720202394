import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import { Contact } from '../../models/Contacts';
import { formatNumberWithNationalCode } from '../../utils/phoneUtil';
import { Styled } from './index.styles';
import { EntityProfileComponentProps } from './interfaces';

const EntityProfileHeader: React.FC<EntityProfileComponentProps> = ({
  contact,
  person,
  uiStore,
  extrContacts,
  navigate,
  getExtrContactByPhoneNumber,
  setIsAddingContact,
  setEditContact,
}) => {
  const [state, setState] = useState<{ extrContacts: Contact[] }>({
    extrContacts: null,
  });

  const refreshContactData = useCallback(() => {
    const extrContactsMerged = extrContacts
      ? extrContacts
      : contact
      ? [getExtrContactByPhoneNumber(contact.phoneNumber)].filter(
          (contact) => contact
        )
      : [];
    if (!state.extrContacts && extrContactsMerged?.length > 0) {
      setState({ extrContacts: extrContactsMerged });
    }
  }, [contact, extrContacts, getExtrContactByPhoneNumber, state.extrContacts]);

  useEffect(() => {
    refreshContactData();
  }, [refreshContactData]);

  const mapName = useCallback(
    (returnName?: boolean) => {
      const checkIfExtrContact =
        contact?.phoneNumber &&
        getExtrContactByPhoneNumber(contact?.phoneNumber);
      if (extrContacts || checkIfExtrContact) {
        return (
          checkIfExtrContact?.DisplayName() || extrContacts[0]?.DisplayName()
        );
      } else if (person) {
        return person.DisplayName;
      } else if (!person && !extrContacts && contact) {
        return returnName && contact.DisplayName;
      }
      return '';
    },
    [contact, extrContacts, getExtrContactByPhoneNumber, person]
  );

  const addExtrContact = (e) => {
    e.preventDefault();
    const newExtrContact = new Contact({
      id: null,
      providerContactId: null,
      providerAccountId: null,
      phoneNumbers: [{ id: null, type: '', number: contact.phoneNumber }],
      emails: [],
      firstName: null,
      lastName: null,
      middleName: null,
      organization: null,
      organizationRole: null,
      createdAt: null,
      updatedAt: null,
      platformUserId: null,
      platformAccountId: null,
      hasProfilePicture: null,
      notes: null,
      isShared: null,
      pictureKey: null,
    });
    setIsAddingContact(true);
    setEditContact(newExtrContact);
    navigate('/addressBook/contact', { state: { from: '/chat' } });
  };

  const mapProperImage = useCallback(() => {
    if (person) {
      return person?.profilePictureUrl || '';
    } else if (state.extrContacts) {
      const extrContact = state.extrContacts[0];
      return extrContact?.pictureUrl || '';
    }
    return '';
  }, [person, state.extrContacts]);

  const name = mapName();
  const image = mapProperImage();
  const contactName = contact && formatNumberWithNationalCode(mapName(true));
  const messageStatus = person && uiStore.selectPersonMessageStatus(person.id);
  const presence = person && uiStore.selectPersonPresenceStatus(person?.id);
  return (
    <Styled.EntityProfileHeader id="entity-profile-header">
      <Styled.UserAvatar
        image={image}
        name={name}
        selectUnreadCounts={uiStore.selectConversationUnreadCounts}
        presence={presence}
        key={`entity-profile-avatar-${person?.id}-${presence?.state}`}
      />
      {!isEmpty(person) && (
        <Styled.DisplayName data-private>
          {name}
          <Popup
            inverted
            trigger={
              <span className="message-status">
                {messageStatus?.title || ''}
              </span>
            }
            content={messageStatus?.message || ''}
            position={'top right'}
          />
        </Styled.DisplayName>
      )}
      {!isEmpty(contact) && !state.extrContacts && !person && (
        <Styled.DisplayName data-private>
          {contactName ? contactName : mapName(true)}
          <Popup
            inverted
            trigger={
              <span className="message-status">
                {messageStatus?.title || ''}
              </span>
            }
            content={messageStatus?.message || ''}
            position={'top right'}
          />
          <div className="extr-contact-info">
            <div onClick={addExtrContact} className="edit-extr-button">
              Add Contact
            </div>
          </div>
        </Styled.DisplayName>
      )}
      {!isEmpty(state.extrContacts) && !person && name && (
        <Styled.DisplayName data-private>
          {name}
          <Popup
            inverted
            trigger={
              <span className="message-status">
                {messageStatus?.title || ''}
              </span>
            }
            content={messageStatus?.message || ''}
            position={'top right'}
          />
        </Styled.DisplayName>
      )}
    </Styled.EntityProfileHeader>
  );
};

export default withRouter(observer(EntityProfileHeader));
