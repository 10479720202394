import cx from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react';
import { IAtMentionHereProps } from './interfaces';

/**
 * @here item in Mention list
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 */
export class AtMentionHereItem extends React.Component<IAtMentionHereProps> {
  componentDidUpdate(prevProps: IAtMentionHereProps) {
    if (
      this.props.isSelected &&
      this.props.isSelected !== prevProps.isSelected
    ) {
      const itemDomNode = ReactDOM.findDOMNode(this.itemRef) as HTMLDivElement;
      itemDomNode.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }

  itemRef: React.Component<DropdownItemProps>;
  private registerRef = (di: React.Component<DropdownItemProps>) =>
    (this.itemRef = di);

  render() {
    const { isSelected, onHereClick } = this.props;
    return (
      <Dropdown.Item
        className={cx('user-mention-row', { selected: isSelected })}
        key="here"
        id="ammi-here"
        onClick={onHereClick}
        ref={this.registerRef}
      >
        <Icon
          className="exclamation-triangle"
          size="large"
          name="exclamation triangle"
        />
        <div className="mention-text-wrapper flex-grow">
          <div className="name-reserved">@here</div>
        </div>
        <div className="mention-text-wrapper flex-grow">
          <div data-value="here" className="name notifyMessage">
            This will notify everyone in this Channel
          </div>
        </div>
      </Dropdown.Item>
    );
  }
}

export default observer(AtMentionHereItem);
