import type { IconButtonProps } from './types';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { Button } from 'Components/shared/Button';
import { Icon } from 'Components/shared/Icon';
import type { SemanticSIZES } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const ICON_BUTTON_SIZES = {
  mini: '20px',
  medium: '40px',
};

const ICON_BUTTON_VARIANTS = {
  primary: css<{ disabled: boolean; size: SemanticSIZES }>`
    ${({ theme, disabled, size }) => css`
      color: ${theme.colors.base} !important;
      border-radius: 50% !important;
      border: 1px solid ${theme.colors.primary} !important;
      background: ${theme.colors.primary} !important;
      padding: ${theme.sizes.xxxsmall} !important;
      &:hover {
        border: 1px solid ${theme.colors.linkHover} !important;
        background: ${theme.colors.linkHover} !important;
      }
      &:active {
        border: 1px solid ${theme.colors.primaryClick} !important;
        background: ${theme.colors.primaryClick} !important;
      }
      ${disabled &&
      css`
        &,
        &:hover,
        &:active {
          border: 1px solid ${theme.colors.primaryDisabled} !important;
          background: ${theme.colors.primaryDisabled} !important;
        }
      `}
      ${(size === 'mini' || size === 'medium') &&
      css`
        height: ${ICON_BUTTON_SIZES[size]} !important;
        width: ${ICON_BUTTON_SIZES[size]} !important;
      `}
    `}
  `,
  secondary: css<{ $active: boolean; disabled: boolean; size: SemanticSIZES }>`
    ${({ theme, $active, disabled, size }) => css`
      color: ${theme.colors.text} !important;
      border-radius: 50% !important;
      border: 1px solid ${theme.colors.border} !important;
      background: ${theme.colors.base} !important;
      padding: ${theme.sizes.xxxsmall} !important;
      &:hover {
        border: 1px solid ${theme.colors.buttonSecondaryBorder} !important;
        background: ${theme.colors.border} !important;
      }
      &:active {
        border: 1px solid ${theme.colors.buttonSecondaryClickBorder} !important;
        background: ${theme.colors.buttonSecondaryClick} !important;
      }
      ${$active &&
      css`
        border: 1px solid ${theme.colors.buttonSecondarySelected} !important;
        background: ${theme.colors.buttonSecondarySelected} !important;
        color: ${theme.colors.base} !important;
        &:hover {
          color: ${theme.colors.text} !important;
        }
      `}
      ${disabled &&
      css`
        &,
        &:hover,
        &:active {
          border: 1px solid ${theme.colors.backgroundHover} !important;
          background: ${theme.colors.base} !important;
          color: ${theme.colors.gray} !important;
        }
      `}
      ${(size === 'mini' || size === 'medium') &&
      css`
        height: ${ICON_BUTTON_SIZES[size]} !important;
        width: ${ICON_BUTTON_SIZES[size]} !important;
      `}
    `}
  `,
  positive: css<{ $active: boolean; disabled: boolean; size: SemanticSIZES }>`
    ${({ theme, $active, disabled, size }) => css`
      color: ${theme.colors.base} !important;
      border-radius: 50% !important;
      background: ${theme.colors.success} !important;
      padding: ${theme.sizes.xxxsmall} !important;
      &:hover {
        background: ${theme.colors.successHover} !important;
      }
      &:active {
        background: ${theme.colors.successClick} !important;
      }
      ${$active &&
      css`
        background: ${theme.colors.successClick} !important;
        color: ${theme.colors.base} !important;
      `}
      ${disabled &&
      css`
        opacity: unset !important;
        &,
        &:hover {
          background: ${theme.colors.successDisabled} !important;
        }
      `}
      ${(size === 'mini' || size === 'medium') &&
      css`
        height: ${ICON_BUTTON_SIZES[size]} !important;
        width: ${ICON_BUTTON_SIZES[size]} !important;
      `}
    `}
  `,
};

const ButtonSafeArea = styled.div<{ $disabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ $disabled }) => $disabled && `cursor: not-allowed;`}
`;

const Badge = styled(BodyCopy)<{ $badge: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: calc(${({ theme }) => theme.sizes.medium} + 2px);
  padding: 0 5px;
  height: 18px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.base};
  border-radius: 10px;
`;

const StyledButton = styled(Button)<{
  $compact: boolean;
  $variant: IconButtonProps['variant'];
}>`
  display: flex !important;
  padding: 0 10px !important;
  margin: 0 !important;
  ${({ $compact }) => $compact && `padding: 0 5px !important;`}
  &&&& {
    ${({ $variant }) => $variant && ICON_BUTTON_VARIANTS[$variant]}
  }
`;

const StyledIcon = styled(Icon)`
  &,
  &:hover,
  &:active {
    color: unset !important;
  }
`;

const LoadingIcon = styled(Icon)`
  &,
  &:hover,
  &:active {
    color: unset !important;
  }

  -webkit-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
`;

export const Styled = {
  ButtonSafeArea,
  Badge,
  Button: StyledButton,
  Icon: StyledIcon,
  LoadingIcon,
};
