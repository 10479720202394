import type { DropDownTopBarProps } from './types';
import blueStarIcon from 'Assets/images/blue-star.svg';
import smsIcon from 'Assets/images/directory-chat.svg';
import makeCallIcon from 'Assets/images/phone-directory.svg';
import videoIcon from 'Assets/images/video-top-bar.svg';
import PhoneNumbersPopup from 'Components/PhoneNumbersPopup';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { TopBarDropdownContext } from 'Components/TopBar/contexts/TopBarDropdownContext';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const DropDownItemTopBar: React.FC<DropDownTopBarProps> = observer(
  ({
    key,
    pictureUrl = null,
    ignoreName = false,
    convType,
    name,
    favorite = false,
    organization = null,
    role = null,
    userInfo = null,
    variant,
  }) => {
    const { makeCall, selection, favoriteList } = React.useContext(
      TopBarDropdownContext
    );
    const {
      ui: { selectConversationUnreadCounts },
      person: { personAvaliableFeatures, loggedInPersonId },
      phoneCall,
      conversation: {
        loadOrCreateConversationWithPost,
        postConferenceByConversationId,
      },
    } = React.useContext<RootStoreProps>(MobXProviderContext);
    const navigate = useNavigate();

    const placeVideoCallConference = (personId, e) => {
      if (!personAvaliableFeatures.video.enabled) return;
      e?.stopPropagation();
      loadOrCreateConversationWithPost(personId).then(({ data }) => {
        navigate(`/chat/conversations/${data.id}/menu`);
        postConferenceByConversationId(data.id.toString());
      });
    };

    const handleOpenChat = (
      data: { personId?: string; phoneNumbers?: string[]; keyValue: string },
      e
    ) => {
      e.stopPropagation();
      if (data?.phoneNumbers?.length > 1) return;
      data?.phoneNumbers?.length
        ? loadOrCreateConversationWithPost(null, data?.phoneNumbers[0]).then(
            ({ data }) => navigate(`/chat/conversations/${data.id}/menu`)
          )
        : loadOrCreateConversationWithPost(data.personId, null).then(
            ({ data }) => navigate(`/chat/conversations/${data.id}/menu`)
          );
    };

    return (
      <div
        className="flex-row flex-align-items-center"
        {...(selection.variant &&
          selection.variant !== variant && {
            title: 'Only company contacts can be grouped',
          })}
      >
        {pictureUrl ? (
          <img className="contact-prof-img" src={pictureUrl} />
        ) : (
          <UserAvatar
            name={ignoreName ? null : name}
            selectUnreadCounts={selectConversationUnreadCounts}
            conversationGrouping={convType || 'OneOnOne'}
          />
        )}
        <div className="user-info">
          {name && (
            <div className="name">
              {name}
              {(favorite || !!favoriteList?.[key]) && (
                <img className="favorite-custom-item" src={blueStarIcon} />
              )}
            </div>
          )}
          {(variant === 'external-contact' || organization || role) && (
            <div className="info">
              {variant === 'external-contact' && 'External contact'}
              {variant === 'external-contact' && (organization || role) && ', '}
              {organization && `${organization}, `}
              {role}
            </div>
          )}
        </div>
        {(!!userInfo?.phoneNumbers?.length || userInfo?.personId) &&
          (!selection.variant || selection.variant === variant) && (
            <div className="aditional-item-buttons">
              {variant !== 'group' && (
                <Popup
                  key="start-chat"
                  inverted
                  position="bottom center"
                  content="Start messaging"
                  trigger={
                    <Button
                      onClick={(e) => handleOpenChat(userInfo, e)}
                      size="small"
                      compact
                      basic
                      id="message-contact"
                      className="flex-row flex-align-items-center"
                    >
                      <img className="icon-18px chat-icon" src={smsIcon} />
                      {userInfo.phoneNumbers?.length > 1 && (
                        <PhoneNumbersPopup
                          phoneNumbers={userInfo.phoneNumbers}
                          callWithPerson={phoneCall.callWithPerson}
                          loadOrCreateConversationWithPost={
                            loadOrCreateConversationWithPost
                          }
                          operationType={'message'}
                          classNames="message-phones-popup"
                        />
                      )}
                    </Button>
                  }
                />
              )}
              {/* Only display call and video call options if the user in question is not the logged in user */}
              {userInfo?.personId !== loggedInPersonId.toString() && (
                <>
                  <Popup
                    key="start-call"
                    inverted
                    content="Start Call"
                    position="bottom center"
                    trigger={
                      <Button
                        onClick={(e) => makeCall(userInfo, e)}
                        size="small"
                        compact
                        id="call-contact"
                        basic
                        className="flex-row align-items-center"
                      >
                        {userInfo.phoneNumbers?.length > 1 && (
                          <PhoneNumbersPopup
                            phoneNumbers={userInfo.phoneNumbers}
                            callWithPerson={phoneCall.callWithPerson}
                            loadOrCreateConversationWithPost={
                              loadOrCreateConversationWithPost
                            }
                            operationType="call"
                            classNames="call-phones-popup"
                          />
                        )}
                        <img id="phone-call" src={makeCallIcon} />
                      </Button>
                    }
                  />
                  {userInfo.personId && (
                    <Popup
                      key="start-video"
                      inverted
                      content="Start video call"
                      position="bottom center"
                      trigger={
                        <Button
                          onClick={(e) =>
                            placeVideoCallConference(userInfo.personId, e)
                          }
                          size="small"
                          compact
                          id="video-call-contact"
                          basic
                          className={`flex-row align-items-center ${
                            personAvaliableFeatures.video?.enabled
                              ? ''
                              : 'disabled-button'
                          }`}
                          disabled={!personAvaliableFeatures.video?.enabled}
                        >
                          <img id="video-icon" src={videoIcon} />
                        </Button>
                      }
                    />
                  )}
                </>
              )}
            </div>
          )}
      </div>
    );
  }
);
