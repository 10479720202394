import styled, { css } from 'styled-components';

const CopyButton = styled.span<{
  $isCopied: boolean;
}>`
  cursor: pointer;
  display: flex;
  margin-left: auto;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #effafb;
    border-radius: 50%;
  }

  ${({ $isCopied }) =>
    $isCopied &&
    css`
      background: #effafb;
      border-radius: 50%;
    `}
`;

export const Styled = {
  CopyButton,
};
