import { CallsDirectoryList } from 'Components/CallLogs/CallsDirectoryList';
import { CallLogsContextWrapper } from 'Components/CallLogs/contexts/CallLogsContextWrapper';
import { SecondaryMenu } from 'Components/shared/SecondaryMenu';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import { CALLS_SUBMENU_OPTIONS } from 'Constants/routing';
import { Channel as ChannelContainer } from 'Containers/Channel';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Grid } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { TYPES_OF_CALLS } from './constants';

export const CallLogs: React.FC = () => {
  const {
    ui: {
      isTouchSupported,
      openTopbarDialpad,
      removeFromOpenedRightSidebarsOrder,
      setDeletePopupDisplay,
      setHideSecondaryMenu,
      setOpenedRightSidebarsOrder,
      setOpenTopbarDialpad,
      hideSecondaryMenu,
    },
    phoneCall: { ActivePhoneCall, incomingPhoneCalls },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  React.useEffect(() => {
    if (isTouchSupported() && window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT)
      setHideSecondaryMenu(false);

    const handleOpenDialpad = () => {
      if (ActivePhoneCall || incomingPhoneCalls.length) return;
      setOpenTopbarDialpad(true);
      setOpenedRightSidebarsOrder('dial-pad');
    };

    const handleCloseDialpad = () => {
      if (openTopbarDialpad) return;
      removeFromOpenedRightSidebarsOrder('dial-pad');
      setOpenTopbarDialpad(false);
    };

    window.innerWidth > 1024 && handleOpenDialpad();

    return () => {
      handleCloseDialpad();
    };
  }, []);

  return (
    <Grid.Column
      className="calls-container flex-row"
      onClick={() => setDeletePopupDisplay(false)}
    >
      <Routes>
        {TYPES_OF_CALLS.map((typeOfCallsId) => (
          <Route
            key={typeOfCallsId}
            path={`/${typeOfCallsId}`}
            element={
              <>
                {!hideSecondaryMenu && (
                  <SecondaryMenu
                    text="Recent calls"
                    options={CALLS_SUBMENU_OPTIONS}
                  />
                )}
                <ChannelContainer />
                <CallLogsContextWrapper {...{ typeOfCallsId }}>
                  <CallsDirectoryList />
                </CallLogsContextWrapper>
              </>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/calls/all" replace />} />
      </Routes>
    </Grid.Column>
  );
};
