import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { List } from 'semantic-ui-react';
import ChannelMemberItemObserver from '../ChannelMemberItem';
import { ChannelMembersListProps } from './interfaces';

/**
 * Renders a `<List>` of `Participant`s in a `Channel`
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelMembersList
 */
export class ChannelMembersList extends React.Component<
  ChannelMembersListProps,
  { emptyField: string }
> {
  render() {
    const {
      uiStore,
      conversationId,
      conversation,
      participantStore,
      person,
      participants,
      contact,
      from,
      setShowPersonDetails,
      isNew,
      getPerson,
      makeCall,
      removeChannelMembers,
      resolveConversationLinkPath,
      pushHistoryPath,
      loggedInPersonId,
    } = this.props;
    return (
      <div
        id="channel-members-list-wrap"
        className="flex-grow-shrink overflow-scroll-y negonerem-marginr"
      >
        <div id="groupName" className="group-modal-topics">
          Group Members
        </div>
        {from === 'group-info' && participants.length > 0 && (
          <List
            id="channel-members-list"
            className="flex-grow-shrink overflow-scroll-y no-hpadding"
            size="large"
            inverted
            selection
          >
            {uniqBy(participants, function (p) {
              return p.id || p.personId || p.phone;
            }).map((p) => {
              return (
                <ChannelMemberItemObserver
                  key={p.id || p.personId || p.phone}
                  isNew={isNew}
                  conversationId={conversationId}
                  participant={p}
                  participantStore={participantStore}
                  getPerson={getPerson}
                  pushHistoryPath={pushHistoryPath}
                  removeChannelMembers={removeChannelMembers}
                  makeCall={makeCall}
                  resolveConversationLinkPath={resolveConversationLinkPath}
                  loggedInPersonId={loggedInPersonId}
                  uiStore={uiStore}
                  conversation={conversation}
                  contact={contact}
                  from={from}
                  setShowPersonDetails={setShowPersonDetails}
                  person={person}
                />
              );
            })}
          </List>
        )}
      </div>
    );
  }
}

export default observer(ChannelMembersList);
