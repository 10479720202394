import type { TopBarProfileStatusButtonProps } from './types';
import arrowDown from 'Assets/images/arrow-down-icon.svg';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { MobXProviderContext, observer } from 'mobx-react';
import { PresenceModel } from 'Models/index';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const TopBarProfileStatusButton: React.FC<TopBarProfileStatusButtonProps> =
  observer(({ handleToggleHeader, displayName, testid }) => {
    const {
      ui: { selectPersonMessageStatus, presenceById },
      person: { loggedInPersonId },
    } = React.useContext<RootStoreProps>(MobXProviderContext);
    const [loggedInPersonPresence, setLoggedInPersonPresence] =
      React.useState<PresenceModel>(
        PresenceModel.CreateOffLineStatus(loggedInPersonId)
      );

    React.useEffect(() => {
      setLoggedInPersonPresence(presenceById?.get(loggedInPersonId.toString()));
    }, [presenceById?.get(loggedInPersonId.toString())?.state]);

    const { title = '', message = '' } =
      selectPersonMessageStatus(loggedInPersonId);

    return (
      <div
        className="user-name-wrap-inner"
        onClick={handleToggleHeader}
        data-testid={`${testid}-buttonProfileStatus`}
      >
        <UserAvatar
          name={displayName}
          {...(loggedInPersonPresence?.state !== 'OffLine' && {
            presence: loggedInPersonPresence,
          })}
        />
        <Popup
          inverted
          trigger={title && <span className="message-status">{title}</span>}
          content={message}
          position="bottom right"
        />
        <img src={arrowDown} className="down-arrow" />
      </div>
    );
  });
