import styled from 'styled-components';


const EmojiWrapper = styled.div<{ $relative: boolean }>`
  ${({ $relative }) =>
    $relative &&
    `
      right: 10px;
      bottom: 0px;
      margin-left: 10px;
      position: relative !important;
    `}
`;

const EmojiIconWrapper = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  z-index: 2;
  cursor: pointer;
`;
const EmojiBox = styled.div`
  z-index: 2;
`;


export const Styled = {
  EmojiWrapper,
  EmojiIconWrapper,
  EmojiBox,
};
