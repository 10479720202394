import { Heading } from 'Components/shared/Heading';
import { ToggleButton } from 'Components/shared/ToggleButton';
import { IS_ELECTRON } from 'Constants/env';
import { usePreferences } from 'Hooks/preferences/usePreferences'; // Import the hook
import React from 'react';
import type { CheckboxProps } from 'semantic-ui-react';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { sendIpcSetOpenOnLogin } from 'Utils/ipcRendererEvents';
import { Styled } from './index.styles';

export const GeneralSettings: React.FC = () => {
  const testid = 'generalSettings';
  const {
    preferences,
    preferencesQuery,
    updatePreferences,
    desktopLocalPreferences,
    updateOpenOnLogin,
  } = usePreferences();

  if (preferencesQuery.isPending) return <div>Loading...</div>;
  if (preferencesQuery.isError) return <div>Error loading preferences</div>;

  const togglePreference = (preferenceName: keyof typeof preferences) => {
    updatePreferences.mutate({
      [preferenceName]: !preferences[preferenceName],
    });
  };

  const onToggleOpenOnLogin = (_e, data: CheckboxProps) => {
    void updateOpenOnLogin(data.checked); // we set the query data with the new setting value
    sendIpcSetOpenOnLogin(data.checked);
    pushToGTMDataLayer('toggleOpenOnLogin');
  };

  return (
    <div className="settings-general">
      <Heading variant="h2">General settings</Heading>
      <div className="flex-column flex-justify-space-between settings-general--wrapper">
        <div>
          {IS_ELECTRON && window['ipcRenderer'] !== undefined && (
            <div>
              <Heading variant="h3">Application</Heading>
              <div className="flex-column flex-justify-space-between">
                <span className="sub-headers margin-bottom-2rem">
                  Open on login
                </span>
                <ToggleButton
                  label="Open Communicator when you log in to this computer (applies to desktop app only)"
                  checked={desktopLocalPreferences.openOnLogin}
                  onChange={onToggleOpenOnLogin}
                  testid={`${testid}-toggleButtonOpenOnLogin`}
                />
              </div>
            </div>
          )}
          <Styled.SettingsOptions>
            <Heading variant="h3">Chat options</Heading>
            <Styled.ToggleButton
              label="Enable call history in chat conversations (beta)"
              checked={preferences.showCallMessagesInChat}
              onChange={() => togglePreference('showCallMessagesInChat')}
              helperText="Disabling call history hides all calls in chats. For now, only calls made on this device will be visible on the calls tab."
              testid={`${testid}-toggleButtonCallHistoryInChatConversations`}
            />
            <Styled.ToggleButton
              label="List conversations with unread messages first"
              checked={preferences.listUnreadFirst}
              onChange={() => togglePreference('listUnreadFirst')}
              testid={`${testid}-toggleButtonUnreadMessagesFirst`}
            />
          </Styled.SettingsOptions>
          <Styled.SettingsOptions>
            <Heading variant="h3">Softphone</Heading>
            <Styled.ToggleButton
              label="Fix softphone on the screen"
              checked={preferences.floatingSoftphone}
              onChange={() => togglePreference('floatingSoftphone')}
              testid={`${testid}-toggleButtonFixSoftPhoneOnScreen`}
            />
          </Styled.SettingsOptions>
        </div>
      </div>
    </div>
  );
};
