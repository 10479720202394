import type { ButtonProps } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const ICON_SIZES = {
  mini: '10px',
  tiny: '15px',
  small: '20px',
  medium: '25px',
  large: '30px',
  big: '35px',
  huge: '40px',
  massive: '45px',
};

const StyledIcon = styled.div<{ $size: ButtonProps['size'] }>`
  color: ${({ theme }) => theme.colors.text} !important;
  ${({ $size }) =>
    css`
      min-width: ${ICON_SIZES[$size]};
      min-height: ${ICON_SIZES[$size]};
      width: ${ICON_SIZES[$size]};
      height: ${ICON_SIZES[$size]};
      max-width: ${ICON_SIZES[$size]};
      max-height: ${ICON_SIZES[$size]};
    `}
`;

export const Styled = {
  Icon: StyledIcon,
};
