import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  * {
  }
`;

export const FlexColumn = css`
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
`;

export const FlexRow = css`
  display: flex !important;
  flex-direction: row;
`;
