import { ContentArea } from 'Components/shared/ContentArea';
import { SecondaryMenu } from 'Components/shared/SecondaryMenu';
import { SETTINGS_SUBPATHS, SETTINGS_MENU_OPTIONS } from 'Constants/routing';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Grid } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { AudioVideoSettings } from './AudioVideoSettings';
import { GeneralSettings } from './GeneralSettings';
import { NotificationsSettings } from './NotificationsSettings';
import { ProfileSettings } from './ProfileSettings';
import { SocialAccounts } from './SocialAccounts';

export const Settings: React.FC = observer(() => {
  const { person, ui } = React.useContext<RootStoreProps>(MobXProviderContext);

  return (
    <Grid.Column
      className="settings-container flex-row"
      onClick={() => ui.setDeletePopupDisplay(false)}
    >
      {!ui.hideSecondaryMenu && (
        <SecondaryMenu text="Settings" options={SETTINGS_MENU_OPTIONS} />
      )}
      <ContentArea>
        <Routes>
          {/** path = /settings/* */}
          <Route path="profile" element={<ProfileSettings />} />
          <Route path="general" element={<GeneralSettings />} />
          {person.personAvaliableFeatures.socialAccounts && (
            <Route path="social-accounts" element={<SocialAccounts />} />
          )}
          <Route path="notifications" element={<NotificationsSettings />} />
          <Route path="audio-video" element={<AudioVideoSettings />} />
          <Route
            path="phone"
            element={<Navigate to={SETTINGS_SUBPATHS.PROFILE} />}
          />
          <Route
            path="voicemails"
            element={<Navigate to={SETTINGS_SUBPATHS.PROFILE} />}
          />
          <Route
            path="*"
            element={<Navigate to={SETTINGS_SUBPATHS.PROFILE} replace />}
          />
        </Routes>
      </ContentArea>
    </Grid.Column>
  );
});
