import type { PopOverMenuOptionProps } from './types';
import React from 'react';
import { Styled } from './index.styles';

export const PopOverMenuOption: React.FC<PopOverMenuOptionProps> = ({
  children,
  destructive = false,
  href,
  onClick,
  testid,
}) => (
  <div>
    <Styled.Button
      {...(href && { href, target: '_blank', rel: 'noreferrer' })}
      {...(onClick && { onClick })}
      $destructive={destructive}
      data-testid={testid}
      compact
      basic
    >
      {children}
    </Styled.Button>
  </div>
);
