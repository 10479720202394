import CallHoldingListItem from 'Components/CallHoldingListItem';
import { observer } from 'mobx-react';
import * as React from 'react';

import { CallHoldingListProps } from './interfaces';

export class CallHoldingList extends React.Component<CallHoldingListProps, {}> {
  render() {
    const { phoneStore, phoneCallsList } = this.props;
    return (
      <div id="call-holding-list-wrap">
        <ul id="call-holding-list">
          {phoneCallsList.length > 0 &&
            !phoneStore.ActivePhoneCall.isWarmTransfer &&
            phoneCallsList
              .slice()
              .reverse()
              .slice(1)
              .map((mp, index) => {
                return (
                  <CallHoldingListItem
                    key={`item-${index}`}
                    phoneStore={phoneStore}
                    phoneCall={mp}
                  />
                );
              })}
          {phoneStore.ActivePhoneCall.isWarmTransfer && (
            <CallHoldingListItem
              phoneStore={phoneStore}
              phoneCall={phoneStore.warmTransferOld}
            />
          )}
        </ul>
      </div>
    );
  }
}

export default observer(CallHoldingList);
