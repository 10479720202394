import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import { SecondaryMenu } from '../../components/shared/SecondaryMenu';
import VideoPanel from '../../components/VideoPanel';
import { VIDEO_SUBMENU_OPTIONS, VIDEO_SUBPATHS } from '../../constants/routing';
import { STORE_PERSON, STORE_UI } from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import { PersonStore, PhoneStore, UiStore } from '../../stores';
import type { WithRouterWrapperProps } from 'Interfaces/container';

class VideoContainer extends React.Component<
  {
    ui: UiStore;
    person: PersonStore;
    match: any;
    phoneCall: PhoneStore;
    browserHistory: any;
  } & WithRouterWrapperProps
> {
  handleCloseModals = () => {
    this.handleCloseDeletePopup();
  };

  handleCloseDeletePopup = () => this.props.ui.setDeletePopupDisplay(false);

  render() {
    const { ui } = this.props;
    return (
      <Grid.Column
        width={ui.isVideoChatHistoryOpened ? ui.containerWidth() : null}
        className="video-container flex-row"
        onClick={this.handleCloseModals}
      >
        {!this.props.ui.hideSecondaryMenu && (
          <SecondaryMenu
            text="Create meeting"
            options={VIDEO_SUBMENU_OPTIONS}
            header={{
              onClick: () => this.props.navigate(VIDEO_SUBPATHS.NEW_ROOM),
              active: window.location.pathname.includes(
                VIDEO_SUBPATHS.NEW_ROOM
              ),
            }}
          />
        )}
        <VideoPanel {...this.props} />
      </Grid.Column>
    );
  }
}

export default inject(
  STORE_UI,
  STORE_PERSON
)(withRouter(observer(VideoContainer)));
