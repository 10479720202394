import type { SystemMessageProps } from './SystemMessageWrapper.types';
import { useEnumerateSystemMessageParticipants } from 'Components/Chat/hooks/useEnumerateSystemMessageParticipants';
import React from 'react';
import { SystemMessageWrapper } from './SystemMessageWrapper';

export const SystemMessageRemoveUser: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { isAuthorAlsoTheParticipant, formattedAuthor, formattedParticipants } =
    useEnumerateSystemMessageParticipants(
      message.systemEvent.reference,
      message?.systemEvent?.payload?.participantsRemoved
    );

  return (
    <SystemMessageWrapper
      created={message.created}
      icon="remove-user"
      title={[
        formattedAuthor,
        ...(isAuthorAlsoTheParticipant
          ? [' left the group']
          : [' removed ', formattedParticipants, ' from the group']),
      ]}
    />
  );
};
