import styled from 'styled-components';

const Mention = styled.span<{ $unknown?: boolean }>`
  background-color: ${({ theme }) => theme.colors.buttonSecondaryClick};
  padding: 5px 10px 4px 10px;
  margin-block: 2.5px;
  line-height: 18px;
  font-weight: 400;
  font-size: 13px;
  border-radius: 30px;
  display: inline-block;
  user-select: none;
  ${({ $unknown, theme }) =>
    $unknown && `background-color: ${theme.colors.scrollBar};`}
`;

export const Styled = {
  Mention,
};
