import type { DirectoryRowProps } from './types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DirectoryActionButtons } from '../DirectoryActionButtons';
import { DirectoryLinks } from '../DirectoryLinks';

import { DirectoryUserInfo } from '../DirectoryUserInfo';
import { Styled } from './index.styles';

export const DirectoryRow: React.FC<DirectoryRowProps> = ({
  data,
  makeCall,
  makeVideoCall,
  testid: parentTestId,
}) => {
  const testid = `${parentTestId}-directoryRow`;

  return (
    <Styled.DirectoryRowWrapper key={data.id}>
      <Grid.Column width={6}>
        <DirectoryUserInfo {...{ data }} />
      </Grid.Column>
      <Grid.Column width={5}>
        <DirectoryLinks {...{ data, makeCall, testid }} />
      </Grid.Column>
      <Grid.Column width={5}>
        <DirectoryActionButtons
          {...{ makeCall, makeVideoCall, userId: Number(data?.id), testid }}
        />
      </Grid.Column>
    </Styled.DirectoryRowWrapper>
  );
};
