import type { ToggleButtonProps } from './interfaces';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import { QuestionHelper } from '../QuestionHelper';
import { Styled } from './index.styles';

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  checked,
  helperText = '',
  label = '',
  onChange,
  testid,
}) => (
  <Styled.ToggleButtonContainer {...{ className }}>
    <Checkbox {...{ onChange, checked, label }} toggle data-testid={testid} />
    {helperText && <QuestionHelper {...{ testid }} content={helperText} />}
  </Styled.ToggleButtonContainer>
);
