import styled from 'styled-components';

const ImagePreview = styled.div<{ $showHoverActions: boolean }>`
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 270px;
  min-height: 50px;
  border: solid 1px transparent;
  ${({ $showHoverActions }) =>
    $showHoverActions && 'border: solid 1px #e6e6e6;'}
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  object-position: left;
`;

const HoverActions = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  margin: ${({ theme }) => theme.sizes.xxsmall};
  gap: ${({ theme }) => theme.sizes.xxxsmall};
`;

export const Styled = {
  ImagePreview,
  Image,
  HoverActions,
};
