import styled from 'styled-components';

const PinnedMessages = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Styled = {
  PinnedMessages,
};
