import { split } from 'lodash';
/** Route to a path, conditionally including the `/conversations/{id}` if it was already present */
export const resolveConversationPath = (
  originalPath: string,
  path: string
): string => {
  const originalPathParts = split(originalPath, '/');

  let conversationPath = '/chat/';
  const isConversationVisible = originalPathParts.includes('conversations');
  if (isConversationVisible) {
    const index = originalPathParts.indexOf('conversations');
    conversationPath = `/chat/${originalPathParts[index]}/${
      originalPathParts[index + 1]
    }/`;
  }

  const updatedPath = `${conversationPath}${path}`;
  return updatedPath;
};

export const createRoutePathWithBrackets = (parameters: Object) => {
  let query = '';
  Object.keys(parameters).forEach((firstKey) => {
    const property = parameters[firstKey];
    Object.keys(property).forEach((secondKey) => {
      query += `${firstKey}[${secondKey}]=${property[secondKey] || ''}&`;
    });
  });
  return query.slice(0, -1);
};
