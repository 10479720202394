import EventModel, { IEvent } from 'Models/Calendar';
import moment from 'moment';
import React from 'react';
import { Calendar, momentLocalizer, ToolbarProps } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarHeader } from './CalendarHeader';
import { eventPropGetter } from './CustomStiling';
import { CustomTimeSlot, CustomToolbar } from './CustomToolTip';
import type { DnDCalendarComponentProps } from './types';

const localizer = momentLocalizer(moment);
const CalendarDragAndDrop = withDragAndDrop(Calendar);

export const DnDCalendar = ({
  calendar,
  calendarEvents,
  person,
  toogleEventPopup,
  toogleCreateEventModal,
  testId: parentTestId
}: DnDCalendarComponentProps) => {
  const testId = `${parentTestId}-dndCalendar`;
  const onEventDropOrResize = async (data) => {
    const { start, end } = data;
    const { event: oldEvent } = data;
    const [, updatedEvent]: [number, IEvent] =
      calendar.getIndexAndEventFromList(
        oldEvent,
        calendar.allEvents
      );

    const isOwnerLoggedInUser = await calendar.checkIsOwnerLoggedIn(updatedEvent);

    if (isOwnerLoggedInUser && !updatedEvent.isReccuringEvent) {
      updatedEvent.startDate = start;
      updatedEvent.start = start;
      updatedEvent.endDate = end;
      updatedEvent.end = end;

      if (updatedEvent) {
        calendar.updateEvent(updatedEvent);
      }
    }
  };

  const handleOffScreen = () => {
    const timeout = setTimeout(() => {
      const overlayElement = document.querySelector('.rbc-overlay');
      const body = document.querySelector('body');

      if (overlayElement && body) {
        const overlayInfo = overlayElement.getBoundingClientRect();
        const bodyInfo = body.getBoundingClientRect();

        if (
          overlayInfo.height + overlayInfo.top > bodyInfo.height &&
          !overlayElement.classList.contains('top-minus-100')
        ) {
          overlayElement.classList.add('top-minus-100');
        }
      }

      clearTimeout(timeout);
    }, 100);
  };

  return (
    <CalendarDragAndDrop
      defaultDate={new Date()}
      defaultView={calendar.calendarView}
      events={calendarEvents || []}
      localizer={localizer}
      onEventDrop={onEventDropOrResize}
      onEventResize={onEventDropOrResize}
      scrollToTime={new Date(new Date().getTime() - 1000 * 60 * 15)} // 15 min earlier
      resizable
      endAccessor={(event: EventModel) => event.end}
      startAccessor={(event: EventModel) => event.start}
      onSelectEvent={toogleEventPopup}
      popup
      messages={{
        //@ts-ignore
        showMore: (target) => (
          <span
            className="ml-2"
            role="presentation"
            onClick={handleOffScreen}
          >
            {' '}
            ...{target} Show more
          </span>
        ),
      }}
      eventPropGetter={eventPropGetter({
        loggedInPersonId: person.loggedInPersonId,
        checkExternalSources: person.checkExternalSources,
      })}
      views={['month', 'week'] as any[]}
      components={{
        toolbar: (props: ToolbarProps) => (
          <CustomToolbar
            calendar={calendar}
            toolbar={props}
            toogleCreateEventModal={toogleCreateEventModal}
            testId={testId}
          />
        ),
        week: {
          header: CalendarHeader,
        },
        timeSlotWrapper: (props: any) => <CustomTimeSlot props={props} />,
        event: (props) => (
          <div data-private>
            <span className="event-time-custom">
              {props.event
                ? moment((props.event as IEvent).start).format('h:mmA')
                : ''}
            </span>
            {props.title}
          </div>
        ),
      }}
    />
  );
}
