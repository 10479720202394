import type { GoogleAuthProps } from './types';
import { useGoogleLogin } from '@react-oauth/google';
import googleIcon from 'Assets/images/google-icon.svg';
import {
  CALENDAR_IDS,
  CALENDAR_SCOPE,
  GOOGLE_CLIENT,
  IS_ELECTRON,
  REDIRECT_URL,
  SHOW_CALENDAR,
} from 'Constants/env';
import { NYLAS_V3 } from 'Constants/featureFlags';
import { has } from 'lodash';
import { Scope } from 'Models/Calendar';
import React, { useMemo } from 'react';
import { handleCodeReceived, sendIpcLoginUrl } from 'Utils/ipcRendererEvents';
import { bugsnagClient } from 'Utils/logUtils';
import { v4 as uuid } from 'uuid';
import useFeatureFlags from '../../hooks/featureFlags/useFeatureFlags';

const GoogleAuthLogin = ({
  person,
  testId,
  hideBelowText,
}: GoogleAuthProps) => {
  const { [NYLAS_V3]: isUsingNylasV3 } = useFeatureFlags([NYLAS_V3]);

  const showCalendar =
    SHOW_CALENDAR || CALENDAR_IDS?.includes(person.loggedInPersonId);
  const SCOPES = `${
    showCalendar ? 'https://www.googleapis.com/auth/calendar.readonly' : ''
  } https://www.googleapis.com/auth/contacts openid profile email`;

  const googleProviderCode = useMemo(
    () => (isUsingNylasV3 ? 'google' : 'gmail'),
    [isUsingNylasV3]
  );

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: SCOPES,
    onSuccess: (resp) => {
      const scopes = ['contacts'] as Scope[];
      showCalendar && scopes.push(CALENDAR_SCOPE);
      person.sendCodeOfProvider(resp.code, googleProviderCode, scopes);
    },
    onError: () => {
      bugsnagClient.notify('Google Login Failed', (event) => {
        event.severity = 'error';
        event.context = 'GoogleAuthLogin';
        event.addMetadata('custom', { function: 'onFailure' });
      });
    },
  });

  const handleClick = () => {
    if (IS_ELECTRON && has(window, 'ipcRenderer')) {
      handleCodeReceived((code) => {
        const scopes = ['contacts'] as Scope[];
        showCalendar && scopes.push(CALENDAR_SCOPE);
        person.sendCodeOfProvider(code, googleProviderCode, scopes);
      });
      sendIpcLoginUrl(
        `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${encodeURIComponent(
          REDIRECT_URL
        )}&response_type=code%20permission%20id_token&scope=${encodeURIComponent(
          SCOPES
        )}&openid.realm&include_granted_scopes=true&client_id=${encodeURIComponent(
          GOOGLE_CLIENT
        )}&nonce=${uuid()}&ss_domain=${encodeURIComponent(
          REDIRECT_URL
        )}&access_type=offline&prompt=consent&origin=${encodeURIComponent(
          REDIRECT_URL
        )}&gsiwebsdk=3&flowName=GeneralOAuthFlow&enable_serial_consent=true&service=lso&o2v=2`
      );
    } else {
      googleLogin();
    }
  };

  return (
    <>
      {SCOPES && (
        <div key={googleIcon} onClick={() => handleClick()}>
          <div className="source-box">
            <img src={googleIcon} />
            <span data-testid={`${testId}-googleAuthLogin-buttonSignIn`}>
              Sign in with Google
            </span>
          </div>
          {!hideBelowText && (
            <div
              className="new-source"
              data-testid={`${testId}-googleAuthLogin-buttonAddNewSource`}
            >
              + Add new source
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GoogleAuthLogin;
