import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

/**
 * Takes in an array of feature flags, and returns an object with their values in boolean.
 * @param featureFlagCodes The codes for the feature flags.
 * @returns An object with feature flags values in boolean.
 */
const useFeatureFlags = (
  featureFlagCodes: string[]
): Record<string, boolean> => {
  const flags = useFlags();

  const featureFlagsMap = useMemo(() => {
    const newRecord = {};

    featureFlagCodes.forEach((featureFlagCode) => {
      newRecord[featureFlagCode] = !!flags?.[featureFlagCode];
    });

    return newRecord;
  }, [featureFlagCodes, flags]);

  return featureFlagsMap;
};

export default useFeatureFlags;
