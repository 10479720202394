import type { AxiosError } from 'axios';
import { ChatFooter } from 'Components/Chat/ChatFooter';
import ContextContentContainer from 'Containers/ContextContent';
import ContextHeaderContainer from 'Containers/ContextHeader';
import { MobXProviderContext, observer } from 'mobx-react';
import { fetchOptOutStatus } from 'Modules/conversation/index.requests';
import { useConversationStore } from 'Modules/conversation/index.store';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';

export const ChatLayout: React.FC = observer(() => {
  const { conversation, person, notification } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const [renderChat, setRenderChat] = React.useState(false);
  const navigate = useNavigate();
  const { resetCurrentConversation, setOptedOutPhoneNumbers } =
    useConversationStore();

  React.useEffect(() => {
    conversation
      .loadConversationByIdIfMissingGet(getCurrentConversationId())
      .catch((err: AxiosError) => {
        const resStatus = err.response.status;
        if (resStatus === 403 || resStatus === 404) {
          navigate('/chat');
        } else throw new Error(err.message);
      });
  }, []);

  React.useEffect(() => {
    void (async () => {
      if (conversation.CurrentConversation) {
        if (
          ['Channel', 'Group', 'OneOnOne'].includes(
            conversation.CurrentConversation?.grouping
          )
        ) {
          const otherParticipants =
            conversation.CurrentConversation.participants.filter(
              ({ personId, phone }) =>
                personId !== person.loggedInPersonId && phone
            );

          if (otherParticipants.length > 0) {
            const otherParticipantsPhoneNumbers = otherParticipants.map(
              ({ phone }) => phone
            );

            try {
              await fetchOptOutStatus(otherParticipantsPhoneNumbers);
            } catch (error) {
              /* TODO: When endpoint is ready, remove this comment
              notification.addAxiosErrorNotification(
                error,
                'Error fetching opt out status'
              );
              */
            }

            setRenderChat(true);
            return;
          }
        }

        setOptedOutPhoneNumbers([]);
        setRenderChat(true);
      }
    })();

    return () => {
      resetCurrentConversation();
    };
  }, [conversation.CurrentConversation?.id, person.loggedInPersonId]);

  return renderChat ? (
    <>
      <ContextHeaderContainer />
      <ContextContentContainer />
      <ChatFooter />
    </>
  ) : null;
});
