import styled from 'styled-components';

const TouchBackground = styled.div<{ $renderTouchBg?: boolean }>`
  position: absolute;
  z-index: 5;
  height: calc(100% - 70px);
  width: 100%;
  top: 70px;
  left: 329px;

  ${({ $renderTouchBg }) =>
    !$renderTouchBg &&
    `
      display:none;
  `};
`;

export const Styled = {
  TouchBackground,
};
