import type { AttachmentPillProps } from './AttachmentPill.types';
import { formatFileExtension } from 'Components/Chat/utils';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { Icon } from 'Components/shared/Icon';
import React from 'react';

import { Styled } from './AttachmentPill.styles';

const testid = 'attachmentPill';

export const AttachmentPill: React.FC<AttachmentPillProps> = ({
  description,
  externalId,
  handleDeleteFile,
  handleDownloadFile,
  isOwnMessage,
}) => {
  const [showHoverActions, setShowHoverActions] = React.useState(false);

  return (
    <Styled.AttachmentPillWrapper
      onMouseEnter={() => setShowHoverActions(true)}
      onMouseLeave={() => setShowHoverActions(false)}
    >
      <Icon icon="attachment" />
      <Styled.FileExtension variant="subtitles" noMargin>
        {formatFileExtension(description)}
      </Styled.FileExtension>
      <BodyCopy variant="subtitles" ellipsis noMargin>
        {description}
      </BodyCopy>
      <Styled.TextButton
        onClick={() => handleDownloadFile(externalId)}
        testid={`${testid}-buttonDownloadFile`}
        variant="primary"
      >
        Download
      </Styled.TextButton>
      {showHoverActions && isOwnMessage && (
        <Styled.IconButton
          icon="cancel-heavy"
          onClick={() => handleDeleteFile(externalId)}
          size="mini"
          testid={`${testid}-buttonDeleteFile`}
          variant="secondary"
        />
      )}
    </Styled.AttachmentPillWrapper>
  );
};
