import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_UI,
} from '../../constants/stores';
import {
  ConversationStore,
  NotificationStore,
  PersonStore,
  PreferenceStore,
  UiStore,
} from '../../stores';
import IncomingVideoItem from '../IncomingVideoItem';

class IncomingVideoCall extends React.Component<{
  ui?: UiStore;
  preference?: PreferenceStore;
  person?: PersonStore;
  notification?: NotificationStore;
  conversation?: ConversationStore;
}> {
  render() {
    const {
      conversation: { listOfIncomingVideoCalls, removeVideoConferenceFromList },
      ui,
      person,
    } = this.props;
    const incomingVideosArray = Array.from(listOfIncomingVideoCalls);
    return (
      <div className="incoming-video-list">
        {incomingVideosArray.map((conferenceMap) => {
          const conference = conferenceMap[1];
          return (
            <IncomingVideoItem
              key={conference.id}
              conference={conference}
              ui={ui}
              person={person}
              removeVideoConferenceFromList={removeVideoConferenceFromList}
            />
          );
        })}
      </div>
    );
  }
}

export default inject(
  STORE_UI,
  STORE_PREFERENCE,
  STORE_NOTIFICATION,
  STORE_CONVERSATION,
  STORE_PERSON
)(observer(IncomingVideoCall));
