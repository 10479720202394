import type { SystemMessageProps } from './SystemMessageWrapper.types';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { SystemMessageWrapper } from './SystemMessageWrapper';
import { Styled } from './SystemMessageWrapper.styles';

export const SystemMessageCall: React.FC<SystemMessageProps> = observer(
  ({ message }) => {
    const {
      person: { selectPersonById, getExtrContactByPhoneNumber },
      conversation: { CurrentConversation },
      participant: { selectOtherParticipants },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const otherParticipant = selectOtherParticipants(
      CurrentConversation?.id || ''
    );

    const otherPerson =
      otherParticipant?.[0]?.personId &&
      selectPersonById(otherParticipant?.[0]?.personId);

    const getCallTitle = (person) => {
      if (message.call.disposition === 'Missed')
        return (
          <>
            <Styled.Danger>Missed call</Styled.Danger> from{' '}
            <Styled.SemiBold>{person}</Styled.SemiBold>
          </>
        );
      if (message.call?.direction === 'Incoming')
        return (
          <>
            <Styled.SemiBold>{person}</Styled.SemiBold> started a call
          </>
        );
      if (message.call?.direction === 'Outgoing')
        return (
          <>
            You called <Styled.SemiBold>{person}</Styled.SemiBold>
          </>
        );
      if (message.call?.direction === 'Unknown')
        return (
          <>
            Call with <Styled.SemiBold>{person}</Styled.SemiBold>
          </>
        );
    };

    return (
      <SystemMessageWrapper
        created={message.created}
        icon={
          message.call?.direction === 'Unknown'
            ? 'call'
            : message.call?.direction === 'Outgoing'
            ? 'ringing'
            : 'incoming-call'
        }
        title={
          otherPerson?.case({
            pending: () => <Styled.SemiBold>Loading...</Styled.SemiBold>,
            rejected: () =>
              getCallTitle(message.call?.to?.phone || 'Unknown User'),
            fulfilled: ({ data }) => getCallTitle(data.DisplayName),
          }) ||
          getCallTitle(
            getExtrContactByPhoneNumber(
              otherParticipant?.[0]?.phone
            )?.DisplayName() ||
              formatNumberWithNationalCode(otherParticipant?.[0]?.phone) ||
              'Unknown User'
          )
        }
      />
    );
  }
);
