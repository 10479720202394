import type { CallLogType } from 'Components/CallLogs/types';

type removeDuplicatesProps = (
  displayedLogs: CallLogType[],
  fetchedLogs: CallLogType[]
) => CallLogType[];

export const removeDuplicates: removeDuplicatesProps = (
  displayedLogs,
  fetchedLogs
) => [
  ...displayedLogs,
  ...fetchedLogs.filter(
    ({ id }) => !displayedLogs.find((log) => log.id === id)
  ),
];
