import { ToggleButton } from 'Components/shared/ToggleButton';
import styled from 'styled-components';

const StyledToggleButton = styled(ToggleButton)`
  margin-top: 35px !important;
`;

export const Styled = {
  ToggleButton: StyledToggleButton,
};
