import localforage from 'localforage';
import { create } from 'zustand';
import {
  PersistStorage,
  StorageValue,
  devtools,
  persist,
} from 'zustand/middleware';
import { AttachmentItem, AttachmentStore } from './index.types';

function serializeAttachmentItem(item: AttachmentItem): { [key: string]: any } {
  const serializableObject: { [key: string]: any } = {};

  for (const key in item) {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      const value = item[key as keyof AttachmentItem];

      if (key === 'file' && value) {
        const file = value as File;
        serializableObject[key] = {
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
        };
      } else {
        serializableObject[key] = value;
      }
    }
  }

  return serializableObject;
}

const storage: PersistStorage<AttachmentStore> = {
  getItem: async (name) => {
    const str = await localforage.getItem<string>(name);
    if (!str) return null;

    const parsedValue = JSON.parse(str) as StorageValue<
      AttachmentStore | { attachments: string[] }
    >;

    return parsedValue as StorageValue<AttachmentStore>;
  },
  setItem: (name, value) => {
    if (value?.state?.attachments) {
      value.state.attachments = value.state.attachments.map(
        serializeAttachmentItem
      ) as unknown as AttachmentItem[];
    }

    void localforage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name) => localforage.removeItem(name),
};

export const createAttachmentStore = () =>
  create(
    devtools(
      persist<AttachmentStore>(
        (set) => ({
          attachments: [],
          addAttachment: (attachment) =>
            set((state) => ({
              attachments: [...state.attachments, attachment],
            })),
          updateStatus: (id, status, error) =>
            set((state) => ({
              attachments: state.attachments.map((attachment) =>
                attachment.id === id
                  ? { ...attachment, ...{ status, error } }
                  : attachment
              ),
            })),
          removeAttachment: (id) =>
            set((state) => ({
              attachments: state.attachments.filter(
                (attachment) => attachment.id !== id
              ),
            })),
          clearErrors: () =>
            set((state) => ({
              attachments: state.attachments.map((attachment) =>
                attachment.status === 'error'
                  ? { ...attachment, status: 'canceled', error: undefined }
                  : attachment
              ),
            })),
          resetAttachments: () =>
            set((state) => ({
              attachments: (state.attachments = []),
            })),
          setS3Object: (id, s3Object) =>
            set((state) => ({
              attachments: state.attachments.map((attachment) =>
                attachment.id === id
                  ? { ...attachment, ...{ s3Object } }
                  : attachment
              ),
            })),
        }),
        {
          name: '__upload-storage-default__',
          storage,
          partialize: (state) =>
            Object.assign({}, state, {
              attachments: state.attachments.filter(
                (attachment) => attachment.status === 'success'
              ),
            }),
        }
      )
    )
  );

export const useAttachmentStore = createAttachmentStore();
