import { CallLogListItem } from 'Components/CallLogs/CallLogListItem';
import {
  CALL_TYPES_CONTENT,
  CALL_LOGS_PER_PAGE,
} from 'Components/CallLogs/constants';
import { CallLogsContext } from 'Components/CallLogs/contexts/CallLogsContext';
import { OfflineBanner } from 'Components/CallLogs/OfflineBanner';
import { ContentArea } from 'Components/shared/ContentArea';
import { FilterBox } from 'Components/shared/FilterBox';
import { Heading } from 'Components/shared/Heading';
import { TESTID_CALL_LOGS } from 'Constants/testids';
import { debounce } from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, List, Loader } from 'semantic-ui-react';
import { formatDateGroup } from 'Utils/formatDateGroup';

export const CallsDirectoryList: React.FC = () => {
  const {
    callLogs,
    callLogsLoading,
    getCallLogs,
    setShouldGetCallLogs,
    shouldGetCallLogs,
    typeOfCallsId,
    getCallLogsFromCache,
  } = React.useContext(CallLogsContext);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [callPageNum, setCallPageNum] = React.useState(1);

  React.useEffect(() => {
    const scrollableList = document.getElementById('directory-list');
    scrollableList?.addEventListener('scroll', () => {
      if (scrollableList?.scrollTop === 0) scrollableList?.scrollTo({ top: 1 });
    });

    setSearchQuery('');
    setShouldGetCallLogs(false);
    scrollableList?.scrollTo({ top: 1 });

    setCallPageNum(1);
    getCallLogsFromCache();

    return () => {
      const scrollableList = document.getElementById('directory-list');
      scrollableList?.removeEventListener('scroll', () => {
        if (scrollableList?.scrollTop === 0)
          scrollableList.scrollTo({ top: 1 });
      });
    };
  }, [typeOfCallsId, shouldGetCallLogs]);

  const filter = debounce((query: string) => {
    setSearchQuery(query);
    !query?.toLowerCase()?.trim()
      ? getCallLogsFromCache()
      : getCallLogs(false, query?.toLowerCase()?.trim());
  }, 500);

  const handleLoadCallLogs = () => {
    setCallPageNum(callPageNum + 1);
    if (!searchQuery?.trim()) getCallLogs(true);
  };

  const displayedSeparators = [];

  return (
    <ContentArea id="call-logs" inlineTopBanner={<OfflineBanner />}>
      <Heading variant="h2" className="header-logs">
        {CALL_TYPES_CONTENT[typeOfCallsId].title}
      </Heading>
      <FilterBox
        placeholder={CALL_TYPES_CONTENT[typeOfCallsId].placeholder}
        sendInput={filter}
        loading={callLogsLoading}
        setPageNum={setCallPageNum}
        testid={TESTID_CALL_LOGS['DIRECTORY_LIST']}
      />
      {callLogs?.length ? (
        <InfiniteScroll
          initialScrollY={1}
          dataLength={callPageNum * CALL_LOGS_PER_PAGE} //This is important field to render the next data
          next={handleLoadCallLogs}
          hasMore
          loader={<></>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={handleLoadCallLogs}
          pullDownToRefresh
          pullDownToRefreshThreshold={4}
          scrollableTarget="directory-list"
        >
          <List
            id="directory-list"
            className="overflow-scroll-y-hidden"
            inverted
            verticalAlign="middle"
            size="large"
            selection
          >
            {callLogs.map((callLog) => {
              const dateGroup = formatDateGroup(callLog.startedAt);
              const separatorShowed = displayedSeparators.includes(dateGroup);
              if (!separatorShowed) displayedSeparators.push(dateGroup);

              return (
                <>
                  {!separatorShowed && dateGroup !== 'Today' && (
                    <div key={dateGroup} className="call-log-separator">
                      {dateGroup}
                    </div>
                  )}
                  <Grid columns={4} key={callLog.id} className="call-log-item">
                    <CallLogListItem {...callLog} />
                  </Grid>
                </>
              );
            })}
          </List>
        </InfiniteScroll>
      ) : (
        <div className="no-calls-info">
          {callLogsLoading ? (
            <Loader indeterminate active />
          ) : searchQuery.trim().length > 2 ? (
            <>
              <div>Oh no! We couldn't find what you were looking for.</div>
              <div>Check your spelling or try searching for other terms.</div>
            </>
          ) : (
            CALL_TYPES_CONTENT[typeOfCallsId].emptySearchText
          )}
        </div>
      )}
    </ContentArea>
  );
};
