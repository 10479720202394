import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

const SearchDialpadWrapper = styled.div`
  padding: 1em 1em 0 1em;
  flex: 0 0 auto;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  && {
    padding: 0;
    display: inline;
  }
`;

const ToggleDialpadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

const Image = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const CancelText = styled.span`
  color: ${({ theme }) => theme.colors.base};
  font-size: 12px;
  cursor: pointer;
`;

const TransferDirectory = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: hidden;
`;

const TransferDirectoryWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const TransferDirectoryList = styled.ul`
  position: relative;
  width: 100%;
  margin-top: -1em;
  padding: 0.65rem 1rem 0 1rem;
  margin-bottom: 15px;
`;

const TransferDirectoryTitle = styled.span`
  color: ${({ theme }) => theme.colors.base};
  font-size: 18px;
  padding: 1rem 1rem 0.5rem 1rem;
  flex: 0 0 auto;
`;

const Divider = styled.div`
  position: sticky;
  top: 0;
  height: 17px;
  z-index: 2;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.text} 0%,
    transparent 100%
  );
`;

export const Styled = {
  CancelText,
  Divider,
  InfiniteScroll: StyledInfiniteScroll,
  Image,
  SearchDialpadWrapper,
  ToggleDialpadWrapper,
  TransferDirectory,
  TransferDirectoryList,
  TransferDirectoryTitle,
  TransferDirectoryWrapper,
};
