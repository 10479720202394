import type { InputDialpadProps } from './types';
import keypad0 from 'Assets/audio/keypad/0.wav';
import keypad1 from 'Assets/audio/keypad/1.wav';
import keypad2 from 'Assets/audio/keypad/2.wav';
import keypad3 from 'Assets/audio/keypad/3.wav';
import keypad4 from 'Assets/audio/keypad/4.wav';
import keypad5 from 'Assets/audio/keypad/5.wav';
import keypad6 from 'Assets/audio/keypad/6.wav';
import keypad7 from 'Assets/audio/keypad/7.wav';
import keypad8 from 'Assets/audio/keypad/8.wav';
import keypad9 from 'Assets/audio/keypad/9.wav';
import keypadAsterisk from 'Assets/audio/keypad/asterisk.wav';
import keypadPound from 'Assets/audio/keypad/pound.wav';
import DialPad from 'Components/Dialpad';
import { TransferCallDropdown } from 'Components/TransferDirectoryListItem';
import { debounce } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { generateAudioElement } from 'Utils/generateAudioElement';
import { Styled } from './index.styles';

const keypads = [
  {
    key: '0',
    src: keypad0,
  },
  {
    key: '1',
    src: keypad1,
  },
  {
    key: '2',
    src: keypad2,
  },
  {
    key: '3',
    src: keypad3,
  },
  {
    key: '4',
    src: keypad4,
  },
  {
    key: '5',
    src: keypad5,
  },
  {
    key: '6',
    src: keypad6,
  },
  {
    key: '7',
    src: keypad7,
  },
  {
    key: '8',
    src: keypad8,
  },
  {
    key: '9',
    src: keypad9,
  },
  {
    key: 'Pound',
    src: keypadPound,
  },
  {
    key: 'Star',
    src: keypadAsterisk,
  },
];

export const InputDialpad: React.FC<InputDialpadProps> = observer(
  ({ phoneCallModel, reset, searchString, onChange }) => {
    const [cursorPosition, setCursorPosition] = React.useState(0);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [isCallable, setIsCallable] = React.useState(false);

    const { search } = React.useContext<RootStoreProps>(MobXProviderContext);

    const inputRef: React.RefObject<HTMLInputElement> = React.useRef();

    React.useEffect(() => {
      if (phoneCallModel.keysPressed) {
        onChange(phoneCallModel.keysPressed);
        setIsCallable(phoneCallModel.keysPressed.length >= 2);
      }
    }, []);

    React.useEffect(() => {
      if (searchString === inputRef.current.value) {
        filter(searchString);
        handleCursorPos();
      }
    }, [searchString]);

    const filter = debounce(
      (q: string) => {
        void search.getPersonContactSearch('TRANSFER', q, 1, false, [
          'SearchableDetailsPerson',
          'SearchableDetailsContact',
        ]);
      },
      500,
      { leading: false }
    );

    const isNumberCallable = (currentValue: string) => {
      if (currentValue.length !== 0) {
        const isInternationalOrShortCode = phoneNumber.length >= 2;
        setPhoneNumber(currentValue.replace(/[^0-9+*]/g, ''));
        setIsCallable(isInternationalOrShortCode);
      }
    };

    const keyPadPush = (num: string) => {
      phoneCallModel.keysPress(num);
      phoneCallModel.dialPadNumDtmf(num);

      if (!inputRef?.current) return;

      const { selectionStart, selectionEnd } = inputRef.current;
      const value =
        searchString.slice(0, selectionStart) +
        num +
        searchString.slice(selectionEnd);

      onChange(value);
      setCursorPosition(selectionStart);
      isNumberCallable(value);
    };

    const handleInput = () => {
      const cursorPositionStart = inputRef.current.selectionStart;
      const cursorPositionEnd = inputRef.current.selectionEnd;
      const lastNum = inputRef.current.value.split('').pop();

      onChange(inputRef.current.value);
      setCursorPosition(cursorPositionStart);
      isNumberCallable(inputRef.current.value);
      phoneCallModel.keysPress(lastNum);

      if (
        (!cursorPositionStart || cursorPositionStart !== cursorPositionEnd) &&
        reset
      )
        reset();
    };

    const handleCursorPos = (cursorPos?: number) => {
      if (!inputRef?.current) return;

      const targetPos = cursorPos || cursorPosition;
      inputRef.current.setSelectionRange(targetPos, targetPos);
      inputRef.current.focus();
      setCursorPosition(targetPos);
    };

    const directory = search.directoryMap['TRANSFER'];
    const iconType: SemanticICONS = 'call';

    return directory ? (
      <>
        <Styled.InputWrapper>
          {search.directoryMap['TRANSFER'].loading ? (
            <Styled.Loader size="tiny" />
          ) : (
            <Styled.SearchIcon name="search" color="grey" />
          )}
          <Styled.Input
            fluid
            onChange={handleInput}
            iconPosition="left"
            placeholder="Enter Name/Number"
            value={searchString}
            input={{ ref: inputRef }}
            icon={
              <TransferCallDropdown
                markup={
                  <Styled.CallIcon
                    name={iconType}
                    inverted={isCallable}
                    color={isCallable ? 'green' : 'grey'}
                    circular
                    link={isCallable}
                  />
                }
                phoneCall={phoneCallModel}
                number={phoneNumber}
                vmDisabled={true}
              />
            }
            type="text"
          />
        </Styled.InputWrapper>
        {phoneCallModel.showDialPad && (
          <DialPad
            numberClick={keyPadPush}
            inputRef={inputRef}
            handleCursorPos={handleCursorPos}
          />
        )}
        {keypads.map(({ key, src }) =>
          generateAudioElement(key, src, phoneCallModel)
        )}
      </>
    ) : null;
  }
);
