import { turndownSvc } from 'Utils/turndownSvc';

interface ProcessMessageProps {
  inputValue: string;
  onHandleDraftRaw: (value: string) => void;
  onHandleDraftHtml: (value: string) => void;
  isDeadKeyPressed: boolean;
}

/**
 * Processes the input message to update its format and content.
 * This utility function is responsible for trimming the input value,
 * replacing specific HTML tags with newline characters, and converting
 * any 'input-mention' classes within the message to a markdown format.
 * It then updates the message content by calling the provided handler functions
 * `onHandleDraftRaw` and `onHandleDraftHtml` with the processed raw and HTML values,
 * respectively, unless a dead key press is detected.
 *
 * @param {ProcessMessageProps} {
 *   inputValue - The original message content to be processed.
 *   onHandleDraftRaw - Handler function to update the message in raw format.
 *   onHandleDraftHtml - Handler function to update the message in HTML format.
 *   isDeadKeyPressed - Flag indicating if a dead key was pressed during input.
 * } The parameters required for processing the message.
 */
export const processMessage = ({
  inputValue,
  onHandleDraftRaw,
  onHandleDraftHtml,
  isDeadKeyPressed,
}: ProcessMessageProps) => {
  inputValue = inputValue.trim();

  if (inputValue) {
    inputValue = inputValue
      .replace(/(<p>|<br>)/gi, '\n')
      .replace(/<\/p>/gi, '')
      .replace(/&amp;/gi, '&');
  }

  let rawResultValue = inputValue;
  if (inputValue.includes('class="input-mention"')) {
    rawResultValue = turndownSvc.turndown(inputValue.replace(/\n/gi, '<br>'));
  }
  rawResultValue = rawResultValue.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>');

  if (!isDeadKeyPressed) {
    onHandleDraftRaw(rawResultValue);
    onHandleDraftHtml(inputValue);
  }
};
