import type { MicrosoftProps } from './types';
import logo from 'Assets/images/microsoft-icon.svg';
import {
  AZURE_GRAPH_APP_CLIENT_ID,
  CALENDAR_IDS,
  CALENDAR_SCOPE,
  IS_ELECTRON,
  REDIRECT_URL,
  SHOW_CALENDAR,
  MICROSOFT_SOURCE_SCOPES,
  AZURE_GRAPH_OAUTH_URL,
} from 'Constants/env';
import { has } from 'lodash';
import { Scope } from 'Models/Calendar';
import React, { useEffect, useState } from 'react';
import { handleCodeReceived, sendIpcLoginUrl } from 'Utils/ipcRendererEvents';
import { randomString } from 'Utils/random';
import { Styled } from './index.styles';

const Microsoft = ({
  person,
  for: propsFor,
  testId,
  hideBelowText,
}: MicrosoftProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const showCalendar =
    SHOW_CALENDAR || CALENDAR_IDS?.includes(person.loggedInPersonId);

  const scope = encodeURIComponent(
    MICROSOFT_SOURCE_SCOPES.filter((a) => a).join(' ')
  );

  const microsoftRedirectionLink = `${AZURE_GRAPH_OAUTH_URL}?
  client_id=${AZURE_GRAPH_APP_CLIENT_ID}&
  response_type=code&
  redirect_uri=${encodeURIComponent(`${REDIRECT_URL}/addressBook/sources`)}&
  response_mode=query&
  prompt=select_account&
  scope=${scope}&
  state=${propsFor}&
  nonce=${randomString(6)}`;

  const handleWebRedirection = () =>
    (window.location.href = microsoftRedirectionLink);

  const logIn = () => {
    if (IS_ELECTRON && has(window, 'ipcRenderer')) {
      handleCodeReceived((code) => {
        if (loading) {
          return;
        }

        const scopesCode = ['contacts'] as Scope[];

        showCalendar && scopesCode.push(CALENDAR_SCOPE);
        setLoading(true);

        person
          .sendCodeOfProvider(code, 'microsoft', scopesCode)
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      });
      sendIpcLoginUrl(microsoftRedirectionLink);
    } else {
      handleWebRedirection();
    }
  };

  useEffect(() => {
    const { search } = window?.location;

    if (!IS_ELECTRON) {
      if (search.includes('code=')) {
        setLoading(true);
        const code = search.split('&').find((item) => !!item.includes('code'));
        const scopesCode = ['contacts'] as Scope[];
        showCalendar && scopesCode.push(CALENDAR_SCOPE);
        person
          .sendCodeOfProvider(code.split('code=')[1], 'microsoft', scopesCode)
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, []);

  return (
    <div>
      {loading && <Styled.Loader />}

      <div key={logo} onClick={logIn}>
        <Styled.SourceBox>
          <img src={logo} />

          <Styled.SourceBoxLabel
            data-testid={`${testId}-microsoft-buttonSignIn`}
          >
            {'Sign in with Microsoft'}
          </Styled.SourceBoxLabel>
        </Styled.SourceBox>

        {!hideBelowText && (
          <Styled.NewSource
            data-testid={`${testId}-microsoft-buttonAddNewSource`}
          >
            {' + Add new source'}
          </Styled.NewSource>
        )}
      </div>
    </div>
  );
};

export default Microsoft;
