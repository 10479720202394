import { observer } from 'mobx-react';
import * as React from 'react';
import { Loader } from 'semantic-ui-react';
import ChannelInfoEditObserver from '../../components/ChannelInfoEdit';
import { ChannelEditProps } from './interface';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelEdit
 */
export class ChannelEdit extends React.Component<ChannelEditProps, {}> {
  render() {
    const {
      conversation,
      saveClick,
      clearTopicNameTaken,
      topicNameIsTaken,
      topicNameTaken,
      conversationVm,
      setConversationVm,
    } = this.props;
    if (conversation === null) {
      return <Loader active indeterminate />;
    } else {
      return (
        <ChannelInfoEditObserver
          conversation={conversation}
          saveClick={saveClick}
          clearTopicNameTaken={clearTopicNameTaken}
          topicNameIsTaken={topicNameIsTaken}
          topicNameTaken={topicNameTaken}
          conversationVm={conversationVm}
          setConversationVm={setConversationVm}
        />
      );
    }
  }
}

export default observer(ChannelEdit);
