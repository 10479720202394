import * as React from 'react';

export interface RoomPinProps {
  pin: string;
}
export const RoomPin = (props: RoomPinProps) => {
  //@ts-ignore
  const pinHTML =
    '<span>' + props?.pin?.match(/.{1,3}/g).join('</span><span>') + '</span>';
  return (
    <span
      className="video-phone-pin-format"
      dangerouslySetInnerHTML={{
        __html: pinHTML,
      }}
    />
  );
};

export default RoomPin;
