import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import AddressBookPanel from '../../components/AddressBookPanel';
import { SecondaryMenu } from '../../components/shared/SecondaryMenu';
import { ADDRESS_BOOK_SUBMENU_OPTIONS } from '../../constants/routing';
import {
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_UI,
} from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import { IAddressBookContainerProps } from './interfaces';

class AddressBookContainer extends React.Component<IAddressBookContainerProps> {
  handleCloseModals = () => {
    this.handleCloseDeletePopup();
  };

  handleCloseDeletePopup = () => this.props.ui.setDeletePopupDisplay(false);

  render() {
    const testid = 'addressBookContainer';
    return (
      <Grid.Column
        className="settings-container flex-row"
        onClick={this.handleCloseModals}
      >
        {!this.props.ui.hideSecondaryMenu && (
          <SecondaryMenu
            text="+ Add new contact"
            options={ADDRESS_BOOK_SUBMENU_OPTIONS}
            header={{
              onClick: () => {
                const { person, navigate } = this.props;
                const { pathname } = window.location;
                (!pathname.includes('contact') ||
                  pathname.includes('contacts')) &&
                  navigate('/addressBook/contact');

                person.setIsAddingContact(false);
                person.setEditContact(null);
                person.setShowPersonDetails(null, 'person');
              },
              active:
                window.location.pathname.includes('contact') &&
                !window.location.pathname.includes('contacts'),
            }}
          />
        )}
        <AddressBookPanel
          browserHistory={this.props.browserHistory}
          {...{ testid }}
        />
      </Grid.Column>
    );
  }
}

export default inject(
  STORE_UI,
  STORE_PREFERENCE,
  STORE_NOTIFICATION,
  STORE_PERSON
)(withRouter(observer(AddressBookContainer)));
