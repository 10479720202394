import styled from 'styled-components';

const ToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & label {
    font-size: 16px !important;
    line-height: 1.3em;
  }
`;

export const Styled = {
  ToggleButtonContainer,
};
