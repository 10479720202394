import { Icon } from 'Components/shared/Icon';
import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 10px;
  margin-left: 12px;
`;

const Action = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  ${({ $disabled }) => `cursor: ${$disabled ? 'auto' : 'pointer'};`}
`;

const ActionText = styled.span<{ $disabled: boolean }>`
  display: none;
  color: #333e48;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;

  ${Action}:hover && {
    ${({ $disabled }) => `${!$disabled && 'display: inline;'}`}
  }
`;

const IconContainer = styled.span`
  position: relative;
  margin-left: 10px;
  width: 18px;
  height: 18px;
`;

const ActionIcon = styled(Icon)`
  min-width: 13px;
  min-height: 13px;
  width: 13px;
  height: 13px;
  max-width: 13px;
  max-height: 13px;
`;

export const Styled = {
  Actions,
  Action,
  ActionText,
  IconContainer,
  ActionIcon,
};
