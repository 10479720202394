// Parents
export const TESTID_CALL_LOGS = {
  ROW_BUTTONS: 'callLogRowButtons',
  DIRECTORY_LIST: 'callLogDirectoryList',
};

export const TESTID_TOP_BAR = {
  DROPDOWN: 'topBarDropdown',
  BAR: 'topBar',
};

// Components
export const TESTID_BUTTONS = {
  CALL: '-buttonCall',
  MESSAGE: '-buttonMessage',
  CONTACT_DETAILS: '-buttonContactDetails',
  VIDEO_CALL: '-buttonVideoCall',
  MORE_OPTIONS: '-buttonMoreOptions',
  DIAL_PAD: '-buttonDialPad',
};

export const TESTID_FILTERBOX = '-filterBoxCallDirectory';
