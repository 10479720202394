import * as React from 'react';
import { Message } from 'semantic-ui-react';
import { ErrorMessageBannerProps } from './types';

export const ErrorMessageBanner: React.FC<ErrorMessageBannerProps> = ({
  show,
  content,
  testid,
}) =>
  show ? <Message visible error data-testid={testid} {...{ content }} /> : null;
