import type { TextLinkProps } from './types';
import React from 'react';
import { Styled } from './index.styles';

export const TextLink: React.FC<TextLinkProps> = ({
  children,
  href,
  icon,
  onClick,
  testid,
  underline = false,
  ...props
}) => (
  <Styled.TextLink
    data-testid={testid}
    $underline={underline}
    {...(href && { href, target: '_blank', rel: 'noreferrer' })}
    {...(onClick && { onClick })}
    {...props}
  >
    {icon && <Styled.Icon {...{ icon }} />}
    {children}
  </Styled.TextLink>
);
