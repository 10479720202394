import SimpleMarkdown, {
  ParserRule,
  HtmlOutputRule,
  ReactOutputRule,
} from 'simple-markdown';

export const buildParagraph = (): ParserRule &
  ReactOutputRule &
  HtmlOutputRule => ({
  ...SimpleMarkdown.defaultRules.paragraph,
  match: SimpleMarkdown.blockRegex(
    /^((?:[^\n]|\n(?! *\n))+?)(?:\n *?)+(?:\n|(?=#))/
  ),
});
