import React from 'react';
import { Modal } from 'semantic-ui-react';
import { UiStore } from '../../../../../../stores';
import { CopyComponent } from '../../../../CopyComponent';

const closeBtn = require('../../../../../../assets/images/close-btn.svg');
const iconExclamationMark = require('../../../../../../assets/images/icon-exclamation-mark.svg');
interface RecordingModalProps {
  showModal: boolean;
  ui: UiStore;
  toogleRecordingsModal: () => void;
  sharableLink: string;
  password: string;
  exipiresIn: string;
  displayName: string;
}
interface RecordingModalState {
  copied: boolean;
}

export class RecordingModal extends React.Component<
  RecordingModalProps,
  RecordingModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleCopy = () => {
    const { ui, displayName, sharableLink, password } = this.props;
    const textToCopy = [
      `Meeting: ${displayName}`,
      `Link: ${sharableLink}`,
      `Password: ${password}`,
    ];
    ui.copyToClipboard(textToCopy);
    this.setState({ copied: true });
  };

  render() {
    const {
      showModal,
      toogleRecordingsModal,
      ui,
      sharableLink,
      password,
      exipiresIn,
      displayName,
    } = this.props;
    const { copied } = this.state;
    const formatedDate = ui.formatTimeStamp(exipiresIn);
    return (
      <Modal
        open={showModal}
        size="small"
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={toogleRecordingsModal}
        className="link-modal recording-modal"
        dimmer="blurring"
      >
        <Modal.Header>
          <span>Share Recording</span>
          <img
            className="close-btn pointer"
            src={closeBtn}
            onClick={toogleRecordingsModal}
          />
        </Modal.Header>
        <Modal.Content className="msg-group-dump-content" scrolling>
          <div className="information">
            Copy this information and share it with other people.
          </div>
          <div className="link-box">
            <div className="text">
              <div className="flex-row">
                <span>Meeting: </span>
                <span>{displayName}</span>
              </div>
              <div className="flex-row">
                <span>Link: </span>
                <span className="link">{sharableLink}</span>
              </div>
              <div className="flex-row">
                <span>Password: </span>
                <span>{password}</span>
              </div>
            </div>
            <CopyComponent
              ui={ui}
              hovered={false}
              copied={copied}
              handleCopy={this.handleCopy}
            />
          </div>
        </Modal.Content>
        <div className="flex-row flex-align-items-center">
          <img src={iconExclamationMark} />
          <span>This link expires in {formatedDate}</span>
        </div>
      </Modal>
    );
  }
}
