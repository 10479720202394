import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Heading } from '../Heading';

const StyledNavLink = styled(NavLink)<{
  $disabled: boolean;
}>`
  height: 50px;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 15px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    background-color: #f4f5f7;
    border-left-color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  &.active {
    background-color: #f4f5f7;
    border-left-color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    cursor: default;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: auto;
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const StyledHeading = styled(Heading)`
  font-weight: inherit;
`;

export const Styled = {
  NavLink: StyledNavLink,
  Heading: StyledHeading,
};
