import type {
  CallLogDirectionType,
  CallLogDispositionType,
} from 'Components/CallLogs/types';
import { SEARCHABLE_TYPE } from 'Constants/enums';
import {
  ConversationsFindRequest,
  ICountableBySkipId,
  MessagesGetRequest,
} from 'Interfaces/apiDtos';
import moment from 'moment-timezone';
import { stringify } from 'query-string';
import { name as sipJsName, version as sipJsVersion } from 'sip.js';
import { createRoutePathWithBrackets } from '../utils/routeNav';
declare global {
  interface Window {
    ENV: any;
  }
}
export const NODE_ENV = window.ENV.NODE_ENV || process.env.NODE_ENV;
export const BV_ENV = window.ENV.BV_ENV || process.env.BV_ENV;
/**
 * `process.env.NODE_ENV === 'production'`
 *
 * **Note:** This is a convention used by many libraries for development/production switches.
 * We also use a separate `BV_ENV` env var to indicate our own switches independent of the conventional `NODE_ENV` switches.
 */
export const NODE_ENV_PRODUCTION = NODE_ENV === 'production';
/**
 * `process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development'`
 */
export const NODE_ENV_LOCAL_OR_DEVELOPMENT =
  ['local', 'development'].indexOf(NODE_ENV) > -1;
export const NODE_ENV_TEST = NODE_ENV === 'test';
export const NODE_ENV_LOCAL = NODE_ENV === 'local';

/**
 * `process.env.BV_ENV === 'production'`
 *
 * **Note:** This is our own switch independent of the conventional `NODE_ENV` switch.
 * We also use a separate `NODE_ENV` env var for use with conventional switches (ex. React production mode)
 */
export const BV_ENV_PRODUCTION = BV_ENV === 'production';
/**
 * `process.env.BV_ENV === 'local' || process.env.BV_ENV === 'development'`
 *
 * **Note:** This is our own switch independent of the conventional `NODE_ENV` switch.
 * We also use a separate `NODE_ENV` env var for use with conventional switches (ex. React production mode)
 */
export const CALENDAR_IDS = window.ENV.CALENDAR_IDS || process.env.CALENDAR_IDS;
export const SHOW_CALENDAR = !CALENDAR_IDS || CALENDAR_IDS?.length === 0;
export const BV_ENV_LOCAL_OR_DEVELOPMENT =
  ['local', 'development'].indexOf(BV_ENV) > -1;
export const BV_ENV_LOCAL = BV_ENV === 'local';

export const IS_BETA_ENV =
  window.ENV.BV_ENV === 'beta' || window.location.hostname.includes('beta');

export const COMMUNICATOR_CURRENT_VERSION_URL =
  window.ENV.COMMUNICATOR_CURRENT_VERSION_URL || 'https://chat.broadvoice.com';

export const PORTAL_URL =
  window.ENV.PORTAL_URL ||
  {
    local: 'https://krompir.dev.lzy.net/',
    development: 'https://krompir.dev.lzy.net/',
    staging: 'https://staging.broadvoice.io/',
    beta: 'https://portal.broadvoice.com/',
    production: 'https://portal.broadvoice.com/',
  }[BV_ENV];

export const DOWNLOAD_APP_URL_WINDOWS =
  window.ENV.DOWNLOAD_APP_URL_WINDOWS ||
  {
    local: 'https://download.broadvoice.com/communicator-development.exe',
    development: 'https://download.broadvoice.com/communicator-development.exe',
    staging: 'https://download.broadvoice.com/communicator-staging.exe',
    beta: 'https://download.broadvoice.com/communicator-beta.exe',
    production: 'https://download.broadvoice.com/communicator.exe',
  }[BV_ENV];
export const DOWNLOAD_APP_URL_MAC =
  window.ENV.DOWNLOAD_APP_URL_MAC ||
  {
    local: 'https://download.broadvoice.com/communicator-development.dmg',
    development: 'https://download.broadvoice.com/communicator-development.dmg',
    staging: 'https://download.broadvoice.com/communicator-staging.dmg',
    beta: 'https://download.broadvoice.com/communicator-beta.dmg',
    production: 'https://download.broadvoice.com/communicator.dmg',
  }[BV_ENV];
export const HELP_URL =
  window.ENV.HELP_URL ||
  {
    local: false,
    development: false,
    staging: 'https://www.broadvoice.com/faqs/',
    beta: false,
    production: false,
  }[BV_ENV];
export const VIDEO_URL =
  window.ENV.VIDEO_URL ||
  {
    qa: 'https://communicator-qa.xbp.io/video/',
    local: 'https://staging.broadvoice.io/video/',
    staging: 'https://communicator-qa.xbp.io/video/',
    production: 'https://chat.broadvoice.com/video/',
    development: 'https://staging.broadvoice.io/video/',
    beta: 'https://chat.beta.broadvoice.io/video/',
  }[BV_ENV];

export const VOICEMAIL_URL =
  window.ENV.VOICEMAIL_URL ||
  {
    qa: 'https://stg-widgets.xbp.io/voicemails',
    local: 'https://stg-widgets.xbp.io/voicemails',
    staging: 'https://stg-widgets.xbp.io/voicemails',
    production: 'https://widgets.broadvoice.com/voicemails',
    beta: 'https://widgets.broadvoice.com/voicemails',
    development: 'https://stg-widgets.xbp.io/voicemails',
  }[BV_ENV];

export const FAX_URL =
  window.ENV.FAX_URL ||
  {
    qa: 'https://stg-widgets.xbp.io/faxes',
    local: 'https://stg-widgets.xbp.io/faxes',
    staging: 'https://stg-widgets.xbp.io/faxes',
    beta: 'https://widgets.broadvoice.com/faxes',
    production: 'https://widgets.broadvoice.com/faxes',
    development: 'https://stg-widgets.xbp.io/faxes',
  }[BV_ENV];

export const EMAIL_GROUP =
  window.ENV.EMAIL_GROUP ||
  {
    qa: 'test.communicator@broadvoice.com',
    staging: 'test.communicator@broadvoice.com',
    test: 'test.communicator@broadvoice.com',
    production: '',
    local: 'support@broadvoice.com',
    beta: 'test.communicator@broadvoice.com',
    development: 'test.communicator@broadvoice.com',
  }[BV_ENV] ||
  'support@broadvoice.com';

export const BASE_CALL_LOGS =
  window.ENV.BASE_CALL_LOGS || process.env.BASE_CALL_LOGS;

export const BASE_CONTACT_API =
  window.ENV.BASE_CONTACT_API ||
  process.env.BASE_CONTACT_API ||
  {
    development: 'https://krompir.dev.lzy.net/api/contacts/',
    local: 'https://krompir.dev.lzy.net/api/contacts/',
    staging: 'https://staging.broadvoice.io/api/contacts/',
    beta: 'https://portal.broadvoice.com/',
    production: 'https://portal.broadvoice.com/api/contacts/',
  }[BV_ENV];

export const BASE_SMS_SERVICE_API = window.ENV.BASE_SMS_SERVICE_API;

export const ContactsSearchApi = (
  limit: number,
  page: number,
  source: string,
  query: string,
  accountId: number
) => {
  if (accountId) {
    return `${BASE_CONTACT_API}contacts?limit=${limit}&page=${page}&source=${source}&accountId=${accountId}&query=${query}`;
  } else {
    return `${BASE_CONTACT_API}contacts?limit=${limit}&page=${page}&source=${source}&query=${query}`;
  }
};

export const AZURE_APP_CLIENT_ID = window.ENV.AZURE_APP_CLIENT_ID;
export const AZURE_GRAPH_APP_CLIENT_ID = window.ENV.AZURE_GRAPH_APP_CLIENT_ID;

export const REDIRECT_URL =
  window.ENV.REDIRECT_URL ||
  process.env.REDIRECT_URL ||
  {
    staging: 'https://chat.staging.broadvoice.io',
    production: 'https://chat.broadvoice.com',
    local: 'https://krompir-communicator.dev.lzy.net',
    beta: 'https://chat.beta.broadvoice.io',
    development: 'https://krompir-communicator.dev.lzy.net',
  }[BV_ENV] ||
  'http://localhost:3000';

export const SHOW_FILE_UPLOAD =
  window.ENV.SHOW_FILE_UPLOAD || process.env.SHOW_FILE_UPLOAD || true;
/** Whether the application is running in an Electron window (either main or renderer) */
export const IS_ELECTRON =
  navigator &&
  navigator.userAgent &&
  navigator.userAgent.toLowerCase().indexOf('electron') > -1;
export const RELEASE_ID = window.ENV.RELEASE_ID || process.env.RELEASE_ID;
/** Base API endpoint */
export let MSG_API_BASE_URI = !NODE_ENV_TEST
  ? 'https://messaging.xbp.io/'
  : 'https://dev01-messaging.xbp.io/';
// @ts-ignore
if (window.ENV.MSG_API_BASE_URI) {
  // @ts-ignore
  MSG_API_BASE_URI = window.ENV.MSG_API_BASE_URI;
} else if (process.env.MSG_API_BASE_URI) {
  MSG_API_BASE_URI = process.env.MSG_API_BASE_URI;
} else {
  // We are making this dynamic based on the URL first, then fallback to the env
  if (window.location.href.indexOf('localhost:3000') > -1) {
    MSG_API_BASE_URI = process.env.MSG_API_BASE_URI;
  } else if (window.location.href.indexOf('communicator-dev.xbp.io') > -1) {
    MSG_API_BASE_URI = 'https://dev01-messaging.xbp.io/';
  } else if (window.location.href.indexOf('communicator-qa.xbp.io') > -1) {
    MSG_API_BASE_URI = 'https://qa01-messaging.xbp.io/';
  } else if (window.location.href.indexOf('chat.beta.broadvoice.io') > -1) {
    MSG_API_BASE_URI = 'https://messagingapi.beta.broadvoice.io/';
  } else if (window.location.href.indexOf('chat.staging.broadvoice.io') > -1) {
    MSG_API_BASE_URI = 'https://messagingapi.staging.broadvoice.io/';
  } else if (window.location.href.indexOf('chat.broadvoice.com') > -1) {
    MSG_API_BASE_URI = 'https://messaging.xbp.io/';
  }
}

/** Interval (ms) between presence updates */
export const PRESENCE_HEARTBEAT =
  window.ENV.PRESENCE_HEARTBEAT || process.env.PRESENCE_HEARTBEAT
    ? parseInt(
        window.ENV.PRESENCE_HEARTBEAT || process.env.PRESENCE_HEARTBEAT,
        10
      )
    : 60 * 60 * 1000;
/** Time (ms) before presence shifts from "Active" to "Idle". Default: 15 minutes (900s) */
export const PRESENCE_IDLE_TIME =
  window.ENV.PRESENCE_IDLE_TIME || process.env.PRESENCE_IDLE_TIME
    ? parseInt(
        window.ENV.PRESENCE_IDLE_TIME || process.env.PRESENCE_IDLE_TIME,
        10
      )
    : 15 * 60 * 1000;
/** Time (ms) before presence shifts from "Idle" to "Away" Default: 60 minutes (3600s) */
export const PRESENCE_AWAY_TIME =
  window.ENV.PRESENCE_AWAY_TIME || process.env.PRESENCE_AWAY_TIME
    ? parseInt(
        window.ENV.PRESENCE_AWAY_TIME || process.env.PRESENCE_AWAY_TIME,
        10
      )
    : 60 * 60 * 1000;
/** Interval (ms) between wake up checks */
export const WAKEUP_INTERVAL =
  window.ENV.WAKEUP_INTERVAL || process.env.WAKEUP_INTERVAL
    ? parseInt(window.ENV.WAKEUP_INTERVAL || process.env.WAKEUP_INTERVAL, 10)
    : 60 * 60 * 1000;
/** Interval (ms) between wake up timeout */
export const WAKEUP_TIMEOUT =
  window.ENV.WAKEUP_TIMEOUT || process.env.WAKEUP_TIMEOUT
    ? parseInt(window.ENV.WAKEUP_TIMEOUT || process.env.WAKEUP_TIMEOUT, 10)
    : 20 * 1000;

/** Time (ms) passed to the `mobx-utils#now` function to get an observable timestamp, updated every X ms */
export const DATETIME_DISPLAY_NOW_WINDOW = 60000;
export const DEFAULT_AVATAR_URI =
  'https://www.gravatar.com/avatar?d=identicon&s=30';

/** Value of the `Limit` parameter used to load "pages" of Messages */
export const MESSAGE_LOAD_LIMIT = 31;
/**
 * Make incoming calls persist, meaning they are **not** removed from the incoming calls array after not being answered (for testing purposes).
 * _Only works when `BV_ENV_LOCAL_OR_DEVELOPMENT` is true.
 * @param {'true' | '1' | 'yes'} process.env.CALL_PERSIST
 */
export const CALL_PERSIST = getBoolEnvVar('CALL_PERSIST');

/**
 * Force show the personal video conference link
 * @param {'true' | '1' | 'yes'} process.env.SHOW_PERSONAL_VIDEO_LINK
 */
export const SHOW_PERSONAL_VIDEO_LINK = getBoolEnvVar(
  'SHOW_PERSONAL_VIDEO_LINK'
);

/**
 * `moment-timezone` will guess the user's current time zone identifier based on available info (with polyfills for older browsers).
 * @see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export const TIMEZONE_IDENTIFIER = moment.tz.guess(true);

/** Minimum milliseconds before another `Message` backfill request can be made (ex. scrolling up to load older `Message`s)  */
export const BACKFILL_THROTTLE_MS =
  window.ENV.BACKFILL_THROTTLE_MS || process.env.BACKFILL_THROTTLE_MS
    ? parseInt(
        window.ENV.BACKFILL_THROTTLE_MS || process.env.BACKFILL_THROTTLE_MS,
        10
      )
    : 1000;

/** Empty TimeUUID used to represent an empty/missing/invalid model Id */
export const EMPTY_TIMEUUID = '00000000-0000-0000-0000-000000000000';

const CONVERSATIONS_BASE_URI = MSG_API_BASE_URI + 'conversations/';
const NOTIFICATION_BASE_URI = MSG_API_BASE_URI + 'notification/';
const CONFERENCE_BASE_URI = MSG_API_BASE_URI + 'conference/';

/** Maximum number of recursive calls to `MessageStore#backfillMessages` before the chain is aborted. Used for short-circuiting backfill on Conversation load, in case the readMessageId is far in the past (or can't be found for whatever reason) */
export const MESSAGE_BACKFILL_RECURSION_LIMIT =
  window.ENV.MESSAGE_BACKFILL_RECURSION_LIMIT ||
  process.env.MESSAGE_BACKFILL_RECURSION_LIMIT
    ? parseInt(
        window.ENV.MESSAGE_BACKFILL_RECURSION_LIMIT ||
          process.env.MESSAGE_BACKFILL_RECURSION_LIMIT,
        10
      )
    : 3;

/** The global limit on a single page of results. This must match the global limit used on the API. This has always been 300, if you are going to change it, make sure the API is changed to match. Some exceptions may apply. */
// TODO: Temporarily lowered to 100 on 2020-10-09 to try and fix the cassandra 300 cartesian product limit
export const API_GLOBAL_RESULT_LIMIT =
  window.ENV.API_GLOBAL_RESULT_LIMIT || process.env.API_GLOBAL_RESULT_LIMIT
    ? parseInt(
        window.ENV.API_GLOBAL_RESULT_LIMIT ||
          process.env.API_GLOBAL_RESULT_LIMIT,
        10
      )
    : 100;
export const GOOGLE_CLIENT =
  window.ENV.GOOGLE_CLIENT ||
  process.env.GOOGLE_CLIENT ||
  {
    staging:
      '1034838076134-dj0rkkou1gns3qjrfvc6t0esuf2qgimc.apps.googleusercontent.com',
    production:
      '1034838076134-dj0rkkou1gns3qjrfvc6t0esuf2qgimc.apps.googleusercontent.com',
    local:
      '938148389137-i0lldhf6pt7150o5qm709f266n25gp0p.apps.googleusercontent.com',
    beta: '1034838076134-dj0rkkou1gns3qjrfvc6t0esuf2qgimc.apps.googleusercontent.com',
    development:
      '938148389137-i0lldhf6pt7150o5qm709f266n25gp0p.apps.googleusercontent.com',
  }[BV_ENV];

export const LAUNCH_DARKLY_CLIENT = window.ENV.LAUNCH_DARKLY_CLIENT;
/** REST Endpoints for the Messaging API. */
export const API_ENDPOINTS = {
  /** /conference/ */
  Conference: CONFERENCE_BASE_URI,
  ConferencePrivate: CONFERENCE_BASE_URI + 'personalconference',
  ConferenceById: (conferenceId: string) => CONFERENCE_BASE_URI + conferenceId,

  VideoRecordings: (historyDetailsId: number) =>
    CONFERENCE_BASE_URI + 'history/' + historyDetailsId + '/recordings',

  VideoHistory: CONFERENCE_BASE_URI + 'history?limit=',
  VideoHistoryDetails: CONFERENCE_BASE_URI + 'history/',
  /** /conversations/ */
  Conversations: CONVERSATIONS_BASE_URI,
  /** /conversations?{query string} */
  ConversationsQuery: (conversationsFindRequest: ConversationsFindRequest) =>
    `${MSG_API_BASE_URI}conversations?sortField=LastMessage&Include=LastMessage&${stringify(
      conversationsFindRequest
    )}`,
  /*conversation/remove*/
  ConversationRemove: (conversationId, rank) =>
    `${CONVERSATIONS_BASE_URI}${conversationId}/ranks/${rank}`,
  /** /conversations/favorites/ */
  ConversationsFavorites:
    CONVERSATIONS_BASE_URI + 'favorites?sortField=LastMessage',
  /** /conversations/with */
  ConversationWith: CONVERSATIONS_BASE_URI + 'with/',
  /** /conversations/withgroup  */
  ConversationWithGroups: CONVERSATIONS_BASE_URI + 'withgroup/',
  /** /conversations/{conversationId}/ */
  ConversationById: (conversationId: string) =>
    CONVERSATIONS_BASE_URI + conversationId + '/',
  /** /conversations/{conversationId}/participants/ */
  ConversationParticipants: (conversationId: string) =>
    CONVERSATIONS_BASE_URI + conversationId + '/participants/',
  /** /conversations/{conversationId}/participants?{query string} */
  ConversationParticipantsGet: (
    conversationId: string,
    participantsLoadQuery: ICountableBySkipId<string>
  ) =>
    CONVERSATIONS_BASE_URI +
    conversationId +
    '/participants?' +
    stringify(participantsLoadQuery),
  /** /conversations/{conversationId}/participants/{participantId}/ */
  ConversationParticipantById: (
    conversationId: string,
    participantId: string
  ) =>
    CONVERSATIONS_BASE_URI +
    conversationId +
    '/participants/' +
    participantId +
    '/',
  /** /conversations/{conversationId}/participants/me/ */
  ConversationParticipantsMe: (conversationId: string) =>
    CONVERSATIONS_BASE_URI + conversationId + '/participants/me/',

  ConversationPinnedMess: (conversationId: string) =>
    CONVERSATIONS_BASE_URI + conversationId + '/pinnedmessages',

  /** (Query String) /conversations/{conversationId}/messages/ */
  ConversationMessages: (
    conversationId: string,
    messagesGetRequest?: MessagesGetRequest
  ) =>
    CONVERSATIONS_BASE_URI +
    conversationId +
    '/messages' +
    (messagesGetRequest ? '?' + stringify(messagesGetRequest) : ''),

  ConversationFile: (fileId: string) => MSG_API_BASE_URI + 'fileInfo/' + fileId,
  /** (Query String) /conversations/{conversationId}/messages/ */
  ConversationMessagesAscending: (
    conversationId: string,
    messagesGetRequest?: MessagesGetRequest
  ) =>
    CONVERSATIONS_BASE_URI +
    conversationId +
    '/messages' +
    (messagesGetRequest ? '?' + stringify(messagesGetRequest) : ''),

  /** /conversations/{conversationId}/messages/{messageId}/ */
  ConversationMessageById: (conversationId: string, messageId: string) =>
    CONVERSATIONS_BASE_URI + conversationId + '/messages/' + messageId + '/',
  /* / GET /conversations/grouping/{type} */
  ConversationHistoryByDateRange: (
    conversationCurrentDate: string,
    conversationSubDate: string
  ) =>
    CONVERSATIONS_BASE_URI +
    'grouping/' +
    'Unkown' +
    '?Start=' +
    conversationCurrentDate +
    '&End=' +
    conversationSubDate +
    '&sortField=LastMessage&Limit=100',
  /* /PUT //notification/call/inbound */
  NotificationCallOutBoundPut: NOTIFICATION_BASE_URI + 'call/outbound',
  NotificationCallInBoundPut: NOTIFICATION_BASE_URI + 'call/inbound',

  /* / GET /meta */
  LinkPreviewMetaGet: (url: string) => MSG_API_BASE_URI + 'meta?url=' + url,

  Search: (
    PartialMatch: string,
    SearchTypes?: SEARCHABLE_TYPE[],
    PageSize = 10,
    PageNumber = 1
  ) =>
    MSG_API_BASE_URI +
    'search?' +
    stringify(
      { PartialMatch, SearchTypes, PageSize, PageNumber },
      { arrayFormat: 'none' }
    ),

  SearchPerson: (partialMatch: string) =>
    MSG_API_BASE_URI + 'search?PartialMatch=' + partialMatch + '&SearchTypes=2',

  DirectorySearch: (
    SearchTerm: string,
    SortField?: string,
    PageSize = 10,
    PageNumber = 1
  ) =>
    MSG_API_BASE_URI +
    'people/directory?' +
    stringify(
      { SearchTerm, SortField, PageSize, PageNumber },
      { arrayFormat: 'none' }
    ),

  CallLogsSearch: (
    limit: number,
    direction: CallLogDirectionType = null,
    disposition: CallLogDispositionType = null,
    query: string,
    lastId: number | string = ''
  ) => {
    const filter = { direction, disposition, query };
    const page = {
      size: limit,
      lastId,
    };
    return (
      BASE_CALL_LOGS +
      '/v2/call-logs?' +
      createRoutePathWithBrackets({ filter, page })
    );
  },
  SipSearch: (sip: string) => `${MSG_API_BASE_URI}people/bysip/${sip}`,
  // Get for All Presence
  PresencePersonById: (personId: string) =>
    MSG_API_BASE_URI + 'presence/' + personId,
  /** /Presence/ */
  Presence: MSG_API_BASE_URI + 'presence',
  PresenceEveryone: MSG_API_BASE_URI + 'presence/everyone',
  /** /Status Messages/ */
  StatusMessages: MSG_API_BASE_URI + 'statusmessage',
  StatusMessagesEveryone: MSG_API_BASE_URI + 'statusmessage/everyone',
  /** Pusher auth endpoint */
  PusherAuth: MSG_API_BASE_URI + 'notification/auth-channel',
  /** /preference/ */
  Preference: MSG_API_BASE_URI + 'preference/',

  /** /config/ */
  Config: MSG_API_BASE_URI + 'config/',

  /** /auth/login*/
  AuthLogin: `${MSG_API_BASE_URI}auth/login`,
  /** /auth/token */
  AuthToken: MSG_API_BASE_URI + 'auth/token',
  /** /profile */
  Profile: MSG_API_BASE_URI + 'profile',
  /** /proxy/people */
  People: MSG_API_BASE_URI + 'people',
  /** /proxy/people/{personId}/ */
  PeoplePersonById: (personId: number) =>
    MSG_API_BASE_URI + 'people/' + personId.toString() + '/',

  Contacts: MSG_API_BASE_URI + 'contacts/',
  /** /Presence SVG */
  WebHookVoxeet: MSG_API_BASE_URI + 'webhook/voxeet',
  BaseEvents: `${BASE_CONTACT_API}calendars/events`,
  EventById: (eventId: number) =>
    `${BASE_CONTACT_API}calendars/events/${eventId}`,
  eventFromTo: (from: string, to: string) =>
    `${BASE_CONTACT_API}calendars/events?from=${from}&to=${to}`,
  AvailabilityById: (personId: number) =>
    `${BASE_CONTACT_API}calendars/events/busy/${personId}`,
  respondEvent: (eventId: number) =>
    `${BASE_CONTACT_API}calendars/events/${eventId}/respond`,
  CheckAvailabilityPhoneNumber: (phoneNumber: string) =>
    `${BASE_SMS_SERVICE_API}message/phone-number/availability/${phoneNumber}`,

  OptOutStatus: () => `${BASE_SMS_SERVICE_API}opt-out-status`,
};
export const KEYCODES = {
  BACKSPACE: 8, // Backspace
  ESCAPE: 27,
  ENTER: 13,
  SPACEBAR: 32, // Space
  LEFTARROW: 37, // ArrowLeft
  UPARROW: 38, // ArrowUp
  RIGHTARROW: 39, // ArrowRight
  DOWNARROW: 40, // ArrowDown
  DELETE: 46,
  DIGIT2: 50,
};

/**
 * Returns true if `process.env[varName]` is 'true', 'yes', or '1' */
function getBoolEnvVar(varName: string) {
  return (
    ((window.ENV[varName] || process.env[varName]) &&
      [true, 'true', 1, '1', 'yes'].indexOf(
        window.ENV[varName] || process.env[varName]
      ) > -1) ||
    false
  );
}

export const AUTH0 = window.ENV.AUTH0;

export const CALENDAR_SCOPE = 'calendar.read_only';

export const AZURE_GRAPH_OAUTH_URL =
  'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

export const MICROSOFT_SOURCE_SCOPES = [
  'offline_access',
  'openid',
  'email',
  'profile',
  'User.Read',
  'https://graph.microsoft.com/User.Read',
  'https://graph.microsoft.com/Contacts.Read',
  'https://graph.microsoft.com/Contacts.Read.Shared',
  'https://graph.microsoft.com/Contacts.ReadWrite',
  'https://graph.microsoft.com/Contacts.ReadWrite.Shared',
];

export const AUTH0_CONNECTION = window.ENV.AUTH0_CONNECTION;

export const SIP_USER_AGENT =
  `${sipJsName && sipJsVersion ? `${sipJsName}/${sipJsVersion}` : 'sip.js'} ` +
  `BroadvoiceCommunicator/${RELEASE_ID}`;

export const INTERCOM_SETTINGS =
  window.ENV.INTERCOM_SETTINGS ||
  {
    local: {
      appId: 'yypavdw1',
      feedbackSurveyId: 35987689,
    },
    development: {
      appId: 'yypavdw1',
      feedbackSurveyId: 35987689,
    },
    staging: {
      appId: 'yypavdw1',
      feedbackSurveyId: 35987689,
    },
    beta: {
      appId: 'rroq8m0r',
      feedbackSurveyId: 32050074,
    },
    production: {
      appId: 'rroq8m0r',
      feedbackSurveyId: 32050074,
    },
  }[BV_ENV];

export const VIDEO_CALL_TIMEOUT = window.ENV.VIDEO_CALL_TIMEOUT || 90000;

export const PUSHER_CHANNEL_EXTENSION = (window.ENV.PUSHER_CHANNEL_EXTENSION ||
  '') as string;
