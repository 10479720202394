import styled from 'styled-components';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Styled = {
  HeaderWrapper,
};
