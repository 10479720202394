import { STORE_CONTACT } from 'Constants/stores';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';

import { Loader, Message } from 'semantic-ui-react';
import {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
} from 'Utils/phoneUtil';

import { ChatListItemTextRendererProps } from './ChatListItemTextRenderer.types';
import { ChatListItemText } from './index';

const ChatListItemTextRendererObserver: React.FC<
  ChatListItemTextRendererProps
> = ({
  conversation,
  conversationId,
  getExtrContactByPhoneNumber,
  contact: { getContactByPhoneNumber },
  addProperName,
  getExtrContactFromPboList,
  selectParticipantPersonInfo,
  from,
  loggedInPersonId,
  selectUnreadCounts,
  ui,
  getOrSetExtrContactByPhoneNumber,
}) => {
  if (from === 'group-info') return null;

  let listOfContactsPromises;
  let isUnread = false;
  if (selectUnreadCounts(conversation.id).unreadMessages > 0) {
    isUnread = true;
  }

  if (
    conversation?.grouping === 'Channel' ||
    conversation?.grouping === 'Group'
  ) {
    if (
      (conversation.topic?.includes('Group') || !conversation.topic) &&
      conversation.grouping === 'Group'
    ) {
      runInAction(
        () => (conversation.topic = addProperName(conversation.participants))
      );
      listOfContactsPromises = conversation.participants.map((participant) =>
        getOrSetExtrContactByPhoneNumber(participant.phone)
      );
      return (
        <ChatListItemText
          key={`chat-list-item-${conversationId}`}
          bold={isUnread}
          title={listOfContactsPromises.map(
            (listOfContactsPromise: { case: (arg0: { pending: () => JSX.Element; fulfilled: () => string; }) => any; }) =>
              listOfContactsPromise?.case({
                pending: () => <Loader indeterminate active size="tiny" />,
                fulfilled: () => addProperName(conversation.participants),
              }) || conversation.topic
          )}
        />
      );
    }

    return (
      <ChatListItemText
        key={`chat-list-item-${conversationId}`}
        bold={isUnread}
        title={conversation.topic}
      />
    );
  }

  if (conversation?.grouping === 'OneOnOne') {
    const otherPtc = conversation?.participants.find(
      ({ personId }) => personId !== loggedInPersonId
    );
    const mySelfPtc = conversation?.participants.find(
      ({ personId }) => personId === loggedInPersonId
    );

    if (otherPtc) {
      if (otherPtc.phone) {
        const phoneNumNoPlus = formatNumberNoPlusIfUS(otherPtc.phone);
        const phoneNumWithNationalCode = formatNumberWithNationalCode(
          otherPtc.phone
        );

        const otherPtcContact = getContactByPhoneNumber(phoneNumNoPlus);
        if (otherPtcContact) {
          const extrContact = getExtrContactByPhoneNumber(
            otherPtcContact.data.phoneNumber
          );
          const displayName =
            extrContact?.DisplayName() || otherPtcContact.data?.DisplayName;

          if (!extrContact) {
            const extrContactPbo = getExtrContactFromPboList(phoneNumNoPlus);

            return extrContactPbo?.case({
              pending: () => <Loader indeterminate active size="tiny" />,
              fulfilled: (resp) => {
                const extrContact =
                  resp.data?.items?.[0] && new Contact(resp.data.items[0]);

                return (
                  <ChatListItemText
                    key={`chat-list-item-${conversationId}`}
                    bold={isUnread}
                    title={
                      extrContact?.DisplayName() ||
                      phoneNumWithNationalCode ||
                      displayName ||
                      conversation.topic
                    }
                    subtitle="[SMS]"
                  />
                );
              },
            });
          }

          return (
            <ChatListItemText
              key={`chat-list-item-${conversationId}`}
              bold={isUnread}
              title={
                displayName || phoneNumWithNationalCode || conversation.topic
              }
              subtitle="[SMS]"
            />
          );
        }

        const extrContactPbo = getExtrContactFromPboList(phoneNumNoPlus);
        if (!extrContactPbo) {
          return (
            <ChatListItemText
              key={`chat-list-item-${conversationId}`}
              bold={isUnread}
              title={phoneNumWithNationalCode}
            />
          );
        }

        return extrContactPbo.case({
          pending: () => <Loader indeterminate active size="tiny" />,
          fulfilled: (resp) => {
            const extrContact =
              resp.data?.items?.[0] && new Contact(resp.data.items[0]);
            return (
              <ChatListItemText
                key={`chat-list-item-${conversationId}`}
                bold={isUnread}
                title={extrContact?.DisplayName() || phoneNumWithNationalCode}
              />
            );
          },
        });
      }

      if (isFinite(otherPtc.personId)) {
        const otherPtcPerson = selectParticipantPersonInfo(otherPtc.personId);
        if (!otherPtcPerson) return <Loader indeterminate active size="tiny" />;

        return otherPtcPerson.case({
          fulfilled: (response) => {
            // Worst case scenario we don't know the name
            const displayName =
              response?.data?.firstName && response?.data?.lastName
                ? `${response.data.firstName} ${response.data.lastName}`
                : conversation.topic;
            const emoji = ui.selectPersonMessageStatus(response.data.id);

            return (
              <ChatListItemText
                key={`chat-list-item-${conversationId}-${emoji?.title}`}
                bold={isUnread}
                title={response?.data?.DisplayName || displayName}
                {...{ emoji }}
              />
            );
          },
          pending: () => <Loader indeterminate active size="tiny" />,
          rejected: () => (
            <div data-private className="display-name color-removed">
              Person {otherPtc.personId}
            </div>
          ),
        });
      }

      return (
        <Message
          visible
          error
          content={
            'Error: Empty personId and phone for Participant ' + otherPtc.id
          }
        />
      );
    }

    if (mySelfPtc && isFinite(mySelfPtc.personId)) {
      const mySelfPtcPerson = selectParticipantPersonInfo(mySelfPtc.personId);
      if (!mySelfPtcPerson) return <Loader indeterminate active size="tiny" />;

      return mySelfPtcPerson.case({
        fulfilled: (response) => {
          // Worst case scenario we don't know the name
          const displayName = response?.data?.DisplayName || conversation.topic;
          const emoji = ui.selectPersonMessageStatus(response.data.id);

          return (
            <ChatListItemText
              key={`chat-list-item-${conversationId}-${emoji?.title}`}
              bold={isUnread}
              title={displayName}
              {...{ emoji, isOwnChat: true }}
            />
          );
        },
        pending: () => <Loader indeterminate active size="tiny" />,
        rejected: () => (
          <div data-private className="display-name color-removed">
            Person {mySelfPtc.personId}
          </div>
        ),
      });
    }

    return <Message visible error content="Other Participant not found!" />;
  }

  return null;
};

export const ChatListItemTextRenderer = inject(STORE_CONTACT)(
  observer(ChatListItemTextRendererObserver)
);
