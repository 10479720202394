import type { IParticipantPerson } from 'Interfaces/participantPerson';
import {
  HtmlOutputRule,
  Rules,
  parserFor,
  htmlFor,
  ruleOutput,
  defaultRules,
} from 'simple-markdown';
import { buildMention, buildMentionKeyword } from '.';

/**
 * Compile the current Markdown formatted text content of a Message, normalizing it and processing its Mentions into `<div>`s
 *
 * @param content The input string to be compiled
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 * @param includeMentions Whether to include rules for processing @Mentions (ex. only in `Channel` mode)
 * @returns HTML string
 */

export type compileEditorMarkdownProps = (props: {
  content: string;
  participantPersons: IParticipantPerson[];
  includeMentions: boolean;
}) => string;

export const compileEditorMarkdown: compileEditorMarkdownProps = ({
  content,
  includeMentions,
  participantPersons,
}) => {
  const markDownRules = {
    text: defaultRules.text,
    newline: defaultRules.newline,
    ...(includeMentions && {
      mentionRule: buildMention(participantPersons),
      mentionKeywordRule: buildMentionKeyword(),
    }),
  } as Rules<HtmlOutputRule>;

  const markDownParse = parserFor(markDownRules);
  const markDownOutput = htmlFor(ruleOutput(markDownRules, 'html'));
  const parseTree = markDownParse(content + '\n\n', { inline: false });
  return markDownOutput(parseTree);
};
