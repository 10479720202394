import type { FilesPreviewProps } from './index.types';
import { ConfirmationModalContext } from 'Components/ChatLayoutHeader/contexts/ConfirmationModalContext';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { API_ENDPOINTS } from 'Constants/env';
import { MobXProviderContext, observer } from 'mobx-react';
import type { IMessageDocument } from 'Models/MessageModel';
import React from 'react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import API from '../../../../api';
import { AttachmentPill } from './AttachmentPill';
import { ImageFilePreview } from './ImageFilePreview';
import { Styled } from './index.styles';

export const FilesPreview: React.FC<FilesPreviewProps> = observer(
  ({ message, isOwnMessage }) => {
    const {
      ui: { handleDownloadWithLink, listOfPinnedMessages },
      message: { getFileDownloadLink },
      conversation: { CurrentConversation },
    } = React.useContext<RootStoreProps>(MobXProviderContext);
    const { setShow } = React.useContext(ConfirmationModalContext);

    const [hasDeletedFiles, setHasDeletedFiles] = React.useState(false);
    const [{ images, documents }, setFiles] = React.useState<{
      images: IMessageDocument[];
      documents: IMessageDocument[];
    }>({ images: [], documents: [] });

    React.useEffect(() => {
      const images: IMessageDocument[] = [];
      const documents: IMessageDocument[] = [];
      message.documents?.forEach((file) => {
        if (file.isDeleted) return setHasDeletedFiles(true);
        file.contentType?.includes('image') && file.previewUrl
          ? images.push(file)
          : documents.push(file);
      });
      setFiles({ images, documents });
    }, [message.documents]);

    const handleDownloadFile = async (externalId: string) => {
      const file = await getFileDownloadLink(externalId);
      if (file) handleDownloadWithLink(file.data.url, file.data.fileName);
    };

    const handleDeleteFile = (externalId: string) => {
      const editMessage = async () => {
        const { status } = await API.put(
          API_ENDPOINTS.ConversationMessageById(
            CurrentConversation.id,
            message.id
          ),
          { documents: externalId ? [{ externalId, isDeleted: true }] : null }
        );

        if (status === 204) {
          message.setDocument?.(externalId);
          // update pin message's files
          listOfPinnedMessages?.set(
            CurrentConversation.id,
            listOfPinnedMessages
              ?.get(CurrentConversation.id)
              .map((pinnedMessage) =>
                pinnedMessage.id === message.id
                  ? {
                      ...pinnedMessage,
                      documents: message.documents.map((document) =>
                        document.externalId === externalId
                          ? { ...document, isDeleted: true }
                          : document
                      ),
                    }
                  : pinnedMessage
              )
          );
        }
      };
      setShow({ type: 'deleteFileInMessage', onConfirm: editMessage });
    };

    const isMultiImage = images.length > 1;

    return (
      <Styled.FilesPreviewWrapper $isMultiImage={isMultiImage}>
        {images?.map(({ description, previewUrl, externalId, created }) => (
          <Styled.PreviewWrapper
            $isMultiImage={isMultiImage}
            key={externalId + created?.toString()}
          >
            <ImageFilePreview
              {...{
                description,
                externalId,
                handleDeleteFile,
                handleDownloadFile,
                isOwnMessage,
                previewUrl,
              }}
            />
          </Styled.PreviewWrapper>
        ))}
        {documents?.map(({ description, externalId, created }) => (
          <AttachmentPill
            key={externalId + created?.toString()}
            {...{
              description,
              externalId,
              handleDeleteFile,
              handleDownloadFile,
              isOwnMessage,
            }}
          />
        ))}
        {hasDeletedFiles && (
          <BodyCopy variant="paragraph" noMargin>
            <i>Some files were deleted from this message</i>
          </BodyCopy>
        )}
      </Styled.FilesPreviewWrapper>
    );
  }
);
