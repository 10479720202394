import { IS_BETA_ENV } from 'Constants/env';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from 'Constants/responsiveness';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';
import { LogoButton } from './LogoButton';
import { TopBarDropdown } from './TopBarDropdown';
import { TopBarUser } from './TopBarUser';

export const TopBar: React.FC = observer(() => {
  const {
    ui: { isTouchSupported, setHideSecondaryMenu },
  } = React.useContext<RootStoreProps>(MobXProviderContext);
  const testid = 'topBar';

  return (
    <Grid.Row
      className="app-header"
      onMouseEnter={() =>
        !isTouchSupported() &&
        setHideSecondaryMenu(window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT)
      }
    >
      <LogoButton {...{ testid }} />
      {IS_BETA_ENV && <Styled.BetaLogoText>BETA</Styled.BetaLogoText>}
      <Grid.Column className="action-button-wrapper">
        <TopBarDropdown />
      </Grid.Column>
      <TopBarUser {...{ testid }} />
    </Grid.Row>
  );
});
