import { useLDClient } from 'launchdarkly-react-client-sdk';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { bugsnagClient } from 'Utils/logUtils';

export const FeatureFlagProvider = observer(({ children }) => {
  const {
    person: { loggedInAccountId, loggedInPersonId, loggedInLocationId },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const ldClient = useLDClient();

  React.useEffect(() => {
    if (loggedInAccountId > 0 && loggedInPersonId > 0 && loggedInLocationId) {
      void (async () => {
        await ldClient.identify({
          kind: 'user',
          key: 'communicator-web',
          account_id: loggedInAccountId,
          location_id: loggedInLocationId,
          person_id: loggedInPersonId,
        });

        try {
          const allFlags = ldClient.allFlags();
          bugsnagClient.addMetadata('featureFlags', allFlags);
        } catch (error) {
          bugsnagClient.notify(
            'Error getting flags from LaunchDarkly',
            (event) => {
              event.severity = 'error';
              event.context = 'FeatureFlagProvider';
            }
          );
        }
      })();
    }

    return () => {
      void ldClient?.close();
    };
  }, [loggedInAccountId, loggedInPersonId, loggedInLocationId]);

  return children;
});
