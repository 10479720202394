import React, { Fragment } from 'react';
import { Rnd } from 'react-rnd';
import { DraggableHeader } from './DraggableHeader';
import { IFloatingWrapper } from './interfaces';
import { ResizableCorner } from './ResizableCorner';

export const FloatingWrapper = (props: IFloatingWrapper) => {
  const { open, isActiveCall, children, onClose, position, size, ...rest } =
    props;

  return (
    <Fragment>
      {open && (
        <Rnd
          className="FloatingWrapper"
          bounds="window"
          allowAnyClick={false}
          minWidth="310px"
          maxWidth="310px"
          minHeight="113px"
          dragHandleClassName="DraggableHeader"
          enableResizing={{ bottomRight: true }}
          resizeHandleComponent={{ bottomRight: <ResizableCorner /> }}
          resizeHandleStyles={{ bottomRight: { right: 0, bottom: 0 } }}
          default={{
            x: position.x,
            y: position.y,
            width: size.width,
            height: size.height,
          }}
          size={size}
          position={position}
          {...rest}
        >
          <div className="FloatingWrapper__children">
            <DraggableHeader isActiveCall={isActiveCall} onClose={onClose} />
            <div className="FloatingWrapper__content">{children}</div>
          </div>
        </Rnd>
      )}
    </Fragment>
  );
};
