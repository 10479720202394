import arhiveIcon from 'Assets/images/archive-icon.svg';
import ConversationMuteIcon from 'Assets/images/conversation-notification-unfilled.svg';
import ConversationUnmuteIcon from 'Assets/images/mute-notification-active.svg';
import cx from 'classnames';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import {
  selectProperVideoIcon,
  selectProperVideoTooltipMessageForOneOnOnes,
} from 'Utils/videoConferenceUtils';
import { MoreActivityButtonProps } from './interface';

export class MoreActivityButton extends Component<
  MoreActivityButtonProps,
  { showConfirmationModal: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { showConfirmationModal: false };
  }

  handlePinnedMessage = (id: number) => () => {
    const { uiStore, conversationId } = this.props;
    uiStore.setOpenedRightSidebarsOrder('pinned-messages');
    uiStore.setActiveConversationIdPinnMess(conversationId);
    uiStore.setMoreActionOptions({ show: false, conversationId: '' });
  };

  conversationMuteToggle = () => {
    const { conversation, currentPersonId, toggleMuteStatus } = this.props;
    if (!currentPersonId) {
      toggleMuteStatus(conversation?.id);
      return;
    }
    this.props.conversationStore
      .loadOrCreateConversationWithPost(currentPersonId)
      .then((resp) => {
        toggleMuteStatus(resp.data.id?.toString());
      });
  };

  placeVideoCallConference = () => {
    const {
      conversationId,
      postConferenceByConversationId,
      currentPersonId,
      loadOrCreateConversationWithPost,
      uiStore,
    } = this.props;
    uiStore.setMoreActionOptions({ show: false, conversationId: '' });
    if (isNullOrUndefined(currentPersonId)) {
      postConferenceByConversationId(conversationId);
      return;
    }

    loadOrCreateConversationWithPost(currentPersonId).then((resp) => {
      postConferenceByConversationId(resp.data.id.toString());
    });
  };

  render() {
    const {
      personPbo,
      conversation,
      from,
      loggedInPersonVideoFeature,
      activeConference,
      toggleConfirmationModal,
      showMoreActionOptions,
      uiStore: { selectIfConvMuted },
      conversationId,
      config,
      darkDisabledVidIcon,
      loggedInUserActiveConferenceConversation,
      participantStore,
    } = this.props;
    const userIsInActiveVideoConference = !isNullOrUndefined(
      loggedInUserActiveConferenceConversation
    );
    const conferenceIsActive =
      !isNullOrUndefined(activeConference) && activeConference.sessionId !== '';

    const otherParticipants =
      participantStore.selectOtherParticipants(conversationId) ?? [];

    return showMoreActionOptions ? (
      <div className="more-action-buttons">
        <div className="flex-row flex-align-items-center flex-justify-center">
          <div className="action-buttons left-1">
            <Button
              onClick={this.conversationMuteToggle}
              size={'large'}
              compact
              basic
              className="top-3"
            >
              {
                <img
                  className="small-icon-14px"
                  src={
                    selectIfConvMuted(conversationId)
                      ? ConversationUnmuteIcon
                      : ConversationMuteIcon
                  }
                />
              }
            </Button>
          </div>
          <span onClick={this.conversationMuteToggle}>Mute Notifications</span>
        </div>
        {
          // Only display video call option if there are any participants other than the logged in user
          otherParticipants.length > 0 &&
            (personPbo
              ? personPbo.case({
                  pending: () => <>Loading...</>,
                  rejected: (reason) => (
                    <span>{get(reason, 'response.data.message')}</span>
                  ),
                  fulfilled: (p) => {
                    return (
                      <>
                        {loggedInPersonVideoFeature.enabled &&
                          !conversation.IsSmsConversation && (
                            <div className="flex-row flex-align-items-center flex-justify-center">
                              <div
                                className={
                                  darkDisabledVidIcon ||
                                  userIsInActiveVideoConference ||
                                  !loggedInPersonVideoFeature.enabled ||
                                  (!get(p, 'data.SupportsChat', false) &&
                                    !get(p, 'data.phoneNumber', false))
                                    ? 'cursor-not-allowed action-buttons left-2 top-3'
                                    : 'action-buttons left-2 top-3'
                                }
                              >
                                <Button
                                  onClick={this.placeVideoCallConference}
                                  size="small"
                                  className="video-icon-relative"
                                  compact
                                  basic
                                  id="video-call-person"
                                  disabled={
                                    !loggedInPersonVideoFeature.enabled ||
                                    darkDisabledVidIcon ||
                                    userIsInActiveVideoConference ||
                                    (!get(p, 'data.SupportsChat', false) &&
                                      !get(p, 'data.phoneNumber', false))
                                  }
                                >
                                  <img
                                    className={cx('small-icon', {
                                      'disabled-video-icon cursor-not-allowed':
                                        darkDisabledVidIcon ||
                                        !loggedInPersonVideoFeature.enabled ||
                                        (!get(p, 'data.SupportsChat', false) &&
                                          !get(p, 'data.phoneNumber', false)) ||
                                        (!conferenceIsActive &&
                                          userIsInActiveVideoConference),
                                      'disabled-active-video-icon':
                                        conferenceIsActive &&
                                        userIsInActiveVideoConference,
                                    })}
                                    src={selectProperVideoIcon(
                                      null,
                                      null,
                                      null
                                    )}
                                  />
                                </Button>
                              </div>
                              <span
                                className={`${
                                  loggedInPersonVideoFeature.enabled
                                    ? null
                                    : 'disabled-button cursor-not-allowed'
                                }`}
                                onClick={
                                  loggedInPersonVideoFeature.enabled
                                    ? this.placeVideoCallConference
                                    : null
                                }
                              >
                                {selectProperVideoTooltipMessageForOneOnOnes(
                                  null,
                                  null,
                                  null
                                )}
                              </span>
                            </div>
                          )}
                      </>
                    );
                  },
                })
              : config &&
                get(config, 'signedInPersonConfig.state') === 'fulfilled' &&
                get(
                  config,
                  'signedInPersonConfig.value.data.video.enabled',
                  false
                ) &&
                !conversation.IsSmsConversation && (
                  <div className="flex-row flex-align-items-center flex-justify-center">
                    <div
                      className={
                        userIsInActiveVideoConference ||
                        !loggedInPersonVideoFeature.enabled
                          ? 'cursor-not-allowed action-buttons left-2 top-3'
                          : 'action-buttons left-2 top-3'
                      }
                    >
                      <Button
                        onClick={this.placeVideoCallConference}
                        size="small"
                        className="video-icon-relative"
                        compact
                        basic
                        disabled={
                          darkDisabledVidIcon ||
                          userIsInActiveVideoConference ||
                          !loggedInPersonVideoFeature.enabled
                        }
                      >
                        <img
                          className={cx('small-icon', {
                            'disabled-video-icon cursor-not-allowed':
                              darkDisabledVidIcon ||
                              !loggedInPersonVideoFeature.enabled ||
                              (!conferenceIsActive &&
                                userIsInActiveVideoConference),
                            'disabled-active-video-icon':
                              conferenceIsActive &&
                              userIsInActiveVideoConference,
                          })}
                          src={selectProperVideoIcon(null, null, null)}
                        />
                      </Button>
                    </div>
                    <span
                      className={`${
                        loggedInPersonVideoFeature.enabled
                          ? null
                          : 'disabled-button cursor-not-allowed'
                      }`}
                      onClick={
                        loggedInPersonVideoFeature.enabled
                          ? this.placeVideoCallConference
                          : null
                      }
                    >
                      {selectProperVideoTooltipMessageForOneOnOnes(
                        null,
                        null,
                        null
                      )}
                    </span>
                  </div>
                ))
        }

        {from !== 'history' && (
          <div className="flex-row flex-align-items-center flex-justify-center">
            <div className="action-buttons">
              <Button
                id="archive-conversation"
                className="no-vpadding pinned-icon-relative"
                onClick={toggleConfirmationModal}
                basic
                size="medium"
              >
                <span>
                  <img className="archive-conversation-icon" src={arhiveIcon} />
                </span>
              </Button>
            </div>

            <span onClick={toggleConfirmationModal}>Archive Conversation</span>
          </div>
        )}
      </div>
    ) : (
      <></>
    );
  }
}

export default MoreActivityButton;
