import type { AvatarComponentProps } from './types';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { Contact } from 'Models/Contacts';
import React from 'react';
import { Image } from 'semantic-ui-react';
import { Styled } from './index.styles';
const videoAvatar = require('Assets/images/video-avatar.jpg');

export const Avatar: React.FC<AvatarComponentProps> = ({
  message,
  person,
  conversationId,
  selectUnreadCounts,
  selectPersonPresenceStatus,
  variant = '',
}) => (
  <Styled.AvatarContainer>
    {variant === 'phone' ? (
      <Styled.PhoneContainer>
        <Styled.PhoneIcon size="small" icon="phone" />
      </Styled.PhoneContainer>
    ) : message.systemEvent?.source?.includes('Voxeet') ? (
      <Image src={videoAvatar} size="mini" />
    ) : person.data instanceof Contact && person.data?.pictureUrl ? (
      <img className="contact-prof-img" src={person.data?.pictureUrl} />
    ) : (
      <UserAvatar
        name={person.data.DisplayName}
        conversationId={conversationId}
        selectUnreadCounts={selectUnreadCounts}
        presence={selectPersonPresenceStatus(person.data.id)}
      />
    )}
  </Styled.AvatarContainer>
);
