import { Loader as SemanticLoader } from 'semantic-ui-react';
import styled from 'styled-components';

const SourceBox = styled.div`
  min-width: 185px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base};
  display: flex;
  align-items: center;
  border-radius: 2px;
  height: 40px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  padding: 0px 8px;
  border: 3px solid transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.6);
    border: 3px solid #0099ad;
  }

  &:active {
    border: 3px solid transparent;
    background: ${({ theme }) => theme.colors.textDarkBackground};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  }
`;

const SourceBoxLabel = styled.span`
  margin-left: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.21875px;
  color: rgba(0, 0, 0, 0.54);
`;

const NewSource = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: rgba(108, 117, 129, 1);
  text-align: center;
  margin-top: 15px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Loader = styled(SemanticLoader)`
  display: block;
  position: static !important;
  margin: 0 auto !important;
  display: block !important;
`;

export const Styled = { SourceBox, NewSource, SourceBoxLabel, Loader };
