import { IconButton } from 'Components/shared/IconButton';
import styled from 'styled-components';

const StyledIcon = styled(IconButton)<{ active?: boolean }>`
  margin-right: 5px;

  &&&& button,
  &&&& button:hover,
  &&&& button:active {
    ${({ active, theme }) =>
      `color: ${
        active ? theme.colors.success : theme.colors.borderDark
      } !important;`}
`;

export const Styled = {
  IconButton: StyledIcon,
};
