
import { BodyCopy } from 'Components/shared/BodyCopy';
import { Icon } from 'Components/shared/Icon';
import { IconButton } from 'Components/shared/IconButton';
import styled, { css } from 'styled-components';

const Description = styled.span`
  margin-right: 8px;
`;

const AttachmentsDeleted = styled.span`
  font-style: italic;
  word-break: break-word;
`;

const AttachmentIcon = styled(Icon)`
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 2px;
`;

const Attachments = styled(BodyCopy)<{ $singleLine: boolean }>`
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  ${({ $singleLine }) => $singleLine && '-webkit-line-clamp: 1;'}
`;

const Extension = styled.span`
  font-weight: bold;
  margin-right: 2px;
`;

const Box = styled.div<{
  $isEditing: boolean;
  $disable?: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-left: 4px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  position: relative;
  padding: 5px 10px 10px;
  width: 300px;

  ${({ $isEditing, $disable, theme }) =>
    css`
      background: ${$isEditing ? theme.colors.lightGray : theme.colors.base};
      ${!$isEditing && !$disable && `cursor: pointer;`}
      ${$disable && `border-left: 4px solid ${theme.colors.disabledPrimary};`}
    `}
`;

const StyledIcon = styled(IconButton)`
  margin-left: 5px;
  .ui {
    padding: 0 !important;
  }
  color: ${({ theme }) => theme.colors.darkGray} !important;
`;

const Message = styled(BodyCopy)<{ $singleLine: boolean }>`
  margin-bottom: 0;
  white-space: normal;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  ${({ $singleLine }) => $singleLine && '-webkit-line-clamp: 1;'}
`;

const Name = styled(BodyCopy)`
  font-weight: 700;
  word-break: break-word;
`;

const NameDateContainer = styled.div`
  margin-bottom: 3px;
  display: flex;
  align-items: start;
`;

const Date = styled(BodyCopy)`
  white-space: nowrap;
  margin-left: auto;
`;

export const Styled = {
  AttachmentIcon,
  AttachmentsDeleted,
  Description,
  Attachments,
  Extension,
  Box,
  Date,
  Icon: StyledIcon,
  Message,
  Name,
  NameDateContainer,
};
