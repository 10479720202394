import { IParticipantPerson } from 'Interfaces/participantPerson';
import React from 'react';
import SimpleMarkdown, {
  HtmlOutputRule,
  ParserRule,
  ReactOutputRule,
} from 'simple-markdown';
import { PersonModel } from '../../models';
import { useConversationStore } from '../../modules/conversation/index.store';
import { Styled } from './buildMention.styles';

/**
 * A `SimpleMarkdown` `mention` rule ( returns React for display, and HTML for input)
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 */
export const buildMention = (
  participantPersons: IParticipantPerson[]
): ParserRule & ReactOutputRule & HtmlOutputRule => ({
  order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
  match: (matchSrc) => RegExp(/^@pr([0-9]\d*)/).exec(matchSrc),
  parse: (capture, parse, state) => ({
    original: capture[0],
    content: parse(capture[1], state),
  }),
  react: (node, output, state) => {
    const resolvedPersonId = output(node.content, state).toString();
    const mentionedParticipant = participantPersons?.find(
      ({ person }) => person.id.toString() === resolvedPersonId
    );

    let mentionedParticipantDetails: PersonModel | undefined;

    // If mentioned participant doesn't have a DisplayName, then get it from the mentionUsers stored on useConversationStore
    if (!mentionedParticipant?.person?.DisplayName) {
      mentionedParticipantDetails = useConversationStore
        .getState()
        .mentionUsers.get(Number(resolvedPersonId));
    }

    return (
      <Styled.Mention key={state.key} $unknown={!mentionedParticipant}>
        @
        {mentionedParticipant?.person?.DisplayName ||
          mentionedParticipantDetails?.DisplayName ||
          'Unknown User'}
      </Styled.Mention>
    );
  },
  html: (node, output, state) => {
    const resolvedPersonId = output(node.content, state).toString();
    const mentionedPtc = participantPersons.find(
      ({ person }) => person.id.toString() === resolvedPersonId
    );
    // Note the extra surrounding spaces, this creates a gap that we can use to prevent the cursor from ending up inside the mention display text
    // This also happens in `Utils/turndownSvc`
    return mentionedPtc !== undefined
      ? `&nbsp;<div class="input-mention" mention-target="@pr${resolvedPersonId}" contenteditable="false">@${mentionedPtc.person.DisplayName}</div>&nbsp;`
      : `&nbsp;<div class="input-mention unknown" mention-target="@pr${resolvedPersonId}" contenteditable="false">@Unknown User</div>&nbsp;`;
  },
});
