import type { SoftphoneProps, SoftphoneLocalConfigProps } from './types';
import { FloatingWrapper } from 'Components/shared/FloatingWrapper';
import { TopBarDialpad } from 'Components/TopBarDialpad';
import { SOFTPHONE_DIMENSIONS_POSITION } from 'Constants/localstorage';
import {
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import CallSidebar from 'Containers/CallSidebar';
import { usePreferences } from 'Hooks/preferences/usePreferences'; // Import the hook
import localforage from 'localforage';
import { debounce, max } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useState, useCallback, useEffect } from 'react';
import { DraggableEvent } from 'react-draggable';
import { DraggableData, Position, ResizableDelta } from 'react-rnd';
import SoftphoneAudios from '../SoftphoneAudios';

const Softphone = (props: SoftphoneProps) => {
  const [position, setPosition] = useState({
    x: window.innerWidth - 310 - 10,
    y: 80,
  });
  const [dimensions, setDimensions] = useState({
    width: 310,
    height: 550,
  });
  const {
    ui: {
      openTopbarDialpad,
      setOpenTopbarDialpad,
      removeFromOpenedRightSidebarsOrder,
    },
    conversation: { listOfIncomingVideoCalls },
    phoneCall: {
      ActivePhoneCall,
      incomingPhoneCalls,
      AnyPhoneConnectionActive,
      isTenSeconds,
      isAttemptingToReconnectSip,
      isWebrtcAttemptDone,
    }
  } = props;

  const { preferences: { floatingSoftphone: isSoftphoneFixed } } = usePreferences();

  useEffect(() => {
    (async () => {
      const res = await localforage.getItem<SoftphoneLocalConfigProps>(
        SOFTPHONE_DIMENSIONS_POSITION
      );
      if (res) {
        const wWidth = window.innerWidth;
        const wHeight = window.innerWidth;
        const {
          dimensions: { width, height },
          position: { x, y },
        } = res;

        const newWidth =
          width && res.dimensions.width > wWidth ? dimensions.width : width;
        const newHeight = height > wHeight ? dimensions.height : height;
        let newPositionX = position.x;
        let newPositionY = position.y;
        newPositionX = max([x + newWidth > wWidth ? position.x : x, 0]);
        newPositionY = max([y + newHeight > wHeight ? position.y : y, -70]);
        if (Number.isFinite(newPositionX) && Number.isFinite(newPositionY)) {
          setPosition({
            x: newPositionX,
            y: newPositionY,
          });
        }
        if (Number.isFinite(newWidth) && Number.isFinite(newHeight)) {
          setDimensions({ height: newHeight, width: newWidth });
        }
        await localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
          dimensions,
          position: {
            x: newPositionX,
            y: newPositionY,
          },
        });
      } else {
        const newHeight = window.innerHeight > 550 ? 550 : window.innerHeight;
        const newPositionY = window.innerHeight > 630 ? 80 : -70;
        setPosition((prev) => ({
          x: prev.x,
          y: newPositionY,
        }));
        setDimensions((prev) => ({ height: newHeight, width: prev.width }));
        await localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
          dimensions,
          position,
        });
      }
    })();

    window.addEventListener('resize', debounce(handleWindowResize, 500));

    return () => {
      window.removeEventListener('resize', debounce(handleWindowResize, 500));
    };
  }, []);

  const handleWindowResize = React.useCallback(async () => {
    const { innerWidth, innerHeight } = window;
    const { position, dimensions } =
      await localforage.getItem<SoftphoneLocalConfigProps>(
        SOFTPHONE_DIMENSIONS_POSITION
      );
    let newPositionX = position.x;
    let newPositionY = position.y;

    if (position.x + dimensions.width > innerWidth) {
      newPositionX = innerWidth - dimensions.width;
    }
    if (position.y + dimensions.height > innerHeight) {
      newPositionY = innerHeight - dimensions.height;
    }
    newPositionX = max([newPositionX, 0]);
    newPositionY = max([newPositionY, -70]);
    const bottomEdge = newPositionY + dimensions.height + 70;
    if (bottomEdge > innerHeight) {
      const diff = bottomEdge - innerHeight;
      dimensions.height -= diff;
      setDimensions(dimensions);
    }
    setPosition({
      x: newPositionX,
      y: newPositionY,
    });
    await localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
      dimensions,
      position: {
        x: newPositionX,
        y: newPositionY,
      },
    });
  }, []);

  const onDragStop = (e: DraggableEvent, data: DraggableData) => {
    const newPosition = {
      x: data.x,
      y: data.y,
    };

    setPosition(newPosition);
    localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
      dimensions,
      position: newPosition,
    });
  };

  const onResizeStop = (
    e: MouseEvent | TouchEvent,
    dir: string,
    elementRef: HTMLElement,
    delta: ResizableDelta,
    position: Position
  ) => {
    setDimensions((prev) => {
      const newDimensions = {
        width: prev.width + delta.width,
        height: prev.height + delta.height,
      };
      localforage.setItem(SOFTPHONE_DIMENSIONS_POSITION, {
        dimensions: newDimensions,
        position,
      });
      return newDimensions;
    });
  };

  const SoftphoneChildren = useCallback(() => {
    return (
      <>
        {openTopbarDialpad &&
          !(
            ActivePhoneCall ||
            incomingPhoneCalls.length ||
            isAttemptingToReconnectSip ||
            isWebrtcAttemptDone
          ) && <TopBarDialpad isFixed={isSoftphoneFixed} />}
        <CallSidebar isFixed={isSoftphoneFixed} />
      </>
    );
  }, [
    ActivePhoneCall,
    incomingPhoneCalls,
    openTopbarDialpad,
    isSoftphoneFixed,
    isAttemptingToReconnectSip,
    isWebrtcAttemptDone,
  ]);

  const isActiveCall =
    AnyPhoneConnectionActive ||
    isTenSeconds ||
    listOfIncomingVideoCalls.size > 0 ||
    isAttemptingToReconnectSip ||
    isWebrtcAttemptDone;

  const handleOnCloseFloatingWrapper = () => {
    removeFromOpenedRightSidebarsOrder('dial-pad');
    setOpenTopbarDialpad(false);
  };

  return (
    <>
      {isSoftphoneFixed ? (
        <SoftphoneChildren />
      ) : (
        <FloatingWrapper
          data-testid="softphone-floatingWrapper"
          open={isActiveCall || openTopbarDialpad}
          isActiveCall={isActiveCall}
          default={{
            ...position,
            ...dimensions,
          }}
          position={position}
          size={dimensions}
          onDragStop={onDragStop}
          onResizeStop={onResizeStop}
          onClose={handleOnCloseFloatingWrapper}
        >
          <SoftphoneChildren />
        </FloatingWrapper>
      )}
      <SoftphoneAudios />
    </>
  );
};

export default inject(
  STORE_UI,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION,
  STORE_CONVERSATION,
  STORE_PREFERENCE
)(observer(Softphone));
