import cx from 'classnames';
import type { CallLogType } from 'Components/CallLogs/types';
import { CallLogRowButtons } from 'Components/CallLogs/CallLogRowButtons';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { DateTime, Duration } from 'luxon';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { isPhoneNumber } from 'Utils/phoneUtil';

export const CallLogListItem: React.FC<CallLogType> = ({
  answeredAt,
  category,
  direction,
  disposition,
  endedAt,
  fullName,
  icon,
  id,
  personId,
  phoneNumber,
  startedAt,
  viaName,
}) => {
  const {
    ui: { selectConversationUnreadCounts, selectPersonPresenceStatus },
    person: { getExtrContactByPhoneNumber },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const displayDuration = () => {
    if (!answeredAt || (direction === 'inbound' && disposition === 'missed'))
      return '';

    const durationInMilis =
      DateTime.fromISO(endedAt).valueOf() -
      DateTime.fromISO(answeredAt).valueOf();
    const formatedDuration = Duration.fromMillis(durationInMilis)
      .toFormat('h:m:s')
      .split(':')
      .map((item) => parseInt(item));
    let hours = formatedDuration[0];
    let minutes = formatedDuration[1];
    const seconds = formatedDuration[2];

    let duration = '';
    if (!hours) {
      if (minutes) duration = `${minutes}m `;
      if (seconds) duration += `${seconds}s`;
    } else {
      if (seconds <= 30) minutes++;
      if (minutes === 60) {
        hours++;
        minutes = 0;
      }
      duration = `${hours}h `;
      if (minutes) duration += `${minutes}m`;
    }
    return duration ? `(${duration.trim()})` : '';
  };

  const displayStartTime = () => {
    const date = DateTime.fromISO(startedAt);
    const meridiem = date.toFormat('a').toUpperCase();
    const startDate = date.setLocale('en-US').toFormat('t').toUpperCase();
    return startDate.includes(meridiem)
      ? startDate
      : `${startDate} ${meridiem}`;
  };

  return (
    <Grid.Row key={id} className="directory-items">
      <Grid.Column width={6}>
        <div className="flex-row flex-align-items-center">
          <UserAvatar
            name={fullName}
            hideInitials={!!phoneNumber && !fullName}
            selectUnreadCounts={selectConversationUnreadCounts}
            presence={selectPersonPresenceStatus(personId || 0)}
          />
          <div className="directory-user-info">
            <div className="call-log-user-info">
              {fullName ||
                viaName ||
                getExtrContactByPhoneNumber(phoneNumber)?.DisplayName() ||
                phoneNumber ||
                'Unknown'}
            </div>
            <div className="call-log-category">
              <img src={icon} />
              <div
                className={cx({
                  'missed-call': category === 'missed',
                })}
              >
                {category}
              </div>
            </div>
          </div>
        </div>
      </Grid.Column>
      <Grid.Column
        className={cx('padding-5 time-duration', {
          'missed-call': category === 'missed',
        })}
        width={5}
      >
        {displayStartTime()} {displayDuration()}
      </Grid.Column>
      {(personId || isPhoneNumber(phoneNumber)) && (
        <Grid.Column width={5} textAlign="right">
          <CallLogRowButtons {...{ personId, phoneNumber }} />
        </Grid.Column>
      )}
    </Grid.Row>
  );
};
