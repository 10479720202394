import styled from 'styled-components';

const MULTI_IMAGE_WIDTH = '430px';

const FilesPreviewWrapper = styled.div<{ $isMultiImage: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizes.xxsmall};
  ${({ $isMultiImage }) =>
    `width: ${$isMultiImage ? MULTI_IMAGE_WIDTH : '300px'};`}
`;

const PreviewWrapper = styled.div<{ $isMultiImage: boolean }>`
  ${({ $isMultiImage, theme }) =>
    `width: ${
      $isMultiImage
        ? `calc(${MULTI_IMAGE_WIDTH}/2 - ${theme.sizes.xxxsmall})`
        : '100%'
    };`}
`;

export const Styled = {
  FilesPreviewWrapper,
  PreviewWrapper,
};
