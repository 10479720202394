import type { SystemMessageProps } from './SystemMessageWrapper.types';
import { useEnumerateSystemMessageParticipants } from 'Components/Chat/hooks/useEnumerateSystemMessageParticipants';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { TextButton } from 'Components/shared/TextButton';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { SystemMessageWrapper } from './SystemMessageWrapper';
import { Styled } from './SystemMessageWrapper.styles';

export const SystemMessageVideo: React.FC<SystemMessageProps> = observer(
  ({ message }) => {
    const { conversation, ui } =
      React.useContext<RootStoreProps>(MobXProviderContext);

    const { formattedAuthor } = useEnumerateSystemMessageParticipants(
      message.personId.toString()
    );

    return (
      <SystemMessageWrapper
        created={message.created}
        icon="video"
        title={
          message.conference?.end ? (
            <Styled.SemiBold>Meeting ended</Styled.SemiBold>
          ) : (
            <>
              {formattedAuthor}{' '}
              {message.conference?.type === 'AdHocScheduled'
                ? 'created a meeting for later'
                : 'started a video meeting'}
            </>
          )
        }
      >
        {message.conference?.duration ? (
          <>
            <BodyCopy noMargin>
              <Styled.SemiBold>Duration: </Styled.SemiBold>
              {message.conference.duration}
            </BodyCopy>
            <BodyCopy noMargin>
              <Styled.SemiBold>Recorded: </Styled.SemiBold>
              {message.conference?.hasRecording ? 'yes' : 'no'}
            </BodyCopy>
          </>
        ) : (
          <TextButton
            onClick={() => {
              ui.navigateVideoConferenceToSession(message.conference.id);
              conversation.removeVideoConferenceFromList(message.conference.id);
            }}
            disabled={{
              condition:
                !!conversation.LoggedInUserActiveConferenceConversation,
              title: 'Leave your ongoing meeting before joining',
            }}
            variant="primary"
            testid="systemMessageVideo-textButtonJoinMeeting"
          >
            <Styled.SemiBold>Join meeting</Styled.SemiBold>
          </TextButton>
        )}
      </SystemMessageWrapper>
    );
  }
);
