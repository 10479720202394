import type { CalendarContainerProps } from './types';
import { CalendarComponent } from 'Components/Calendar';
import { ContentArea } from 'Components/shared/ContentArea';
import { SecondaryMenu } from 'Components/shared/SecondaryMenu';
import { SourcesAddressBook } from 'Components/SourcesAddressBook';
import { CALENDAR_SUBMENU_OPTIONS, CALENDAR_SUBPATHS } from 'Constants/routing';
import {
  STORE_CALENDAR,
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';

const testId = 'calendarContainer';

export const CalendarContainer = inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_CALENDAR,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(
  withRouter(
    observer(({ calendar, person, ui, ...props }: CalendarContainerProps) => {
      const handleCloseModals = () => {
        handleCloseDeletePopup();
      };

      const handleCloseDeletePopup = () => ui.setDeletePopupDisplay(false);

      useEffect(() => {
        const {
          allSources: sourceList,
          allContacts: contactList,
          loadingContacts,
          getAllContacts,
          getSourcesIfMissing,
        } = person;

        if (contactList.length === 0 && !loadingContacts) {
          getAllContacts();
        }
        if (sourceList.length === 0) {
          getSourcesIfMissing();
        }

        calendar.loadEventsFromTo(
          calendar.dateFromTo.from,
          calendar.dateFromTo.to
        );
      }, [
        person.allContacts,
        person.loadingContacts,
        person.getAllContacts,
        person.getSourcesIfMissing,
        calendar.dateFromTo,
      ]);

      return (
        <Grid.Column
          className="calendar-container flex-row"
          onClick={handleCloseModals}
          id="calendar-container"
        >
          {!ui.hideSecondaryMenu && (
            <SecondaryMenu text="Calendar" options={CALENDAR_SUBMENU_OPTIONS} />
          )}

          <ContentArea>
            {/** path = /calendar/* */}
            <Routes>
              <Route
                path="events"
                element={<CalendarComponent testId={testId} />}
              />
              <Route
                path="calendars"
                element={
                  <SourcesAddressBook
                    {...props}
                    titleFor="calendars"
                    testId={testId}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={CALENDAR_SUBPATHS.EVENTS} replace />}
              />
            </Routes>
          </ContentArea>
        </Grid.Column>
      );
    })
  )
);
