import { IPresenceUpdated } from 'Interfaces/notifications';
import { action, observable, makeObservable } from 'mobx';
import { STATE_PRESENCE } from '../constants/enums';

export interface IPresenceResponse {
  statuses: IPresenceModel[];
}

export interface IPresenceModel {
  personId: number;
  /** update Time ISO8601 UTC */
  update: number;
  /**[Active, Inactive, OnCall, OffLine] missing state or record of state is the same as OffLine  */
  state: STATE_PRESENCE;
  doNotDisturb: boolean; // nullable boolean, missing is the same as NO CHANGE
  heartBeat: number;
  info: string;
}

export interface IPresenceUpdateResponse {
  changed: boolean;
  current: IPresenceModel;
  previous: IPresenceModel;
}

export class PresenceModel implements IPresenceModel {
  constructor(
    personId: number,
    update: number = Date.now(),
    state: STATE_PRESENCE,
    doNotDisturb: boolean,
    heartBeat: number = Date.now(),
    info: string
  ) {
    makeObservable(this);
    this.personId = personId;
    this.update = update;
    this.state = state;
    this.doNotDisturb = doNotDisturb !== undefined ? doNotDisturb : false;
    this.heartBeat = heartBeat;
    this.info = info;
  }

  static CreateOffLineStatus = (personId: number) =>
    new PresenceModel(personId, Date.now(), 'OffLine', false, Date.now(), '');

  static FromResponseDto = (responseDto: IPresenceModel) =>
    new PresenceModel(
      responseDto.personId,
      responseDto.update,
      responseDto.state,
      false,
      responseDto.heartBeat,
      responseDto.info
    );

  static FromPusherResponseDto = (responseDto: IPresenceUpdated) =>
    new PresenceModel(
      responseDto.personId,
      Date.now(),
      responseDto.state,
      responseDto.doNotDisturb,
      Date.now(),
      responseDto.info
    );

  @observable
  public personId: number;

  @observable
  public update: number = Date.now();

  @observable
  public state: STATE_PRESENCE;

  @observable
  public doNotDisturb: boolean;

  @observable
  info: string;

  @action
  setState = (state: STATE_PRESENCE) => (this.state = state);

  @action
  setDND = (DND: boolean) => (this.doNotDisturb = DND);

  @action
  setInfo = (info: string) => (this.info = info);

  @observable
  public heartBeat: number = Date.now();
}

export default PresenceModel;
