import { observer } from 'mobx-react';
import React, { useCallback, FC } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { DialPadProps } from './interfaces';

const DialPad: FC<DialPadProps> = ({
  numberClick,
  inputRef,
  handleCursorPos,
}) => {
  const numberPressed = useCallback(
    (e) => {
      numberClick(e.target.value);
      if (inputRef?.current) {
        handleCursorPos(inputRef.current.selectionStart + 1);
      }
    },
    [handleCursorPos, inputRef, numberClick]
  );

  return (
    <Grid.Column className="dialpad-component">
      <Button.Group attached="bottom" className="borderBottom evenHeight">
        <Button
          data-testid="dialpad-keypad-1"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-1"
          value="1"
          onClick={numberPressed}
        >
          <Button.Content>1</Button.Content>
          <Button.Content style={{ height: '0.55rem' }}></Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-2"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-2"
          value="2"
          onClick={numberPressed}
        >
          <Button.Content>2</Button.Content>
          <Button.Content>ABC</Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-3"
          className="evenFlex dialpad-button"
          id="keypad-3"
          value="3"
          onClick={numberPressed}
        >
          <Button.Content>3</Button.Content>
          <Button.Content>DEF</Button.Content>
        </Button>
      </Button.Group>
      <Button.Group attached="bottom" className="borderBottom evenHeight">
        <Button
          data-testid="dialpad-keypad-4"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-4"
          value="4"
          onClick={numberPressed}
        >
          <Button.Content>4</Button.Content>
          <Button.Content>GHI</Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-5"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-5"
          value="5"
          onClick={numberPressed}
        >
          <Button.Content>5</Button.Content>
          <Button.Content>JKL</Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-6"
          className="evenFlex dialpad-button"
          id="keypad-6"
          value="6"
          onClick={numberPressed}
        >
          <Button.Content>6</Button.Content>
          <Button.Content>MNO</Button.Content>
        </Button>
      </Button.Group>
      <Button.Group attached="bottom" className="borderBottom evenHeight">
        <Button
          data-testid="dialpad-keypad-7"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-7"
          value="7"
          onClick={numberPressed}
        >
          <Button.Content>7</Button.Content>
          <Button.Content>PQRS</Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-8"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-8"
          value="8"
          onClick={numberPressed}
        >
          <Button.Content>8</Button.Content>
          <Button.Content>TUV</Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-9"
          className="evenFlex dialpad-button"
          id="keypad-9"
          value="9"
          onClick={numberPressed}
        >
          <Button.Content>9</Button.Content>
          <Button.Content>WXYZ</Button.Content>
        </Button>
      </Button.Group>
      <Button.Group attached="bottom" className="evenHeight">
        <Button
          data-testid="dialpad-keypad-*"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-*"
          value="*"
          onClick={numberPressed}
        >
          <Button.Content>
            <span className="star-key">*</span>
          </Button.Content>
          <Button.Content></Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-0"
          className="rightBorder evenFlex dialpad-button"
          id="keypad-0"
          value="0"
          onClick={numberPressed}
        >
          <Button.Content>0</Button.Content>
          <Button.Content>+</Button.Content>
        </Button>
        <Button
          data-testid="dialpad-keypad-#"
          className="evenFlex dialpad-button"
          id="keypad-#"
          value="#"
          onClick={numberPressed}
        >
          <Button.Content>#</Button.Content>
          <Button.Content></Button.Content>
        </Button>
      </Button.Group>
    </Grid.Column>
  );
};

export default observer(DialPad);
