import type { ThreeDotsButtonProps } from './ThreeDotsButton.types';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import { ConfirmationModalContext } from 'Components/ChatLayoutHeader/contexts/ConfirmationModalContext';
import { IconButton } from 'Components/shared/IconButton';
import { PopOverMenu } from 'Components/shared/PopOverMenu';
import { PopOverMenuOption } from 'Components/shared/PopOverMenuOption';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const ThreeDotsButton: React.FC<ThreeDotsButtonProps> = ({
  conversationId,
  isOwnMessage,
  message,
  openThreeDots,
  setOpenThreeDots,
  setShowThreeDots,
  testid,
  togglePinMessage,
  pinnedBy,
}) => {
  const { message: messageStore } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const { setShow } = React.useContext(ConfirmationModalContext);
  const { setEditMessageId, setReplyMessage } =
    React.useContext(ChatLayoutContext);

  const closeMenu = (callback?: () => void) => {
    callback?.();
    setShowThreeDots(false);
    setOpenThreeDots(false);
  };

  const deleteMessage = () => {
    const onConfirm = () =>
      void messageStore.deleteMessage(conversationId, message.id);
    setShow({ type: 'deleteMessage', onConfirm });
  };

  const replyMessage = () => {
    setReplyMessage({
      id: message.id,
      type: 'reply',
      snapshot: {
        chat: message.chat,
        created: message.created,
        documents: message.documents,
        personId: message.personId,
      },
    });
  };

  return (
    <PopOverMenu
      trigger={
        <IconButton
          compact
          icon="more"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setOpenThreeDots(true);
          }}
          testid={`${testid}-buttonMore`}
        />
      }
      content={
        <React.Fragment>
          {!message.sms && (
            <PopOverMenuOption
              onClick={() => closeMenu(replyMessage)}
              testid={`${testid}-buttonReply`}
            >
              Reply
            </PopOverMenuOption>
          )}
          {isOwnMessage && !message.sms && (
            <React.Fragment>
              <PopOverMenuOption
                onClick={() => closeMenu(() => setEditMessageId(message.id))}
                testid={`${testid}-buttonEditMessage`}
              >
                Edit
              </PopOverMenuOption>
              <PopOverMenuOption
                onClick={() => closeMenu(deleteMessage)}
                testid={`${testid}-buttonDeleteMessage`}
              >
                Delete
              </PopOverMenuOption>
            </React.Fragment>
          )}
          <PopOverMenuOption
            onClick={() => closeMenu(togglePinMessage)}
            testid={`${testid}-buttonPinMessage`}
          >
            {pinnedBy ? 'Unpin message' : 'Pin message'}
          </PopOverMenuOption>
        </React.Fragment>
      }
      show={openThreeDots}
      onOutsideClick={() => closeMenu()}
      position="top right"
      offset={[5, 0]}
      hideOnScroll
    />
  );
};
