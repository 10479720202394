import addNewFiledIcon from 'Assets/images/add-new.svg';
import bhiveIcon from 'Assets/images/bhive-logo.svg';
import phoneIcon from 'Assets/images/call-start.svg';
import messageIcon from 'Assets/images/chat.svg';
import closeBtn from 'Assets/images/close-btn.svg';
import editIconWhite from 'Assets/images/icon-edit-white.svg';
import removeFiledIcon from 'Assets/images/remove-field.svg';
import user from 'Assets/images/user-details.svg';
import { EmailTypes, PhoneNumberTypes } from 'Constants/enums';
import {
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import {
  ContactEmailInterface,
  ContactPhoneInterface,
} from 'Interfaces/contacts';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import React from 'react';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Input,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import withRouter from '../../hocs/WithRouter';
import PhoneNumbersPopup from '../PhoneNumbersPopup';
import { UserAvatar } from '../shared/UserAvatar';

import {
  AddNewContactProps,
  AddNewContactState,
  ExtendedContactEmailInterface,
  ExtendedContactPhoneInterface,
} from './interfaces';

const permanentProviders: DropdownItemProps[] = [
  {
    key: 99,
    image: { avatar: true, src: bhiveIcon },
    text: 'Personal contacts',
    value: 'B_HIVE_PERSONAL',
  },
  {
    key: 100,
    image: { avatar: true, src: bhiveIcon },
    text: 'Organization contacts',
    value: 'B_HIVE_SHARED',
  },
];

class AddNewContact extends React.Component<
  AddNewContactProps,
  AddNewContactState
> {
  initializedFormData: Contact = {
    phoneNumbers: [{ type: 'mobile', number: '', id: null }],
    emails: [{ type: 'work', email: '', id: null }],
    firstName: '',
    lastName: '',
    middleName: '',
    organization: '',
    organizationRole: '',
    isShared: null,
    pictureKey: '',
    pictureUrl: '',
    notes: '',
    id: 0,
    accountId: null,
    source: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      aditionalPhoneFields: [],
      aditionalEmailFields: [],
      formData: this.initializedFormData,
      showRemoveModal: false,
      removedUser: null,
      loadingProfilePictureUrl: false,
      profilePicture: '',
      startedEditing: false,
      operationType: null,
      dropdownOptions: null,
      savingInProgress: false,
      isSourceDropdownVisible: true,
    };
  }

  componentDidMount() {
    this.setState({ dropdownOptions: permanentProviders });

    if (this.props.person.editContactDetails) {
      const data = this.mapDataFromProps();
      const aditionalEmailFields = data.emails?.map((email) => ({
        type: email.type,
        email: email.email,
        id: email.id,
        guid: new Date().getMilliseconds(),
      }));
      const aditionalPhoneFields = data.phoneNumbers?.map((phone) => ({
        type: phone.type,
        number: phone.number,
        id: phone.id,
        guid: new Date().getMilliseconds(),
      }));
      aditionalEmailFields.shift();
      aditionalPhoneFields.shift();
      this.setState({
        formData: {
          ...data,
          emails: [data.emails[0]],
          phoneNumbers: [data.phoneNumbers[0]],
        },
        aditionalEmailFields: aditionalEmailFields,
        aditionalPhoneFields: aditionalPhoneFields,
      });
    }
  }

  componentDidUpdate() {
    if (
      !this.props.person?.editContactDetails &&
      this.state.formData.id !== 0
    ) {
      this.setState({
        ...this.state,
        formData: this.initializedFormData,
        aditionalPhoneFields: [],
        aditionalEmailFields: [],
      });
    }
  }

  handleChange = (e, d: DropdownProps) => {
    const { person } = this.props;
    const id = e.target.id || d.id;
    person.editContactDetails &&
      !this.state.startedEditing &&
      this.handleEditMode();
    if (id.includes('Type')) {
      const dataForUpdate =
        id === 'emailType'
          ? [...this.state.formData.emails]
          : [...this.state.formData.phoneNumbers];
      const fieldForUpdate = id === 'emailType' ? 'emails' : 'phoneNumbers';
      dataForUpdate[0].type = d.value as string;
      this.setState({
        formData: { ...this.state.formData, [fieldForUpdate]: dataForUpdate },
      });
    } else if (id === 'email' || id === 'phoneNumber') {
      const dataForUpdate =
        id === 'email'
          ? [...this.state.formData.emails]
          : [...this.state.formData.phoneNumbers];
      const fieldForUpdate = id === 'email' ? 'emails' : 'phoneNumbers';
      id === 'phoneNumber' && this.checkIfExtrInfoSidebar();
      id === 'email'
        ? ((dataForUpdate[0] as ContactEmailInterface).email = e.target.value)
        : ((dataForUpdate[0] as ContactPhoneInterface).number = e.target.value);
      this.setState({
        formData: { ...this.state.formData, [fieldForUpdate]: dataForUpdate },
      });
    } else if (id === 'selectedSource') {
      const isBhiveAcc = d.value.toString().includes('B_HIVE');
      const isShared = isBhiveAcc && d.value === 'B_HIVE_SHARED';
      this.setState({
        formData: {
          ...this.state.formData,
          isShared: isShared,
          accountId: isBhiveAcc ? null : Number(d.value),
          [id]: d.value,
        },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, [id]: e.target.value || d.value },
      });
    }
  };

  checkIfExtrInfoSidebar = () => {
    const { person } = this.props;
    // we have to close info sidebar if number is changed
    person.showPersonDetails.type === 'extrContact' &&
      person.setShowPersonDetails(null, 'person');
  };

  handleChangeAdditionalFields = (e) => {
    const { aditionalEmailFields, aditionalPhoneFields, startedEditing } =
      this.state;
    const { person } = this.props;
    person.editContactDetails && !startedEditing && this.handleEditMode();
    const isEmail = e.target.id.includes('email');
    const index = e.target.id.split('-')[1];
    const fieldForUpdate = isEmail
      ? [...aditionalEmailFields]
      : [...aditionalPhoneFields];
    isEmail
      ? ((fieldForUpdate[index] as ExtendedContactEmailInterface).email =
          e.target.value)
      : ((fieldForUpdate[index] as ExtendedContactPhoneInterface).number =
          e.target.value);
    isEmail
      ? this.setState({
          aditionalEmailFields:
            fieldForUpdate as ExtendedContactEmailInterface[],
        })
      : this.setState({
          aditionalPhoneFields:
            fieldForUpdate as ExtendedContactPhoneInterface[],
        });
    !isEmail && this.checkIfExtrInfoSidebar();
  };

  handleEditMode = () => {
    if (!this.state.startedEditing) {
      this.setState({ startedEditing: true });
      this.props.person.setIsEditingContact(true);
    }
  };

  handleChangeType = (e, d) => {
    const { aditionalEmailFields, aditionalPhoneFields, startedEditing } =
      this.state;
    const { person } = this.props;
    person.editContactDetails && !startedEditing && this.handleEditMode();
    const index = d.id.split('-')[1];
    const fieldForUpdate = d.id.includes('email')
      ? [...aditionalEmailFields]
      : [...aditionalPhoneFields];
    fieldForUpdate[index].type = d.value;
    d.id.includes('email')
      ? this.setState({
          aditionalEmailFields:
            fieldForUpdate as ExtendedContactEmailInterface[],
        })
      : this.setState({
          aditionalPhoneFields:
            fieldForUpdate as ExtendedContactPhoneInterface[],
        });
  };

  insertNewField = (fieldType: 'Email' | 'PhoneNumber') => () => {
    const { aditionalEmailFields, aditionalPhoneFields } = this.state;

    fieldType === 'Email'
      ? this.setState({
          aditionalEmailFields: [
            ...aditionalEmailFields,
            {
              id: null,
              email: '',
              type: 'work',
              guid: new Date().getMilliseconds(),
            },
          ],
        })
      : this.setState({
          aditionalPhoneFields: [
            ...aditionalPhoneFields,
            {
              id: null,
              number: '',
              type: 'mobile',
              guid: new Date().getMilliseconds(),
            },
          ],
        });
  };

  removeFieldFromList = (field: string, guid: number) => () => {
    const newList =
      field === 'Email'
        ? this.state.aditionalEmailFields.filter((item) => item.guid !== guid)
        : this.state.aditionalPhoneFields.filter((item) => item.guid !== guid);
    field === 'Email'
      ? this.setState({
          aditionalEmailFields: newList as ExtendedContactEmailInterface[],
          startedEditing: true,
        })
      : this.setState({
          aditionalPhoneFields: newList as ExtendedContactPhoneInterface[],
          startedEditing: true,
        });
  };

  checkIsFormValid = (contact: Contact) => {
    const {
      person: { setErrorNewContact },
    } = this.props;
    const phoneNumEmpty = contact.phoneNumbers?.find(
      (phone) => !phone.type || !phone.number || phone.number === '+'
    );
    const invalidFirstName =
      !contact.firstName ||
      isNullOrUndefined(contact.firstName) ||
      contact.firstName.trim().length === 0;
    const invalidLastName =
      !contact.lastName ||
      isNullOrUndefined(contact.lastName) ||
      contact.lastName.trim().length === 0;
    const invalidOrganization =
      !contact.organization ||
      isNullOrUndefined(contact.organization) ||
      contact.organization.trim().length === 0;
    if (!contact.accountId && isNullOrUndefined(contact.isShared)) {
      setErrorNewContact({
        show: true,
        message: 'Save contact to field is required',
        field: 'selectedSource',
      });
      return false;
    }

    if ((invalidFirstName || invalidLastName) && invalidOrganization) {
      invalidLastName &&
        setErrorNewContact({
          show: true,
          message: 'Last name field is required',
          field: 'lastName',
        });
      invalidFirstName &&
        setErrorNewContact({
          show: true,
          message: 'First name field is required',
          field: 'firstName',
        });
      return false;
    }
    if (invalidOrganization && (invalidFirstName || invalidLastName)) {
      setErrorNewContact({
        show: true,
        message: 'Organization field is required',
        field: 'organization',
      });
      return false;
    }

    if (phoneNumEmpty) {
      const index = contact.phoneNumbers.indexOf(phoneNumEmpty);
      setErrorNewContact({
        show: true,
        message: 'Phone number and phone type field are required',
        field: `phoneField${index}`,
      });
      return false;
    } else if (contact.phoneNumbers.length > 0) {
      let valid = true;
      contact.phoneNumbers.forEach((phone, index) => {
        try {
          if (
            !this.props.person.checkPhoneNumberValidation(phone.number) ||
            this.props.person.phoneNumInvalid(phone.number)
          ) {
            setErrorNewContact({
              show: true,
              message: 'Phone number is not in a correct format',
              field: `phoneField${index}`,
            });
            valid = false;
          }
        } catch (err) {
          setErrorNewContact({
            show: true,
            message: 'Phone number is not in a correct format',
            field: `phoneField${index}`,
          });
          valid = false;
        }
      });
      return valid;
    }
    return true;
  };

  handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { person, navigate } = this.props;
    const isBHiveSource =
      typeof this.state.formData['selectedSource'] !== 'number';
    const allEmails: ContactEmailInterface[] = [
      ...this.state.formData.emails,
      ...this.state.aditionalEmailFields,
    ]
      .map((item) => ({ id: item?.id, type: item?.type, email: item?.email }))
      .filter((item) => item.type && item.email);
    const allPhones: ContactPhoneInterface[] = [
      ...this.state.formData.phoneNumbers,
      ...this.state.aditionalPhoneFields,
    ]
      .map((item) => ({
        id: item?.id,
        type: item?.type,
        number: person.removeAllSpecialCaracters(item.number),
      }))
      .filter((item) => item.number);
    const formatedData: Contact = {
      ...this.state.formData,
      emails: allEmails,
      phoneNumbers: allPhones,
    };
    if (
      isBHiveSource &&
      (formatedData.pictureKey ||
        formatedData.pictureUrl ||
        this.state.profilePicture)
    ) {
      formatedData.hasProfilePicture = true;
    } else {
      formatedData.hasProfilePicture = false;
      formatedData.pictureUrl = undefined;
      formatedData.pictureKey = undefined;
    }
    const isValid = this.checkIsFormValid(formatedData);
    if (isValid) {
      this.setState({ savingInProgress: true });
      //external source, image should not be uploaded to the s3
      formatedData.accountId && person.setContactPictureLocally(null);
      const resp =
        person.editContactDetails && !person.isAddingContact
          ? await person.updateContact(formatedData as any)
          : await person.addNewContact(formatedData as any);
      if (resp) {
        navigate(-1);
        this.closeEditingMode();
      } else {
        this.setState({ savingInProgress: false });
      }
    } else {
      return false;
    }
  };

  makeCall = (e) => {
    const { phoneCall, person } = this.props;
    e.stopPropagation();
    if (person.editContactDetails.phoneNumbers.length > 1) {
      this.setState({ operationType: 'call' });
    } else if (person.editContactDetails?.phoneNumbers.length === 1) {
      const phone = person.editContactDetails?.phoneNumbers[0].number;
      phoneCall.callWithPerson(null, phone);
    }
  };

  handleOpenChat = async (e) => {
    const { conversation, person, navigate } = this.props;
    e.stopPropagation();
    if (person.editContactDetails?.phoneNumbers.length > 1) {
      this.setState({ operationType: 'message' });
    } else if (person.editContactDetails?.phoneNumbers.length === 1) {
      const phone = person.editContactDetails?.phoneNumbers[0].number;
      const resp = await conversation.loadOrCreateConversationWithPost(
        null,
        phone
      );
      navigate(`/chat/conversations/${resp.data.id}/menu`);
    }
  };

  clearOperationType = () => this.setState({ operationType: null });

  closeEditingMode = () => {
    const { person } = this.props;
    person.setEditContact(null);
    person.setIsEditingContact(false);
    this.setState({
      startedEditing: false,
      formData: this.initializedFormData,
      savingInProgress: false,
    });
    person.setEditingLeavePopup({ show: false, wantedUrl: '' });
    if (person.isAddingContact) {
      person.setIsAddingContact(false);
    }
  };

  mapDataFromProps = () => {
    const formData = {};
    const editContactData = this.props.person.editContactDetails;

    Object.keys(this.state.formData).forEach((key) => {
      if (['emails', 'phoneNumbers'].includes(key)) {
        const value = editContactData[key];
        const data = value.map((resp) =>
          key === 'emails'
            ? { type: resp.type, id: resp.id, email: resp.email }
            : { type: resp.type, id: Number(resp.id), number: resp.number }
        );
        formData[key] =
          value.length > 0
            ? data
            : key === 'emails'
            ? [
                {
                  type: 'work',
                  email: '',
                  id: null,
                },
              ]
            : [
                {
                  type: 'mobile',
                  number: '',
                  id: null,
                },
              ];
      } else if (key === 'accountId') {
        formData[key] = editContactData[key];
        formData['selectedSource'] = editContactData[key];
      } else if (key === 'source') {
        formData[key] = editContactData[key];
        //checking source because we dont have accountId for contacts of Bhive
        if (editContactData[key] === 'B_HIVE_PERSONAL') {
          formData['isShared'] = false;
          formData['selectedSource'] = editContactData[key];
        } else if (
          editContactData[key] === 'B_HIVE_SHARED' ||
          editContactData[key] === 'EXTERNAL'
        ) {
          formData['isShared'] = true;
          formData['selectedSource'] = editContactData[key];
          this.setState({ isSourceDropdownVisible: false });
        }
      } else if (!this.state.formData[key]) {
        formData[key] = editContactData[key];
      }
    });
    return { ...this.state.formData, ...formData };
  };

  toggleRemoveModal = () =>
    this.setState({ showRemoveModal: !this.state.showRemoveModal });

  handleRedirectOnEdit = () => {
    const { person, navigate } = this.props;
    const url = person.showEditingLeavePopup.wantedUrl;
    person.setContactPictureLocally(null);
    this.closeEditingMode();
    navigate(url);
  };

  toggleEditLeaveModal = () => {
    const {
      person: { setEditingLeavePopup, showEditingLeavePopup },
    } = this.props;
    setEditingLeavePopup({
      show: !showEditingLeavePopup,
      wantedUrl: showEditingLeavePopup.wantedUrl,
    });
  };

  handleRemoveContact = async () => {
    const { person, navigate } = this.props;
    if (person.isEditingContact) {
      person.setIsEditingContact(false);
    }
    await person.removeContact(person.editContactDetails.id);
    this.setState({ showRemoveModal: false });
    navigate(-1);
  };

  handleSaveAndContinue = (e) => {
    this.toggleEditLeaveModal();
    this.handleSave(e);
  };

  RemoveModal = () => {
    const { editContactDetails } = this.props.person;
    const firstName = editContactDetails ? editContactDetails.firstName : '';
    const lastName = editContactDetails ? editContactDetails.lastName : '';
    return (
      <Modal
        size="small"
        id="remove-modal"
        className="remove-modal"
        open={this.state.showRemoveModal}
        dimmer="blurring"
        closeOnDimmerClick={true}
        centered={true}
        onClose={this.toggleRemoveModal}
      >
        <Modal.Content>
          <div className="text-center">
            You are about to remove <b>{`${firstName} ${lastName}`}</b> from
            contacts. <br />
            Are you sure?
          </div>
          <div className="buttons flex-row flex-align-items-center flex-justify-center">
            <Button primary onClick={this.toggleRemoveModal}>
              No
            </Button>
            <Button secondary onClick={this.handleRemoveContact}>
              Yes
            </Button>
          </div>
        </Modal.Content>
        <img src={closeBtn} onClick={this.toggleRemoveModal} />
      </Modal>
    );
  };

  EditLeaveModal = () => {
    return (
      <Modal
        size="small"
        id="remove-modal"
        className="remove-modal"
        open={this.props.person.showEditingLeavePopup.show}
        dimmer="blurring"
        closeOnDimmerClick={true}
        centered={true}
        onClose={this.toggleEditLeaveModal}
      >
        <Modal.Content>
          <div className="text-center">
            The edited contact details are not saved yet.
            <br />
            Would you like to continue without saving?
          </div>
          <div className="buttons flex-row flex-align-items-center flex-justify-center">
            <Button primary onClick={this.handleSaveAndContinue}>
              SAVE AND CONTINUE
            </Button>
            <Button secondary onClick={this.handleRedirectOnEdit}>
              Yes
            </Button>
          </div>
        </Modal.Content>
        <img src={closeBtn} onClick={this.toggleEditLeaveModal} />
      </Modal>
    );
  };

  EditSection = () => {
    const { person, conversation, phoneCall } = this.props;
    return (
      <div className="edit-contact-section">
        <div className="buttons">
          {person.editContactDetails &&
            !person.isAddingContact &&
            this.state.operationType && (
              <PhoneNumbersPopup
                phoneNumbers={person.editContactDetails.phoneNumbers}
                callWithPerson={phoneCall.callWithPerson}
                loadOrCreateConversationWithPost={
                  conversation.loadOrCreateConversationWithPost
                }
                operationType={this.state.operationType}
              />
            )}
          <span className="icons" onClick={this.handleOpenChat}>
            <img src={messageIcon} />
          </span>
          <span className="icons left-8" onClick={this.makeCall}>
            <img src={phoneIcon} />
          </span>
          <span className="text-section" onClick={this.toggleRemoveModal}>
            DELETE CONTACT
          </span>
        </div>
      </div>
    );
  };

  redirectToPrevPage = () => {
    const { navigate, location, person } = this.props;
    if (location.state?.from === '/contacts') {
      if (person.isEditingContact) {
        person.setEditingLeavePopup({
          show: true,
          wantedUrl: '/addressBook/contacts',
        });
        return;
      }
      navigate('/addressBook/contacts');
      return;
    }
    navigate(-1);
    this.closeEditingMode();
  };

  fileImportEvent = async (e: any): Promise<void> => {
    this.setState({
      ...this.state,
      loadingProfilePictureUrl: true,
    });
    const { person } = this.props;
    const files = e.clipboardData?.files || e.target?.files || [];
    if (!files) {
      return;
    }
    const importedFiles: File[] = Array.from(files);
    person.setContactPictureLocally(importedFiles[0]);
    this.setState({
      ...this.state,
      loadingProfilePictureUrl: false,
      profilePicture: URL.createObjectURL(importedFiles[0]),
      formData: { ...this.state.formData },
    });
    person.editContactDetails &&
      !this.state.startedEditing &&
      this.handleEditMode();
  };

  setDefaultOnOpen = (e, d) => {
    if (d.id === 'phoneNumberType') {
      const index = this.state.formData.phoneNumbers.length - 1;
      const phone = this.state.formData.phoneNumbers[index];
      if (!phone.type) {
        this.setState({
          formData: {
            ...this.state.formData,
            phoneNumbers: [
              ...this.state.formData.phoneNumbers.slice(0, index),
              { ...phone, type: 'mobile' },
            ],
          },
        });
      }
    } else if (d.id === 'emailType') {
      const index = this.state.formData.emails.length - 1;
      const email = this.state.formData.emails[index];
      if (!email.type) {
        this.setState({
          formData: {
            ...this.state.formData,
            emails: [
              ...this.state.formData.emails.slice(0, index),
              { ...email, type: 'work' },
            ],
          },
        });
      }
    }
  };

  render() {
    const { person, ui } = this.props;
    const {
      formData,
      aditionalEmailFields,
      aditionalPhoneFields,
      loadingProfilePictureUrl,
      profilePicture,
      startedEditing,
      dropdownOptions,
    } = this.state;
    const phoneNumber = formData?.phoneNumbers[0]?.number || '';
    const formatedPhoneNumber = formatNumberWithNationalCode(phoneNumber) || '';
    const showImageUpload =
      !loadingProfilePictureUrl &&
      typeof formData['selectedSource'] !== 'number'; // selectedSource for external providers are numbers

    return (
      <div className="new-contact" onClick={this.clearOperationType}>
        <div className="flex-row">
          <div className="contact-profile">
            {profilePicture || person.editContactDetails?.pictureUrl ? (
              <img
                data-private
                className="hasImage"
                src={profilePicture || person.editContactDetails?.pictureUrl}
              />
            ) : person.editContactDetails && !person.isAddingContact ? (
              <UserAvatar
                name={person.editContactDetails.DisplayName()}
                selectUnreadCounts={ui.selectConversationUnreadCounts}
                presence={null}
              />
            ) : (
              <img src={user} />
            )}
            {showImageUpload && (
              <>
                <input
                  type="file"
                  onChange={this.fileImportEvent}
                  accept="image/png, image/gif, image/jpeg"
                  className="inputfile"
                  id="profilefileinput"
                />
                <label htmlFor="profilefileinput">
                  <img src={editIconWhite} />
                </label>
              </>
            )}
          </div>
          {person.editContactDetails && !person.isAddingContact && (
            <this.EditSection />
          )}
          <div
            className={`buttons flex-row header-buttons ${
              person.editContactDetails ? 'left-30' : ''
            }`}
          >
            <Button
              primary
              type="button"
              onClick={this.handleSave}
              disabled={
                (person.editContactDetails &&
                  !startedEditing &&
                  !person.isAddingContact) ||
                this.state.savingInProgress
              }
            >
              {!person.editContactDetails || person.isAddingContact
                ? 'ADD CONTACT'
                : 'SAVE'}
            </Button>
            <Button onClick={this.redirectToPrevPage} secondary>
              CANCEL
            </Button>
          </div>
        </div>
        <div className="form flex-row">
          <div className="flex-evenly">
            {this.state.isSourceDropdownVisible && (
              <Dropdown
                data-private
                id="selectedSource"
                selectOnBlur={false}
                value={formData['selectedSource'] || ''}
                className={`form-input ${
                  person.newContactErrors.field === 'selectedSource'
                    ? 'error-field'
                    : ''
                }`}
                placeholder="Save Contact To"
                fluid
                selection
                options={dropdownOptions}
                onChange={this.handleChange}
              />
            )}

            <Input
              data-private
              className={`form-input ${
                person.newContactErrors.field === 'firstName'
                  ? 'error-field'
                  : ''
              }`}
              value={formData?.firstName || ''}
              placeholder="First Name"
              id="firstName"
              onChange={this.handleChange}
            />
            <Input
              data-private
              className={`form-input ${
                person.newContactErrors.field === 'lastName'
                  ? 'error-field'
                  : ''
              }`}
              value={formData?.lastName || ''}
              placeholder="Last Name"
              id="lastName"
              onChange={this.handleChange}
            />
            <Input
              data-private
              className={`form-input ${
                person.newContactErrors.field === 'organization'
                  ? 'error-field'
                  : ''
              }`}
              value={formData?.organization || ''}
              placeholder="Organization (Company)"
              id="organization"
              onChange={this.handleChange}
            />
            <Input
              data-private
              className="form-input"
              value={formData?.organizationRole || ''}
              placeholder="Role"
              id="organizationRole"
              onChange={this.handleChange}
            />
            <div className="flex-row">
              <Input
                data-private
                className={`form-input ${
                  person.newContactErrors.field === 'phoneField0'
                    ? 'error-field'
                    : ''
                }`}
                value={formatedPhoneNumber || formData?.phoneNumbers[0]?.number}
                placeholder="Phone number"
                id="phoneNumber"
                onChange={this.handleChange}
              />
              <Dropdown
                data-private
                id="phoneNumberType"
                className={`form-input type-input ${
                  person.newContactErrors.field === 'phoneField0'
                    ? 'error-field'
                    : ''
                }`}
                value={formData?.phoneNumbers[0]?.type || ''}
                placeholder="Type"
                fluid
                selection
                options={PhoneNumberTypes}
                onChange={this.handleChange}
                onOpen={this.setDefaultOnOpen}
              />
              <img
                src={addNewFiledIcon}
                onClick={this.insertNewField('PhoneNumber')}
              />
            </div>
            {aditionalPhoneFields?.map((phone, index) => {
              return (
                <div
                  className="flex-row"
                  key={`phoneNumberSection-${phone.guid}`}
                >
                  <Input
                    data-private
                    className={`form-input ${
                      person.newContactErrors.field === `phoneField${index + 1}`
                        ? 'error-field'
                        : ''
                    }`}
                    value={
                      formatNumberWithNationalCode(phone.number) ||
                      phone.number ||
                      ''
                    }
                    placeholder="Phone number"
                    id={`phoneNumber-${index}`}
                    name={`phoneNumber-${index}`}
                    onChange={this.handleChangeAdditionalFields}
                  />
                  <Dropdown
                    id={`phoneNumberType-${index}`}
                    className={`form-input type-input ${
                      person.newContactErrors.field === `phoneField${index + 1}`
                        ? 'error-field'
                        : ''
                    }`}
                    name={`phoneNumberType-${index}`}
                    value={phone.type || ''}
                    placeholder="Type"
                    fluid
                    selection
                    options={PhoneNumberTypes}
                    onChange={this.handleChangeType}
                  />
                  <img
                    src={removeFiledIcon}
                    onClick={this.removeFieldFromList(
                      'PhoneNumber',
                      phone.guid
                    )}
                  />
                </div>
              );
            })}
            <div className="flex-row">
              <Input
                data-private
                className={`form-input ${
                  person.newContactErrors.field === 'emailField0'
                    ? 'error-field'
                    : ''
                }`}
                type="email"
                value={formData?.emails[0]?.email || ''}
                placeholder="Email"
                id="email"
                onChange={this.handleChange}
              />
              <Dropdown
                id="emailType"
                className={`form-input type-input ${
                  person.newContactErrors.field === 'emailField0'
                    ? 'error-field'
                    : ''
                }`}
                value={formData?.emails[0]?.type || ''}
                placeholder="Type"
                fluid
                selection
                options={EmailTypes}
                onChange={this.handleChange}
              />
              <img
                src={addNewFiledIcon}
                onClick={this.insertNewField('Email')}
              />
            </div>
            {aditionalEmailFields?.map((email, index) => {
              return (
                <div className="flex-row" key={`emailSection-${email.guid}`}>
                  <Input
                    type="email"
                    className={`form-input ${
                      person.newContactErrors.field === `emailField${index + 1}`
                        ? 'error-field'
                        : ''
                    }`}
                    value={email.email || ''}
                    placeholder="Email"
                    id={`email-${index}`}
                    name={`email-${index}`}
                    onChange={this.handleChangeAdditionalFields}
                  />
                  <Dropdown
                    id={`emailType-${index}`}
                    name={`emailType-${index}`}
                    className={`form-input type-input ${
                      person.newContactErrors.field === `emailField${index + 1}`
                        ? 'error-field'
                        : ''
                    }`}
                    value={email.type || ''}
                    placeholder="Type"
                    fluid
                    selection
                    options={EmailTypes}
                    onChange={this.handleChangeType}
                  />
                  <img
                    src={removeFiledIcon}
                    onClick={this.removeFieldFromList('Email', email.guid)}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-evenly">
            <TextArea
              data-private
              id="notes"
              rows={8}
              className="form-input"
              value={formData?.notes || ''}
              placeholder="Notes"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <this.RemoveModal />
        <this.EditLeaveModal />
      </div>
    );
  }
}

export default inject(
  STORE_UI,
  STORE_PREFERENCE,
  STORE_NOTIFICATION,
  STORE_CONVERSATION,
  STORE_PERSON,
  STORE_PHONE_CALL
)(withRouter(observer(AddNewContact)));
