import withRouter from 'Hocs/WithRouter';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { MergeAttendeeOptionsDropdownProps } from './interfaces';

const MUTE = 'Mute';
const UNMUTE = 'Unmute';
const MESSAGE_DIRECTLY = 'Message Directly';
const REMOVE_FROM_CALL = 'Remove from Call';

const MergeAttendeeOptionsDropdown: React.FC<
  MergeAttendeeOptionsDropdownProps
> = ({ sendMessage, isPerson, navigate, markup, attendeeId, testId }) => {
  const handleChange = (e, { value }) => {
    e.stopPropagation();
    const attendeeOpt = e.target.innerText;

    switch (attendeeOpt) {
      case MUTE:
      case UNMUTE:
        // Future merge work
        break;
      case MESSAGE_DIRECTLY:
        sendMessage(...(isPerson ? [value] : [null, value])).then((resp) =>
          navigate(`/chat/conversations/${resp.data.id}/menu`)
        );
        break;
      case REMOVE_FROM_CALL:
        // Future merge work
        break;
      default:
        break;
    }
  };

  const options = [
    // { key: 1, text: !isMuted ? MUTE : UNMUTE, value: attendeeId, disabled: isAdmin === false },
    { key: 2, text: MESSAGE_DIRECTLY, value: attendeeId },
    // { key: 3, text: REMOVE_FROM_CALL, value: attendeeId, disabled:  isAdmin === false}
  ];

  return (
    <Dropdown
      selectOnBlur={false}
      trigger={markup}
      fluid
      direction="left"
      options={options}
      icon={null}
      value=""
      onChange={handleChange}
      data-testid={`${testId}-mergeAttendeeOptionsDropdown`}
    />
  );
};

export const RoutedMergeAttendeeOptionsDropdown = withRouter(
  MergeAttendeeOptionsDropdown
);
