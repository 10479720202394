import type { UseEnumerateSystemMessageParticipantsProps } from './useEnumerateSystemMessageParticipants.types';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './useEnumerateSystemMessageParticipants.styles';

export const useEnumerateSystemMessageParticipants: UseEnumerateSystemMessageParticipantsProps =
  (reference, participants) => {
    const {
      contact: { loadContactByPhoneNumber },
      person: { selectPersonById, loggedInPersonId },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const deduplicateParticipants = participants?.filter(
      (participant, index) =>
        participants.findIndex(
          ({ personId }) => personId === participant.personId
        ) === index
    );

    const formattedParticipants = deduplicateParticipants?.map(
      (
        { personId, phone }: { personId: number; phone: string },
        index: number
      ): React.ReactNode => {
        const person =
          personId > 0
            ? selectPersonById(personId)
            : loadContactByPhoneNumber(phone);

        return person?.case({
          fulfilled: ({ data }) => (
            <>
              {loggedInPersonId === personId ? (
                'you'
              ) : (
                <Styled.SemiBold key={data.id}>
                  {data.DisplayName || data.phoneNumber}
                </Styled.SemiBold>
              )}
              {index < deduplicateParticipants.length - 2 && ', '}
              {index === deduplicateParticipants.length - 2 && ' and '}
            </>
          ),
        });
      }
    );

    const formattedAuthor =
      parseInt(reference) === loggedInPersonId
        ? 'You'
        : selectPersonById(parseInt(reference))?.case({
            fulfilled: ({ data }) => (
              <Styled.SemiBold>
                {data.DisplayName || data.phoneNumber}
              </Styled.SemiBold>
            ),
          });

    const isAuthorAlsoTheParticipant =
      deduplicateParticipants?.length === 1 &&
      deduplicateParticipants[0].personId === parseInt(reference);

    return {
      isAuthorAlsoTheParticipant,
      formattedAuthor,
      formattedParticipants,
    };
  };
