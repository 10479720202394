import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

// Workaround until we move on from semantic ui
const StyledButton = styled(Button)<{ $destructive: boolean }>`
  &&&&& {
    width: 100%;
    text-align: start;
    &:hover {
      background: ${({ theme }) => theme.colors.backgroundHover} !important;
    }
    ${({ $destructive, theme }) =>
      `color: ${$destructive ? '#f95050' : theme.colors.text} !important;`}
  }
`;

export const Styled = {
  Button: StyledButton,
};
