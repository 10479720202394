import { BodyCopy } from 'Components/shared/BodyCopy';
import styled, { css } from 'styled-components';

const ParentBox = styled.div<{ $isOwnMessage: boolean }>`
  width: fit-content;
  max-width: 64%;
  ${({ $isOwnMessage }) => $isOwnMessage && 'margin: 0 5px 5px auto;'}
`;

const BoxWithAvatar = styled.div`
  display: flex;
  margin: 3px 0 7px;
`;

const Box = styled.div<{ $isOwnMessage: boolean }>`
  border-radius: 4px;
  position: relative;
  padding: 5px 10px 10px;

  ::after {
    content: '';
    position: absolute;
    top: 10px;
    transform: rotate(-45deg);
    width: 11px;
    height: 11px;
  }

  ${({ $isOwnMessage }) =>
    $isOwnMessage
      ? css`
          background: ${({ theme }) => theme.colors.lightGray};
          ::after {
            right: -5px;
            background: ${({ theme }) => theme.colors.lightGray};
          }
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.border};
          ::after {
            background: ${({ theme }) => theme.colors.base};
            left: -6px;
            border-top: 1px solid ${({ theme }) => theme.colors.border};
            border-left: 1px solid ${({ theme }) => theme.colors.border};
          }
        `}
  ${({ onClick }) => onClick && 'cursor: pointer'}
`;

const NameDateContainer = styled.div`
  display: flex;
  align-items: start;
`;

const Date = styled(BodyCopy)`
  white-space: nowrap;
  margin-left: auto;
`;

const EditTag = styled(BodyCopy)`
  margin-left: ${({ theme }) => theme.sizes.xxxsmall};
`;

const Name = styled(BodyCopy)<{ $showThreeDots: boolean }>`
  font-weight: 700;
  margin-bottom: 3px;
  word-break: break-word;
  ${({ $showThreeDots }) =>
    `margin-right: ${$showThreeDots ? '10px' : '40px'};`}
`;

const Message = styled(BodyCopy)`
  margin-bottom: 5px;
  word-break: break-word;
  white-space: preserve wrap;
`;

const AvatarContainer = styled.div`
  align-self: baseline;
  margin-right: 10px;
`;

export const Styled = {
  AvatarContainer,
  Box,
  BoxWithAvatar,
  Date,
  EditTag,
  Message,
  Name,
  NameDateContainer,
  ParentBox,
};
