import { NODE_ENV_PRODUCTION, TIMEZONE_IDENTIFIER } from 'Constants/env';
import {
  Dictionary,
  differenceBy,
  dropRight,
  each,
  eachRight,
  forOwn,
  groupBy,
  head,
  isEmpty,
  last,
  tail,
} from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { now, createTransformer } from 'mobx-utils';
import moment from 'moment-timezone';
import { isNullOrUndefined } from 'util';
import { getCorrectedMsFromUnixEpoch } from 'Utils/timeUUIDParser';
import { MessageGroup } from './MessageGroup';
import { MessageModel } from './MessageModel';

/** A container for `Message`s grouped by day into `MessageGroup`s (keys in `YYYY-MM-DD` format) */
export class GroupedMessagesContainer {
  constructor(
    conversationId: string,
    messages: MessageModel[],
    getReadMessageId: (conversationId: string) => string
  ) {
    makeObservable(this);
    this.conversationId = conversationId;
    this.getReadMessageId = getReadMessageId;
    if (!isEmpty(messages)) {
      this.pushNewerMessages(...messages);
    }
  }

  readonly conversationId: string;
  readonly getReadMessageId: (conversationId: string) => string;
  static GetGroupKey = (msg: MessageModel) =>
    msg.CreatedMoment.format('YYYY-MM-DD');

  static SortAscending = (messages: MessageModel[]) => {
    if (isEmpty(messages)) {
      if (!NODE_ENV_PRODUCTION) {
        console.debug(
          'SortAscendingAndGroup called with empty messages parameter, returning empty Object.'
        );
      }
      return [] as MessageModel[];
    }

    return messages.sort((a, b) => {
      if (isEmpty(a.id) || isEmpty(b.id) || a.id === b.id) {
        return 0;
      } else if (!isEmpty(a.id) && !isEmpty(b.id)) {
        // If `a` was created after `b`, `b` comes first (we want oldest -> newest)
        return getCorrectedMsFromUnixEpoch(a.id) >
          getCorrectedMsFromUnixEpoch(b.id)
          ? 1
          : -1;
      } else {
        return 0;
      }
    });
  };

  static SortAscendingAndGroup = (messages: MessageModel[]) => {
    if (isEmpty(messages)) {
      if (!NODE_ENV_PRODUCTION) {
        console.debug(
          'SortAscendingAndGroup called with empty messages parameter, returning empty Object.'
        );
      }
      return {} as Dictionary<MessageModel[]>;
    }
    const sorted = GroupedMessagesContainer.SortAscending(messages);
    return groupBy(sorted, GroupedMessagesContainer.GetGroupKey);
  };

  /**
   * Primary map of `MessageGroup`s.
   *
   * Most read operations for a particular day (`MessageGroup`) should be performed on one of these `MessageGroup`s.
   *
   * Most create/update operations should be performed on the overall `GroupedMessageContainer` (this class).
   */
  @observable
  groups = observable.map<string, MessageGroup>();

  /**
   * Milliseconds since Unix Epoch that the newest `Message` was created _or_ added.
   *
   * Upon construction of `GroupedMessagesContainer`, the current unix epoch ms.
   */
  @observable
  private newestMessageCreatedOrAddedEpochMs: number = moment
    .tz(TIMEZONE_IDENTIFIER)
    .valueOf();

  @computed
  get MsSinceNewestMessageCreatedOrAdded() {
    // Notice that the `moment` value is not observable! Recomputes only when `this.newestMessageCreatedOrAddedEpochMs` is changed. (RP 2019-08-30)
    return (
      moment.tz(TIMEZONE_IDENTIFIER).valueOf() -
      this.newestMessageCreatedOrAddedEpochMs
    );
  }

  getReadMessage = () => {
    const readMessageId = this.getReadMessageId(this.conversationId);
    if (isEmpty(readMessageId) || isNullOrUndefined(readMessageId)) {
      return this.NewestMessage;
    }
    return this.selectMessageById(readMessageId);
  };

  selectMessageById = createTransformer(
    (messageId: string) =>
      this.AllMessagesAscending.find((m) => m.id === messageId) || null
  );

  selectContainsMessage = createTransformer(
    (messageId: string) => this.selectMessageById(messageId) !== null
  );

  /**
   * Retrieve a computed array of the contained `MessageGroup`s
   */
  @computed
  get SortedGroups() {
    return this.GroupKeysSorted.map((gk) => this.groups.get(gk));
  }

  /**
   * `id`s of the newest (up to) 5 `Message`s within the newest `MessageGroup`.
   * Will attempt to retrieve `Message`s, up to a max of 5, from older `MessageGroup`s,
   * if the newest `MessageGroup` doesn't have enough.
   */
  @computed
  get NewestFiveMessageIds() {
    const groupKeysOrdered = this.GroupKeysSorted;
    const ids: string[] = [];
    eachRight(groupKeysOrdered, (gk) => {
      if (ids.length >= 5) {
        return false;
      }
      const gkMsgIds = this.groups.get(gk).NewestFiveIds;
      if (gkMsgIds !== null) {
        ids.push(...gkMsgIds);
      }
    });
    return ids;
  }

  /**
   * Group keys (`YYYY-MM-DD`), in whatever order they were stored in (probably Ascending).
   * You should sort these for your own use. (RP 01/03/2018)
   */
  @computed
  get GroupKeys() {
    const gks: string[] = [];
    this.groups.forEach((mg, gk) => {
      gks.push(gk);
    });
    return gks;
  }

  /** `this.GroupKeys`, shallow cloned and sorted */
  @computed
  get GroupKeysSorted() {
    return this.GroupKeys.slice().sort();
  }

  /** The key representing the newest daily message group (formatted `YYYY-MM-DD`) */
  @computed
  get NewestGroupKey() {
    return !isEmpty(this.GroupKeys) ? last(this.GroupKeysSorted) : null;
  }

  /** The newest message in the newest group */
  @computed
  get NewestMessage() {
    return this.NewestGroupKey !== null && this.groups.has(this.NewestGroupKey)
      ? this.groups.get(this.NewestGroupKey).NewestMessage
      : null;
  }

  /** `id` of the newest message in the newest group */
  @computed
  get NewestMessageId() {
    return this.NewestGroupKey !== null && this.groups.has(this.NewestGroupKey)
      ? this.groups.get(this.NewestGroupKey).NewestId
      : null;
  }

  /** The key representing the oldest daily message group (formatted `YYYY-MM-DD`) */
  @computed
  get OldestGroupKey() {
    return !isEmpty(this.GroupKeys) ? this.GroupKeysSorted[0] : null;
  }

  /** The oldest message in the oldest group */
  @computed
  get OldestMessage() {
    return this.OldestGroupKey !== null && this.groups.has(this.OldestGroupKey)
      ? this.groups.get(this.OldestGroupKey).OldestMessage
      : null;
  }

  /** `id` of the oldest message in the oldest group */
  @computed
  get OldestMessageId() {
    return this.OldestGroupKey !== null && this.groups.has(this.OldestGroupKey)
      ? this.groups.get(this.OldestGroupKey).OldestId
      : null;
  }

  /** Get all `MessageModel`s, sorted oldest -> newest (default display sort) */
  @computed
  get AllMessagesAscending() {
    const allMsgs: MessageModel[] = [];
    each(this.GroupKeysSorted, (gk) => {
      allMsgs.push(...this.groups.get(gk).messages.slice());
    });
    // Guarantee ascending sort (via TimeUUID comparison of `.id`s)
    return GroupedMessagesContainer.SortAscending(allMsgs);
  }

  /**
   * Get all `MessageModel`s, sorted newest -> oldest.
   * Use this to scan for an Updated message starting at most recent, therefore most likely matches,
   * since people probably won't edit very old messages.
   */
  @computed
  get AllMessagesDescending() {
    return Array.from(this.AllMessagesAscending).reverse();
  }

  @computed
  get TotalMessageCount() {
    return this.AllMessagesAscending.length;
  }

  @computed
  get TotalUnreadMessageCount() {
    const sortedGroups = this.SortedGroups;
    let totalUnreadMessages = 0;

    for (let index = sortedGroups.length - 1; index >= 0; index--) {
      const group = sortedGroups[index];

      if (!group.UnreadMessages || group.UnreadMessages.length === 0) {
        break;
      }

      totalUnreadMessages += group.UnreadMessages.length;
    }

    return totalUnreadMessages;
  }

  get MessagesWithLinkPreviewHeightCount() {
    return this.AllMessagesAscending.reduce((accum, val) => {
      if (val.linkPreviewDimensions !== null) {
        accum += 1;
      }
      return accum;
    }, 0);
  }

  /** Sum of vertical pixel heights for link preview containers in all `MessageModel`s (used to compensate when scrolling) */
  @computed
  get TotalLinkPreviewHeightSum() {
    return this.AllMessagesAscending.reduce((accum, val) => {
      if (val.linkPreviewDimensions !== null) {
        accum += val.linkPreviewDimensions.height;
      }
      return accum;
    }, 0);
  }

  /** Sum of vertical pixel heights for link preview images in all `MessageModel`s (used to compensate when scrolling) */
  @computed
  get TotalLinkPreviewImageHeightSum() {
    return this.AllMessagesAscending.reduce((accum, val) => {
      if (val.linkPreviewDimensions !== null) {
        accum += val.linkPreviewDimensions.imageHeight;
      }
      return accum;
    }, 0);
  }

  /** Number of Messages with link preview images that have not been loaded (and heights have not been computed), and did not fail when loading */
  @computed
  get MessagesWithLinkPreviewImageNotLoaded() {
    return this.AllMessagesAscending.reduce((accum, val) => {
      if (
        !isEmpty(val.linkPreview) &&
        !isEmpty(val.linkPreview.image) &&
        (val.linkPreviewDimensions === null ||
          !val.linkPreviewDimensions.imageLoadFailed)
      ) {
        accum += 1;
      }
      return accum;
    }, 0);
  }

  findMessageById = createTransformer((messageId: string) => {
    return this.AllMessagesDescending.find((m) => m.id === messageId);
  });

  @action
  deleteMessageById = (messageId: string) => {
    const message = this.findMessageById(messageId);
    const gk = GroupedMessagesContainer.GetGroupKey(message);
    if (!this.groups.has(gk)) {
      this.groups.set(
        gk,
        new MessageGroup(this.conversationId, gk, this.getReadMessage)
      );
    }
    const grp = this.groups.get(gk);
    const messageIndex = grp.messages.findIndex((m) => m.id === messageId);
    if (messageIndex > -1) {
      grp.messages.splice(messageIndex, 1);
    }
  };

  /**
   * Push a `Message` to the appropriate group, without attempting to resolve an overlap point. If there's any newer message already, place the message behind it (this can happen in pusher);
   * (ex. for adding a new message from the user)
   */
  @action
  pushMessageDirect = (message: MessageModel) => {
    const groupKey = GroupedMessagesContainer.GetGroupKey(message);
    this.newestMessageCreatedOrAddedEpochMs = now('frame');

    if (!this.groups.has(groupKey)) {
      this.groups.set(
        groupKey,
        new MessageGroup(this.conversationId, groupKey, this.getReadMessage)
      );
    }

    const group = this.groups.get(groupKey);
    const newestIn5min = group.NewestFirstIn5Message || {};
    const minuteDiff = moment
      .duration(moment(message.created).diff(moment(newestIn5min.created)))
      .asMinutes();

    if (
      minuteDiff > 5 ||
      newestIn5min.personId !== message.personId ||
      newestIn5min.phone !== message.phone ||
      newestIn5min.systemEvent ||
      message.systemEvent
    ) {
      message.isFirstIn5Mins = true;
    }

    let indexToInsert = group.messages.length;

    // TODO: Use findLastIndex (have to update TS first)
    for (let index = group.messages.length - 1; index >= 0; index--) {
      const existingMessage = group.messages[index];
      // If the existing message is newer than the message we're trying to insert, we should insert the new message before the existing message
      // Using CreatedMomentTime seems to create some ordering/time sync issues, so we're using CreatedAtDate instead, which uses the client's local time
      if (
        existingMessage.CreatedAtDate.valueOf() <
        message.CreatedAtDate.valueOf()
      ) {
        break;
      }

      indexToInsert = index;
    }

    group.messages.splice(indexToInsert, 0, message);
  };

  /**
   * Sort and group "newer" `messages`, then insert them in the appropriate `MessageGroup`s.
   *
   * This method will attempt to automatically match the `id`s of `Message`s,
   * using overlapping `id`s to find the correct `push` or `splice` location in the existing arrays
   * (which is possible because we overscan 1 `Message` when loading "more" `Message`s).
   */

  @action
  groupMessagesByMinutes = (
    grouped: Dictionary<MessageModel[]>
  ): Dictionary<MessageModel[]> => {
    for (const key in grouped) {
      let firstMessage = grouped[key][0];
      firstMessage.isFirstIn5Mins = true;
      grouped[key].forEach((message) => {
        const messageDate = moment(message.created);
        const minuteDiff = moment
          .duration(messageDate.diff(moment(firstMessage.created)))
          .asMinutes();
        if (
          minuteDiff > 5 ||
          message.personId !== firstMessage.personId ||
          firstMessage.systemEvent ||
          message.systemEvent
        ) {
          message.isFirstIn5Mins = true;
          firstMessage = message;
        }
      });
    }
    return grouped;
  };

  @action
  pushNewerMessages = (...messages: MessageModel[]) => {
    if (isEmpty(messages)) {
      if (!NODE_ENV_PRODUCTION) {
        console.warn(
          'Attempted call to pushNewerMessages with empty messages parameter, exiting early.'
        );
      }
      return;
    }

    // groups messages by date in a map, where the key is the date in YYYY-MM-DD format
    let groupedMessagesByDate: Dictionary<MessageModel[]> =
      GroupedMessagesContainer.SortAscendingAndGroup(messages);

    const grpedKeys = Object.keys(groupedMessagesByDate);

    groupedMessagesByDate = this.groupMessagesByMinutes(groupedMessagesByDate);

    const lastGroup = groupedMessagesByDate[grpedKeys[grpedKeys.length - 1]];

    // Get the created Unix Epoch ms from the latest `Message`s `CreatedMoment`
    this.newestMessageCreatedOrAddedEpochMs =
      lastGroup[lastGroup.length - 1].CreatedMoment.valueOf();

    forOwn(groupedMessagesByDate, (messages, groupKey) => {
      if (!this.groups.has(groupKey)) {
        // Creates a base message group, but with no messages still
        this.groups.set(
          groupKey,
          new MessageGroup(this.conversationId, groupKey, this.getReadMessage)
        );
      }
      const group = this.groups.get(groupKey);
      if (group.messages.length > 1) {
        const firstNewMsgId = head(messages).id;
        if (firstNewMsgId === group.NewestId) {
          group.messages.push(
            ...GroupedMessagesContainer.SortAscending(tail(messages))
          );
        } else {
          const insertIdx = group.messages.findIndex(
            (m) => m.id === firstNewMsgId
          );
          if (insertIdx > -1) {
            group.messages.splice(
              insertIdx,
              messages.length,
              ...GroupedMessagesContainer.SortAscending(messages)
            );
          } else {
            // This shouldn't happen... it means we are missing some of the contiguous set of Messages in this group.
            // Fallback to basic `push` to prevent app crash, but THIS IS NOT DESIRABLE (RP 2018-02-01)
            const missingMsgs = differenceBy(group.messages, messages, 'id');
            console.warn(
              `pushNewerMessages: Unable to find an insertion index for ${firstNewMsgId}. This means we are missing some of the contiguous set of Messages in this MessageGroup. Pushing the following missingMsgs:`,
              missingMsgs
            );
            group.messages.push(
              ...GroupedMessagesContainer.SortAscending(missingMsgs)
            );
          }
        }
      } else {
        group.messages.push(
          ...GroupedMessagesContainer.SortAscending(messages)
        );
      }
    });
  };

  /**
   * Sort and group "older" `messages`, then insert them in the appropriate `MessageGroup`s.
   *
   * This method will attempt to automatically match the `id`s of `Message`s,
   * using overlapping `id`s to find the correct `unshift` or `splice` location in the existing arrays
   * (which is possible because we overscan 1 `Message` when loading "more" `Message`s).
   */
  @action
  unshiftOlderMessages = (...messages: MessageModel[]) => {
    if (isEmpty(messages)) {
      if (!NODE_ENV_PRODUCTION) {
        console.warn(
          'Attempted call to unshiftOlderMessages with empty messages parameter, exiting early.'
        );
      }
      return;
    }
    let grouped = GroupedMessagesContainer.SortAscendingAndGroup(messages);
    grouped = this.groupMessagesByMinutes(grouped);
    forOwn(grouped, (msgs, gk) => {
      if (!this.groups.has(gk)) {
        this.groups.set(
          gk,
          new MessageGroup(this.conversationId, gk, this.getReadMessage)
        );
      }
      const grp = this.groups.get(gk);
      if (grp.messages.length > 1) {
        const lastNewMsgId = last(msgs).id;
        if (lastNewMsgId === grp.OldestId) {
          grp.messages.unshift(
            ...GroupedMessagesContainer.SortAscending(dropRight(msgs, 1))
          );
        } else {
          const insertIdx = grp.messages.findIndex(
            (m) => m.id === lastNewMsgId
          );
          if (insertIdx > -1) {
            grp.messages.splice(
              insertIdx,
              1,
              ...GroupedMessagesContainer.SortAscending(msgs)
            );
          } else {
            // This shouldn't happen... it means we are missing some of the contiguous set of Messages in this group.
            // Fallback to basic `unshift` to prevent app crash, but THIS IS NOT DESIRABLE (RP 01/02/2018)
            console.warn(
              `unshiftOlderMessages: Unable to find an insertion index for ${lastNewMsgId}. This means we are missing some of the contiguous set of Messages in this MessageGroup. Falling back to unshift at start.`
            );
            grp.messages.unshift(
              ...GroupedMessagesContainer.SortAscending(msgs)
            );
          }
        }
      } else {
        grp.messages.unshift(...GroupedMessagesContainer.SortAscending(msgs));
      }
    });
  };
}
