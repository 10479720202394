import { isObject } from 'lodash';
// Adapted from https://github.com/levithomason/keyboard-key/blob/master/src/keyToCode.js

/** `keyCode` -> `key` mapping for all keys except F1-F24 and a-zA-Z (which are generated below) */
export const codeToKey = {
  // ----------------------------------------
  // By Code
  // ----------------------------------------
  3: 'Cancel',
  6: 'Help',
  8: 'Backspace',
  9: 'Tab',
  12: 'Clear',
  13: 'Enter',
  16: 'Shift',
  17: 'Control',
  18: 'Alt',
  19: 'Pause',
  20: 'CapsLock',
  27: 'Escape',
  28: 'Convert',
  29: 'NonConvert',
  30: 'Accept',
  31: 'ModeChange',
  32: ' ',
  33: 'PageUp',
  34: 'PageDown',
  35: 'End',
  36: 'Home',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  39: 'ArrowRight',
  40: 'ArrowDown',
  41: 'Select',
  42: 'Print',
  43: 'Execute',
  44: 'PrintScreen',
  45: 'Insert',
  46: 'Delete',
  48: ['0', ')'],
  49: ['1', '!'],
  50: ['2', '@'],
  51: ['3', '#'],
  52: ['4', '$'],
  53: ['5', '%'],
  54: ['6', '^'],
  55: ['7', '&'],
  56: ['8', '*'],
  57: ['9', '('],
  91: 'OS',
  93: 'ContextMenu',
  144: 'NumLock',
  145: 'ScrollLock',
  181: 'VolumeMute',
  182: 'VolumeDown',
  183: 'VolumeUp',
  186: [';', ':'],
  187: ['=', '+'],
  188: [',', '<'],
  189: ['-', '_'],
  190: ['.', '>'],
  191: ['/', '?'],
  192: ['`', '~'],
  219: ['[', '{'],
  220: ['\\', '|'],
  221: [']', '}'],
  222: ["'", '"'],
  224: 'Meta',
  225: 'AltGraph',
  246: 'Attn',
  247: 'CrSel',
  248: 'ExSel',
  249: 'EraseEof',
  250: 'Play',
  251: 'ZoomOut',
};

// Function Keys (F1-24)
for (let i = 0; i < 24; i += 1) {
  codeToKey[112 + i] = 'F' + (i + 1);
}

// Alphabet (a-Z)
for (let j = 0; j < 26; j += 1) {
  const n = j + 65;
  codeToKey[n] = [String.fromCharCode(n + 32), String.fromCharCode(n)];
}

/** `key` -> `keyCode`/`which` mapping */
export const keyToCode = {
  Cancel: 3,
  Help: 6,
  Backspace: 8,
  Tab: 9,
  Clear: 12,
  Enter: 13,
  Shift: 16,
  Control: 17,
  Alt: 18,
  Pause: 19,
  CapsLock: 20,
  Escape: 27,
  Convert: 28,
  NonConvert: 29,
  Accept: 30,
  ModeChange: 31,
  ' ': 32,
  Spacebar: 32,
  PageUp: 33,
  PageDown: 34,
  End: 35,
  Home: 36,
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
  Select: 41,
  Print: 42,
  Execute: 43,
  PrintScreen: 44,
  Insert: 45,
  Delete: 46,
  '0': 48,
  Digit0: 48,
  ')': 48,
  RightParenthesis: 48,
  '1': 49,
  Digit1: 49,
  '!': 49,
  ExclamationPoint: 49,
  '2': 50,
  Digit2: 50,
  '@': 50,
  AtSign: 50,
  '3': 51,
  Digit3: 51,
  '#': 51,
  PoundSign: 51,
  '4': 52,
  Digit4: 52,
  $: 52,
  DollarSign: 52,
  '5': 53,
  Digit5: 53,
  '%': 53,
  PercentSign: 53,
  '6': 54,
  Digit6: 54,
  '^': 54,
  Caret: 54,
  '7': 55,
  Digit7: 55,
  '&': 55,
  Ampersand: 55,
  '8': 56,
  Digit8: 56,
  '*': 56,
  Asterisk: 56,
  '9': 57,
  Digit9: 57,
  '(': 57,
  LeftParenthesis: 57,
  a: 65,
  A: 65,
  b: 66,
  B: 66,
  c: 67,
  C: 67,
  d: 68,
  D: 68,
  e: 69,
  E: 69,
  f: 70,
  F: 70,
  g: 71,
  G: 71,
  h: 72,
  H: 72,
  i: 73,
  I: 73,
  j: 74,
  J: 74,
  k: 75,
  K: 75,
  l: 76,
  L: 76,
  m: 77,
  M: 77,
  n: 78,
  N: 78,
  o: 79,
  O: 79,
  p: 80,
  P: 80,
  q: 81,
  Q: 81,
  r: 82,
  R: 82,
  s: 83,
  S: 83,
  t: 84,
  T: 84,
  u: 85,
  U: 85,
  v: 86,
  V: 86,
  w: 87,
  W: 87,
  x: 88,
  X: 88,
  y: 89,
  Y: 89,
  z: 90,
  Z: 90,
  OS: 91,
  ContextMenu: 93,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  F13: 124,
  F14: 125,
  F15: 126,
  F16: 127,
  F17: 128,
  F18: 129,
  F19: 130,
  F20: 131,
  F21: 132,
  F22: 133,
  F23: 134,
  F24: 135,
  NumLock: 144,
  ScrollLock: 145,
  VolumeMute: 181,
  VolumeDown: 182,
  VolumeUp: 183,
  ';': 186,
  Semicolon: 186,
  ':': 186,
  Colon: 186,
  '=': 187,
  EqualsSign: 187,
  '+': 187,
  PlusSign: 187,
  ',': 188,
  Comma: 188,
  '<': 188,
  LeftAngleBracket: 188,
  '-': 189,
  MinusSign: 189,
  _: 189,
  Underscore: 189,
  '.': 190,
  Period: 190,
  '>': 190,
  RightAngleBracket: 190,
  '/': 191,
  Slash: 191,
  '?': 191,
  QuestionMark: 191,
  '`': 192,
  GraveAccent: 192,
  '~': 192,
  Tilde: 192,
  '[': 219,
  LeftSquareBracket: 219,
  '{': 219,
  LeftCurlyBrace: 219,
  '\\': 220,
  BackSlash: 220,
  '|': 220,
  Pipe: 220,
  ']': 221,
  RightSquareBracket: 221,
  '}': 221,
  RightCurlyBrace: 221,
  "'": 222,
  SingleQuote: 222,
  '"': 222,
  DoubleQuote: 222,
  Meta: 224,
  AltGraph: 225,
  Attn: 246,
  CrSel: 247,
  ExSel: 248,
  EraseEof: 249,
  Play: 250,
  ZoomOut: 251,
};

export type KeyboardKeyNames = keyof typeof keyToCode;

/**
 * Get the `which` (or fall back to `keyCode`) value from a keyboard event or `key` name.
 * @see https://www.w3.org/TR/uievents/#relationship-between-key-code
 */
export function getWhichCodeFromEventOrKeyName(
  eventOrKey: React.KeyboardEvent | KeyboardKeyNames
): number {
  if (KeyboardEventTypeGuards.isKeyboardEvent(eventOrKey)) {
    return eventOrKey.which || eventOrKey.keyCode || keyToCode[eventOrKey.key];
  } else {
    return keyToCode[eventOrKey];
  }
}

/**
 * Get the `key` name from a keyboard event or `keyCode`/`which` value.
 *
 * **Warning**: If a `number` (`keyCode`/`which` value) is provided, the lower-case `key` will always be returned. If you need case sensitivity, you must pass a `React.KeyboardEvent`.
 * @see https://www.w3.org/TR/uievents/#relationship-between-key-code
 */
export function getKeyNameFromEventOrWhichCode(
  eventOrWhichCode: React.KeyboardEvent | number
): string {
  let keyName: string | string[];
  if (KeyboardEventTypeGuards.isKeyboardEvent(eventOrWhichCode)) {
    keyName = codeToKey[eventOrWhichCode.which || eventOrWhichCode.keyCode];
  } else {
    keyName = codeToKey[eventOrWhichCode];
  }
  // Handle lower/upper case
  if (Array.isArray(keyName)) {
    if (KeyboardEventTypeGuards.isKeyboardEvent(eventOrWhichCode)) {
      keyName = keyName[eventOrWhichCode.shiftKey ? 1 : 0];
    } else {
      // If only a `which`/`keyCode` was provided, default to lower case since we can't determine if Shift was held
      keyName = keyName[0];
    }
  }
  return keyName;
}

/**
 * Determine whether the `which` code in `event` specifies it as alphanumeric `[a-zA-Z0-9]`
 * @param event
 */
export function keyIsAlphanumeric(event: React.KeyboardEvent) {
  const isAtSign = event.key === '@';
  return (
    !isAtSign &&
    ((event.which >= 64 && event.which <= 90) || // Letters
      (event.which >= 48 && event.which <= 57 && !event.shiftKey))
  ); // Numbers
}

/**
 * Determine whether the `which` code in `event` specifies it as alphanumeric `[a-zA-Z0-9]` **or**
 * one of `@, Shift, Ctrl, Alt, CapsLock, Convert, NonConvert, Accept, ModeChange, Meta, AltGraph`.
 *
 * Used to determine if a keypress should be processed for filter input (ex. Mention list auto-complete)
 */
export function keyIsValidFilterInput(event: React.KeyboardEvent) {
  return (
    event.key === '@' ||
    keyIsAlphanumeric(event) ||
    [16, 17, 18, 20, 28, 29, 30, 31, 224, 225].includes(event.which)
  );
}

export class KeyboardEventTypeGuards {
  static isKeyboardEvent(
    eventOrKeyOrCode: React.KeyboardEvent | string | number
  ): eventOrKeyOrCode is React.KeyboardEvent {
    const asKbEvt = eventOrKeyOrCode as React.KeyboardEvent;
    return (
      isObject(asKbEvt) &&
      (asKbEvt.keyCode !== undefined ||
        asKbEvt.which !== undefined ||
        asKbEvt.key !== undefined)
    );
  }
}
