import useFileUpload from 'Components/Chat/hooks/attachments/useFileUpload';
import { useAttachmentStore } from 'Components/Chat/store/attachments';
import {
  AttachmentItem,
  S3Object,
} from 'Components/Chat/store/attachments/index.types';
import { FileTypeProps, fileToBase64, mapIcon } from 'Models/FileType';
import { resizeImage } from 'Utils/resizeImage';
import { v4 as uuidv4 } from 'uuid';

export const useAttachments = () => {
  const { addAttachment, resetAttachments } = useAttachmentStore();
  const { uploadAll } = useFileUpload();

  const getAttachmentsCount = useAttachmentStore(
    (state) => state.attachments.length
  );

  const hasUploadErrors = useAttachmentStore((state) =>
    state.attachments.some((a) => a.status === 'error')
  );
  const hasUploadsInProgress = useAttachmentStore((state) =>
    state.attachments.some((a) => a.status === 'uploading')
  );
  const uploadedFilesMap = useAttachmentStore((state) =>
    state.attachments.map<FileTypeProps>((a) => a.file)
  );
  const uploadedS3ObjectsMap = useAttachmentStore((state) => {
    return state.attachments.map<S3Object>((a) => a.s3Object);
  });
  // FIXME: For some reason, we can't use the above selector in the src\app\containers\ContextPanel\index.tsx ;
  // we need this function so it gets evaluated with the right state
  const getUploadedS3ObjectsMap = () => {
    return useAttachmentStore
      .getState()
      .attachments.map<S3Object>((a) => a.s3Object);
  };

  const addAttachmentsViaEvent = async (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ClipboardEvent<HTMLInputElement>
  ) => {
    const selectedFiles =
      'clipboardData' in e
        ? e.clipboardData?.files
        : e.target?.files || ([] as unknown as FileList);

    if (selectedFiles) {
      await addAttachments(selectedFiles);
    }
  };

  const addAttachments = async (selectedFiles: FileList) => {
    const attachments: AttachmentItem[] = [];

    for (const file of selectedFiles) {
      let attachment: AttachmentItem;

      if (['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        let icon = '';
        const extension = file.type.split('/')[1];
        const compressedFile = await resizeImage(file, extension);
        icon = (await fileToBase64(compressedFile)).toString();

        attachment = {
          id: uuidv4(),
          file: compressedFile,
          status: 'pending',
          isImage: true,
          icon: icon ?? '',
        };
      } else {
        attachment = {
          file,
          id: uuidv4(),
          status: 'pending',
          icon: mapIcon(file, undefined),
        };
      }
      attachments.push(attachment);
      addAttachment(attachment);
    }

    return await uploadAll(attachments);
  };

  return {
    addAttachments,
    addAttachmentsViaEvent,
    resetAttachments,
    getAttachmentsCount,
    hasUploadErrors,
    hasUploadsInProgress,
    uploadedFilesMap,
    uploadedS3ObjectsMap,
    getUploadedS3ObjectsMap,
  };
};
