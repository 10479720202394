import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledList = styled(List)`
  margin-bottom: 0.25rem;
  flex: 1;
  max-height: calc(100vh - 190px);
  overflow: hidden;
  padding-top: 25px;
  &:hover {
    overflow-y: auto;
  }
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #ccc transparent;
  scroll-behavior: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  && {
    padding: 0;
  }
`;

export const Styled = {
  List: StyledList,
  InfiniteScroll: StyledInfiniteScroll,
};
