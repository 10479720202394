import styled from 'styled-components';

const ClearStatusButton = styled.span`
  position: relative;
  top: -9px;
  border-bottom: 1px solid black;
  padding: 0px;
  height: 22px;
  font-size: 12px;
`;

export const Styled = {
  ClearStatusButton,
};
