import ActivityListItems from 'Components/ActivityListItems';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_UI,
} from 'Constants/stores';
import { usePreferences } from 'Hooks/preferences/usePreferences'; // Import the hook
import { inject, observer } from 'mobx-react';
import React, {useEffect, useCallback} from 'react';
import { Grid } from 'semantic-ui-react';
import withRouter from '../../hocs/WithRouter';
import { resolveConversationPath } from '../../utils/routeNav';
import { ActivityListActionButtons } from '../ActivityListActionButtons';
import { ActivityListProps } from './interfaces';


const ActivityList: React.FC<ActivityListProps> = ({
  conversation,
  participant,
  person,
  ui,
  phoneCall,
  pusher,
  contact,
  navigate,
  location,
}) => {
  const updateRoutes = useCallback(
    (path) => {
      const newPath = resolveConversationPath(location.pathname, path);
      navigate(newPath);
    },
    [location.pathname, navigate]
  );

  const makeCall = useCallback(
    (personId?: number, phone?: string) => {
      phoneCall.callWithPerson(personId, phone);
    },
    [phoneCall]
  );

  useEffect(() => {
    ui.getMutedConv();
  }, [ui]);

  const conversationId = conversation.CurrentConversation?.id;
  const { preferences } = usePreferences();


  return (
    <Grid.Row
      id="activity-list"
      columns={1}
      className="action-panel-child flex-column overflow-scroll-y"
    >
      <Grid.Column>
        <ActivityListItems
          setShowPersonDetails={person.setShowPersonDetails}
          conversationId={conversationId} // Not used by child, but required by `ISetConversationAndTotalUnreadCounts` (RP 2019-07-31)
          conversationStore={conversation}
          currentConversationId={conversationId}
          isOnline={pusher.isOnline}
          listUnreadFirst={preferences.listUnreadFirst}
          loadPresenceIfMissing={ui.loadPresenceIfMissing}
          loggedInAccountId={person.loggedInAccountId}
          loggedInPersonId={person.loggedInPersonId}
          makeCall={makeCall}
          participantStore={participant}
          phoneStore={phoneCall}
          selectConversationParticipants={
            participant.loadConversationParticipantsIfMissing
          }
          selectOtherConversationParticipants={
            participant.selectOtherParticipants
          }
          selectParticipantPersonInfo={person.loadPersonByIdGetIfMissingGet}
          selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
          selectUnreadCounts={ui.selectConversationUnreadCounts}
          setConversationAndTotalUnreadCount={
            ui.setConversationAndTotalUnreadCount
          }
          updateMyLastReadMessage={participant.updateMyLastReadMessage}
          updateRoute={updateRoutes}
          personStore={person}
          setMoreActionOptions={ui.setMoreActionOptions}
          activeItem={ui.activeSidebarItem}
          contact={contact}
        />
      </Grid.Column>
      <ActivityListActionButtons />
    </Grid.Row>
  );
};

export default inject(
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_PUSHER,
  STORE_CONTACT
)(withRouter(observer(ActivityList)));