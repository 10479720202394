import type { ChatLayoutViewProps } from './ChatLayoutView.types';
import { PinnedMessageView } from 'Components/Chat/ChatLayout/PinnedMessageView';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import React from 'react';

export const ChatLayoutView: React.FC<ChatLayoutViewProps> = ({ children }) => {
  const { view } = React.useContext(ChatLayoutContext);

  return view === 'pinned-messages' ? (
    <PinnedMessageView />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
