import type { FormInputProps } from './interfaces';
import closeIcon from 'Assets/images/close.svg';
import editIcon from 'Assets/images/edit-icon.svg';
import checkIcon from 'Assets/images/icon-check.svg';
import React from 'react';
import { Form, Ref } from 'semantic-ui-react';


export const FormInput: React.FC<FormInputProps> = ({
  onChange,
  id,
  label,
  value,
  disabled,
  editProps,
  testid: parentTestid,
}) => {
  const testid = `${parentTestid}-formInput${id}`;

  return (
    <Form.Field className="input-self">
      {editProps ? (
        <React.Fragment>
          <Ref innerRef={(node) => (editProps.ref.current = node)}>
            <Form.Input
              {...{ disabled, value, onChange, id, label }}
              {...{ name: id, placeholder: label }}
              data-testid={testid}
              data-private
              fluid
            />
          </Ref>
          <span
            className="input-self--edit"
            data-testid={`${testid}-buttonEdit`}
            onClick={editProps.onEditClick}
          >
            <img src={editIcon} />
          </span>
          {!disabled && (
            <div className="input-self--controls">
              <span
                className="input-self--button input-self--button-submit"
                data-testid={`${testid}-buttonSubmit`}
                onClick={editProps.onSubmitClick}
              >
                <img src={checkIcon} />
              </span>
              <span
                className="input-self--button input-self--button-cancel"
                data-testid={`${testid}-buttonCancel`}
                onClick={editProps.onCancelClick}
              >
                <img src={closeIcon} />
              </span>
            </div>
          )}
        </React.Fragment>
      ) : (
        <Form.Input
          {...{ disabled, value, onChange, id, label }}
          {...{ name: id, placeholder: label }}
          data-testid={testid}
          data-private
          fluid
        />
      )}
    </Form.Field>
  );
};
