/**
 * Opens a blank popup window and returns a reference to it, which can then be redirected within an asynchronous control block.
 *
 * This is intended to bypass popup blockers when the destination URL is resolved asynchronously.
 * It should be called in the same tick as a user action (click, key, etc.).
 *
 * @param [url] Optionally pass a URL to initialize the popup. **NOTE** If provided, this method will NOT bypass popup blockers, and is intended only for Electron (or other envs without popup blockers).
 * @param [leftMargin=0] (Default: 0) Add this margin to the left side of the video popup.
 * @param [topMargin=0] (Default: 0) Add this margin to the top of the video popup.
 */
export const configureConferencePopupWindow = (
  url?: string,
  leftMargin = 0,
  topMargin = 0
) => {
  // Fixes dual-screen position
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const left = dualScreenLeft + window.screen.availWidth / 5.5 + leftMargin;
  const top = dualScreenTop + window.screen.availHeight / 5 + topMargin;
  const width = window.screen.availWidth * 0.65;
  const height = window.screen.availHeight * 0.7;
  return window.open(
    url || '',
    '_blank',
    `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
  );
};
