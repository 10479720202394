import type { SystemMessageWrapperProps } from './SystemMessageWrapper.types';
import { parseMessageDate } from 'Components/Chat/utils';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { Icon } from 'Components/shared/Icon';
import * as React from 'react';
import { Styled } from './SystemMessageWrapper.styles';

export const SystemMessageWrapper: React.FC<SystemMessageWrapperProps> = ({
  created,
  icon,
  children,
  title,
}) => (
  <Styled.Wrapper>
    <Icon {...{ icon }} />
    <Styled.TextWrapper>
      <BodyCopy variant="paragraph" noMargin>
        {title}
      </BodyCopy>
      <BodyCopy variant="subtitles" noMargin>
        {parseMessageDate(created)}
      </BodyCopy>
      {children}
    </Styled.TextWrapper>
  </Styled.Wrapper>
);
