import { sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Dropdown, DropdownProps, Icon, Popup } from 'semantic-ui-react';
import chevronRightIcon from '../../../../assets/images/chevron-right-icon.svg';
import chevronRightWhiteIcon from '../../../../assets/images/chevron-right-white-icon.svg';
import closeBtn from '../../../../assets/images/close-btn.svg';
import infoIcon from '../../../../assets/images/info-gray-icon.svg';
import { TIMEZONE_IDENTIFIER } from '../../../../constants/env';
import { STORE_CONVERSATION } from '../../../../constants/stores';
import withRouter from '../../../../hocs/WithRouter';
import { parseMessageDate } from '../../../Chat/utils';
import { Styled } from './index.styles';
import { IVideoHistoryProps } from './VideoHistoryDetails/types';

const stateOptions = [
  {
    text: 'Last 24 Hours',
    key: 1,
    value: 1,
  },
  {
    text: 'Last 7 Days',
    key: 7,
    value: 7,
  },
  {
    text: 'Last 14 Days',
    key: 14,
    value: 14,
  },
  {
    text: 'Last 30 Days',
    key: 30,
    value: 30,
  },
  {
    text: 'Last 60 Days',
    key: 60,
    value: 60,
  },
  {
    text: 'Last 90 Days',
    key: 90,
    value: 90,
  },
  {
    text: 'Last 180 Days',
    key: 180,
    value: 180,
  },
  {
    text: 'Last Year',
    key: 365,
    value: 365,
  },
];

class VideoHistory extends React.Component<
  IVideoHistoryProps,
  { tableData: any; type: 'ASC' | 'DESC'; activeHoverElement: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      type: null,
      activeHoverElement: 0,
    };
  }

  componentDidMount() {
    const { conversation } = this.props;
    conversation.loadVideoHistory(20, 1, false);
  }

  alignThead = (index: number): 'left' | 'right' | 'center' => {
    let alignment;
    switch (index) {
      case 3:
        alignment = 'right';
        break;
      case 5:
        alignment = 'center';
      default:
        alignment = 'left';
    }
    return alignment;
  };

  handleFilter = (e, d: DropdownProps) => {
    const {
      conversation: { videoHistoryList },
    } = this.props;
    const filteredData = videoHistoryList.data.results.filter((item) => {
      const itemDay = moment(item.started);
      const momentInst = moment().tz(TIMEZONE_IDENTIFIER).utc();
      const difference = momentInst.diff(itemDay, 'day', true);
      if (typeof d.value !== 'number') {
        return false;
      }
      return difference < d.value;
    });
    this.setState({ tableData: filteredData });
  };

  redirectToHistoryDetails = (id: number) => () => {
    const { navigate } = this.props;
    navigate(`/video-app/history/${id}`);
  };

  handleSortDates = () => {
    const {
      conversation: { videoHistoryList },
    } = this.props;
    const data = this.state.tableData || videoHistoryList?.data?.results;
    const sorted =
      this.state.type === 'ASC'
        ? sortBy(data, 'started')
        : sortBy(data, 'started').reverse();
    this.setState({
      tableData: sorted,
      type: this.state.type === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  handleLoadMoreContacts = () => {
    const { conversation } = this.props;
    const pageNum = conversation.pageNumberVideoSearch + 1;
    conversation.loadVideoHistory(20, pageNum, true);
  };

  handleHovering = (id: number) => () =>
    this.setState({ activeHoverElement: id });

  handleClearFilter = () => this.setState({ tableData: null });
  render() {
    const {
      conversation: { videoHistoryList, pageNumberVideoSearch },
    } = this.props;
    const headers = ['MEETING TITLE', 'DATE', 'TIME', 'ACTIONS'];
    const historyData = this.state.tableData || videoHistoryList?.data?.results;
    return (
      <Styled.History className="history">
        <Styled.HistoryHeader>
          <Styled.HistoryHeaderTitle>History</Styled.HistoryHeaderTitle>
          <Styled.HistoryFilter>
            {this.state.tableData && (
              <Styled.HistoryFilterImage
                src={closeBtn}
                onClick={this.handleClearFilter}
              />
            )}
            <Dropdown
              placeholder="Last 7 days"
              search
              selection
              options={stateOptions}
              onChange={this.handleFilter}
            />
          </Styled.HistoryFilter>
        </Styled.HistoryHeader>
        <Styled.HistoryTable className="history-table" cellSpacing={0}>
          <Styled.TableHeader>
            <Styled.TableHeaderFlexRow>
              {headers.map((text, index) => (
                <Styled.TableHeaderCell key={text}>
                  {text}
                  {text === 'DATE' && (
                    <Icon
                      name="chevron down"
                      className="pointer"
                      color="grey"
                      onClick={this.handleSortDates}
                    />
                  )}
                </Styled.TableHeaderCell>
              ))}
            </Styled.TableHeaderFlexRow>
          </Styled.TableHeader>
          <br />
          <InfiniteScroll
            initialScrollY={1}
            dataLength={pageNumberVideoSearch * 20} //This is important field to render the next data
            next={this.handleLoadMoreContacts}
            hasMore={true}
            loader={<></>}
            endMessage={<></>}
            // below props only if you need pull down functionality
            refreshFunction={this.handleLoadMoreContacts}
            pullDownToRefresh
            pullDownToRefreshThreshold={4}
            scrollableTarget="tbody"
          >
            <Styled.TableBody id="tbody" onMouseLeave={this.handleHovering(0)}>
              {historyData?.length > 0 ? (
                historyData.map((value, index) => {
                  const day = parseMessageDate(value.started, true);
                  const timeOnCall = `${moment(value.started).format(
                    'hh:mm A'
                  )} - ${moment(value.completed).format('hh:mm A')}`;
                  return (
                    <Styled.TableBodyFlexRow
                      key={value.id}
                      onClick={
                        value.isAdmin
                          ? this.redirectToHistoryDetails(value.id)
                          : undefined
                      }
                      onMouseEnter={this.handleHovering(value.id)}
                      isAdmin={!!value.isAdmin}
                    >
                      <Styled.TableBodyMeetingTitleCell data-private>
                        {value.displayName}
                      </Styled.TableBodyMeetingTitleCell>
                      <Styled.TableBodyDateCell>{day}</Styled.TableBodyDateCell>
                      <Styled.TableBodyTimeCell>
                        {timeOnCall}
                      </Styled.TableBodyTimeCell>
                      <Styled.TableBodyActionsCell>
                        {value.hasRecordings ? (
                          <Styled.RecordingWrapper>
                            <Styled.RecordingIconWrapper>
                              <Styled.RecordingIcon />
                            </Styled.RecordingIconWrapper>
                            <Styled.Recording>Recorded</Styled.Recording>
                          </Styled.RecordingWrapper>
                        ) : value.isAdmin ? (
                          <Styled.ChevronWrapper
                            isHovering={
                              this.state.activeHoverElement === value.id
                            }
                          >
                            <img
                              src={
                                this.state.activeHoverElement === value.id
                                  ? chevronRightWhiteIcon
                                  : chevronRightIcon
                              }
                            />
                          </Styled.ChevronWrapper>
                        ) : (
                          <Styled.ChevronWrapper>
                            <Popup
                              inverted
                              trigger={<img src={infoIcon} />}
                              content="Guests are not allowed to see the meeting details"
                              position={'top right'}
                            />
                          </Styled.ChevronWrapper>
                        )}
                      </Styled.TableBodyActionsCell>
                    </Styled.TableBodyFlexRow>
                  );
                })
              ) : (
                <>History list is empty.</>
              )}
            </Styled.TableBody>
          </InfiniteScroll>
        </Styled.HistoryTable>
      </Styled.History>
    );
  }
}

export default inject(STORE_CONVERSATION)(withRouter(observer(VideoHistory)));
