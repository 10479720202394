
import type { AxiosPromise } from 'axios';
import { VIDEO_URL } from 'Constants/env';
import { STORE_CONVERSATION, STORE_PERSON, STORE_UI } from 'Constants/stores';
import { inject, observer } from 'mobx-react';

import { IVideoHistoryDetails } from 'Models/VideoHistoryModel';
import moment from 'moment-timezone';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import withRouter from '../../../../../hocs/WithRouter';
import { Attendees } from './Attendees';
import { ChatHistory } from './ChatHistory';
import { ConnectionInfo } from './ConnectionInfo';
import { HistoryDetailsForbidden } from './HistoryDetailsForbidden';
import { Styled } from './index.styles';
import { Recordings } from './Recordings';
import { RecordingModal } from './RecordingsModal';
import { ShortHistory } from './ShortHistory';
import { VideoHistoryDetailsProps, VideoHistoryDetailsState } from './types';

class VideoHistoryDetails extends React.Component<
  VideoHistoryDetailsProps,
  VideoHistoryDetailsState
> {
  constructor(props) {
    super(props);
    this.state = {
      showChatHistory: false,
      showModal: false,
      sherableLink: '',
      password: '',
      exipiresIn: '',
      deleted: { personData: null, date: '', time: '' },
      loadingDeleteResp: false,
    };
  }

  componentDidMount() {
    const { conversation } = this.props;
    conversation.setVideoHistoryDetails(null);
    this.loadHistoryDetails();
  }

  componentWillUnmount() {
    const { ui } = this.props;
    ui.setIsVideoChatHistoryOpened(false);
    ui.removeFromOpenedRightSidebarsOrder('video-chat-history');
  }

  loadHistoryDetails = () => {
    const { conversation, location } = this.props;
    const historyId = parseInt(location.pathname.split('/').pop());
    const resp: AxiosPromise<IVideoHistoryDetails> =
      conversation.loadDetailsVideoHistory(historyId);
    void resp.then((videoData) => {
      const recording = videoData.data.recordings[0];
      this.setState({ loadingDeleteResp: false });
      if (recording?.deletedByPersonId) {
        this.loadPersonData(recording.deletedByPersonId, recording.deletedAt);
      }
    });
  };

  loadPersonData = (personId: number, date: string) => {
    const { person } = this.props;
    const personResp = person.loadPersonByIdGetIfMissingGet(personId);
    personResp.case({
      fulfilled: (personPbo) => {
        const deletedDate = moment(date).format('dddd, MMMM DD. YYYY.');
        const time = moment(date).format('hh:mm A');
        this.setState({
          deleted: {
            personData: personPbo.data,
            date: deletedDate,
            time: time,
          },
        });
      },
    });
  };

  toggleRecordingsModal = () =>
    this.setState({ showModal: !this.state.showModal });

  toggleChatHistory = () => {
    const { ui } = this.props;
    const { showChatHistory } = this.state;
    this.setState({ showChatHistory: !showChatHistory });
    ui.setIsVideoChatHistoryOpened(!showChatHistory);
    if (showChatHistory)
      ui.removeFromOpenedRightSidebarsOrder('video-chat-history');
    else ui.setOpenedRightSidebarsOrder('video-chat-history');
  };

  handleOpenRecordingLink = (recordingId: string) => () => {
    const { conversation, location } = this.props;
    const historyId = parseInt(location.pathname.split('/').pop());
    if (recordingId) {
      const respRecordings = conversation.loadVideoRecordings(
        historyId,
        recordingId,
        true
      );
      void respRecordings.then((recordings: any) => {
        const recording = recordings.data[0];
        const baseUrl: string = VIDEO_URL.slice(0, -1);
        this.setState(
          {
            sherableLink: baseUrl + (recording.downloadLink as string),
            password: recording.password,
            exipiresIn: recording.exipiresIn,
          },
          () => this.toggleRecordingsModal()
        );
      });
    }
  };

  render() {
    const {
      conversation: { videoHistoryDetails },
    } = this.props;
    const { showChatHistory, showModal, sherableLink, password, exipiresIn } =
      this.state;
    return (
      <Styled.VideoHistoryDetailsStyled
        $adjustWidth={showChatHistory}
        className="history-details flex-row"
      >
        <div
          className="page-title"
          onClick={showChatHistory ? this.toggleChatHistory : () => null}
        >
          Meeting Details
        </div>
        <div className="third-container button-chat-history flex-justify-center">
          <Button
            content="VIEW CHAT HISTORY"
            onClick={this.toggleChatHistory}
          />
        </div>
        <div
          className="history-details-container flex-row"
          onClick={showChatHistory ? this.toggleChatHistory : () => null}
        >
          <ShortHistory videoHistoryDetails={videoHistoryDetails} />
          <Attendees
            conversation={this.props.conversation}
            ui={this.props.ui}
          />
        </div>
        {showChatHistory && (
          <ChatHistory
            ui={this.props.ui}
            conversation={this.props.conversation}
            toogleChatHistory={this.toggleChatHistory}
          />
        )}
        <ConnectionInfo
          ui={this.props.ui}
          conversation={this.props.conversation}
        />
        <Recordings
          deleted={this.state.deleted}
          handleOpenRecordingLink={this.handleOpenRecordingLink}
          loadHistoryDetails={this.loadHistoryDetails}
          conversation={this.props.conversation}
          ui={this.props.ui}
          location={this.props.location}
        />
        {showModal && (
          <RecordingModal
            ui={this.props.ui}
            password={password}
            exipiresIn={exipiresIn}
            sharableLink={sherableLink}
            showModal={showModal}
            toogleRecordingsModal={this.toggleRecordingsModal}
            displayName={videoHistoryDetails?.data?.displayName}
          />
        )}
        {this.props.ui.displayForbiddenScreen && <HistoryDetailsForbidden />}
      </Styled.VideoHistoryDetailsStyled>
    );
  }
}
export default inject(
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PERSON
)(withRouter(observer(VideoHistoryDetails)));
