import type { UserAvatarProps } from './types';
import groupChatIcon from 'Assets/images/group-chat.svg';
import phoneIcon from 'Assets/images/phone-icon-sidebar.svg';
import cx from 'classnames';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { formatUserInitials } from 'Utils/formatUserInitials';
import { Styled } from './index.styles';


export const UserAvatar: React.FC<UserAvatarProps> = ({
  conversationId = '0',
  presence,
  showUnread,
  image,
  name,
  conversationGrouping,
  hideInitials,
  className,
  selectUnreadCounts = () => {},
}) => {
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const getUserPresenceTooltipLabel = (): string | undefined => {
    if (!presence?.state) return '';

    const presenceState = presence.state.toLowerCase();
    if (presence.state === 'DoNotDisturb') {
      return 'Do Not Disturb';
    }
    if (presence.state === 'OnCall') {
      return 'On Call';
    }
    return presenceState.charAt(0).toUpperCase() + presenceState.slice(1);
  };

  const getAvatar = () => {
    if (!(name || image)) {
      return (
        <Styled.Avatar className={`list-item-icon-wrapper ${className || ''}`}>
          <Styled.GroupImage
            src={conversationGrouping === 'Channel' ? groupChatIcon : phoneIcon}
          />
        </Styled.Avatar>
      );
    }

    return (
      <Styled.Avatar
        $hideInitials={hideInitials}
        className={`list-item-icon-wrapper ${className || ''}`}
      >
        {!hideInitials && (
          <>
            {image && !imageError ? (
              <Styled.ImageWrapper>
                <Styled.Image
                  src={image}
                  alt={name || 'profile picture'}
                  onError={handleImageError}
                />
              </Styled.ImageWrapper>
            ) : (
              <Styled.Initials className="credentials">
                {formatUserInitials(name)}
              </Styled.Initials>
            )}
          </>
        )}
        <Styled.UserPresence
          $hideInitials={hideInitials}
          state={presence?.state}
          conversationId={conversationId}
          showUnread={showUnread}
          selectUnreadCounts={selectUnreadCounts}
        />
      </Styled.Avatar>
    );
  };

  return (
    <Popup
      trigger={getAvatar()}
      content={getUserPresenceTooltipLabel()}
      position="top right"
      className={cx('status-popup', {
        'hide-popup': !presence,
      })}
    />
  );
};
