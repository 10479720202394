import styled from 'styled-components';

const ChatListWrapper = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
`;

export const Styled = {
  ChatListWrapper,
};
