import { uniqBy } from 'lodash';
import { TopBarDropdownContextProps } from '../contexts/TopBarDropdownContext';

export type sortAndDeduplicateOptionsProps = (
  resultArray: TopBarDropdownContextProps['options'],
  favoriteList: Record<string, true>
) => TopBarDropdownContextProps['options'];

export const sortAndDeduplicateOptions: sortAndDeduplicateOptionsProps = (
  resultArray: TopBarDropdownContextProps['options'],
  favoriteList
) => {
  const sortedOptions = uniqBy(
    resultArray.filter(({ text }) => text),
    ({ key }) => key
  ).sort((a, b) => a.text.localeCompare(b.text));

  if (Object.keys(favoriteList).length) {
    const favoriteOptions: TopBarDropdownContextProps['options'] = [];
    const nonFavoriteOptions: TopBarDropdownContextProps['options'] = [];
    sortedOptions.forEach((option) =>
      favoriteList?.[option.key]
        ? favoriteOptions.push(option)
        : nonFavoriteOptions.push(option)
    );

    return [...favoriteOptions, ...nonFavoriteOptions];
  }

  return sortedOptions;
};
