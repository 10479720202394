import React from 'react';
import { Styled } from './index.styles';

export const UploadStatusBadge = ({
  isUploading,
  hasError,
}: {
  isUploading: boolean;
  hasError: boolean;
}) => {
  if (isUploading && !hasError) {
    return (
      <Styled.StatusBadge>
        <Styled.LineSpinner />
        Uploading...
      </Styled.StatusBadge>
    );
  }

  if (hasError) {
    return (
      <Styled.StatusBadge $hasError={hasError}>
        <Styled.Icon icon="error-filled" size="small" $hasError={hasError} />
        Error uploading
      </Styled.StatusBadge>
    );
  }

  return;
};
