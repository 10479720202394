import type { MuteNotificationsButtonProps } from './MuteNotificationsButton.types';
import * as React from 'react';

import { IconButton } from '../shared/IconButton';

export const MuteNotificationsButton: React.FC<
  MuteNotificationsButtonProps
> = ({
  conversationId,
  currentPersonId,
  listOfCMutedConverstions,
  loadOrCreateConversationWithPost,
  popupPosition,
  selectIfConvMuted,
  size,
  testid,
  toggleMuteStatus,
}) => {
  const [isMuted, setIsMuted] = React.useState(false);

  React.useEffect(() => {
    const checkIsMuted = async () => {
      if (!currentPersonId || conversationId) {
        setIsMuted(selectIfConvMuted(conversationId));
        return;
      }
      const { data } = await loadOrCreateConversationWithPost(currentPersonId);
      setIsMuted(selectIfConvMuted(data.id?.toString()));
    };

    checkIsMuted();
  }, [listOfCMutedConverstions]);

  const conversationMuteToggle = async () => {
    if (!currentPersonId) {
      setIsMuted(toggleMuteStatus(conversationId));
      return;
    }

    const { data } = await loadOrCreateConversationWithPost(currentPersonId);
    setIsMuted(toggleMuteStatus(data.id?.toString()));
  };

  return (
    <IconButton
      content={isMuted ? 'Unmute Notifications' : 'Mute Notifications'}
      icon={isMuted ? 'bell-off' : 'bell'}
      onClick={conversationMuteToggle}
      testid={`${testid}-buttonMuteNotifications`}
      {...{ size, popupPosition }}
    />
  );
};
