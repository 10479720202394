import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizes.small};
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.xxxsmall};
`;

export const Styled = {
  Wrapper,
  IconWrapper,
};
