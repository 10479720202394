import { NODE_ENV_LOCAL_OR_DEVELOPMENT } from '../constants/env';

/**
 * Push an `event` and optional metadata to Google Tag Manager's data layer.
 *
 * @see https://tagmanager.google.com/#/container/accounts/6000369423/containers/30399222/workspaces/19
 *
 * @see https://analytics.google.com/analytics/web/#/report-home/a98911w213443318p204492464
 *
 * @param event GTM `event`
 * @param metadata Metadata to attach to the `event`
 */
export function pushToGTMDataLayer(
  event: string,
  metadata?: IGTMDataLayerMetadata
) {
  try {
    if (window['gtag']) {
      window['gtag']('event', event, {
        ...(metadata || {}),
        event,
      });
    }
  } catch (err) {
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug(`Failed to send event ${event} to GTM.`);
    }
  }
}

/** Metadata provided to Google Tag Manager `event`s */
export interface IGTMDataLayerMetadata {
  [key: string]: string | number | boolean;
}

/*
pushToGTMDataLayer('event', {
    conversationId: ''
});
*/
