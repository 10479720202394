import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  margin: ${({ theme }) => theme.sizes.xxsmall}; 0px;
`;

const TextWrapper = styled.div`
  margin-left: ${({ theme }) => theme.sizes.xxxsmall};
`;

const SemiBold = styled.span`
  font-weight: 600;
`;

const Danger = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};
`;

export const Styled = {
  Wrapper,
  TextWrapper,
  SemiBold,
  Danger,
};
