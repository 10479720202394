import React from 'react';
import useStayScrolled from 'react-stay-scrolled';

export type useScrollToBottomProps = () => {
  scrollRef: React.MutableRefObject<HTMLDivElement>;
};

export const useScrollToBottom: useScrollToBottomProps = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { scrollBottom } = useStayScrolled(scrollRef);

  React.useLayoutEffect(() => {
    setTimeout(() => scrollBottom(), 10);
  }, []);

  return { scrollRef };
};
