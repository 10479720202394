import * as React from 'react';
import type {
  CallLogCategoryType,
  CallLogType,
} from 'Components/CallLogs/types';

export type CallLogsContextType = {
  callLogsLoading: boolean;
  setCallLogsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  shouldGetCallLogs: boolean;
  setShouldGetCallLogs: React.Dispatch<React.SetStateAction<boolean>>;
  callLogs: CallLogType[];
  getCallLogs: (
    appendResults: boolean,
    query?: string,
    initialFetch?: boolean
  ) => Promise<void>;
  typeOfCallsId: CallLogCategoryType;
  getCallLogsFromCache: () => void;
};

export const CallLogsContext = React.createContext<CallLogsContextType>({
  callLogsLoading: false,
  setCallLogsLoading: () => {},
  shouldGetCallLogs: false,
  setShouldGetCallLogs: () => {},
  callLogs: [],
  getCallLogs: () => Promise.resolve(),
  typeOfCallsId: 'all',
  getCallLogsFromCache: () => {},
});
