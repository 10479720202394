import type { MicrosoftPersonalProps } from './types';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { CALENDAR_IDS, CALENDAR_SCOPE, SHOW_CALENDAR } from '../../constants/env';
import { STORE_NOTIFICATION, STORE_PERSON } from '../../constants/stores';
import { Scope } from '../../models/Calendar';
const closeBtn = require('../../assets/images/close-btn.svg');
const externalLinkIcon = require('../../assets/images/icon-external-link.svg');
const logo = require('../../assets/images/outlook-icon.svg');

interface MicrosoftPersonalState {
  isModalOpen: boolean;
  email: { value: string; error?: string };
  password: { value: string; error?: string };
  sendingCode: boolean;
  showCalendar: boolean;
}

class MicrosoftPersonal extends React.Component<
  MicrosoftPersonalProps,
  MicrosoftPersonalState
> {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      email: {
        value: '',
        error: undefined,
      },
      password: {
        value: '',
        error: undefined,
      },
      sendingCode: false,
      showCalendar: false,
    };
  }

  componentDidMount(): void {
    const showCalendar =
      SHOW_CALENDAR ||
      CALENDAR_IDS?.includes(this.props.person.loggedInPersonId);
    this.setState({ ...this.state, showCalendar });
  }

  componentDidUpdate() {
    const addressContainer = document.getElementById('addressBook-container');
    const calendarContainer = document.getElementById('calendar-container');
    const container = addressContainer || calendarContainer;
    this.state.isModalOpen && container?.scrollTo(0, 0);
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [e.target.name]: { value: e.target.value },
    });
  };

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  setInputError = (key: string, error?: string) => {
    this.setState({
      ...this.state,
      [key]: {
        ...this.state[key],
        error: error,
      },
    });
  };

  handleSubmit = debounce(async () => {
    const { email, password } = this.state;
    const scopes = ['contacts'] as Scope[];
    this.state.showCalendar && scopes.push(CALENDAR_SCOPE);
    if (email.value.length === 0 || !this.validateEmail(email.value)) {
      this.setInputError('email', 'Email is empty or invalid!');
      return;
    }
    if (password.value.length === 0) {
      this.setInputError('password', 'Password is empty!');
      return;
    }
    this.setState({ sendingCode: true });
    const resp = await this.props.person.sendCodeOfProvider(
      '',
      'outlook',
      scopes,
      email.value,
      password.value
    );
    if (resp) {
      this.setState({
        isModalOpen: false,
        email: {
          value: '',
          error: undefined,
        },
        password: {
          value: '',
          error: undefined,
        },
        sendingCode: false,
      });
    } else {
      this.setInputError('email', 'Please check Email !');
      this.setInputError('password', 'Please check Password!');
      this.setState({ sendingCode: false });
    }
  }, 500);

  handleModalToggle = () => {
    this.setState({
      ...this.state,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      email: {
        value: '',
      },
      password: {
        value: '',
      },
      sendingCode: false,
    });
  };

  render() {
    const appSpecificUrl =
      'https://support.microsoft.com/en-us/account-billing/using-app-passwords-with-apps-that-don-t-support-two-step-verification-5896ed9b-4263-e681-128a-a6f2979a7944';
    return (
      <>
        <div onClick={this.handleModalToggle}>
          <div className="source-box">
            <img src={logo} />
            <span>Sign in with Outlook Personal</span>
          </div>
          {!this.props.hideBelowText && (
            <div
              className="new-source"
              data-testid={`${this.props.testId}-microsoftPersonal-buttonAddNewSource`}
            >
              + Add new source
            </div>
          )}
        </div>
        {this.state.isModalOpen && (
          <div className="icould-modal">
            <div className="source-list">
              <h2 className="settings-title">
                Synchronize contacts (Add a new source from Outlook)
              </h2>
              <p className="settings-description">
                After you turn on two-step verification or set up the
                Authenticator app, you may run into issues if you use apps or
                older devices that don't support two-step verification. If you
                have two-step verification turned on and an app isn't prompting
                you to enter a security code when you sign in, you may be able
                to sign in with an app password instead.
              </p>
              <a
                href={appSpecificUrl}
                target="_blank"
                className="ui button icloud-generate-password flex-grow-shrink" rel="noreferrer"
              >
                GENERATE PASSWORD <img src={externalLinkIcon} />
              </a>
              <Grid.Row className="ui grid margin-top-0 margin-bottom-0">
                <Grid.Column width={6}>
                  <Form.Field className="input-self input-self--secondary">
                    <Form.Input
                      fluid
                      id="email"
                      name="email"
                      label="Enter your Outlook email"
                      placeholder="Email"
                      value={this.state.email.value || ''}
                      onChange={this.handleInputChange}
                    />
                    {this.state.email.error && (
                      <span className="error-message">
                        {this.state.email.error}
                      </span>
                    )}
                  </Form.Field>
                  <Form.Field className="input-self input-self--secondary">
                    <Form.Input
                      fluid
                      id="password"
                      name="password"
                      type="password"
                      label="Enter your Outlook password"
                      placeholder="Password"
                      value={this.state.password.value || ''}
                      onChange={this.handleInputChange}
                    />
                    {this.state.password.error && (
                      <span className="error-message">
                        {this.state.password.error}
                      </span>
                    )}
                  </Form.Field>
                  <div className="source-list--buttons flex-row">
                    <Button
                      disabled={this.state.sendingCode}
                      content="CONTINUE"
                      onClick={this.handleSubmit}
                    />
                    <Button content="CANCEL" onClick={this.handleClose} />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </div>
            <img
              src={closeBtn}
              className="icould-modal--close"
              onClick={this.handleClose}
            />
          </div>
        )}
      </>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_NOTIFICATION
)(observer(MicrosoftPersonal));
