import type { SocialAccountIdentitiesProps } from './types';
import { ButtonManageSocialAccount } from 'Components/shared/ButtonManageSocialAccount';
import React from 'react';
import { Loader } from 'semantic-ui-react';

export const SocialAccountIdentities: React.FC<
  SocialAccountIdentitiesProps
> = ({
  auth0Identities,
  mapProperLogoProvide,
  unlinkSocialAccount,
  testid,
}) => {
  if (typeof auth0Identities?.length !== 'number') {
    return <Loader active inline />;
  }

  if (auth0Identities?.length === 0) {
    return (
      <div>
        Your list is empty. Click on the button below to Link Social Account to
        the list.
      </div>
    );
  }

  return (
    <React.Fragment>
      {auth0Identities?.map((identity) => (
        <div className="identity-item" key={identity.user_id}>
          <div className="identity-item--leftside">
            <div className="contact flex-row">
              <span data-private>{identity.profileData?.name}</span>
              <span className="identity-icon">
                <img src={mapProperLogoProvide(identity.provider)} />
              </span>
            </div>
            <div data-private className="content">
              {identity.profileData?.email}
            </div>
          </div>
          {!identity.provider.includes('Bhive') && (
            <ButtonManageSocialAccount
              action="REMOVE"
              icons={{ enabled: 'source-black-link' }}
              onClick={() => unlinkSocialAccount(identity)}
              testid={`${testid}-buttonRemoveSocialAccount`}
            />
          )}
        </div>
      ))}
    </React.Fragment>
  );
};
