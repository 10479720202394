import styled from 'styled-components';
import { UserPresence } from '../UserPresence';

const Avatar = styled.div<{ $hideInitials?: boolean }>`
  min-width: 30px;
  width: 30px;
  height: 30px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.base};
  ${({ $hideInitials }) =>
    $hideInitials &&
    `
      background: none;
      border: none;
  `}
`;
const StyledUserPresence = styled(UserPresence)<{ $hideInitials?: boolean }>`
  ${({ $hideInitials }) =>
    $hideInitials &&
    `
    position: static;
    display: flex;
  `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Initials = styled.span``;

const GroupImage = styled.img`
  max-height: 100%;
`;

export const Styled = {
  Avatar,
  UserPresence: StyledUserPresence,
  Initials,
  ImageWrapper,
  Image,
  GroupImage,
};
