import type { SidebarMenuDrawerProps } from './interfaces';
import type { IconDefinition } from '../shared/Icon/index.generated';
import React from 'react';
import { PORTAL_URL } from '../../constants/env';
import { ROUTE_PATHS } from '../../constants/routing';
import { Styled } from './SidebarMenuDrawer.styles';

export const SidebarMenuDrawer: React.FC<SidebarMenuDrawerProps> = ({
  isOpen,
  menu,
  showPortalMenuItem,
  showCalendar,
  activeTab,
  testid,
  onClose,
}) => {
  return (
    <Styled.Drawer $isOpen={isOpen}>
      <Styled.Container>
        {menu.map(({ label, icon, to, slug, testid }) => {
          if (slug === 'calendar' && !showCalendar) return null;
          return (
            <Styled.SideBarMenuButton
              key={`sidebar-menu-drawer-${slug}`}
              {...{
                label,
                icon: icon as IconDefinition,
                isActive: activeTab === slug,
                to,
                testid: `${testid}-sideBarMenuDrawer`,
              }}
            />
          );
        })}
        {showPortalMenuItem && (
          <Styled.SideBarMenuButton
            isExternal
            {...{
              label: 'Portal',
              icon: 'portal',
              to: PORTAL_URL,
              testid: `${testid}-sideBarMenuDrawer`,
            }}
          />
        )}
        <Styled.SideBarMenuButton
          {...{
            label: 'Settings',
            icon: 'settings',
            isActive: activeTab === 'settings',
            to: ROUTE_PATHS.settings.path,
            testid: `${testid}-sideBarMenuDrawer`,
          }}
        />
        <Styled.CloseButton
          onClick={() => onClose()}
          data-testid={`${testid}-sideBarMenuDrawer-btn`}
        >
          <Styled.Icon icon="arrow-left" size="tiny" />
        </Styled.CloseButton>
      </Styled.Container>
    </Styled.Drawer>
  );
};
