import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';

/**
 * **NOTE:** This is being used to disable the standard codeBlock rule from SimpleMarkdown.
 * There seems to be a bug with certain very fringe use cases.
 * Setting match false doesn't affect the markdown in any way.
 */
export const buildCodeBlock = (): ParserRule & ReactOutputRule => ({
  ...SimpleMarkdown.defaultRules.codeBlock,
  order: SimpleMarkdown.defaultRules.codeBlock.order - 0.5,
  match: () => false,
  react: ({ content }) => (
    <pre>
      <>{content}</>
    </pre>
  ),
});
