import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledPopup = styled(Popup)`
  padding: 0 !important;
  &::before {
    z-index: -1 !important;
  }
`;

const PopupContent = styled.div`
  padding: 3px;
  min-width: 190px;
  max-width: 380px;
`;

export const Styled = {
  Popup: StyledPopup,
  PopupContent,
};
