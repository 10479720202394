import type { CustomToolbarProps } from './types';
import leftArrow from 'Assets/images/arrow-left.svg';
import { observer } from 'mobx-react';
import { SupportedViews } from 'Models/Calendar';
import moment, { Moment } from 'moment-timezone';
import React, { useCallback } from 'react';
import { Loader } from 'semantic-ui-react';
import { Styled } from './index.styles';
export const CustomTimeSlot = ({ props }) => {
  return (
    <div className={props.children.props.className}>
      {props.children.props.children && (
        <span className={props.children.props.children.props?.className}>
          {moment(props.value).format('h A')}
        </span>
      )}
    </div>
  );
};

export const CustomToolbar = observer(
  ({
    toolbar,
    calendar,
    toogleCreateEventModal,
    testId: parentTestId,
  }: CustomToolbarProps) => {
    const testId = `${parentTestId}-customToolbar`;
    const backStartEnd = (date: Moment, view: SupportedViews) => {
      const endOfNewWeek = date.startOf(view).subtract(1, 'day').endOf(view);
      const startOfNewWeek = endOfNewWeek.clone().startOf(view);
      const startDateString = startOfNewWeek.toLocaleString();
      const endDateString = endOfNewWeek.toLocaleString();
      return [startDateString, endDateString];
    };

    const nextStartEnd = (date: Moment, view: SupportedViews) => {
      const startOfNewWeek = date.endOf(view).add(1, 'day').startOf(view);
      const endOfNewWeek = startOfNewWeek.clone().endOf(view);
      const startDateString = startOfNewWeek.toLocaleString();
      const endDateString = endOfNewWeek.toLocaleString();
      return [startDateString, endDateString];
    };

    const goToBack = useCallback(() => {
      const cloneDate = moment(toolbar.date).clone();
      const [start, end] = backStartEnd(
        cloneDate,
        toolbar.view as SupportedViews
      );
      calendar.setDateToFrom({ from: start, to: end });
      calendar.loadEventsFromTo(start, end);
      toolbar.onNavigate('PREV');
    }, [toolbar.date, toolbar.view]);

    const goToNext = useCallback(() => {
      const cloneDate = moment(toolbar.date).clone();
      const [start, end] = nextStartEnd(
        cloneDate,
        toolbar.view as SupportedViews
      );
      calendar.setDateToFrom({ from: start, to: end });
      calendar.loadEventsFromTo(start, end);
      toolbar.onNavigate('NEXT');
    }, [toolbar.date, toolbar.view]);

    const goToday = () => {
      const today = moment();
      const view = toolbar.view as 'month' | 'week';
      const [start, end] = [
        today.startOf(view).toLocaleString(),
        today.endOf(view).toLocaleString(),
      ];
      calendar.setDateToFrom({ from: start, to: end });
      calendar.loadEventsFromTo(start, end);
      toolbar.onNavigate('TODAY');
    };

    const label = useCallback(
      () => moment(toolbar.date).format('MMMM YYYY'),
      [toolbar.date]
    );

    const setActiveView = useCallback(
      (e) => {
        const supportedViews = ['week', 'month'];
        const cloneDate = moment(toolbar.date).clone();
        if (e?.target?.id && supportedViews.includes(e?.target?.id)) {
          getEventsBasedOnView(cloneDate, e.target.id);
          calendar.setCalendarView(e.target.id);
          toolbar.onView(e.target.id);
        }
      },
      [calendar.calendarView, toolbar.date]
    );

    const getEventsBasedOnView = (date: Moment, view: SupportedViews) => {
      const startOfView = date.startOf(view);
      const endOfView = startOfView.clone().endOf(view);
      const startDateString = startOfView.toLocaleString();
      const endDateString = endOfView.toLocaleString();
      calendar.setDateToFrom({ from: startDateString, to: endDateString });
      calendar.loadEventsFromTo(startDateString, endDateString);
    };

    const handleArrowKeys = (e) => {
      switch (e?.target?.keyCode) {
        case 37:
          goToBack();
          break;
        case 39:
          goToNext();
          break;
      }
    };

    return (
      <div onKeyDown={handleArrowKeys}>
        <div
          className={
            'toolbar-container flex-row flex-align-items-center flex-wrap flex-justify-space-between'
          }
        >
          <div className="flex-row flex-align-items-center">
            <Styled.TodayButton
              onClick={goToday}
              data-testid={`${testId}-buttonToday`}
            >
              Today
            </Styled.TodayButton>

            <div className={'back-next-buttons'}>
              <img
                src={leftArrow}
                className="back-arrow"
                onClick={goToBack}
                data-testid={`${testId}-buttonBack`}
              />
              <img
                src={leftArrow}
                className="next-arrow"
                onClick={goToNext}
                data-testid={`${testId}-buttonForward`}
              />
            </div>

            <label className="label-date">{label()}</label>
          </div>

          <div className="view-buttons flex-row flex-align-items-center">
            <Styled.CalendarButton
              id="week"
              onClick={setActiveView}
              content="WEEK"
              $isActive={calendar.calendarView === 'week'}
              testid={`${testId}-buttonWeekCalendar`}
            />

            <Styled.CalendarButton
              id="month"
              onClick={setActiveView}
              content="MONTH"
              $isActive={calendar.calendarView === 'month'}
              testid={`${testId}-buttonMonthCalendar`}
            />
          </div>

          <Styled.CreateButton
            variant="primary"
            onClick={(e) => {
              toogleCreateEventModal(e, true);
            }}
            id="click-event"
            disabled={!calendar.allEventsAreLoaded}
            testid={`${testId}-buttonCreateEvent`}
          >
            CREATE
            {!calendar.allEventsAreLoaded && (
              <Loader inline active size="tiny" />
            )}
          </Styled.CreateButton>
        </div>
      </div>
    );
  }
);
