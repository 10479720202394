import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { isNullOrUndefined } from 'util';
import API from '~/api';
import PersonModel from '../../models/PersonModel';
import { useConversationStore } from './index.store';
import { OptOutStatusResponse } from './index.types';

export const QUERY_KEY_FETCH_OPT_OUT_STATUS = 'conversation.fetchOptOutStatus';
export const QUERY_KEY_FETCH_PERSON_BY_PERSON_ID =
  'conversation.fetchPersonByPersonId';

/*FIXME: For now we need to export this, so we can call on the mobx store (class instance)
 * We will also be able to rely on "useQueries" to parallelize the requests, instead of the Promise.allSettled
 */
export const fetchOptOutStatus = async (phoneNumbers: string[]) => {
  if (phoneNumbers.length === 0) {
    useConversationStore.getState().setOptedOutPhoneNumbers([]);
    return [];
  }

  try {
    const { data: optOutStatusResponse } = await API.get<OptOutStatusResponse>(
      API_ENDPOINTS.OptOutStatus(),
      {
        params: { phoneNumbers },
      }
    );

    const optedOutPhoneNumbers = optOutStatusResponse
      .filter(
        (contact) =>
          contact.optedOut && phoneNumbers.includes(contact.phoneNumber)
      )
      .map((contact) => contact.phoneNumber);

    // FIXME: In the future when we dont need to call this via mobx store, we can use the setOptedOutPhoneNumbers
    // with the useConversationStore hook
    useConversationStore
      .getState()
      .setOptedOutPhoneNumbers(optedOutPhoneNumbers);

    return optedOutPhoneNumbers;
  } catch (error) {
    useConversationStore.getState().setOptedOutPhoneNumbers([]);

    throw error;
  }
};

const fetchMentionUsers = async (
  personIds: number[]
): Promise<Map<number, PersonModel>> => {
  if (isNullOrUndefined(personIds) || personIds.length === 0) {
    return useConversationStore.getState().mentionUsers;
  }

  const fetchPromises = personIds.map(async (personId) => {
    const { data } = await API.get<PersonModel>(
      API_ENDPOINTS.PeoplePersonById(personId)
    );

    useConversationStore
      .getState()
      .addMentionUser(PersonModel.FromResponseDto(data));
  });

  await Promise.all(fetchPromises);

  return useConversationStore.getState().mentionUsers;
};

export const useFetchOptOutStatus = (phoneNumbers: string[]) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_OPT_OUT_STATUS, phoneNumbers],
    queryFn: async () => fetchOptOutStatus(phoneNumbers),
  });

export const useFetchMentionUsers = (personIds: number[]) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_PERSON_BY_PERSON_ID, personIds],
    queryFn: async () => fetchMentionUsers(personIds),
  });

export const useConversation = () => ({
  useFetchOptOutStatus,
  useFetchMentionUsers,
});
