import CloseBtn from 'Assets/images/close-btn.svg';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Button, Header, Popup, Segment } from 'semantic-ui-react';
import { resolveConversationPath } from 'Utils/routeNav';


export interface CloseButtonProps {
  /** The current `location.pathname` */
  pathName: string;
  /** Text to show instead of 'Close'  */
  popupText?: string;
  /** Optional method to run on close click  */
  onClose?: (from: string, path: string) => void;
  from?: string;
}
export const CloseButton = (props: CloseButtonProps) => (
  <Segment
    onClick={props.onClose}
    className="close-button-wrap no-vmargin flex-shrink"
    clearing
    basic
  >
    <Header as="h3" className="inverted">
      <Popup
        trigger={
          <Button
            className="right floated close-button"
            onClick={() =>
              props.onClose(
                props.from,
                resolveConversationPath(props.pathName, 'menu')
              )
            }
            compact
            basic
            size="small"
          >
            <img className="close-icon" src={CloseBtn} />
          </Button>
        }
        inverted
        position="bottom right"
        content={!isEmpty(props.popupText) ? props.popupText : 'Close'}
      />
    </Header>
  </Segment>
);

export default CloseButton;
