import { History } from 'history';
import { computed } from 'mobx';
import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
} from 'mobx-react-router';
import { RootStore } from 'Stores/RootStore';

/**
 * @deprecated ONLY USE ROUTER STORE INSIDE OTHER STORES,
 * as the stores cannot access the `react-router-dom` Provider in any other way.
 * For components, please use `react-router-dom` hooks instead.
 **/
export class RouterStore extends BaseRouterStore {
  rootStore: RootStore;

  constructor(history: History, rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
    this.history = syncHistoryWithStore(history, this);
  }

  @computed
  get currentPath() {
    // @ts-ignore
    return this.location?.location?.pathname || window.location.pathname;
  }
}
