import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef } from 'react';
import { STORE_PHONE_CALL } from '../../../../constants/stores';
import { SoftphoneAudiosProps } from './types';
const incomingCallsMp3 = require('../../../../assets/audio/IncomingCalls.mp3');
const outgoingCallsMp3 = require('../../../../assets/audio/OutgoingCalls.mp3');
const secondIncomingCallMp3 = require('../../../../assets/audio/SecondIncomingCall.mp3');

const SoftphoneAudios = (props: SoftphoneAudiosProps) => {
  const { phoneCall: phoneStore } = props;
  const remoteAudio = useRef<HTMLAudioElement>(null);
  const ringTone = useRef<HTMLAudioElement>(null);
  const incomingTone = useRef<HTMLAudioElement>(null);
  const secondIncomingTone = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    phoneStore.setLocalRemoteAudio(remoteAudio.current);
    phoneStore.setLocalRingTone(ringTone.current);
    phoneStore.setLocalIncomingTone(incomingTone.current);
    phoneStore.setSecondLocalIncomingtone(secondIncomingTone.current);
  }, []);

  return (
    <Fragment>
      <audio
        key="mainRemoteAudio"
        ref={(elem) => (remoteAudio.current = elem)}
        id="mainRemoteAudio"
      />
      <audio
        key="ringtone"
        ref={(elem) => (ringTone.current = elem)}
        src={incomingCallsMp3}
        id="ringtone-audio"
        preload="auto"
      />
      <audio
        key="secondIncomingtone"
        ref={(elem) => (secondIncomingTone.current = elem)}
        src={secondIncomingCallMp3}
        id="secondIncomingtone-audio"
        preload="auto"
      />
      <audio
        key="incomingtone"
        ref={(elem) => (incomingTone.current = elem)}
        src={outgoingCallsMp3}
        id="incomingtone-audio"
        preload="auto"
      />
    </Fragment>
  );
};

export default inject(STORE_PHONE_CALL)(observer(SoftphoneAudios));
