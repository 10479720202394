import type { ConfirmationModalState } from './ConfirmationModalContextWrapper.types';
import * as React from 'react';

type ConfirmationModalContextType = {
  setShow: React.Dispatch<React.SetStateAction<ConfirmationModalState>>;
};

export const ConfirmationModalContext =
  React.createContext<ConfirmationModalContextType>({
    setShow: () => {},
  });
