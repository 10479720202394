import { EMPTY_TIMEUUID, TIMEZONE_IDENTIFIER } from 'Constants/env';
import { eachRight, isEmpty } from 'lodash';
import {
  computed,
  IObservableArray,
  observable,
  toJS,
  makeObservable,
} from 'mobx';
import { createTransformer } from 'mobx-utils';
import moment from 'moment-timezone';
import { isNullOrUndefined } from 'util';
import { getCorrectedMsFromUnixEpoch } from '../utils/timeUUIDParser';
import { MessageModel } from './MessageModel';
/** A set of `Message`s, grouped/keyed by day (`YYYY-MM-DD`) */
export class MessageGroup {
  constructor(
    conversationId: string,
    groupKey: string,
    getReadMessage: () => MessageModel
  ) {
    makeObservable(this);
    this.conversationId = conversationId;
    this.getReadMessage = getReadMessage;
    this.groupKey = groupKey;
    this.formattedDate = moment
      .tz(this.groupKey, TIMEZONE_IDENTIFIER)
      .format('MMMM Do, YYYY');
  }

  readonly conversationId: string;
  readonly getReadMessage: () => MessageModel;
  /** The date this MessageGroup represents formatted as `YYYY-MM-DD`  */
  readonly groupKey: string;
  /** `groupKey` formatted with `moment#format('LLL') */
  readonly formattedDate: string;
  /**
   * Whether **all** of the `Message`s in this group are read.
   *
   * True if all of the `Message`s were `created` _before_ the last read `Message` OR `computeReadMessage` returned `null` OR readMessage.id is an empty TIMEUUID
   */
  @computed
  get AllMessagesRead() {
    const readMessage = this.getReadMessage();
    if (isNullOrUndefined(readMessage) || readMessage.id === EMPTY_TIMEUUID) {
      return true; // *Default true for all Messages read
    }
    const readMsgUnixEpochMs = getCorrectedMsFromUnixEpoch(readMessage.id);
    return this.messages.every(
      (m) => getCorrectedMsFromUnixEpoch(m.id) <= readMsgUnixEpochMs
    );
  }

  /**
   * Whether **any** of the `Message`s in this group are read.
   *
   * True if any of the `Message`s were `created` _before_ the last read `Message` OR `computeReadMessage` returned `null` OR readMessage.id is an empty TIMEUUID
   */
  @computed
  get AnyMessagesRead() {
    const readMessage = this.getReadMessage();
    if (isNullOrUndefined(readMessage) || readMessage.id === EMPTY_TIMEUUID) {
      return true; // *Default true for all Messages read
    }
    const readMsgUnixEpochMs = getCorrectedMsFromUnixEpoch(readMessage.id);
    return this.messages.some(
      (m) => getCorrectedMsFromUnixEpoch(m.id) <= readMsgUnixEpochMs
    );
  }

  /**
   * Whether **all** of the `Message`s in this group are read.
   *
   * True if all of the `Message`s were `created` _after_ the last read `Message` OR `computeReadMessage` returned `null`
   */
  @computed
  get AllMessagesUnread() {
    const readMessage = this.getReadMessage();
    if (isNullOrUndefined(readMessage) || readMessage.id === EMPTY_TIMEUUID) {
      return false; // *Default false for no Messages unread
    }
    const readMsgUnixEpochMs = getCorrectedMsFromUnixEpoch(readMessage.id);
    return this.messages.every(
      (m) => getCorrectedMsFromUnixEpoch(m.id) > readMsgUnixEpochMs
    );
  }

  /**
   * Whether **any** of the `Message`s in this group are read.
   *
   * True if any of the `Message`s were `created` _after_ the last read `Message` OR `computeReadMessage` returned `null`
   */
  @computed
  get AnyMessagesUnread() {
    const readMessage = this.getReadMessage();
    if (isNullOrUndefined(readMessage) || readMessage.id === EMPTY_TIMEUUID) {
      return false; // *Default false for no Messages unread
    }
    const readMsgUnixEpochMs = getCorrectedMsFromUnixEpoch(readMessage.id);
    return this.messages.some(
      (m) => getCorrectedMsFromUnixEpoch(m.id) > readMsgUnixEpochMs
    );
  }

  /**
   * Returns all messages with a boolean flag indicating if the message is read
   */
  @computed
  get AllMessages() {
    const mostRecentReadMessage = this.getReadMessage();

    if (
      isNullOrUndefined(mostRecentReadMessage) ||
      mostRecentReadMessage.id === EMPTY_TIMEUUID
    ) {
      this.messages.forEach((message) => message.setIsRead(true));

      return this.messages;
    }

    const mostRecentReadMessageTimeMs = getCorrectedMsFromUnixEpoch(
      mostRecentReadMessage.id
    );

    this.messages.forEach((message) => {
      const isRead =
        getCorrectedMsFromUnixEpoch(message.id) <= mostRecentReadMessageTimeMs;

      message.setIsRead(isRead);
    });

    return this.messages;
  }

  /**
   * The `Message`s in this group which have **NOT** been read. Returns `null` if none are unread.
   */
  @computed
  get UnreadMessages() {
    const readMessage = this.getReadMessage();
    if (isNullOrUndefined(readMessage) || readMessage.id === EMPTY_TIMEUUID) {
      return null; // *Return no Messages as unread
    }
    const readMsgUnixEpochMs = getCorrectedMsFromUnixEpoch(readMessage.id);
    return (
      this.messages.filter(
        (m) => getCorrectedMsFromUnixEpoch(m.id) > readMsgUnixEpochMs
      ) || null
    ); // newer than the read Message, exclusive
  }

  selectIsMessageRead = createTransformer((messageId: string) => {
    if (isEmpty(messageId) || messageId === EMPTY_TIMEUUID) {
      return true; // *Default Message as read
    }
    const msg = this.messages.find((m) => m.id === messageId);
    if (isNullOrUndefined(msg)) {
      console.error(
        `selectIsMessageRead was unable to find Message ${messageId}, and is returning true by default. This should never happen, if it does, something is very wrong.`
      );
      return true; // *Default Message as read
    }
    const readMessage = this.getReadMessage();
    if (isNullOrUndefined(readMessage) || readMessage.id === EMPTY_TIMEUUID) {
      return true;
    }
    return (
      getCorrectedMsFromUnixEpoch(msg.id) <=
      getCorrectedMsFromUnixEpoch(readMessage.id)
    );
  });

  /**
   * Messages, should be sorted from oldest -> newest (i.e. SortBy=Descending, then reverse)
   *
   * **DO NOT MODIFY THIS ARRAY DIRECTLY. USE `GroupedMessages` `unshiftOlderMessages` or `pushNewerMessages` INSTEAD.**
   */
  @observable
  messages: IObservableArray<MessageModel> = observable.array();

  /** Get a human-friendly JSON representation of the basic properties of this `MessageGroup` */
  @computed
  get SerializedJSON() {
    const readMessage = this.getReadMessage();
    return JSON.stringify(
      {
        DisplayName: this.DisplayName,
        GroupKey: this.groupKey,
        AllMessagesRead: this.AllMessagesRead,
        AnyMessagesRead: this.AnyMessagesRead,
        AllMessagesUnread: this.AllMessagesUnread,
        AnyMessagesUnread: this.AnyMessagesUnread,
        AllMessages: toJS(this.messages),
        ReadMessage: readMessage,
        // ReadMessages: this.ReadMessages, // uncomment for verbose list of ReadMessages
        // UnreadMessages: this.UnreadMessages, // uncomment for verbose list of UnreadMessages
        NewestId: this.NewestId,
        OldestId: this.OldestId,
        NewestFiveIds: this.NewestFiveIds,
        NewestCreated: this.NewestCreated.toISOString(),
        OldestCreated: this.OldestCreated.toISOString(),
        LinkPreviewHeightSum: this.LinkPreviewHeightSum,
        LinkPreviewImageHeightSum: this.LinkPreviewImageHeightSum,
      },
      null,
      4
    );
  }

  /** Text to display in the divider at the start of this group */
  @computed
  get DisplayName() {
    const startOfTodayMoment = moment
      .tz(new Date(), TIMEZONE_IDENTIFIER)
      .startOf('d');
    const gkMoment = moment.tz(this.groupKey, TIMEZONE_IDENTIFIER);
    const diff = startOfTodayMoment.diff(gkMoment, 'd');
    if (diff === 0) {
      return 'Today';
    }
    if (diff === 1) {
      return '1 Day ago';
    }
    if (gkMoment.year() === startOfTodayMoment.year()) {
      return gkMoment.format('dddd, MMMM Do');
    }
    return this.formattedDate;
  }

  /** The first (oldest) `MessageModel` in this group */
  @computed
  get OldestMessage() {
    return this.messages.length > 0 ? this.messages[0] : null;
  }

  /** `id` of the first (oldest) `MessageModel` in this group */
  @computed
  get OldestId() {
    return this.OldestMessage !== null ? this.OldestMessage.id : null;
  }

  /** `CreatedMoment` of the first (oldest) `MessageModel` in this group */
  @computed
  get OldestCreated() {
    return this.OldestMessage !== null
      ? this.OldestMessage.CreatedMoment
      : null;
  }

  /** The last (newest) `MessageModel` in this group */
  @computed
  get NewestMessage() {
    return this.messages.length > 0
      ? this.messages[this.messages.length - 1]
      : null;
  }

  @computed
  get NewestFirstIn5Message() {
    const firstMessagesIn5Mins = (this.messages || []).filter(
      (m) => !!m.isFirstIn5Mins
    );
    return firstMessagesIn5Mins.length > 0
      ? firstMessagesIn5Mins[firstMessagesIn5Mins.length - 1]
      : null;
  }

  /** `id` of the last (newest) `MessageModel` in this group */
  @computed
  get NewestId() {
    return this.NewestMessage !== null ? this.NewestMessage.id : null;
  }

  /** `CreatedMoment` of the last (newest) `MessageModel` in this group */
  @computed
  get NewestCreated() {
    return this.NewestMessage !== null
      ? this.NewestMessage.CreatedMoment
      : null;
  }

  /** `id`s of the newest (up to) 5 `Message`s in this `MessageGroup` */
  @computed
  get NewestFiveIds() {
    if (this.messages.length === 0) {
      return null;
    }
    const ids: string[] = [];
    eachRight(this.messages, (msg) => {
      if (ids.length >= 5) {
        return false;
      }
      ids.push(msg.id);
    });
    return ids;
  }

  /** Sum of vertical pixel heights for link preview containers in this `MessageGroup` (used to compensate when scrolling) */
  @computed
  get LinkPreviewHeightSum() {
    const heightSum = this.messages.reduce((accum, val) => {
      if (val.linkPreviewDimensions !== null) {
        accum += val.linkPreviewDimensions.height;
      }
      return accum;
    }, 0);
    return heightSum;
  }

  /** Sum of vertical pixel heights for link preview images in this `MessageGroup` (used to compensate when scrolling) */
  @computed
  get LinkPreviewImageHeightSum() {
    const imageHeightSum = this.messages.reduce((accum, val) => {
      if (val.linkPreviewDimensions !== null) {
        accum += val.linkPreviewDimensions.imageHeight;
      }
      return accum;
    }, 0);
    return imageHeightSum;
  }
}
