
import AtMentionMembers from 'Components/AtMentionMembers';
import ContentEditable from 'Components/ContentEditable';
import { TextButton } from 'Components/shared/TextButton';
import styled from 'styled-components';

const EditWrapper = styled.div`
  max-width: 64%;
  margin: 10px 5px 5px auto;
`;

const InputArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  max-height: 169px;
  padding: 10px 15px 0px !important;
  border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  border-radius: 5px;

  ::after {
    content: '';
    position: absolute;
    top: 10px;
    right: -5px;
    transform: rotate(-45deg);
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.colors.base};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    border-right: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const StyledContentEditable = styled(ContentEditable)`
  overflow-y: scroll;
  font: normal normal normal 13px/18px Open Sans;
  padding: 0 !important;
  border: none !important;
`;

const HoverButtons = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px;
  width: 100%;
`;

const StyledAtMentionMembers = styled(AtMentionMembers)`
  &&& {
    position: relative;
    bottom: 0;
    right: 0;
  }
  margin-right: 10px;
`;

const EditActions = styled.div`
  margin-top: 10px;
  text-align: right;
`;

const CancelButton = styled(TextButton)`
  margin-right: ${({ theme }) => theme.sizes.xxsmall};
`;

export const Styled = {
  EditWrapper,
  InputArea,
  ContentEditable: StyledContentEditable,
  HoverButtons,
  AtMentionMembers: StyledAtMentionMembers,
  EditActions,
  CancelButton,
};
