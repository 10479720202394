import React, { useEffect, useState } from 'react';

import { Styled } from './index.styles';
import { MessageBannerProps } from './index.types';

export const MessageBanner: React.FC<MessageBannerProps> = ({ icon, children, isVisible = false, canClose = true }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(isVisible);
  const [canAnimate, setCanAnimate] = useState(false);

  useEffect(() => {
    setIsBannerVisible(isVisible);
    isVisible && setCanAnimate(true)
  }, [isVisible]);


  const handleClose = () => {
    setIsBannerVisible(false);
  };

  return (
    <Styled.Wrapper
      isVisible={isBannerVisible}
      canAnimate={canAnimate}
      aria-hidden={!isBannerVisible}
      key={isBannerVisible ? 'visible' : 'hidden'}
      role='banner'
    >
      <Styled.Icon icon={icon} size="tiny" />
      <Styled.Text>{children}</Styled.Text>
      {canClose && <Styled.CloseIconButton
        variant="text"
        icon="cross"
        size="mini"
        onClick={handleClose}
        testid="MessageBanner-close"
        compact
      />}
    </Styled.Wrapper>
  );
};
