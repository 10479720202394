import { observer } from 'mobx-react';
import * as React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { TransferHoldingListItemProps } from './interfaces';

const holdCallIcon = require('../../assets/images/btn-call-hold.svg');

/**
 * List item display of a call on hold beyond one.
 */
export class TransferHoldingListItem extends React.Component<TransferHoldingListItemProps> {
  setTransfer = (e) => {
    e.persist();
    const { phoneCall } = this.props;
    if (e.target.className.includes('hold-toggle-btn')) {
      this.toggleHold();
    } else {
      phoneCall.setTransferMode(false);
    }
  };

  toggleHold = () => {
    const { phoneCall } = this.props;
    if (!phoneCall.isCallOnHold) {
      phoneCall.hold();
      phoneCall.onHoldIconState('pause circle');
    } else {
      phoneCall.unHold();
      phoneCall.onHoldIconState('pause circle outline');
    }
  };

  render() {
    const { phoneCall } = this.props;
    return (
      <li
        className="call-holding-list-item"
        onClick={this.setTransfer}
        key={'1'}
      >
        <div className="status-timer-wrapper">
          <div className="status">
            {phoneCall.isCallOnHold ? 'HOLDING...' : 'TALKING...'}
          </div>
          <div className="call-timer">{phoneCall.timerDisplay}</div>
        </div>
        <div className="text-btn-wrapper">
          <div className="text-wrapper">
            {phoneCall.person !== null ? (
              phoneCall.person.case({
                pending: () => <Loader indeterminate active />,
                rejected: (reason) => (
                  <Message
                    visible
                    error
                    content={
                      'Error Loading Person: ' + reason.response.data.message
                    }
                  />
                ),
                fulfilled: (response) => {
                  return (
                    <>
                      <div className="name">
                        {response.data.DisplayName
                          ? response.data.DisplayName
                          : 'Unknown User'}
                      </div>
                      <div className="number">
                        {response.data.lines
                          ? response.data.formatNumber()
                          : ''}
                      </div>
                    </>
                  );
                },
              })
            ) : (
              <>
                <div className="name">{'Unknown User'}</div>
                <div className="number">
                  {phoneCall.FormattedNumber
                    ? phoneCall.FormattedNumber
                    : 'Unknown Number'}
                </div>
              </>
            )}
          </div>
          <div className="btn-wrapper">
            <button
              className={`${
                phoneCall.isCallOnHold ? 'active' : ''
              } hold-toggle-btn`}
            >
              <img src={holdCallIcon} />
            </button>
          </div>
        </div>
      </li>
    );
  }
}

export default observer(TransferHoldingListItem);
