import { ChatLayoutContextWrapper } from 'Components/Chat/contexts/ChatLayoutContextWrapper';
import GroupInfo from 'Components/GroupInfo';
import ActionPanel from 'Containers/ActionPanel';
import { ContextPanel } from 'Containers/ContextPanel';
import EntityProfile from 'Containers/EntityProfile';
import PinnedMessages from 'Containers/PinnedMessages';
import UiBridge from 'Containers/UiBridge';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export const ChatPage: React.FC = observer(() => {
  const {
    ui: { activePinnedConversationId, hideSecondaryMenu },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  return (
    <ChatLayoutContextWrapper>
      {!hideSecondaryMenu && <ActionPanel />}
      <ContextPanel />
      <EntityProfile />
      <UiBridge />
      <GroupInfo />
      {activePinnedConversationId && <PinnedMessages />}
    </ChatLayoutContextWrapper>
  );
});
