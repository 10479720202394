import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Grid, Header } from 'semantic-ui-react';
const loader = require('../../assets/images/icon-warning.svg');

export const AppError = ({ error }: { error: string }) => {
  const auth0 = useAuth0();

  useEffect(() => {
    const timeout = setTimeout(() => {
      auth0.logout({
        returnTo: window.location.origin,
      });
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Grid textAlign="center" verticalAlign="middle" className="login">
      <Grid.Column style={{ maxWidth: 450 }} className="login-container">
        <Header as="h3" textAlign="center" className="being-loaded">
          <img src={loader} className="loader" />
          <br />
          Error: {error}
        </Header>
        <span>You will be redirected to login shortly!</span>
      </Grid.Column>
    </Grid>
  );
};
