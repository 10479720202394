//TODO We should update this to styled components.

import type { EventPropGetter } from './types';
import { IEvent } from '../../models/Calendar';

export const eventPropGetter = (props: EventPropGetter) => (event: IEvent) => {
  const { loggedInPersonId, checkExternalSources } = props;
  const isLoggedInParticipant = event.participants.find(
    (participant) => participant.platformUserId === loggedInPersonId
  );
  const isExternalSource =
    event.providerEventId && checkExternalSources(event.participants);
  const status = isLoggedInParticipant?.status || isExternalSource?.status;
  const newStyle = {
    backgroundColor: 'rgba(244, 245, 247, 1)',
    color: 'rgba(117, 134, 149, 1)',
    borderRadius: '4px',
    border: 'none',
    borderLeft: '3px solid rgba(141, 155, 168, 1)',
  };

  if (status === 'no') {
    (newStyle.backgroundColor = 'white'),
      (newStyle.color = !event.providerEventId
        ? 'rgba(52, 173, 186, 1)'
        : 'rgba(117, 134, 149, 1)'),
      (newStyle.border = !event.providerEventId
        ? '1px solid rgba(56, 189, 205, 1)'
        : '1px solid rgba(141, 155, 168, 1)'),
      (newStyle.borderLeft = !event.providerEventId
        ? '3px solid rgba(56, 189, 205, 1)'
        : '3px solid rgba(141, 155, 168, 1)');
  } else if (!event.providerEventId) {
    (newStyle.backgroundColor = 'rgba(225, 247, 249, 1)'),
      (newStyle.color = 'rgba(52, 173, 186, 1)'),
      (newStyle.borderLeft = '3px solid rgba(56, 189, 205, 1)');
  }

  return {
    className: status === 'no' ? 'declined-event' : '',
    style: newStyle,
  };
};
