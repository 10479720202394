import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { UIStoreState, UIStore } from './index.type';

const initialState: UIStoreState = {
  emojiPickerState: {
    editing: false,
    open: false,
  },
};

const uiStore = create(
  devtools<UIStore>((set) => ({
    ...initialState,

    setEmojiPickerState: (emojiPickerState) =>
      set((state) => ({
        ...state,
        emojiPickerState,
      })),

    reset: () => set(initialState),
  }))
);

export const useUIStore = uiStore;
