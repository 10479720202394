import cs from 'classnames';
import React from 'react';
import { IconSvg } from './index.generated';
import { Styled } from './index.styles';
import { IconProps } from './types';
export * from './index.generated';

export const Icon: React.FC<IconProps> = ({
  size = 'small',
  icon,
  style,
  className,
}) => (
  <Styled.Icon {...{ style }} $size={size} className={cs('Icon', className)}>
    {IconSvg[icon]}
  </Styled.Icon>
);
