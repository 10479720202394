import { BodyCopy } from 'Components/shared/BodyCopy';
import styled from 'styled-components';

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const DividerLine = styled.hr`
  flex-grow: 1;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const DividerText = styled(BodyCopy)`
  margin: 0 10px;
  text-align: center;
`;

const ContentItemsList = styled.div`
  height: 100%;
  position: relative;

  padding-top: 1rem;

  overflow-x: hidden;
  overflow-y: auto;

  flex-basis: 100%;
  flex: 1 1 auto;

  padding-right: 11px;
  overflow: hidden;

  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #ccc transparent;
  scroll-behavior: auto;

  &:hover {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const Styled = {
  DividerContainer,
  DividerLine,
  DividerText,
  ContentItemsList,
};
