export const stopMediaStream = (stream: MediaStream) => {
  stream?.getAudioTracks().forEach((track) => {
    track.stop();
  });
};

export const stopInitialStream = () => {
  const redDotInterval = setInterval(() => {
    window.localStream?.getAudioTracks().forEach((track) => {
      track.stop();
      clearInterval(redDotInterval);
    });
  }, 2000);
};

//Closing red dot bug from Chrome tab
export const stopAllStreams = (stream?: MediaStream) => {
  const allIncomingStreams = [
    stream,
    window.localStream2,
    window.localStream,
    window.localStream3,
  ].filter(Boolean);
  allIncomingStreams.forEach((stream: MediaStream) => {
    stopMediaStream(stream);
  });
};