import type { ChatLayoutContextProps } from './ChatLayoutContext';
import type { ChatLayoutContextWrapperProps } from './ChatLayoutContextWrapper.types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ChatLayoutContext } from './ChatLayoutContext';

export const ChatLayoutContextWrapper: React.FC<
  ChatLayoutContextWrapperProps
> = ({ children }) => {
  const { pathname } = useLocation();

  const [view, setView] = React.useState<ChatLayoutContextProps['view']>('');
  React.useEffect(() => setView(''), [pathname]);

  const [editMessageId, setEditMessageId] = React.useState('');

  const [replyMessage, setReplyMessage] =
    React.useState<ChatLayoutContextProps['replyMessage']>(null);

  return (
    <ChatLayoutContext.Provider
      value={{
        view,
        setView,
        editMessageId,
        setEditMessageId,
        replyMessage,
        setReplyMessage,
      }}
    >
      {children}
    </ChatLayoutContext.Provider>
  );
};
