import moment from 'moment';

export type formatDateGroupProps = (dateString: string) => string;

export const formatDateGroup: formatDateGroupProps = (dateString) => {
  const date = moment(dateString);
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const currentYearStart = moment().startOf('year');

  if (date.isSame(today, 'day')) return 'Today';
  if (date.isSame(yesterday, 'day')) return 'Yesterday';
  if (today.diff(date, 'days') < 7) return date.format('dddd');
  if (date.isAfter(currentYearStart)) return date.format('MMMM DD');
  return date.format('MMMM D, YYYY');
};
