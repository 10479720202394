import SimpleMarkdown, {
  ParserRule,
  ReactOutputRule,
  HtmlOutputRule,
} from 'simple-markdown';

export const buildHeading = (): ParserRule &
  ReactOutputRule &
  HtmlOutputRule => ({
  ...SimpleMarkdown.defaultRules.heading,
  match: SimpleMarkdown.blockRegex(/^ *(#{1,6}) *([^\n]+?) *#* *(?:\n *)*\n/),
});
