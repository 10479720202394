export const DTMF_ALLOWED_CHARACTERS = 'ABCD0123456789#*';

// input should have between 1 and 21 allowed DTMF characters
export const DTMF_MAX_CHARACTER_VALIDATION = new RegExp(
  `^(?=(?:[^${DTMF_ALLOWED_CHARACTERS}+]*[${DTMF_ALLOWED_CHARACTERS}+]){1,21}[^${DTMF_ALLOWED_CHARACTERS}+]*$).*$`,
  'g'
);

// these characters can be typed on the dialpad, but are discarded once user makes a call
export const DISCARDED_DIALPAD_INPUT_CHARACTERS = /[-()#]/g;

export const INVALID_DIALPAD_INPUT_CHARACTERS = /[^0-9A-D+*\-()#]/g;
