import React from 'react';
import { Styled } from './index.styles';
import { TextButtonProps } from './types';

export const TextButton: React.FC<TextButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
  testid,
  variant,
}) => (
  <Styled.Button
    $variant={variant}
    $disabled={disabled?.condition}
    {...(!disabled?.condition ? { onClick } : { title: disabled?.title })}
    {...{ testid, className }}
  >
    {children}
  </Styled.Button>
);
