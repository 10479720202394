import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { MobXProviderContext, observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import { PersonModel } from 'Models/PersonModel';
import * as React from 'react';
import { Grid, Loader } from 'semantic-ui-react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import { ErrorMessageBanner } from '../../shared/ErrorMessageBanner';
import { DirectoryRow } from './DirectoryRow';
import { DirectoryListItemProps } from './types';

export const DirectoryListItem: React.FC<DirectoryListItemProps> = observer(
  ({ searchItemId, testid: parentTestId }) => {
    const testid = `${parentTestId}-directoryListItem`;
    const { conversation, phoneCall, person } =
      React.useContext<RootStoreProps>(MobXProviderContext);

    const makeCall: (props: {
      type: 'userId' | 'phoneNumber';
      data: PersonModel;
    }) => void = ({ type, data }) => {
      if (type === 'userId' && data?.id) {
        phoneCall.callWithPerson(data.id, null);
      }
      if (type === 'phoneNumber' && data?.mobileNumber) {
        phoneCall.callWithPerson(null, data?.mobileNumber);
      }
    };

    const makeVideoCall = async () => {
      const response = await conversation.loadOrCreateConversationWithPost(
        searchItemId
      );
      conversation.postConferenceByConversationId(response.data.id.toString());
    };

    const personPbo: IPromiseBasedObservable<AxiosResponseT<PersonModel>> =
      searchItemId ? person.loadPersonByIdGetIfMissingGet(searchItemId) : null;

    return (
      <Grid columns={4}>
        {personPbo?.case({
          pending: () => <Loader indeterminate active size="tiny" />,
          rejected: ({ message, response }) => (
            <ErrorMessageBanner
              show={message !== 'Network Error'}
              content={
                'Error loading Participant: ' +
                (response?.data?.message || 'No Message')
              }
              testid={`${testid}-bannerErrorLoadParticipant`}
            />
          ),
          fulfilled: (ptcResp: AxiosResponseT<PersonModel>) => (
            <DirectoryRow
              {...{
                data: ptcResp.data,
                makeCall: (type) => makeCall({ data: ptcResp.data, type }),
                makeVideoCall,
                testid,
              }}
            />
          ),
        })}
      </Grid>
    );
  }
);
