import type { UserPresenceProps } from './types';
import type { IUnreadMessageAndMentionCounts } from '../../../interfaces/components';
import cx from 'classnames';
import * as React from 'react';
import { Styled } from './index.styles';

const testid = 'userPresence';

const createPresenceIcon = (
  icon: React.ReactElement,
  unreadMessages: number
) => {
  const unreadCount = unreadMessages > 99 ? '99+' : unreadMessages;

  if (unreadMessages > 0) {
    return (
      <Styled.UserPresence data-testid={`${testid}-icon`}>
        <Styled.UnreadMessageCount
          className={cx('number-circle', {
            'over-99': unreadMessages > 99,
          })}
        >
          {unreadCount}
        </Styled.UnreadMessageCount>
        {icon}
      </Styled.UserPresence>
    );
  }

  return icon;
};

export const UserPresence: React.FC<UserPresenceProps> = ({
  state,
  conversationId,
  showUnread,
  selectUnreadCounts,
  className,
}) => {
  const renderPresence = () => {
    if (!state) return null;
    const unreadCounts: IUnreadMessageAndMentionCounts =
      selectUnreadCounts(conversationId);
    const unreadMessages = showUnread ? unreadCounts.unreadMessages : 0;

    const statuses = {
      Active: createPresenceIcon(
        <Styled.AvailableIcon
          icon="available-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
      Idle: createPresenceIcon(
        <Styled.AwayIcon
          icon="away-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
      Away: createPresenceIcon(
        <Styled.AwayIcon
          icon="away-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
      OnCall: createPresenceIcon(
        <Styled.BusyIcon
          icon="busy-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
      Invisible: createPresenceIcon(
        <Styled.OfflineIcon
          icon="offline-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
      DoNotDisturb: createPresenceIcon(
        <Styled.DNDIcon
          icon="do-not-disturb-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
      OffLine: createPresenceIcon(
        <Styled.OfflineIcon
          icon="offline-filled"
          size="tiny"
          className={className}
        />,
        unreadMessages
      ),
    };

    return statuses[state || 'OffLine'];
  };

  return renderPresence();
};
