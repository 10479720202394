import styled from 'styled-components';
import { IconButton } from '../../shared/IconButton';

const StyledIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.sizes.small};
`;

export const Styled = {
  IconButton: StyledIconButton,
};
