import { Button } from 'Components/shared/Button';
import { Heading } from 'Components/shared/Heading';
import { Icon } from 'Components/shared/Icon';
import styled from 'styled-components';

const SaveButton = styled(Button)`
  margin-left: auto !important;
  margin-top: 30px !important;
`;

const CalendarEventTimeLabel = styled(Heading)`
  color: #758695;
  margin-top: 24px;
  margin-bottom: 9px;
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
`;

const RemoveUserButtonContainer = styled.div`
  position: absolute !important;
  right: 6px !important;
  top: 14px !important;
`;

export const Styled = {
  RemoveUserButtonContainer,
  CalendarEventTimeLabel,
  SaveButton,
  SearchIcon,
};
