import { IconButton } from 'Components/shared/IconButton';
import styled, { css } from 'styled-components';

const AttachmentsContainer = styled.div`
  padding: 9px 0 6px 0;
`;

const LeftChevron = styled.img`
  transform: rotate(180deg);
`;

const ImageWrapper = styled.div<{ $isImage: boolean }>`
  max-width: 250px;
  word-break: break-all;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  height: 76px;
  margin-top: 8px;
  position: relative;
  ${({ $isImage }) => ($isImage ? 'padding: 0;' : 'padding-right: 7px;')}
`;

const ImagePreview = styled.img<{ $isImage: boolean }>`
  margin: 0 15px;
  ${({ $isImage }) =>
    $isImage &&
    css`
      border-radius: 12px;
      object-fit: cover;
      height: 100%;
      width: 100%;
      margin: 0;
    `}
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: -6px;
  top: -8px;
`;
const FilePickerInput = styled.input.attrs({
  type: 'file',
  multiple: true,
})`
  display: none;
`;

export const Styled = {
  LeftChevron,
  ImageWrapper,
  ImagePreview,
  IconButton: StyledIconButton,
  AttachmentsContainer,
  FilePickerInput,
};
