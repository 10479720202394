import EntityProfileHeaderObserver from 'Components/EntityProfileHeader';
import EntityProfileInfoObserver from 'Components/EntityProfileInfo';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import withRouter from '../../hocs/WithRouter';
import { pushToGTMDataLayer } from '../../utils/analytics';
import { bugsnagClient } from '../../utils/logUtils';
import { EntityProfileComponentProps } from './interfaces';
/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class EntityProfile
 */
export class EntityProfile extends React.Component<EntityProfileComponentProps> {
  componentDidMount() {
    if (
      isEmpty(this.props.contact) &&
      isEmpty(this.props.person) &&
      isEmpty(this.props.extrContacts)
    ) {
      bugsnagClient.notify(
        'Either the contact or person prop is required',
        (event) => {
          event.context = 'EntityProfile';
          event.addMetadata('custom', {
            function:
              'componentDidMount | Either the contact or person prop is required',
            props: this.props,
          });
        }
      );
      this.props.navigate('/addressBook/contacts');
    }
    pushToGTMDataLayer('profileView', {
      profileType: isEmpty(this.props.contact) ? 'Person' : 'Contact',
    });
  }

  render() {
    const {
      contact,
      currentConversation,
      loggedInUserActiveConferenceConversation,
      loadOrCreateConversationWithPost,
      person,
      makeCall,
      resolveConversationLinkPath,
      messageUser,
      loggedInAccountId,
      loggedInPersonId,
      conversationId,
      postConferenceByConversationId,
      selectPersonValueById,
      phoneCalls,
      uiStore,
      extrContacts,
      setEditContact,
      setIsAddingContact,
      getExtrContactByPhoneNumber,
      loggedInPersonVideoFeature,
      channelInfoDetails,
      allContactByPhone,
    } = this.props;
    return (
      <div id="entity-profile" className="flex-grow-shrink">
        <EntityProfileHeaderObserver
          getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
          person={person}
          contact={contact}
          extrContacts={extrContacts}
          uiStore={uiStore}
          setEditContact={setEditContact}
          setIsAddingContact={setIsAddingContact}
        />
        <EntityProfileInfoObserver
          currentConversation={currentConversation}
          loggedInUserActiveConferenceConversation={
            loggedInUserActiveConferenceConversation
          }
          makeCall={makeCall}
          conversationId={conversationId}
          loggedInAccountId={loggedInAccountId}
          loggedInPersonId={loggedInPersonId}
          loadOrCreateConversationWithPost={loadOrCreateConversationWithPost}
          contact={contact}
          person={person}
          extrContacts={extrContacts}
          messageUser={messageUser}
          postConferenceByConversationId={postConferenceByConversationId}
          resolveConversationLinkPath={resolveConversationLinkPath}
          selectPersonValueById={selectPersonValueById}
          phoneCalls={phoneCalls}
          setEditContact={setEditContact}
          getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
          loggedInPersonVideoFeature={loggedInPersonVideoFeature}
          channelInfoDetails={channelInfoDetails}
          allContactByPhone={allContactByPhone}
        />
      </div>
    );
  }
}

export default withRouter(observer(EntityProfile));
