import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import API from '~/api';
import { usePersonStore } from './index.store';
import { IAvailabilityPhoneNumberResponse, InboundNumber } from './index.types';

export const QUERY_KEY_CHECK_AVAILABILITY_PHONENUMBER =
  'person.fetchAvailabilityPhoneNumber';

/*FIXME: For now we need to export this, so we can call on the mobx store (class instance)
 * We will also be able to rely on "useQueries" to parallelize the requests, instead of the Promise.allSettled
 */
export const fetchAvailabilityPhoneNumberQuery = async (
  inboundNumbers: InboundNumber[]
) => {
  const promises = inboundNumbers.map((inboundNumber) => {
    return API.get<IAvailabilityPhoneNumberResponse>(
      API_ENDPOINTS.CheckAvailabilityPhoneNumber(inboundNumber.number)
    );
  });

  const results = await Promise.allSettled(promises);

  const hasAnyAvailablePhoneNumber = results.some(
    (result) => result.status === 'fulfilled' && result.value.data.isAvailable
  );

  const campaignEnabled10Dlc = results.some(
    (result) =>
      result.status === 'fulfilled' &&
      result.value.data.campaignEnabled !== false
  );

  // FIXME: In the future when we dont need to call this via mobx store, we can use the setAvailabilityPhoneNumberSelector
  // with the usePersonStore hook
  usePersonStore.setState({
    isAvailablePhoneNumber: hasAnyAvailablePhoneNumber,
    campaignEnabled10Dlc,
  });

  return results.map((r) => r.status === 'fulfilled' && r.value.data);
};

export const useFetchAvailabilityPhoneNumber = (inboundNumbers: InboundNumber[]) =>
  useQuery({
    queryKey: [QUERY_KEY_CHECK_AVAILABILITY_PHONENUMBER, ...inboundNumbers],
    queryFn: async () => fetchAvailabilityPhoneNumberQuery(inboundNumbers),
  });

export const usePerson = () => ({
  useFetchAvailabilityPhoneNumber,
});
