import styled from 'styled-components';

const IframeContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

export const Styled = {
  IframeContainer,
};
