import type { ErrorBannerProps } from './interfaces';
import React from 'react';
import { Styled } from './index.styles';


export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  show,
  action,
  content,
}) =>
  show ? (
    <Styled.Banner>
      <Styled.BannerText>{content}</Styled.BannerText>
      {action}
    </Styled.Banner>
  ) : null;
