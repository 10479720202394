import { TextLink } from 'Components/shared/TextLink';
import { IS_ELECTRON } from 'Constants/env';
import React from 'react';
import { ParserRule, ReactOutputRule } from 'simple-markdown';
import { electronOpenExternal } from 'Utils/electronHelpers';

export const buildImageLink = (): ParserRule & ReactOutputRule => ({
  order: 0,
  match: (matchSrc) =>
    RegExp(/^(https?:\/\/.*\.(?:png|jpg|jpeg|gif).*)/).exec(matchSrc),
  parse: (capture) => ({ target: capture[0] }),
  react: (node, _output, state) => (
    <>
      <TextLink
        key={state.key + '_a'}
        testid={`markdownCompiler-linkImage-${state.key}`}
        underline
        {...(IS_ELECTRON
          ? { 'data-ext-url': node.target, onClick: electronOpenExternal }
          : { href: node.target, target: '_blank', rel: 'noreferrer' })}
      >
        {node.target}
      </TextLink>
      <br />
      <img
        key={state.key}
        className="inline-image"
        src={node.target}
        onError={(e) => (e.currentTarget.style.display = 'none')}
      />
    </>
  ),
});
