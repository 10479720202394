/**
 * Adapted from
 * https://raw.githubusercontent.com/lovasoa/react-contenteditable/master/src/react-contenteditable.tsx
 * https://github.com/lovasoa/react-contenteditable/blob/master/LICENSE
 */
import { KEYCODES, SHOW_FILE_UPLOAD } from 'Constants/env';
import { isEqual } from 'lodash';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import {
  collapseRangeToEndOfPreviousSibling,
  collapseRangeToStartOfNextSibling,
  getSelectionRange,
  handleDropAsPlainText,
  handlePasteAsPlainText,
  ISelectionRange,
  normalizeHtml,
  normalizeNonCollapsedRangeAroundMentions,
  placeCursorAtEndOfElement,
  placeCursorAtSpecificPosition,
  trySetRangeEnd,
  trySetRangeStart,
} from 'Utils/inputNodeUtils';
import { IContentEditableProps } from './interfaces';

/**
 * A `contenteditable` div with special handling for Mentions.
 *
 * **Note:** This class is _not_ designed to be an `observer`
 */
export default class ContentEditable extends React.Component<IContentEditableProps> {
  lastHtml: string;
  htmlEl: HTMLElement | null = null;

  selectionRange: ISelectionRange = {
    start: 0,
    end: 0,
    htmlStart: 0,
    htmlEnd: 0,
    range: null,
    selection: null,
    isFocusInsideMention: false,
    isAnchorInsideMention: false,
    isFocusBeforeMention: false,
    isAnchorBeforeMention: false,
    isFocusAfterMention: false,
    isAnchorAfterMention: false,
  };

  constructor(props: IContentEditableProps) {
    super(props);
    this.emitChange = this.emitChange.bind(this);
    this.lastHtml = props.html;
  }

  onSelect = () => {
    if (this.htmlEl) {
      this.selectionRange = getSelectionRange(this.htmlEl);
      const { isFocusInsideMention, isAnchorInsideMention, selection, range } =
        this.selectionRange;

      const focus = selection.focusNode;
      if (range.collapsed && isFocusInsideMention) {
        // Try everything to escape the Mention if the focus point ends up inside of it
        if (!collapseRangeToStartOfNextSibling(focus, range)) {
          const didCollapse = collapseRangeToEndOfPreviousSibling(focus, range); // Only if `nextSibling` is missing or not Element/Text
          if (!didCollapse) {
            console.warn(
              'Failed to move collapsed selection from Mention',
              selection.inspect(),
              range.inspect()
            );
          }
        }
      }
      // Expand/contract a non-collapsed range so its focus and anchor are not inside a Mention
      else if (!range.collapsed) {
        normalizeNonCollapsedRangeAroundMentions(
          selection,
          range,
          isFocusInsideMention,
          isAnchorInsideMention
        );
      }
    }
  };

  onKeyDown = (e: React.KeyboardEvent<Element>) => {
    const { isFocusBeforeMention, isFocusAfterMention, selection, range } =
      this.selectionRange;

    if (!isNullOrUndefined(selection)) {
      const { focusNode } = selection;

      if (range.collapsed && isFocusAfterMention) {
        if (e.keyCode === KEYCODES.BACKSPACE) {
          trySetRangeStart(focusNode, focusNode.previousSibling, range, true);
          selection.setSingleRange(range);
          range.deleteContents();

          // Prevent the default BACKSPACE behaviour to prevent 1 additional character deletion,
          // except if there is only 1 prepended whitespace left.
          if (
            focusNode?.previousSibling?.previousSibling !== null ||
            focusNode?.nextSibling?.nodeType === Node.ELEMENT_NODE
          ) {
            e.preventDefault();
          }
          this.props.updateMessage(this.htmlEl.innerHTML);
        }

        if (e.keyCode === KEYCODES.LEFTARROW) {
          // We are adjacent to a Mention, so use it as the target to walk towards the Node/Text preceding the Mention
          collapseRangeToEndOfPreviousSibling(
            selection.focusNode.previousSibling,
            range
          );
          selection.setSingleRange(range);
        }
      }

      if (range.collapsed && isFocusBeforeMention) {
        if (e.keyCode === KEYCODES.DELETE) {
          trySetRangeEnd(focusNode, focusNode.nextSibling, range, true);
          selection.setSingleRange(range);
          range.deleteContents();
        }

        if (e.keyCode === KEYCODES.RIGHTARROW) {
          // We are adjacent to a Mention, so use it as the target to walk towards the Node/Text following the Mention
          collapseRangeToStartOfNextSibling(
            selection.focusNode.nextSibling,
            range
          );
          selection.setSingleRange(range);
        }
      }
    } else {
      console.warn('ContentEditable onKeydown selection was null/undefined');
    }

    // TODO: Handle non-collapsed range (2018-11-21 RP)

    this.props.onKeyDown?.(e);
  };

  handlePaste = (e: React.ClipboardEvent<HTMLElement>) => {
    if (
      this.props.fileImportEvent &&
      e.clipboardData.files[0] &&
      SHOW_FILE_UPLOAD
    ) {
      e.preventDefault();
      this.props.fileImportEvent(e);
    } else {
      handlePasteAsPlainText(e);
    }
    this.props.onPaste?.(e);
  };

  handleDrop = (e: React.DragEvent<HTMLElement>) => {
    handleDropAsPlainText(e);
    this.props.onDrop?.(e);
  };

  handleFocus = (e: React.ChangeEvent) => {
    if (e.currentTarget.hasAttribute('preventChangeEvent')) {
      e.currentTarget.removeAttribute('preventChangeEvent');
    } else {
      this.emitChange(e);
    }

    this.props.onFocus?.(e);
  };

  render() {
    const { html, id, placeholder, fileImportEvent, ...props } = this.props;

    return (
      <div
        {...props}
        ref={(e: HTMLElement) => (this.htmlEl = e)}
        {...{ id, placeholder }}
        onInput={this.emitChange}
        onFocus={this.handleFocus}
        onBlur={this.props.onBlur || this.emitChange}
        onSelect={this.onSelect}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.props.onKeyUp}
        onPaste={this.handlePaste}
        onDrop={this.handleDrop}
        contentEditable={!this.props.disabled}
        dangerouslySetInnerHTML={{ __html: html }}
      >
        {this.props.children}
      </div>
    );
  }

  UNSAFE_shouldComponentUpdate(nextProps: IContentEditableProps): boolean {
    const { props, htmlEl } = this;

    // We need not rerender if the change of props simply reflects the user's edits.
    // Rerendering in this case would make the cursor/caret jump

    // Rerender if there is no element yet... (somehow?)
    if (!htmlEl) {
      return true;
    }

    // ...or if html really changed... (programmatically, not by user edit)
    if (normalizeHtml(nextProps.html) !== normalizeHtml(htmlEl.innerHTML)) {
      return true;
    }

    // Handle additional properties
    return (
      props.disabled !== nextProps.disabled ||
      props.className !== nextProps.className ||
      !isEqual(props.style, nextProps.style)
    );
  }

  componentDidMount() {
    // https://broadvoice-jira.atlassian.net/browse/CUU2-632
    // we need to trigger change in case someone is stuck with broken message
    this.lastHtml = '';
    const event = new Event('input', { bubbles: true });
    this.htmlEl.dispatchEvent(event);
    placeCursorAtEndOfElement(this.htmlEl);
  }

  componentDidUpdate(prevProps) {
    if (!this.htmlEl) return;

    if (this.props.html !== this.htmlEl.innerHTML) {
      this.htmlEl.innerHTML = this.props.html;
      this.lastHtml = this.props.html;
    }

    if (!this.props.html) return;

    if (!prevProps.html) {
      placeCursorAtEndOfElement(this.htmlEl);
    } else if (this.htmlEl.innerHTML !== prevProps.html) {
      placeCursorAtSpecificPosition(this.htmlEl, this.selectionRange.start);
    }
  }

  emitChange(originalEvt) {
    if (!this.htmlEl) {
      return;
    }
    const html = this.htmlEl.innerHTML;
    if (this.props.onChange && html !== this.lastHtml) {
      // Clone event with Object.assign to avoid
      // "Cannot assign to read only property 'target' of object"
      const evt = Object.assign({}, originalEvt, {
        target: {
          value: html,
        },
      });

      this.selectionRange = getSelectionRange(this.htmlEl);

      this.props.onChange(evt);
      this.lastHtml = html;
    }
  }
}
