import { MessageInputArea } from 'Components/Chat/ChatFooter/MessageInputArea';
import { MobXProviderContext, observer } from 'mobx-react';

import { useConversationStore } from 'Modules/conversation/index.store';
import { usePersonStore } from 'Modules/person/index.store';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';

export const ChatFooter: React.FC = observer(() => {
  const { conversation, participant } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const { optedOutPhoneNumbers } = useConversationStore();
  const { campaignEnabled10Dlc } = usePersonStore();

  const optInParticipants =
    conversation?.CurrentConversation?.participants?.filter((participant) => {
      return (
        !optedOutPhoneNumbers.includes(participant.phone) && !!participant.phone
      );
    });

  const canCreateGroupWithOptinMembers =
    optedOutPhoneNumbers?.length >= 1 && optInParticipants?.length >= 2;

  const createGroupWithOptinMembers = () => {
    conversation.setChannelInfoDetails(
      conversation.channelInfoDetails.channelConversationId,
      'new',
      'phone'
    );
    void conversation.handleAdHocGroups(
      optInParticipants.map((participant) => participant.phone?.toString())
    );
  };

  const isGroupChat =
    conversation.CurrentConversation?.grouping === 'Channel' ||
    conversation.CurrentConversation?.grouping === 'Group';

  // If the current conversation is a group chat, but the user is not part of it anymore (this check has priority over the following ones)
  if (isGroupChat && !conversation.CurrentConversation?.isActiveParticipant) {
    return (
      <Styled.MessageInputAreaDisabled>
        <Styled.BodyCopy>
          You can't send messages to this group because you are not a member
          anymore.
        </Styled.BodyCopy>
      </Styled.MessageInputAreaDisabled>
    );
  }

  // If there's at least one member of the current conversation (current user ignored) that has chosen to opt-out...
  if (optedOutPhoneNumbers.length > 0) {
    // ...and it's a group chat
    if (isGroupChat) {
      return (
        <Styled.MessageInputAreaDisabled>
          <Styled.BodyCopy>
            You can’t text this group because at least one member has opted-out
            from receiving messages.
            {canCreateGroupWithOptinMembers && (
              <Styled.CenteredTextLink
                testid="createGroupOptinMembers-textLink"
                onClick={createGroupWithOptinMembers}
              >
                Create a group with the remaining members
              </Styled.CenteredTextLink>
            )}
          </Styled.BodyCopy>
        </Styled.MessageInputAreaDisabled>
      );
      // ...and it's a one on one conversation
    } else {
      return (
        <Styled.MessageInputAreaDisabled>
          <Styled.BodyCopy>
            You can't text this number because they opted-out from receiving
            messages.
          </Styled.BodyCopy>
        </Styled.MessageInputAreaDisabled>
      );
    }
  }

  const isSMSConversation =
    conversation.CurrentConversation?.id &&
    !!participant
      .selectOtherParticipants(conversation.CurrentConversation?.id)
      ?.some(({ phone }) => phone);

  if (campaignEnabled10Dlc === false && isSMSConversation) {
    return (
      <Styled.MessageInputAreaDisabled>
        <Styled.BodyCopy>
          Your number is not enabled for SMS messaging. Contact your account
          admin to register it.
        </Styled.BodyCopy>
      </Styled.MessageInputAreaDisabled>
    );
  }

  /* if some reason there's not current conversation,
  or we in the way to create an ad-hoc group, don't show a footer */
  if (
    !(
      conversation?.CurrentConversation ||
      window.location.pathname.includes('adHocGroup')
    )
  ) {
    return null;
  } else {
    // If none of the previous conditions are met, just show the input area
    return <MessageInputArea />;
  }
});
