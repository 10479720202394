import { Button, Table } from 'semantic-ui-react';
import styled from 'styled-components';

const RecordingsTableStyled = styled.div`
  width: 100%;
  margin-top: 47px;

  tr {
    border: none !important;
  }
  b {
    margin-right: 10px;
  }
  td {
    width: 20%;
    &:last-child {
      width: 40%;
    }
  }
`;

const PendingRecordStyled = styled(Table.Row)`
  margin-bottom: 7px;
  height: 49px !important;
  td:first-child {
    width: 54%;
    position: absolute;
    display: flex;
    align-items: center;
  }
`;

const ErrorRecordStyled = styled(Table.Row)`
  color: ${(props) => props.theme.colors.danger};
  margin-bottom: 7px;
  height: 41px;
  td {
    width: 100% !important;
    position: absolute;
  }
  b {
    margin-right: 5px !important;
  }
`;

const DeletedRecordStyled = styled(Table.Row)`
  height: 49px !important;
  td:first-child {
    width: 65% !important;
    b {
      margin: 0px !important;
    }
  }
  td:last-child {
    b {
      margin: 0px !important;
    }
  }
`;

const ActionPopupStyled = styled.div`
  position: relative;
  top: -1px;
  display: inline-flex;
  img,
  li {
    cursor: pointer;
  }
  ul {
    box-shadow: 0 4px 4px ${({ theme }) => theme.colors.border};
    position: absolute;
    top: 15px;
    right: -1px;
    background: ${({ theme }) => theme.colors.base};
    list-style: none;
    width: 100%;
    padding: 6px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGray};
    border-radius: 4px;
    .disabled-delete {
      color: ${({ theme }) => theme.colors.gray} !important;
    }
  }
  img {
    padding: 0 8px;
  }
`;

const DownloadStyled = styled.img`
  margin-right: 28px;
  display: inline-block;
  cursor: pointer;
`;

const ShareButtonStyled = styled(Button)`
  &.ui.button,
  &.ui.button:hover {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    letter-spacing: 0.075em;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 194px;
    justify-content: space-around;
    color: ${({ theme }) => theme.colors.base};
    img {
      width: 18px;
    }
  }
`;

const PageTitleStyled = styled.div`
  margin-right: auto;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
`;

const ShareCellStyled = styled(Table.Cell)`
  border: none !important;
`;

export const Styled = {
  ActionPopupStyled,
  DeletedRecordStyled,
  DownloadStyled,
  ErrorRecordStyled,
  PageTitleStyled,
  PendingRecordStyled,
  RecordingsTableStyled,
  ShareButtonStyled,
  ShareCellStyled,
};
