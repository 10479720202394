import { IconButton } from 'Components/shared/IconButton';
import { ReplyBox } from 'Components/shared/MessageBubble/ReplyBox';
import styled from 'styled-components';

const MessageInputArea = styled.div`
  position: relative;
`;

const StyledReplyBox = styled(ReplyBox)`
  margin: 15px 15px 0;
`;

const InputWrapper = styled.div`
  grid-row: 2; 
  width: 100%;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.base};
  margin: ${({ theme }) => theme.sizes.xxsmall} auto 0;
`;

const SubmitIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.sizes.xxxsmall};
  margin-top: ${({ theme }) => theme.sizes.xxsmall};
  grid-row: 2;
  grid-column: 2;
`;

const MessageBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto; 
  grid-template-rows: auto; 
`;

const MessageInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #277ee3;
  font-size: 14px;
  cursor: default;
  min-height: 3em;
  padding: 0.85em 1em 0.6em 1em;
  width: 100%;
  text-align: center;
  color: white !important;
  z-index: 2;
  border-radius: 4px;
`;

const FileInput = styled.input`
  position: relative;
  opacity: 0;
`;

const AttachmentIconContainer = styled.label`
  position: absolute;
`;

export const Styled = {
  AttachmentIconContainer,
  FileInput,
  MessageBar,
  MessageInputArea,
  ReplyBox: StyledReplyBox,
  InputWrapper,
  SubmitIconButton,
  MessageInfo,
};
