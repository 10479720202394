import type { IconButtonProps } from './types';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { Styled } from './index.styles';

export const IconButton: React.FC<IconButtonProps> = ({
  active = false,
  className,
  compact = false,
  content,
  counter = 0,
  disabled,
  icon,
  loading = false,
  onClick,
  popupPosition: position,
  size,
  testid,
  variant = 'text',
}) => (
  <Styled.ButtonSafeArea
    {...{ className }}
    $disabled={disabled?.condition}
    {...(disabled?.condition && { title: disabled?.title })}
  >
    {!!counter && <Styled.Badge>{counter > 99 ? '99+' : counter}</Styled.Badge>}
    <Popup
      {...(variant === 'text' && { inverted: true })}
      {...{ content, position }}
      disabled={!content || disabled?.condition}
      trigger={
        <div>
          <Styled.Button
            {...(!loading && { onClick })}
            {...{ disabled: disabled?.condition, size }}
            $compact={compact}
            $variant={variant}
            $active={active}
            data-testid={testid}
            compact
            basic
          >
            {loading ? (
              <Styled.LoadingIcon {...{ icon: 'spinner', size }} />
            ) : (
              <Styled.Icon {...{ icon, size }} />
            )}
          </Styled.Button>
        </div>
      }
    />
  </Styled.ButtonSafeArea>
);
