import { observer } from 'mobx-react';
import React from 'react';
import { Loader, Message } from 'semantic-ui-react';

import holdCallIcon from '~/assets/images/btn-call-hold.svg';
import { Styled } from './index.styles'; // Import the Styled object
import { CallHoldingListItemProps } from './interfaces';

const CallHoldingListItem = ({
  phoneCall,
  phoneStore,
}: CallHoldingListItemProps) => {
  const activeCall = () => {
    phoneStore.setOtherSessionsOnHold(phoneCall.session);
    phoneStore.makeCallActive(phoneCall.session);
    void phoneCall.unHold();
  };

  return (
    <Styled.ListItem onClick={activeCall} key={'1'}>
      <Styled.StatusTimerWrapper>
        <Styled.Status>HOLDING...</Styled.Status>
        <Styled.CallTimer>{phoneCall.timerDisplay}</Styled.CallTimer>
      </Styled.StatusTimerWrapper>
      <Styled.TextButtonWrapper>
        <Styled.TextWrapper>
          {phoneCall.person !== null ? (
            phoneCall.person.case({
              pending: () => <Loader indeterminate active />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={`Error Loading Person: ${reason.response.data.message}`}
                />
              ),
              fulfilled: (response) => (
                <>
                  <Styled.Name>
                    {response.data.firstName} {response.data.lastName}
                  </Styled.Name>
                  <Styled.Number>
                    {response.data.lines &&
                    typeof response.data.formatNumber === 'function'
                      ? response.data?.formatNumber()
                      : ''}
                  </Styled.Number>
                </>
              ),
            })
          ) : (
            <>
              <Styled.Name>
                {phoneCall.isMerge
                  ? 'Multiple Callers'
                  : phoneCall.callerIdName}
              </Styled.Name>
              <Styled.Number>
                {phoneCall.FormattedNumber
                  ? phoneCall.FormattedNumber
                  : 'Unknown Number'}
              </Styled.Number>
            </>
          )}
        </Styled.TextWrapper>
        <Styled.BtnWrapper>
          <Styled.HoldToggleBtn
            className={`${
              phoneCall.isCallOnHold ? 'active' : ''
            } hold-toggle-btn`}
          >
            <img src={holdCallIcon as string} />
          </Styled.HoldToggleBtn>
        </Styled.BtnWrapper>
      </Styled.TextButtonWrapper>
    </Styled.ListItem>
  );
};

export default observer(CallHoldingListItem);
