import type { TopBarDialpadProps } from './types';
import backspace from 'Assets/images/backspace-dialpad.svg';
import cx from 'classnames';
import Dialpad from 'Components/Dialpad';
import { IconButton } from 'Components/shared/IconButton';
import { MobXProviderContext, observer } from 'mobx-react';
import {
  DISCARDED_DIALPAD_INPUT_CHARACTERS,
  DTMF_MAX_CHARACTER_VALIDATION,
  INVALID_DIALPAD_INPUT_CHARACTERS,
} from 'Modules/softphone/components/constants';
import React, { createRef, useEffect, useState } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';

export const TopBarDialpad: React.FC<TopBarDialpadProps> = observer(
  ({ isFixed, keyPadPush }) => {
    const {
      ui: {
        openTopbarDialpad,
        setOpenTopbarDialpad,
        removeFromOpenedRightSidebarsOrder,
        openedRightSidebarsOrder,
      },
      phoneCall: { ActivePhoneCall, callWithPerson },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const initialInputValue = () =>
      ActivePhoneCall?.showDialPad ? ActivePhoneCall.keysPressed : '';

    const [inputNumber, setInputNumber] = useState<string>(initialInputValue());
    const inputRef = createRef<HTMLInputElement>();
    const [lastFocusedPosition, setLastFocusedPosition] = useState(
      initialInputValue().length
    );

    useEffect(() => {
      inputRef?.current?.focus();
    }, []);

    useEffect(() => {
      handleCursorPos(lastFocusedPosition);
    }, [inputNumber]);

    const handleCursorPos = (cursorPos?: number) => {
      if (inputRef.current) {
        if (cursorPos) {
          inputRef.current.setSelectionRange(cursorPos, cursorPos);
          inputRef.current.focus();
          setLastFocusedPosition(cursorPos);
        } else {
          inputRef.current.setSelectionRange(
            lastFocusedPosition,
            lastFocusedPosition
          );
          inputRef.current.focus();
        }
      }
    };

    const makeButtonFocused = (number: string) => {
      if (/\d|#|\*|\+/.test(number)) {
        number = number === '+' ? '0' : number;
        const element = document.getElementById(`keypad-${number}`);
        element.classList.add('focused');
        element.focus();
        keyPadPush?.(number);
        const focusedInterval = setTimeout(() => {
          element.classList.remove('focused');
          clearInterval(focusedInterval);
        }, 300);
      }
    };

    const setValuefromDialpad = (value: string) => {
      makeButtonFocused(value);
      setInputNumber(
        inputNumber.slice(0, lastFocusedPosition) +
          value +
          inputNumber.slice(lastFocusedPosition)
      );
      if (document.activeElement?.id?.includes(`keypad-${value}`)) {
        setLastFocusedPosition(lastFocusedPosition + 1);
      }
    };

    const makeCall = () => {
      void callWithPerson(
        null,
        inputNumber
          ?.replace(/\+/g, '00')
          ?.replace(DISCARDED_DIALPAD_INPUT_CHARACTERS, '')
      );
      setOpenTopbarDialpad(false);
    };

    const deleteWithBackspace = () => {
      if (inputRef.current?.selectionStart !== inputRef.current?.selectionEnd) {
        setInputNumber(
          inputNumber.slice(0, inputRef.current?.selectionStart) +
            inputNumber.slice(
              inputRef.current?.selectionEnd,
              inputNumber.length
            )
        );
        return;
      }

      if (lastFocusedPosition === 0) return;

      setInputNumber(
        inputNumber.slice(0, lastFocusedPosition - 1) +
          inputNumber.slice(lastFocusedPosition)
      );
      setLastFocusedPosition(lastFocusedPosition ? lastFocusedPosition - 1 : 0);
    };

    const onFocusLost = (e) => {
      setLastFocusedPosition(inputRef?.current?.selectionStart);
    };

    const shouldChangeInput = (e, newInputValue: string) =>
      !(keyPadPush && e?.nativeEvent?.inputType === 'deleteContentBackward') &&
      newInputValue !== inputNumber;

    const onInputChange = (e) => {
      const position = inputRef?.current?.selectionStart;
      const number = e.target.value[position - 1];
      const newInputValue = e.target.value
        ?.trim()
        ?.toUpperCase()
        ?.replace(INVALID_DIALPAD_INPUT_CHARACTERS, '');
      if (
        e?.nativeEvent?.inputType !== 'deleteContentBackward' &&
        newInputValue.length - inputNumber.length === 1
      )
        makeButtonFocused(number);
      if (shouldChangeInput(e, newInputValue)) {
        setInputNumber(newInputValue);
        setLastFocusedPosition(position);
      }
    };

    const closeTopbarDialpad = () => {
      setOpenTopbarDialpad(false);
      removeFromOpenedRightSidebarsOrder('dial-pad');
    };

    const onKeyUp = (e) => {
      if (e?.key === 'Enter' && !ActivePhoneCall?.showDialPad) makeCall();
    };

    const largeColumns = 4;
    const smallColumns = !openTopbarDialpad ? 4 : 6;
    const showDialpad = ActivePhoneCall?.showDialPad;

    return (
      <Styled.TopBarDialpadStyled
        $zIndex={openedRightSidebarsOrder.get('dial-pad')}
        $isRightSidebarOpened={openedRightSidebarsOrder.size > 1}
        $isFixed={isFixed}
        icon="labeled"
        className={cx('topbar-dialpad-wrapper', {
          'softphone--fixed softphone--fixed': isFixed,
        })}
        widescreen={largeColumns}
        computer={largeColumns}
        desktop={largeColumns}
        tablet={smallColumns}
        mobile={smallColumns}
      >
        <div id="topbar-dialpad">
          {!showDialpad && isFixed && (
            <Grid.Row className="close-topbar-dialpad">
              <span onClick={closeTopbarDialpad}>Close</span>
            </Grid.Row>
          )}
          <Grid.Row>
            <Input
              input={{ ref: inputRef }}
              className={inputNumber.length > 0 ? 'large-text' : ''}
              id="dialpad-input"
              placeholder={!showDialpad ? 'Type a number to start' : ''}
              value={inputNumber}
              onChange={onInputChange}
              onBlur={onFocusLost}
              onKeyUp={onKeyUp}
            />
            {inputNumber.length > 0 && !showDialpad && (
              <img
                className="small-icon"
                id="backspace-dialpad"
                src={backspace}
                onClick={deleteWithBackspace}
              />
            )}
          </Grid.Row>
          <Grid.Row>
            <Dialpad numberClick={setValuefromDialpad}></Dialpad>
          </Grid.Row>
          <Grid.Row>
            {!showDialpad && (
              <Grid.Column className="call-dialpad">
                <IconButton
                  icon="call"
                  onClick={makeCall}
                  size="medium"
                  testid="topBarDialPad-buttonConfirmCall"
                  variant="positive"
                  disabled={{
                    title: !inputNumber.replace(
                      DISCARDED_DIALPAD_INPUT_CHARACTERS,
                      ''
                    ).length
                      ? 'Please type a phone number'
                      : 'Please limit the phone number to 21 characters or less',
                    condition: !inputNumber.match(
                      DTMF_MAX_CHARACTER_VALIDATION
                    ),
                  }}
                />
                <span>Call</span>
              </Grid.Column>
            )}
          </Grid.Row>
        </div>
      </Styled.TopBarDialpadStyled>
    );
  }
);
