import { ChatLayoutHeaderText } from 'Components/ChatLayoutHeader/ChatLayoutHeaderText';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { ChatLayoutHeaderRendererProps } from './types';

export const ChatLayoutHeaderRenderer: React.FC<ChatLayoutHeaderRendererProps> =
  observer(({ conversationId }) => {
    const { contact, conversation, participant, person, ui } =
      React.useContext<RootStoreProps>(MobXProviderContext);
    const convPbo = conversation.selectConversationById(conversationId);

    if (!convPbo) return <Loader indeterminate active />;

    return convPbo.case({
      pending: () => <Loader indeterminate active />,
      rejected: (reason) => (
        <Message
          visible
          error
          content={
            'Error loading Conversation :' + reason.response.data.message
          }
        />
      ),
      fulfilled: (resp) => {
        const convo = resp.data;
        if (convo?.grouping === 'OneOnOne') {
          const otherParticipantsPbo =
            participant.participantByConvMap.get(conversationId);
          if (!otherParticipantsPbo) {
            return (
              <Message
                visible
                error
                content="Error loading other Participant"
              />
            );
          }

          const otherParticipant = convo?.participants.find(
            ({ personId }) => personId !== person.loggedInPersonId
          );
          const mySelf = convo?.participants.find(
            ({ personId }) => personId === person.loggedInPersonId
          );

          if (otherParticipant) {
            const otherPersonId = otherParticipant.personId;
            if (otherPersonId > 0) {
              const otherPerson = person.selectPersonById(otherPersonId);
              return !otherPerson ? (
                <Loader indeterminate active size="tiny" />
              ) : (
                otherPerson.case({
                  pending: () => <Loader indeterminate active size="tiny" />,
                  rejected: (reason) => (
                    <Message
                      visible
                      error
                      content={`${
                        otherPerson.state === 'rejected'
                          ? 'User No Longer Exists'
                          : reason.response.data.message
                      }`}
                    />
                  ),
                  fulfilled: (personResp) => {
                    conversation.setOwnerOfTheChat(
                      personResp.data?.DisplayName
                    );
                    const emojiStatus = ui.selectPersonMessageStatus(
                      personResp.data.id
                    );
                    const presence = ui.selectPersonPresenceStatus(
                      personResp.data?.id
                    );
                    // One on one with other user
                    return (
                      <>
                        <UserAvatar
                          hideInitials
                          conversationId={convo.id}
                          name={personResp.data.DisplayName}
                          selectUnreadCounts={ui.selectConversationUnreadCounts}
                          presence={presence}
                          key={`chat-header-avatar-${personResp?.data?.id}-${presence?.state}`}
                        />
                        <ChatLayoutHeaderText
                          title={personResp.data.DisplayName}
                          emoji={{
                            title: emojiStatus?.title || '',
                            message: emojiStatus?.message || '',
                          }}
                        />
                      </>
                    );
                  },
                })
              );
            }
            if (otherParticipant?.phone) {
              const phoneNumNoPlus = otherParticipant.phone.startsWith('+')
                ? otherParticipant.phone.substring(1)
                : otherParticipant.phone;
              const extrContact = person.getExtrContactByPhoneNumber(
                otherParticipant.phone
              );

              return contact.loadContactByPhoneNumber(phoneNumNoPlus).case({
                pending: () => <Loader indeterminate active size="tiny" />,
                rejected: (reason) => (
                  <Message
                    visible
                    error
                    content={
                      'Error loading Contact: ' + reason.response.data.message
                    }
                  />
                ),
                fulfilled: (contactByPhone) => {
                  if (!contactByPhone) {
                    return (
                      <ChatLayoutHeaderText
                        title={
                          extrContact?.DisplayName() || otherParticipant.phone
                        }
                        subtitle="External number (SMS)"
                      />
                    );
                  }

                  return (
                    // SMS contact
                    <ChatLayoutHeaderText
                      title={
                        extrContact?.DisplayName() ||
                        contactByPhone.data.DisplayName
                      }
                      subtitle={`${
                        extrContact
                          ? formatNumberWithNationalCode(
                              otherParticipant.phone
                            ) + ' •'
                          : ''
                      } External number (SMS)`}
                      {...(extrContact && {
                        thirdline: `${extrContact?.organization || ''}${
                          extrContact?.organizationRole
                            ? ` ( ${extrContact.organizationRole} )`
                            : ''
                        }`,
                      })}
                    />
                  );
                },
              });
            }

            return (
              <Message
                visible
                error
                content="Error: personId and phone were both missing"
              />
            );
          }

          if (mySelf) {
            // when it is my own chat conversation
            return person.selectPersonById(mySelf.personId)?.case({
              pending: () => <Loader indeterminate active size="tiny" />,
              rejected: () => (
                <Message visible error content="Error Loading YourSelf:" />
              ),
              fulfilled: (personResp) => (
                <ChatLayoutHeaderText title={personResp.data.DisplayName} />
              ),
            });
          }

          return (
            <Message
              visible
              error
              content="Error: Unable to find other Participant"
            />
          );
        }

        if (convo?.grouping === 'Channel' || convo?.grouping === 'Group') {
          const changeConvTopic = () => {
            const copy = { ...convo };
            copy.topic =
              '#' +
              conversation.addProperName(
                convo.participants.filter(
                  (participant) =>
                    participant.personId !== person.loggedInPersonId
                ),
                'channelHeader'
              );
            return copy;
          };

          const conversationCopy =
            person && convo.grouping === 'Group' ? changeConvTopic() : convo;

          return (
            <ChatLayoutHeaderText
              title={conversationCopy.topic}
              subtitle={conversationCopy.description}
            />
          );
        }
      },
    });
  });
