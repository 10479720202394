import type { MoreButtonsMenuItemProps } from './types';
import { IconButton } from 'Components/shared/IconButton';
import React from 'react';
import { Styled } from './index.styles';

export const MoreButtonsMenuItem: React.FC<MoreButtonsMenuItemProps> = ({
  wrapperClassname,
  clickHandler,
  icon,
  disabled,
  textContent,
  textClassname,
  setShowMoreOptionsModal,
  testid,
}) => {
  React.useEffect(() => {
    return () => setShowMoreOptionsModal(false);
  }, []);

  return (
    <Styled.Wrapper>
      <div className={wrapperClassname}>
        {icon && (
          <IconButton
            onClick={clickHandler}
            size="small"
            compact
            {...{ icon, disabled, testid }}
          />
        )}
      </div>
      <span
        style={{ textAlign: 'left' }}
        className={textClassname}
        onClick={clickHandler}
      >
        {textContent}
      </span>
    </Styled.Wrapper>
  );
};
