import TransferHoldingListItem from 'Components/TransferHoldingListItem';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TransferHoldingListProps } from './interfaces';

/*
    Renders a list of calls that are on hold when the directory is open
*/
export class TransferHoldingList extends React.Component<
  TransferHoldingListProps,
  {}
> {
  render() {
    const { callHoldlist } = this.props;
    return (
      <div id="call-holding-list-wrap">
        {
          <ul id="call-holding-list">
            {callHoldlist.map((mp, index) => {
              return (
                <TransferHoldingListItem key={`item-${index}`} phoneCall={mp} />
              );
            })}
          </ul>
        }
      </div>
    );
  }
}

export default observer(TransferHoldingList);
