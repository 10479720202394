import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const InfoSidebarStyled = styled(Grid.Column)<{ $zIndex: number }>`
  position: absolute !important;
  right: 0px;
  top: 70px;
  max-width: 260px;
  background-color: white;
  padding: 14px 20px 14px 14px;
  ${({ $zIndex }) => {
    return (
      $zIndex &&
      `
    z-index: ${$zIndex} !important;
    `
    );
  }};
`;

export const Styled = {
  InfoSidebarStyled,
};
