import phoneIcon from 'Assets/images/menu-call.svg';
import chatIcon from 'Assets/images/sms-icon.svg';
import { CALL_STATE } from 'Constants/enums';
import { SHOW_PERSONAL_VIDEO_LINK } from 'Constants/env';
import { STORE_CONFIG } from 'Constants/stores';
import { isEmpty, startCase } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';

import { Grid, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { formatNumber, formatNumberWithNationalCode } from 'Utils/phoneUtil';
import {
  selectProperVideoIcon,
  selectProperVideoTooltipMessageForOneOnOnes,
} from 'Utils/videoConferenceUtils';

import withRouter from '../../hocs/WithRouter';
import { EntityInfoComponentProps } from './interfaces';
import PersonalVideoMeetingInfo from './PersonalVideoMeetingInfo ';

const EntityProfileInfo: React.FC<EntityInfoComponentProps> = ({
  contact,
  person,
  loggedInPersonVideoFeature,
  loggedInAccountId,
  loggedInPersonId,
  loggedInUserActiveConferenceConversation,
  channelInfoDetails,
  selectPersonValueById,
  phoneCalls,
  openDetailsFromWhere,
  getExtrContactByPhoneNumber,
  makeCall,
  postConferenceByConversationId,
  loadOrCreateConversationWithPost,
  messageUser,
  setEditContact,
  navigate,
  allContactByPhone,
  ...props
}) => {
  const [extrContacts, setExtrContacts] = React.useState<Contact[]>(null);

  React.useEffect(() => {
    if (props.extrContacts) {
      setExtrContacts(props.extrContacts);
      return;
    }

    const extrContactsMerged = extrContacts
      ? extrContacts
      : contact
      ? [getExtrContactByPhoneNumber(contact.phoneNumber)].filter(
          (contact) => contact
        )
      : [];
    if (
      (!extrContacts || extrContacts?.length !== extrContactsMerged?.length) &&
      extrContactsMerged?.length > 0
    ) {
      setExtrContacts(extrContactsMerged);
    }
  }, [contact, extrContacts, getExtrContactByPhoneNumber, props.extrContacts]);

  const callDefaultUserLine = (e: React.MouseEvent) => {
    e.preventDefault();
    if (person) {
      makeCall(Number.parseInt(person.id), null);
    } else if (extrContacts) {
      makeCall(null, extrContacts[0]?.phoneNumbers[0].number);
    } else {
      const primaryLine = contact.phoneNumber;
      if (!isEmpty(primaryLine)) {
        makeCall(null, primaryLine);
      } else {
        throw new Error('Contact Phone Number is Empty');
      }
    }
  };

  const setDataLayer = (conversationIdFromUrl: string) => {
    if (window['dataLayer']) {
      (window['dataLayer'] as any[]).push({
        event: 'placeVideoConference',
        conversationId: conversationIdFromUrl,
      });
    }
  };

  const placeVideoCallConference = async (personId: string) => {
    const conversationIdFromUrl = selectPersonValueById(
      Number.parseInt(personId)
    ).data.personalConferenceUrl;
    const isDefault = channelInfoDetails.channelConversationId === '0';
    if (isDefault && conversationIdFromUrl) {
      const conversationId = isDefault
        ? conversationIdFromUrl.split('-').pop()
        : channelInfoDetails.channelConversationId;
      void postConferenceByConversationId(conversationId);
      setDataLayer(conversationId);
    } else if (personId) {
      await loadOrCreateConversationWithPost(personId).then((resp) => {
        const conversationId = resp.data.id.toString();
        void postConferenceByConversationId(conversationId);
        setDataLayer(conversationId);
      });
    }
  };

  const onMessageUserClick = () => {
    if (!isEmpty(person)) {
      messageUser(person.id, null, person.contactType);
    } else if (extrContacts) {
      messageUser(null, extrContacts[0]?.phoneNumbers[0].number, null);
    } else {
      messageUser(null, contact.FormattedPhone, contact.contactType);
    }
  };

  const getContact = (id: number, phoneNums: any[]) => {
    const phoneNum = phoneNums && phoneNums[0]?.number;
    const formatedPhoneNum = formatNumberWithNationalCode(phoneNum) as string;
    const contactFromPhoneList =
      phoneNum &&
      allContactByPhone.has(formatedPhoneNum) &&
      allContactByPhone.get(formatedPhoneNum);
    const contactFromAllContact =
      !contactFromPhoneList &&
      extrContacts?.find((contact) => contact.id === id);
    return contactFromPhoneList || contactFromAllContact;
  };

  const editExtContacts =
    (id: number, phoneNumbers: string[]) => (e: React.MouseEvent) => {
      e.stopPropagation();
      const contact = getContact(id, phoneNumbers);
      setEditContact(contact);
      navigate('/addressBook/contact', { state: { from: '/chat' } });
    };

  const conferenceIsActive = React.useMemo(
    () =>
      !isNullOrUndefined(loggedInUserActiveConferenceConversation) &&
      !isNullOrUndefined(
        loggedInUserActiveConferenceConversation.activeConference
      ) &&
      loggedInUserActiveConferenceConversation.activeConference.sessionId !==
        '',
    [loggedInUserActiveConferenceConversation]
  );

  const userIsInActiveVideoConference = React.useMemo(
    () => !isNullOrUndefined(loggedInUserActiveConferenceConversation),
    [loggedInUserActiveConferenceConversation]
  );

  const commProfile = React.useMemo(
    () =>
      !isNullOrUndefined(person) &&
      person.id?.toString() !== loggedInPersonId?.toString(),
    [person, loggedInPersonId]
  );

  const mobileProfile = React.useMemo(
    () => isNullOrUndefined(person) && !isNullOrUndefined(contact),
    [person, contact]
  );

  let callState = CALL_STATE.Available;
  const from = 'group-info';
  phoneCalls.filter((p) => {
    if (!isEmpty(person)) {
      const elementPR = p.callUri?.split('@')[0]?.substring(2);
      if (elementPR === person.id.toString()) {
        callState = p.isCallConnecting
          ? CALL_STATE.Connecting
          : CALL_STATE.Connected;
      }
    } else if (
      formatNumber(contact?.phoneNumber) ===
      formatNumber(p.callUri?.split('@')[0])
    ) {
      callState = p.isCallConnecting
        ? CALL_STATE.Connecting
        : CALL_STATE.Connected;
    }
  });

  const isVideoCallDisabled = React.useMemo(
    () =>
      !loggedInPersonVideoFeature.enabled ||
      contact?.phoneNumber ||
      extrContacts?.[0]?.phoneNumbers?.[0]?.number,
    [contact?.phoneNumber, extrContacts, loggedInPersonVideoFeature.enabled]
  ) as boolean;

  const email =
    person?.email ||
    (extrContacts &&
      extrContacts[0]?.emails &&
      extrContacts[0]?.emails[0]?.email);

  return (
    <Grid>
      {!isNullOrUndefined(extrContacts) && !isEmpty(extrContacts) && (
        <Grid.Row>
          <Grid.Column data-private className="extr-contact-info">
            {extrContacts[0]?.organizationRole && (
              <div className="org-role">{extrContacts[0].organizationRole}</div>
            )}
            {extrContacts[0]?.organization && (
              <div className="org">{extrContacts[0].organization}</div>
            )}
            <div className="all-phones">
              {startCase(extrContacts[0]?.phoneNumbers[0].type)}
              {': '}
              {formatNumberWithNationalCode(
                extrContacts[0]?.phoneNumbers[0]?.number
              )}
            </div>
            <div
              onClick={editExtContacts(
                extrContacts[0]?.id,
                extrContacts[0]?.phoneNumbers
              )}
              className="edit-extr-button"
            >
              Edit Contact
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
      {person && (
        <Grid.Row data-private textAlign="center">
          <Grid.Column>
            {person?.jobTitle && (
              <div className="org-role">{person?.jobTitle}</div>
            )}
            {person?.department && (
              <div className="org">{person?.department}</div>
            )}
            {!isNullOrUndefined(person) &&
              person.lines?.map((line) => (
                <div key={line.id}>
                  {line.friendlyName}: {line.PhoneDisplay}
                </div>
              ))}
            {
              // [BC-2511]
              SHOW_PERSONAL_VIDEO_LINK &&
                !isNullOrUndefined(person) &&
                person.id === loggedInPersonId &&
                (loggedInAccountId === 9 || loggedInAccountId === 862) && (
                  <PersonalVideoMeetingInfo
                    loggedInPersonVideoFeature={loggedInPersonVideoFeature}
                    loggedInPersonId={loggedInPersonId}
                    conferenceIsActive={conferenceIsActive}
                    userIsInActiveVideoConference={
                      userIsInActiveVideoConference
                    }
                    placeVideoCallConference={placeVideoCallConference}
                    selectPersonValueById={selectPersonValueById}
                  />
                )
            }
          </Grid.Column>
        </Grid.Row>
      )}
      {(commProfile || mobileProfile || extrContacts) && (
        <>
          {email && (
            <Grid.Row textAlign="center">
              <Grid.Column data-private>{email}</Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row
            columns={3}
            centered
            id="contact-options"
            className="margin-top-2rem wrapped-contact-options-icons"
          >
            {openDetailsFromWhere !== 'callLogs' &&
              (person && person.SupportsChat ? (
                <Grid.Column
                  textAlign="center"
                  className="info-sidebar-action-buttons"
                >
                  <Popup
                    inverted
                    position="bottom left"
                    content="Start Message"
                    trigger={
                      <div className="flex-column flex-wrap flex-justify-center">
                        <button
                          className="custom-button"
                          onClick={onMessageUserClick}
                          id="chat-info-person"
                          data-testid="chat-info-person"
                        >
                          <img className="chat-icon" src={chatIcon} />
                        </button>
                        <div className="text">Chat</div>
                      </div>
                    }
                  />
                </Grid.Column>
              ) : (
                /*----- SMS BUTTON -----*/
                <Grid.Column
                  textAlign="center"
                  className="info-sidebar-action-buttons"
                >
                  <Popup
                    inverted
                    position="bottom right"
                    content="SMS User"
                    trigger={
                      <div className="flex-row flex-wrap flex-justify-center">
                        <button
                          className="custom-button"
                          onClick={onMessageUserClick}
                          id="sms-info-person"
                          data-testid="sms-info-person"
                        >
                          <div className="no-icon-sms">SMS</div>
                        </button>
                        <div className="text">SMS</div>
                      </div>
                    }
                  />
                </Grid.Column>
              ))}
            {!isVideoCallDisabled && (
              <Grid.Column
                textAlign="center"
                className="info-sidebar-action-buttons"
              >
                <Popup
                  inverted
                  position="bottom left"
                  trigger={
                    <div className="flex-column flex-wrap flex-justify-center">
                      <button
                        className={`custom-button`}
                        id="video-call-info-person"
                        data-testid="video-call-info-person"
                        onClick={() => placeVideoCallConference(person.id)}
                      >
                        <img
                          className={'video-icon'}
                          src={selectProperVideoIcon(
                            null,
                            null,
                            null,
                            null,
                            from
                          )}
                        />
                      </button>
                      <div className="text">Video</div>
                    </div>
                  }
                >
                  <div>
                    <div>
                      {selectProperVideoTooltipMessageForOneOnOnes(null, null)}
                    </div>
                  </div>
                </Popup>
              </Grid.Column>
            )}
            {/*----- CALL BUTTON -----*/}
            {openDetailsFromWhere !== 'callLogs' && (
              <Grid.Column
                textAlign="center"
                className="info-sidebar-action-buttons"
              >
                <Popup
                  inverted
                  position="bottom left"
                  content={
                    callState !== CALL_STATE.Available
                      ? 'You are currently on a call with this person'
                      : 'Start Call'
                  }
                  trigger={
                    <div className="flex-column flex-wrap flex-justify-center">
                      <button
                        id="phone-call-info-person"
                        data-testid="phone-call-info-person"
                        className="custom-button"
                        onClick={(e) => {
                          if (callState === CALL_STATE.Available) {
                            callDefaultUserLine(e);
                          }
                        }}
                      >
                        <img
                          id="phone-call"
                          className="phone-icon"
                          src={phoneIcon}
                        />
                      </button>
                      <div className="text">Call</div>
                    </div>
                  }
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </>
      )}
      {extrContacts?.length > 1 && (
        <div className="overflow-scroll-y same-contacts">
          {extrContacts.map((contact, index) => {
            if (index >= 1) {
              return (
                <div
                  key={contact.id}
                  className="extr-contact-info margin-top-2rem"
                >
                  <div>Same phone number on</div>
                  <div className="other-extContact">
                    {contact.DisplayName()}
                  </div>
                  <div
                    className="edit-extr-button"
                    onClick={editExtContacts(
                      contact.id,
                      contact.phoneNumbers as string[]
                    )}
                  >
                    Edit Contact
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </Grid>
  );
};

export default inject(STORE_CONFIG)(withRouter(observer(EntityProfileInfo)));
