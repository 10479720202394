import deletedIcon from 'Assets/images/deleted-file.svg';
import docIcon from 'Assets/images/doc.svg';
import xlsIcon from 'Assets/images/excel.svg';
import genericIcon from 'Assets/images/generic.svg';
import jsonIcon from 'Assets/images/json.svg';
import pdfIcon from 'Assets/images/pdf.svg';
import powerPointIcon from 'Assets/images/power-point.svg';
import videoIcon from 'Assets/images/video-file.svg';
import zipIcon from 'Assets/images/zip.svg';

export type FileTypeProps = {
  name: string;
  size: number;
  type: string;
  icon?: string;
};

export const mapIcon = (file: File, fileType?: string): string => {
  switch (file?.type || fileType) {
    case 'application/pdf':
      return pdfIcon;
    case 'application/doc':
    case 'application/ms-doc':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return docIcon;
    case 'application/excel':
    case 'application/x-excel':
    case 'application/x-msexcel':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return xlsIcon;
    case 'application/zip':
      return zipIcon;
    case 'application/json':
      return jsonIcon;
    case 'application/mspowerpoint':
    case 'application/powerpoint':
    case 'application/x-mspowerpoint':
    case 'application/vnd.ms-powerpoint':
      return powerPointIcon;
    case 'image/png':
    case 'image/jpeg':
      return URL.createObjectURL(file);
    case 'video/mp4':
    case 'application/mp4':
    case 'image/mov':
    case 'video/avi':
    case 'audio/x-wav':
    case 'video/quicktime':
    case 'video/webm':
    case 'audio/webm':
    case 'audio/mpeg3':
    case 'audio/x-mpeg-3':
    case 'video/mpeg':
    case 'video/x-mpeg':
      return videoIcon;
    case 'deleted':
      return deletedIcon;
    default:
      return genericIcon;
  }
};

export const fileToBase64 = (file: File) => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};
