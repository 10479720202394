import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { STORE_UI } from '../../constants/stores';
import { UiStore } from '../../stores';

class ContextContentLoadingPanel extends React.Component<{ ui: UiStore }> {
  render() {
    const url = window.location.pathname;
    const modalOpened = this.props.ui.groupModalOpen;
    const showWelcome = [
      '/chat/conversation',
      '/voicemail',
      '/fax',
      'video-app',
      '/chat/details',
      '/chat/adHocGroup',
      'settings',
      'directory',
    ].some((path) => url.includes(path));

    //should be hidden for conversations, voicemails, fax ..
    if (showWelcome || modalOpened) {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <div id="loading-panel">
        Welcome! Getting started is easy.
        <span>
          Simply search contacts or enter a number to chat, make a call, or
          start a group chat.
        </span>
      </div>
    );
  }
}
export default inject(STORE_UI)(observer(ContextContentLoadingPanel));
