import React from 'react';

export class HistoryDetailsForbidden extends React.Component {
  render() {
    return (
      <div className="forbidden">
        <div>
          You are not allowed to see meeting details. Please contact the owner
          of the meeting to provide you the needed information.
        </div>
      </div>
    );
  }
}
