import type { TopBarDropdownContextProps } from 'Components/TopBar/contexts/TopBarDropdownContext';

export type filterSelectedUsersProps = (
  selectedUsers: string[],
  options: TopBarDropdownContextProps['options']
) => TopBarDropdownContextProps['options'];

/**
 * Required on `setOptions`.
 * `semantic-ui-react`'s Dropdown needs to have the selected options in the option array, otherwise it will erase the selected option
 **/
export const filterSelectedUsers: filterSelectedUsersProps = (
  selectedUsers,
  options
) =>
  selectedUsers
    ?.map((id) => options.find((option) => option?.key === id))
    ?.filter((option) => option?.key) || [];
