import type { SmallSidebarMenuProps } from './interfaces';
import type { IconDefinition } from '../shared/Icon/index.generated';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { CALENDAR_IDS, PORTAL_URL, SHOW_CALENDAR } from '../../constants/env';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../constants/responsiveness';
import { MAIN_TABS, ROUTE_PATHS } from '../../constants/routing';
import { STORE_PERSON, STORE_UI } from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import { useWindowHeight } from '../../lib/hooks/useWindowHeight';
import { Icon } from '../shared/Icon';
import {
  HEADER_HEIGHT,
  SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT,
  SMALL_SIDEBAR_MENU_OPTIONS,
  SMALL_SIDEBAR_MENU_TESTID,
} from './constants';
import { Styled } from './index.styles';
import { SideBarMenuButton } from './SideBarMenuButton';
import { SidebarMenuDrawer } from './SidebarMenuDrawer';

const SmallSidebarMenu: React.FC<SmallSidebarMenuProps> = ({
  person,
  ui: { setHideSecondaryMenu, isTouchSupported, hideSecondaryMenu },
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const height = useWindowHeight();

  const hasParentParameter = (parameters, parentParameters) => {
    const data = parentParameters
      .map((v) => parameters.includes(v) && v)
      .filter((v) => !!v && v);
    return data[0];
  };

  useEffect(() => {
    window.addEventListener('resize', handleActionPanelVisibility);
    return () => {
      window.removeEventListener('resize', handleActionPanelVisibility);
    };
  }, []);

  const handleActionPanelVisibility = () => {
    if (window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT) {
      if (isTouchSupported()) {
        setHideSecondaryMenu(false);
      } else setHideSecondaryMenu(true);
    } else {
      setHideSecondaryMenu(false);
    }
  };

  const lastParameter = window.location.pathname.split('/').pop();
  const parentParameter =
    hasParentParameter(window.location.pathname, [
      'addressBook',
      'calendar',
      'calls',
      'settings',
      'video-app',
    ]) || 'chat';

  const activeTab = MAIN_TABS.includes(lastParameter)
    ? lastParameter
    : parentParameter;

  const showPortalMenuItem = person.loggedInPersonRole.toLowerCase() !== 'user';
  const showCalendar =
    SHOW_CALENDAR || CALENDAR_IDS?.includes(person.loggedInPersonId);

  const numberOfElementsInSidebarMenu = Math.floor(
    (height - HEADER_HEIGHT - SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT) /
      SMALL_SIDEBAR_MENU_ELEMENT_HEIGHT
  );

  const sidebarMenu = [...SMALL_SIDEBAR_MENU_OPTIONS].splice(
    0,
    numberOfElementsInSidebarMenu
  );
  const drawerMenu = [...SMALL_SIDEBAR_MENU_OPTIONS].splice(
    numberOfElementsInSidebarMenu,
    SMALL_SIDEBAR_MENU_OPTIONS.length
  );

  const renderTouchSidebar =
    isTouchSupported() &&
    hideSecondaryMenu &&
    window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT;

  return (
    <Fragment>
      <SidebarMenuDrawer
        isOpen={isDrawerOpen}
        menu={drawerMenu}
        showPortalMenuItem={showPortalMenuItem}
        showCalendar={showCalendar}
        activeTab={activeTab}
        testid={SMALL_SIDEBAR_MENU_TESTID}
        onClose={() => {
          setIsDrawerOpen(!isDrawerOpen);
        }}
      />
      <Styled.SidebarMenu
        id="small-sidebar-menu"
        onMouseEnter={() => !isTouchSupported() && setHideSecondaryMenu(false)}
      >
        <Styled.TouchSidebar
          $renderTouchSidebar={renderTouchSidebar}
          onClick={() => setHideSecondaryMenu(false)}
        />
        <Styled.TopSidebarMenu>
          {sidebarMenu.map(({ label, icon, to, slug, testid }) => {
            if (slug === 'calendar' && !showCalendar) return null;
            return (
              <SideBarMenuButton
                key={slug}
                {...{
                  label,
                  icon: icon as IconDefinition,
                  isActive: activeTab === slug,
                  to,
                  testid,
                }}
              />
            );
          })}
        </Styled.TopSidebarMenu>

        {drawerMenu.length ? (
          <Styled.Column
            $isActive={isDrawerOpen}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <Icon icon="more" size="medium" />
            <Styled.Label $isActive={isDrawerOpen}>More</Styled.Label>
          </Styled.Column>
        ) : (
          <Styled.BottomSidebarMenu>
            {showPortalMenuItem && (
              <SideBarMenuButton
                isExternal
                {...{
                  label: 'Portal',
                  icon: 'portal',
                  to: PORTAL_URL,
                  testid: SMALL_SIDEBAR_MENU_TESTID,
                }}
              />
            )}
            <SideBarMenuButton
              {...{
                label: 'Settings',
                icon: 'settings',
                isActive: activeTab === 'settings',
                to: ROUTE_PATHS.settings.path,
                testid: SMALL_SIDEBAR_MENU_TESTID,
              }}
            />
          </Styled.BottomSidebarMenu>
        )}
      </Styled.SidebarMenu>
    </Fragment>
  );
};

export default inject(
  STORE_PERSON,
  STORE_UI
)(withRouter(observer(SmallSidebarMenu)));
