import type { IMessageReferences } from 'Models/MessageModel';
import React from 'react';

export type ChatLayoutContextProps = {
  view: '' | 'pinned-messages';
  setView: React.Dispatch<React.SetStateAction<ChatLayoutContextProps['view']>>;

  editMessageId: string;
  setEditMessageId: React.Dispatch<React.SetStateAction<string>>;

  replyMessage: IMessageReferences | null;
  setReplyMessage: React.Dispatch<
    React.SetStateAction<IMessageReferences | null>
  >;
};
export const ChatLayoutContext = React.createContext<ChatLayoutContextProps>({
  view: '',
  setView: () => {},
  editMessageId: '',
  setEditMessageId: () => {},
  replyMessage: null,
  setReplyMessage: () => {},
});
