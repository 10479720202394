import type { ImageFilePreviewProps } from './ImageFilePreview.types';
import { IconButton } from 'Components/shared/IconButton';
import React from 'react';

import { Styled } from './ImageFilePreview.styles';

const testid = 'imageFilePreview';

export const ImageFilePreview: React.FC<ImageFilePreviewProps> = ({
  description,
  externalId,
  handleDeleteFile,
  handleDownloadFile,
  isOwnMessage,
  previewUrl,
}) => {
  const [showHoverActions, setShowHoverActions] = React.useState(false);

  return (
    <Styled.ImagePreview
      $showHoverActions={showHoverActions}
      onMouseEnter={() => setShowHoverActions(true)}
      onMouseLeave={() => setShowHoverActions(false)}
    >
      <Styled.Image src={previewUrl} title={description} />
      {showHoverActions && (
        <Styled.HoverActions>
          {isOwnMessage && (
            <IconButton
              content="Delete"
              icon="delete"
              onClick={() => handleDeleteFile(externalId)}
              popupPosition="top center"
              size="small"
              testid={`${testid}-buttonDeleteFile`}
              variant="secondary"
            />
          )}
          <IconButton
            content="Download"
            icon="download"
            onClick={() => handleDownloadFile(externalId)}
            popupPosition="top center"
            size="small"
            testid={`${testid}-buttonDownloadFile`}
            variant="secondary"
          />
        </Styled.HoverActions>
      )}
    </Styled.ImagePreview>
  );
};
