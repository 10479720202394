import { BodyCopy } from 'Components/shared/BodyCopy';
import { Icon } from 'Components/shared/Icon';
import styled from 'styled-components';

const PinBox = styled.div<{ $isOwnMessage: boolean }>`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
  ${({ $isOwnMessage }) => !$isOwnMessage && 'margin-left: 48px;'}
`;

const StyledBodyCopy = styled(BodyCopy)`
  margin-left: 3px;
  user-select: none;
  transition: transform 500ms;

  &:hover {
    transition: transform 0.5 ease-out;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary} !important;
`;

export const Styled = {
  BodyCopy: StyledBodyCopy,
  PinBox,
  Icon: StyledIcon,
};
