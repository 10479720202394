/// <reference path="../../../typings/global.d.ts"/>
import type { AxiosError } from 'axios';
import { IS_ELECTRON, NODE_ENV_LOCAL_OR_DEVELOPMENT } from 'Constants/env';
import {
  WEB_NOTIFICATION_ACTION_TAKEN,
  WEB_NOTIFICATIONS_ENABLED,
} from 'Constants/localstorage';
import {
  WN_EVENT_PREFIX,
  WN_INCOMING_CALL_PREFIX,
  WN_MESSAGE_CREATED_PREFIX,
} from 'Constants/webNotifications';
import {
  IPushJsNotificationAdded,
  IPushJsNotificationOptions,
} from 'Interfaces/notifications';
import * as localforage from 'localforage';
import { get, has, isEmpty } from 'lodash';
import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { GroupedMessagesContainer } from 'Models/GroupedMessagesContainer';
import { NotificationProps } from 'Modules/notification/NotificationItem/index.types';
import { useNotificationStore } from 'Modules/notification/store/notification';
import * as Push from 'push.js';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import {
  sendIpcClickNotification,
  sendIpcNativeToast,
} from 'Utils/ipcRendererEvents';

/**
 * Adapted from https://github.com/igorprado/react-notification-system/issues/118
 *
 * @class NotificationStore
 */
export class NotificationStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
    this.webNotificationsSupported =
      IS_ELECTRON ||
      (window && 'Notification' in window && has(window, 'Notification'));
    if (IS_ELECTRON) {
      // Bypass action taken requirement for Electron. This setter also saves the value in localforage
      this.setWebNotificationPromptActionTaken(true);
      // Default to enabled (since no security prompt) and persist to localforage for Electron (user can still disable later via Settings).
      this.setWebNotificationsEnabled(true);
    } else {
      localforage
        .getItem<boolean>(WEB_NOTIFICATION_ACTION_TAKEN)
        .then((actionTaken) => {
          if (actionTaken !== null) {
            runInAction(
              () => (this.webNotificationPromptActionTaken = actionTaken)
            );
          }
        });
    }
    localforage.getItem<boolean>(WEB_NOTIFICATIONS_ENABLED).then((enabled) => {
      if (enabled !== null) {
        runInAction(() => (this.webNotificationsEnabled = enabled));
      }
    });
  }

  /** Whether Web Notifications are supported in this environment */
  readonly webNotificationsSupported: boolean = false;
  /**
   * Whether Web Notifications are supported *AND* permission is 'granted'
   * [BC-769] Always `true` for Electron environments
   */
  public isWebNotificationPermissionGranted = () =>
    IS_ELECTRON ||
    (this.webNotificationsSupported &&
      (Notification as any).permission === 'granted');
  /**
   * Whether Web Notifications are supported *AND* permission is 'denied'
   * [BC-769] Always `false` for Electron environments
   */

  public isWebNotificationPermissionDenied = () =>
    !IS_ELECTRON &&
    this.webNotificationsSupported &&
    (Notification as any).permission === 'denied';

  /**
   * Whether Web Notifications are enabled, regardless of whether `Notification.permission === 'granted'`.
   * If permission has not been `'granted'`, this does nothing.
   *
   * Can be used to disable/override Web Notifications even if permission was previously granted, but does not directly affect the permission.
   */
  @observable
  public webNotificationsEnabled = false;
  /**
   * Set Whether Web Notifications are enabled.
   *
   * Also persists the value in `localforage`
   */

  @observable
  isNetworkErrorDisplayed = false;

  @action
  setIsNetworkErrorDisplayed = (value: boolean) =>
    (this.isNetworkErrorDisplayed = value);

  /**
    Used only to disable notifications during the logout routine
  */
  @observable
  notificationsEnabled = true;

  @action
  public setWebNotificationsEnabled = (enabled: boolean) => {
    if (
      this.webNotificationsSupported &&
      this.isWebNotificationPermissionGranted()
    ) {
      this.webNotificationsEnabled = enabled;
      localforage.setItem(WEB_NOTIFICATIONS_ENABLED, enabled);
    }
    return this.webNotificationsEnabled;
  };

  /** Whether the User has clicked on the in-app prompt to trigger a Web Notification Permission request. */
  @observable
  public webNotificationPromptActionTaken = false;
  /**
   * Set whether the User has clicked on the in-app prompt to trigger a Web Notification Permission request.
   *
   * Also persists the value in `localforage`.
   *
   * **Note:** This prompt is only required in-browser, Electron environments will always have this set to true (see constructor).
   */

  @action
  public setWebNotificationPromptActionTaken = (actionTaken: boolean) => {
    this.webNotificationPromptActionTaken = actionTaken;
    localforage.setItem(WEB_NOTIFICATION_ACTION_TAKEN, actionTaken);
  };

  /** Check permissions, and either enable Web Notifications _or_ request permission (then enable or disable as appropriate) */
  public tryEnableWebNotifications = () => {
    if (this.isWebNotificationPermissionGranted()) {
      this.setWebNotificationsEnabled(true);
    } else if (this.webNotificationsSupported) {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          this.setWebNotificationsEnabled(true);
        } else {
          this.setWebNotificationsEnabled(false);
          this.showWebNotificationDisabledOrNotSupportedMessage();
        }
      });
    } else {
      this.setWebNotificationsEnabled(false);
      this.showWebNotificationDisabledOrNotSupportedMessage();
    }
  };

  /** `true` if Web Notifications are: _supported, not currently enabled,
   * permission is not denied, and the user has either not been prompted or has not clicked or dismissed the prompt_ */
  @computed
  get ShouldDisplayWebNotificationPrompt() {
    return (
      this.webNotificationsSupported &&
      !this.webNotificationsEnabled &&
      !this.isWebNotificationPermissionDenied() &&
      !this.webNotificationPromptActionTaken
    );
  }

  @action
  clearAllData = () => {
    const { clearAllNotifications } = useNotificationStore.getState();

    this.notificationsEnabled = false;
    this.messageCreatedWebNotifications.clear();
    clearAllNotifications();
    Push.clear();
  };

  /** Track `MessageCreated` notifications, keyed by Conversation Id. */
  messageCreatedWebNotifications = observable.map<
    string,
    IObservableArray<IPushJsNotificationAdded>
  >();

  /**
   * Create a notification displayed with custom notification module.
   *
   * @description
   * This method serves as an adapter between the legacy MobX notification system and the new Zustand-based notification system.
   * To avoid a large-scale refactor affecting multiple containers and components, this adapter maintains backward compatibility
   * while gradually transitioning to the new system.
   *
   * TODO: Create technical debt task to:
   * 1. Refactor NotificationStore from MobX to Zustand
   * 2. Update all containers to use new notification system directly
   * 3. Remove this adapter method after migration is complete
   * 4. Update tests to reflect new notification implementation
   */
  @action
  addNotification(
    message: string,
    title: string = null,
    level: NotificationProps['type'] = 'info',
    position: NotificationProps['position'] = 'tc',
    autoDismiss = 5,
    logToConsole = false,
    onClick?: Function,
    duration?: string
  ) {
    if (!this.notificationsEnabled) {
      return;
    }

    const { addNotify, removeNotify } = useNotificationStore.getState();

    const hasAction = ['new message', 'new mention'].some((string) =>
      title?.includes(string)
    );

    const notificationId = addNotify({
      message,
      title,
      type: level,
      position,
      autoDismiss,
      dismissible: 'button',
      action: hasAction && {
        label: 'Open',
        onClick: () => {
          if (onClick) {
            onClick();
            removeNotify(position, notificationId);
          }
        },
      },
    });

    if (logToConsole) {
      switch (level) {
        case 'error':
          console.error(`addNotification (${level}): ${title}: ${message}`);
          break;
        case 'warning':
          console.warn(`addNotification (${level}): ${title}: ${message}`);
          break;
        case 'success':
        case 'info':
        default:
          console.info(`addNotification (${level}): ${title}: ${message}`);
          break;
      }
    }
  }

  /**
   * Display an AxiosError notification, displaying the `response.data.message` if possible
   *
   * @param error An `AxiosError` Promise rejection reason
   * @param [responseTitle='Response Error'] (Default: "Response Error") Title to display on the notification
   * @param [appendStatusToTitle=true] (Default: true) Append the HTTP status code and status text to the `responseTitle`
   * @param [position="tc"] (Default: tc) Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center).
   * @param [autoDismiss=5] (Default: 5) Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification.
   * @param [logToConsole=false] (Default: false) Also log using `console.error`
   */
  @action
  addAxiosErrorNotification(
    error: AxiosError,
    responseTitle = 'Response Error',
    appendStatusToTitle = true,
    position: NotificationProps['position'] = 'tc',
    autoDismiss = 5,
    logToConsole = false
  ) {
    if (!this.notificationsEnabled) {
      return;
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const msg = get(error.response, 'data.message', error.message);
      this.addNotification(msg, responseTitle, 'error', position, autoDismiss);
      if (logToConsole) {
        console.error(responseTitle, msg, error);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      if (
        has(error, 'request') &&
        error.message === 'Network Error' &&
        !this.isNetworkErrorDisplayed
      ) {
        this.addNotification(
          null,
          'No Internet Connection!',
          'error',
          position,
          autoDismiss
        );
        this.setIsNetworkErrorDisplayed(true);
      }
      if (logToConsole) {
        console.error('No Internet Connection!', error.message, error);
      }
    }
  }

  /** Suppress (force remove) a Web Notification by its `tag`. */
  @action
  closeWebNotification = (tag: string) => {
    Push.close(tag);
  };

  /** Close all `push.js` Web Notifications indicating Messages Created in a Conversation. */
  closeAllMessageCreatedWebNotifications = (conversationId: string) => {
    if (this.messageCreatedWebNotifications.has(conversationId)) {
      const mcwns = this.messageCreatedWebNotifications.get(conversationId);
      mcwns.forEach((m) => {
        const wnTag = `${WN_MESSAGE_CREATED_PREFIX}${conversationId}:${m.messageId}`;
        if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
          console.debug('Closing Web Notif ' + wnTag);
        }
        Push.close(wnTag);
      });
      mcwns.clear();
    }
  };

  @action
  addNotificationWhenPushNotifEnabled = (
    fallbackLogLevel: NotificationProps['type'] | false = 'info',
    options: IPushJsNotificationOptions,
    title: string,
    duration: string
  ) => {
    if (!this.notificationsEnabled) {
      return;
    }
    if (
      this.webNotificationsEnabled &&
      this.webNotificationsSupported &&
      fallbackLogLevel
    )
      this.addNotification(
        options.body,
        title,
        fallbackLogLevel,
        'tr',
        isFinite(options.timeout) ? Math.ceil(options.timeout / 1000) : 10,
        undefined,
        options.onClick,
        duration
      );
  };
  /**
   * Send a Web Notification, if supported and enabled. Allows fallback to notification if `fallbackLogLevel` is a valid log level and not false.
   *
   * @param title Title used for the Notification
   * @param options `IPushJsNotificationOptions` options for the Notification
   * @param duration ...
   * @param [fallbackLogLevel='info'] (Default: info) If not `false`, the notification log level to use if Web Notifications are disabled/unsupported].
   *
   * **WARNING**: If this is `false`, it is possible for notifications to get completely dropped, in which case, a warning message is displayed in the console.
   * @param [forceShow=false] (Default: false) By default, Web Notifications are not displayed if certain rules are met. If `true`, forces them to display regardless of these rules.
   *
   */

  @action
  addWebNotification(
    title: string,
    options: IPushJsNotificationOptions,
    duration: string,
    fallbackLogLevel: NotificationProps['type'] | false = 'info',
    forceShow = false
  ) {
    if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
      console.debug('addWebNotification', arguments);
    }
    if (!this.webNotificationsEnabled || !this.webNotificationsSupported) {
      if (fallbackLogLevel) {
        this.addNotification(
          options.body,
          title,
          fallbackLogLevel,
          'tr',
          isFinite(options.timeout) ? Math.ceil(options.timeout / 1000) : 10,
          undefined,
          options.onClick,
          duration
        );
      } else {
        console.warn(
          'Web notifications disabled or not supported, and fallbackLogLevel was not provided. The notification has been dropped completely.'
        );
      }
    } else {
      if (isEmpty(options.tag)) {
        throw new Error('You must provide a tag for a Web Notification');
      }
      this.addNotificationWhenPushNotifEnabled(
        fallbackLogLevel,
        options,
        title,
        duration
      );
      // Automatically suppress if the window is focused/active AND `forceShow` is false
      let suppress = this.rootStore.uiStore.IsFocused && !forceShow;

      let conversationId: string = null;
      let messageId: string = null;
      let groupedMessagesContainer: GroupedMessagesContainer = null;
      // If this is a `MessageCreated` notification, track it for later closing
      const isMessageCreated = this.isMessageCreatedTag(options.tag);
      const isIncomingCall = this.isIncomingCallTag(options.tag);
      const isEventNotification = this.isEventNotifaction(options.tag);
      if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
        console.debug({ isMessageCreated, isIncomingCall });
      }
      if (isMessageCreated) {
        const convAndMsgId: ConversationAndMessageId =
          this.extractMessageCreatedConversationAndMessageId(options.tag);
        conversationId = convAndMsgId.conversationId;
        messageId = convAndMsgId.messageId;
        groupedMessagesContainer =
          this.rootStore.messageStore.groupedMessagesByConversationMap.get(
            convAndMsgId.conversationId
          );
      } else if (isIncomingCall) {
        conversationId = this.extractIncomingCallConversationId(options.tag);
      } else if (!options.tag.startsWith('test:') && !isEventNotification) {
        throw new Error(
          'Invalid desktop notification tag, a valid prefix is required: ' +
            options.tag
        );
      }

      const isCurrentConversation =
        conversationId === getCurrentConversationId();

      if (!suppress && isMessageCreated) {
        // Display only if the notification is NOT for the current `Conversation`
        if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
          console.debug('IsBlurred', this.rootStore.uiStore.IsBlurred);
        }
        if (!isCurrentConversation || this.rootStore.uiStore.IsBlurred) {
          if (this.messageCreatedWebNotifications.has(options.tag)) {
            const pjna =
              this.messageCreatedWebNotifications.get(conversationId);
            pjna.unshift({ added: Date.now().toString(), messageId, options });
          } else {
            const pjna = observable.array<IPushJsNotificationAdded>(null, {
              deep: false,
            });
            pjna.push({ added: Date.now().toString(), messageId, options });
            this.messageCreatedWebNotifications.set(conversationId, pjna);
            this.addNotification(
              options.body,
              title,
              'info',
              'tr',
              isFinite(options.timeout)
                ? Math.ceil(options.timeout / 1000)
                : 10,
              undefined,
              options.onClick,
              duration
            );
          }
        } else {
          if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
            console.debug('Suppressing web Notification!');
          }
          suppress = true;
        }
      }

      if (!suppress) {
        if (IS_ELECTRON) {
          if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
            console.debug(
              `addWebNotification sending Native Windows toast: ${title} | ${options.body}`
            );
          }
          const eventId =
            isEventNotification && this.extractEventId(options.tag);
          sendIpcNativeToast(
            conversationId,
            messageId,
            eventId,
            [title, options.body],
            `bvcom://conversations/${conversationId}/menu`,
            options.icon ? { src: options.icon, id: 1 } : null
          );
        } else {
          const that = this;
          const pjsOpts = {
            ...options,
            onClick() {
              // Wrap any provided `onClick` to close the notification instance
              if (typeof options.onClick === 'function') {
                options.onClick();
              }
              // Attempt to mark the latest Message as read
              if (
                isMessageCreated &&
                (that.rootStore.conversationStore.conversationByIdMap.has(
                  conversationId
                ) ||
                  that.rootStore.conversationStore.conversationByIdRecentHist.has(
                    conversationId
                  ))
              ) {
                let markMessageIdAsRead = messageId;
                if (
                  groupedMessagesContainer !== undefined &&
                  groupedMessagesContainer.NewestMessageId !== null
                ) {
                  markMessageIdAsRead =
                    groupedMessagesContainer.NewestMessageId;
                }
              }
              // Inside an Electron renderer process
              sendIpcClickNotification(options.tag);

              if (parent) {
                parent.focus();
              }
              // Fallback to `window` if possible
              if (window) {
                window.focus();
              }
              // `this` is the `push.js` notification instance
              this.close();
            },
          };

          Push.create(title, pjsOpts);
        }
      } else {
        if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
          console.debug(
            `Suppressed Message Created Web Notification with tag (${options.tag})`
          );
        }
      }
    }
  }

  extractEventId = (tag: string) => {
    if (tag) {
      const splittedTag = tag.split(':');
      if (splittedTag && splittedTag[1] && !isNaN(splittedTag[1] as any)) {
        return Number(splittedTag[1]);
      }
    }
  };

  @action
  showWebNotificationDisabledOrNotSupportedMessage = () => {
    this.addNotification(
      "We'll keep showing you these kinds of notifications.",
      'Desktop Notification' +
        (this.webNotificationsSupported
          ? ' Permission Denied'
          : 's Unsupported'),
      'warning'
    );
  };

  /** Extract the `Conversation` and `Message` Ids from a MessageCreated tag (prefixed with 'mc:') */
  private extractMessageCreatedConversationAndMessageId = (tag: string) => {
    // Fail on empty tag, or tag that doesn't start with a recognized prefix indicating a Conversation Id.
    if (isEmpty(tag) || !tag.startsWith(WN_MESSAGE_CREATED_PREFIX)) {
      console.warn('extractConversationId no prefix for', tag);
      return null;
    }
    const convMsgIdStr: string = tag.replace(WN_MESSAGE_CREATED_PREFIX, '');
    const [conversationId, messageId] = convMsgIdStr.split(':');
    return { conversationId, messageId } as ConversationAndMessageId;
  };

  /** Extract the `Conversation` Id from a IncomingCall tag (prefixed with 'ic:') */
  private extractIncomingCallConversationId = (tag: string) => {
    // Fail on empty tag, or tag that doesn't start with a recognized prefix indicating a Conversation Id.
    if (isEmpty(tag) || !tag.startsWith(WN_INCOMING_CALL_PREFIX)) {
      console.warn('extractConversationId no prefix for', tag);
      return null;
    }
  };

  /** Whether the tag starts with `WN_MESSAGE_CREATED_PREFIX` (default: 'mc:') */
  private isMessageCreatedTag = (tag: string) =>
    tag.startsWith(WN_MESSAGE_CREATED_PREFIX);

  /** Whether the tag starts with `WN_INCOMING_CALL_PREFIX` (default: 'ic:') */
  private isIncomingCallTag = (tag: string) =>
    tag.startsWith(WN_INCOMING_CALL_PREFIX);

  private isEventNotifaction = (tag: string) => tag.startsWith(WN_EVENT_PREFIX);
}

interface ConversationAndMessageId {
  conversationId: string;
  messageId: string;
}
