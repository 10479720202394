import type { ReplyBoxProps } from './ReplyBox.types';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import {
  formatFileExtension,
  normalizeChatText,
  parseMessageDate,
} from 'Components/Chat/utils';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { useMarkdownCompiler } from 'Utils/markdownCompiler';
import { Styled } from './ReplyBox.styles';

const testid = 'replyBox';

export const ReplyBox: React.FC<ReplyBoxProps> = ({
  className,
  reference: {
    id,
    snapshot: { chat, created, documents, personId },
  },
  onRemoveClick,
}) => {
  const { view, setView } = React.useContext(ChatLayoutContext);

  const {
    person: { selectPersonById },
    ui: { setMessageIdToScroll },
    message: { selectGroupedMessagesForConversation },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const { compileViewerMarkdown } = useMarkdownCompiler(id);

  const isEditing = !!onRemoveClick;

  const hasText = !!chat?.text_v2 || !!chat?.text;
  const hasDocuments = !!documents?.length;
  const allDocumentsDeleted = documents?.every(({ isDeleted }) => isDeleted);

  const conversationId = getCurrentConversationId();

  const isMessageInStore = React.useMemo(() => {
    return selectGroupedMessagesForConversation(
      conversationId
    ).SortedGroups.some((messageGroup) => {
      if (messageGroup.messages.some((message) => message.id === id)) {
        return true;
      }
    });
  }, [
    selectGroupedMessagesForConversation(conversationId).SortedGroups,
    conversationId,
  ]);

  const onClick = (e) => {
    // Prevent running "setView" when unnecessary
    e.stopPropagation();

    const referencedMessage = document.getElementById(id);

    // If message exists in the store, and we're on the pinned messaged view
    if (isMessageInStore && view === 'pinned-messages') {
      setView('');
      setMessageIdToScroll(id);
    } else {
      referencedMessage?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      pushToGTMDataLayer('replyQuoteClick', { enabled: !!referencedMessage });
    }
  };

  return (
    <Styled.Box
      $isEditing={isEditing}
      {...(!isMessageInStore && {
        title:
          "Apologies, we can't take you back to this message, but you can scroll up to its original date",
        $disable: true,
      })}
      {...(!isEditing && { onClick })}
      {...{ className }}
    >
      <Styled.NameDateContainer>
        <Styled.Name variant="paragraph" noMargin ellipsis>
          {selectPersonById(personId).case({
            fulfilled: ({ data }) => data.DisplayName,
          })}
        </Styled.Name>
        <Styled.Date variant="subtitles" noMargin>
          {parseMessageDate(created)}
        </Styled.Date>
        {onRemoveClick && (
          <Styled.Icon
            onClick={onRemoveClick}
            variant="text"
            icon="cancel-heavy"
            size="tiny"
            testid={`${testid}-buttonRemoveReplyMessage`}
          />
        )}
      </Styled.NameDateContainer>
      <Styled.Message
        $singleLine={hasDocuments && !allDocumentsDeleted}
        ellipsis
        variant="paragraph"
      >
        {compileViewerMarkdown(normalizeChatText(chat?.text_v2 || chat?.text))}
      </Styled.Message>
      {hasDocuments && (
        <Styled.Attachments
          $singleLine={!!chat?.text}
          noMargin
          variant="subtitles"
        >
          {!allDocumentsDeleted
            ? documents?.map(({ contentType, description, isDeleted }) =>
                isDeleted ? null : (
                  <>
                    <Styled.AttachmentIcon
                      icon={
                        contentType.includes('image') ? 'image' : 'attachment'
                      }
                      size="tiny"
                    />
                    <Styled.Extension>
                      {formatFileExtension(description)}
                    </Styled.Extension>
                    <Styled.Description>{description}</Styled.Description>
                  </>
                )
              )
            : !hasText && (
                <Styled.AttachmentsDeleted>
                  Some files have been removed, so we have nothing to show here.
                </Styled.AttachmentsDeleted>
              )}
        </Styled.Attachments>
      )}
    </Styled.Box>
  );
};
