import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 20%;
`;

export const Styled = {
  Wrapper,
};
