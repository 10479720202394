import ChannelMembersListObserver from 'Components/ChannelMembersList';
import { ContactListSearch } from 'Components/ContactListSearch';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChannelMembersProps } from './interfaces';

/**
 * Container for the List of `Participant`s in a `Channel`, as well as `Person`s who may be added to the `Channel`.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelMembers
 */
export class ChannelMembers extends React.Component<ChannelMembersProps, {}> {
  render() {
    const {
      channelCandidates,
      saveClick,
      setDescription,
      conversationModel,
      getPerson,
      uiStore,
      from,
      participantStore,
      conversation,
      person,
    } = this.props;
    return (
      <>
        <ChannelMembersListObserver {...this.props} />
        {from !== 'group-info' && (
          <ContactListSearch
            {...this.props}
            loadPersonByIdGetIfMissingGet={person.loadPersonByIdGetIfMissingGet}
            personCandidatesEmptySearch={channelCandidates}
            selectParticipantPersonInfo={getPerson}
            uiStore={uiStore}
            conversationVm={conversation.conversationVm}
            setDescription={setDescription}
            saveClick={saveClick}
            removeChannelMembers={participantStore.removeParticipantsDelete}
            participantByConvMap={participantStore.participantByConvMap}
            conversationModel={conversationModel}
            setConversationVm={conversation.setConversationVm}
            topicNameTaken={conversation.topicNameTaken}
            groupModalOpen={uiStore.groupModalOpen}
            setAdHocGrouParticipants={conversation.setAdHocGrouParticipants}
            loadOrCreateAdHocGroup={conversation.loadOrCreateAdHocGroup}
            selectedAdHocParticipants={uiStore.selectedAdHocParticipants}
            setSelectedParticipants={uiStore.setSelectedParticipants}
            clearNameTaken={conversation.clearNameTaken}
            channelInfoDetails={conversation.channelInfoDetails}
          />
        )}
      </>
    );
  }
}
export default observer(ChannelMembers);
