import cx from 'classnames';
import { RoutedMergeAttendeeOptionsDropdown } from 'Components/MergeAttendeeOptionsDropdown';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { isEmpty, uniqBy } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';
import { Loader, Message, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { formatNumberWithNationalCode, isPhoneNumber } from 'Utils/phoneUtil';
import CallActiveButtons from 'Components/CallActiveButtons';
import CallButtons from 'Components/CallButtons';
import withRouter from 'Hocs/WithRouter';
import { ContactModel, PersonModel } from 'Models/index';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { TopBarDialpad } from '../TopBarDialpad';
import { CallActiveProps } from './interfaces';


const keypad0 = require('../../assets/audio/keypad/0.wav');
const keypad1 = require('../../assets/audio/keypad/1.wav');
const keypad2 = require('../../assets/audio/keypad/2.wav');
const keypad3 = require('../../assets/audio/keypad/3.wav');
const keypad4 = require('../../assets/audio/keypad/4.wav');
const keypad5 = require('../../assets/audio/keypad/5.wav');
const keypad6 = require('../../assets/audio/keypad/6.wav');
const keypad7 = require('../../assets/audio/keypad/7.wav');
const keypad8 = require('../../assets/audio/keypad/8.wav');
const keypad9 = require('../../assets/audio/keypad/9.wav');
const keypadAsterisk = require('../../assets/audio/keypad/asterisk.wav');
const keypadPound = require('../../assets/audio/keypad/pound.wav');
const ellipsisIcon = require('../../assets/images/ellipse.svg');
const infoBtn = require('../../assets/images/info-btn.svg');
const unknownCallerAvatar = require('../../assets/images/unknown-caller-avatar.png');

export class CallActive extends React.Component<CallActiveProps> {
  showPeopleInRoom = false;
  @observable
  listOfPeopleInARoom = [];

  constructor(props: CallActiveProps) {
    super(props);
    makeObservable(this);
  }

  @action
  uniqueInfoOfPeopleInRoom(respData: any) {
    if (respData.contactType === 'Person') {
      this.listOfPeopleInARoom.push({
        uniqId: (respData as PersonModel).DisplayName,
        data: (
          <div className={'participant'}>
            <img src={respData.DisplayAvatar} className="avatar" />
            <div className="main">
              <div className="name">{respData.DisplayName}</div>
              <div className="phone">
                {(respData as PersonModel)?.formatNumber()}
              </div>
            </div>
            <RoutedMergeAttendeeOptionsDropdown
              markup={
                <div className="attendee-options-wrapper">
                  <img src={ellipsisIcon} />
                </div>
              }
              sendMessage={this.props.loadOrCreateConversationWithPost}
              attendeeId={respData.id}
              isPerson={true}
            />
          </div>
        ),
      });
    } else {
      this.listOfPeopleInARoom.push({
        uniqId: (respData as ContactModel).FormattedPhone,
        data: (
          <div className={'participant'}>
            <img
              src={
                respData.DisplayAvatar
                  ? respData.DisplayAvatar
                  : unknownCallerAvatar
              }
              className="avatar"
            />
            <div className="main">
              <div className="name">
                {(respData as ContactModel)
                  ? (respData as ContactModel).DisplayName
                  : 'Unknown Caller'}
              </div>
              <div className="phone">
                {(respData as ContactModel).FormattedPhone}
              </div>
            </div>
            <RoutedMergeAttendeeOptionsDropdown
              markup={
                <div className="attendee-options-wrapper">
                  <img src={ellipsisIcon} />
                </div>
              }
              sendMessage={this.props.loadOrCreateConversationWithPost}
              attendeeId={(respData as ContactModel).phoneNumber}
              isPerson={false}
            />
          </div>
        ),
      });
    }
  }

  keyPadPush = (num: string) => {
    const { phoneCall } = this.props;
    phoneCall.keysPress(num);
    phoneCall.dialPadNumDtmf(num);
  };

  muteCall = () => {
    const { phoneCall } = this.props;
    if (!phoneCall.isCallMuted) {
      phoneCall.mute();
      phoneCall.muteIconState('microphone slash');
    } else {
      phoneCall.unMute();
      phoneCall.muteIconState('microphone');
    }
  };

  pickup = () => {
    const { phoneCall } = this.props;
    phoneCall.pickup();
  };

  reject = () => {
    const { phoneCall } = this.props;
    phoneCall.reject();
  };

  cancel = () => {
    const { phoneCall, cancleCallOnNoWebRTC, isWebRTCConnected, isOnline } =
      this.props;
    if (!isWebRTCConnected || !isOnline) {
      cancleCallOnNoWebRTC();
    } else {
      phoneCall.cancel();
    }
  };

  holdCall = () => {
    const { phoneCall } = this.props;
    if (!phoneCall.isCallOnHold) {
      phoneCall.hold();
      phoneCall.onHoldIconState('pause circle');
    } else {
      phoneCall.unHold();
      phoneCall.onHoldIconState('pause circle outline');
    }
  };

  transferCall = () => {
    const { phoneCall } = this.props;
    phoneCall.setTransferMode(true);
    if (!phoneCall.isCallOnHold) {
      phoneCall.hold();
    }
  };

  displayListofPeopleInARoom = () => {
    const {
      selectPeopleOrContactsFromConferenceRoom,
      conferenceRoomsIdAndNumbers,
      conferenceIdGuid,
    } = this.props;
    if (conferenceRoomsIdAndNumbers.has(conferenceIdGuid)) {
      const peopleInARoom = selectPeopleOrContactsFromConferenceRoom(
        conferenceIdGuid
      ) as IPromiseBasedObservable<
        AxiosResponseT<ContactModel | PersonModel>
      >[];
      peopleInARoom.map((p) => {
        return (p as any)?.case({
          fulfilled: (
            resp: AxiosResponseT<ContactModel> | AxiosResponseT<PersonModel>
          ) => {
            const respData = resp.data;
            this.showPeopleInRoom = true;
            this.uniqueInfoOfPeopleInRoom(respData);
          },
        });
      });
    }
  };

  @action
  closeListOfPeopleInRoom = () => {
    this.showPeopleInRoom = false;
    this.listOfPeopleInARoom = [];
  };

  endCall = () => {
    // hide the dialpad if it was open and reset the keys being displayed
    const { phoneCall } = this.props;
    if (phoneCall.showDialPad) {
      phoneCall.toggleDialPad();
    }
    if (phoneCall.isWarmTransfer) {
      phoneCall.setTransferMode(true);
      phoneCall.setWarmTransfer(false);
    }
    phoneCall.hangUp();
  };

  displayNumber = (respData) => {
    if (respData.contactType === 'Person') {
      return (respData as PersonModel).formatNumber();
    } else if (respData.DisplayName === respData.phoneNumber) {
      return null;
    } else {
      return (respData as ContactModel).FormattedPhone;
    }
  };

  render() {
    const {
      loggedInAccountId,
      phoneCall,
      transferToConference,
      oldSession,
      isWebRTCConnected,
      loadOrCreateConversationWithPost,
      postConferenceByConversationId,
      loggedInUserActiveConferenceConversation,
      selectPeopleOrContactsFromConferenceRoom,
      conferenceRoomsIdAndNumbers,
      conferenceIdGuid,
      getExtrContactByPhoneNumber,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      uiStore,
      personStore,
      phoneStore,
    } = this.props;
    const callIsInTransit =
      phoneCall.isIncomingCall &&
      (phoneCall.isMediaConnecting || phoneCall.isCallConnecting);
    if (isEmpty(phoneCall)) {
      return <Loader active indeterminate size="mini" />;
    }
    const extrContact =
      phoneCall.phoneNumber &&
      getExtrContactByPhoneNumber(phoneCall.phoneNumber);
    // main Large Avatar
    let avatarEl =
      phoneCall.person !== null ? (
        phoneCall.person?.case({
          pending: () => <Loader indeterminate active />,
          rejected: (reason) => (
            <Message
              visible
              error
              content={'Error Loading Person: ' + reason.response?.data.message}
            />
          ),
          fulfilled: (response) => {
            const person = response.data;
            const image = person.profilePictureUrl;
            return image?.startsWith('http') ? (
              <img
                src={image}
                className={`${
                  phoneCall?.isCallConnecting ? 'transit-call-image-border' : ''
                }`}
              />
            ) : phoneCall.callerIdName ? (
              <UserAvatar
                name={phoneCall.callerIdName}
                selectUnreadCounts={selectUnreadCounts}
                setConversationAndTotalUnreadCount={
                  setConversationAndTotalUnreadCount
                }
                updateMyLastReadMessage={updateMyLastReadMessage}
              />
            ) : (
              <UserAvatar
                name={person.DisplayName}
                selectUnreadCounts={selectUnreadCounts}
              />
            );
          },
        })
      ) : extrContact ? (
        <>
          {extrContact.pictureUrl ? (
            <img src={extrContact.pictureUrl} className="profile-image" />
          ) : (
            <UserAvatar
              name={extrContact.DisplayName()}
              selectUnreadCounts={selectUnreadCounts}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={updateMyLastReadMessage}
            />
          )}
        </>
      ) : phoneCall.contact !== null ? (
        phoneCall.contact?.case({
          pending: () => <Loader indeterminate active />,
          rejected: (reason) => (
            <Message
              visible
              error
              content={'Error Loading Person: ' + reason.response?.data.message}
            />
          ),
          fulfilled: () => {
            return <UserAvatar selectUnreadCounts={selectUnreadCounts} />;
          },
        })
      ) : (
        <UserAvatar selectUnreadCounts={selectUnreadCounts} />
      );
    // if we have any ConfereId gernerated, this portion will generate the people
    /* ----------------- Generate Small Avatar List --------------- */
    /* ----------------------------------------------------------- */
    let firstPersonFullName: any;
    let numberOfPeopleInConferenceRoom;
    const confAvatarEls: any[] = [];
    if (
      phoneCall.isMerge &&
      conferenceRoomsIdAndNumbers.has(conferenceIdGuid)
    ) {
      const peopleInARoom = selectPeopleOrContactsFromConferenceRoom(
        conferenceIdGuid
      ) as IPromiseBasedObservable<
        AxiosResponseT<ContactModel | PersonModel>
      >[];
      peopleInARoom.map((p) => {
        return (p as any)?.case({
          fulfilled: (
            resp: AxiosResponseT<ContactModel> | AxiosResponseT<PersonModel>
          ) => {
            if (!resp) return null;
            const respData = resp.data;
            const content = (
              <div className="active-call-small-avatars-popup-content">
                <div className="name">
                  {respData.contactType === 'Person' ||
                  respData.contactType === 'Base'
                    ? respData.DisplayName
                    : respData.name
                    ? respData.name
                    : 'Unknown Caller'}
                </div>
                <div className="number">
                  {respData.contactType === 'Person'
                    ? (respData as PersonModel).formatNumber()
                    : respData.DisplayName === respData.phoneNumber
                    ? null
                    : (respData as ContactModel).FormattedPhone}
                </div>
              </div>
            );
            const popup = (
              <Popup
                position="top center"
                content={content}
                trigger={
                  <img
                    key={resp.data?.id}
                    src={
                      respData.contactType === 'Person'
                        ? (respData as PersonModel).DisplayAvatarLg
                        : respData.DisplayAvatar
                        ? respData.DisplayAvatar
                        : unknownCallerAvatar
                    }
                    className={'avatar'}
                  />
                }
              />
            );
            if (!firstPersonFullName && !isNullOrUndefined(respData)) {
              // If this is the first Communicator user in the list, put them at the top of the list
              firstPersonFullName = (respData as PersonModel).DisplayName;
            }
            avatarEl = (
              <img
                src={
                  respData.contactType === 'Person'
                    ? (respData as PersonModel).DisplayAvatarLg
                    : respData.DisplayAvatar
                    ? respData.DisplayAvatar
                    : unknownCallerAvatar
                }
                className={'avatar'}
              />
            );

            confAvatarEls.push({
              uniqId:
                respData.contactType === 'Person'
                  ? respData.DisplayName
                  : (respData as ContactModel).FormattedPhone,
              data: popup,
            });
          },
        });
      });

      /* ----------------- Generate contact-info-top-row CONTENT --------------- */
      /* --------------------------------------------------------------------- */
      if (peopleInARoom.length > 0 && !firstPersonFullName) {
        firstPersonFullName = (peopleInARoom[0] as any)?.case({
          fulfilled: (
            resp: AxiosResponseT<ContactModel> | AxiosResponseT<PersonModel>
          ) => !isNullOrUndefined(resp) && resp.data.DisplayName,
        });
      }
      numberOfPeopleInConferenceRoom = peopleInARoom.length - 1;
    }

    return (
      <div
        className={cx('call-active', 'call-item', {
          extended: !phoneCall.isCallConnecting,
          connecting: phoneCall.isCallConnecting,
          'warm-transfer': phoneCall.isWarmTransfer,
          incoming: phoneCall.isIncomingCall,
        })}
      >
        {phoneCall.isMediaConnected && (
          <div className="call-timer">{phoneCall.timerDisplay}</div>
        )}
        <div
          className={cx('call-active-first-row', {
            connecting: phoneCall.isCallConnecting,
          })}
        >
          {phoneCall.showDialPad && (
            <div className="calling-dialpad">
              <TopBarDialpad keyPadPush={this.keyPadPush} />
            </div>
          )}
          {!phoneCall.showDialPad && (
            <div
              className={cx('contact-image-wrapper', {
                isMerge: phoneCall.phoneStore.ActivePhoneCall.isMerge,
              })}
            >
              {phoneCall.phoneStore.ActivePhoneCall.isMerge && (
                <div className="small-avatars-list">
                  {' '}
                  {uniqBy(confAvatarEls, (ua) => ua.uniqId).map(
                    (a) => a.data
                  )}{' '}
                </div>
              )}
              {!phoneCall.phoneStore.ActivePhoneCall.isMerge && avatarEl}
            </div>
          )}
        </div>
        {
          <div className="call-content">
            {phoneCall.phoneStore.ActivePhoneCall.isMerge &&
              this.showPeopleInRoom && (
                <div className="call-details">
                  <div className="header">
                    <div className="title">CALL DETAILS</div>
                    <div
                      className="close"
                      onClick={this.closeListOfPeopleInRoom}
                    >
                      Close
                    </div>
                    <div className="push" />
                  </div>
                  <div className="call-details-list">
                    {uniqBy(this.listOfPeopleInARoom, (p) => p.uniqId).map(
                      (item) => {
                        return item.data;
                      }
                    )}
                  </div>
                </div>
              )}
            <div className="call-top">
              {phoneCall.phoneStore.ActivePhoneCall.isMerge &&
                phoneCall.isCallConnected && (
                  <div data-private className="contact-info">
                    <>
                      <div className={'contact-name'}>
                        {firstPersonFullName}
                      </div>
                      {numberOfPeopleInConferenceRoom > 0 && (
                        <div className={'contact-count'}>
                          & {numberOfPeopleInConferenceRoom}{' '}
                          {numberOfPeopleInConferenceRoom > 1
                            ? 'others'
                            : 'other'}
                        </div>
                      )}
                      <img
                        src={infoBtn}
                        className={'contact-info-btn'}
                        onClick={this.displayListofPeopleInARoom}
                      />
                    </>
                  </div>
                )}
              <div data-private className="contact-info">
                {!(
                  phoneCall.phoneStore.ActivePhoneCall.isMerge &&
                  phoneCall.isCallConnected
                ) && (
                  <>
                    {phoneCall.person !== null &&
                      phoneCall.person?.case({
                        fulfilled: (response) => {
                          return (
                            <>
                              <div className="contact-name">
                                {response?.data?.firstName}{' '}
                                {response?.data?.lastName}
                              </div>
                              {response?.data?.email && (
                                <div className="contact-number">
                                  {response?.data?.email}
                                </div>
                              )}
                              <div className="contact-number">
                                {response?.data?.lines &&
                                response.data.formatNumber
                                  ? response?.data?.formatNumber()
                                  : ''}
                              </div>
                            </>
                          );
                        },
                      })}
                    {!phoneCall.person && extrContact ? (
                      <>
                        <div className="contact-name">
                          {extrContact.DisplayName()}
                        </div>
                        {extrContact.emails?.length > 0 && (
                          <div className="contact-number">
                            {extrContact.emails[0]?.email}
                          </div>
                        )}
                        <div className="contact-number">
                          {formatNumberWithNationalCode(phoneCall.phoneNumber)}
                        </div>
                      </>
                    ) : phoneCall.contact !== null ? (
                      phoneCall.contact?.case({
                        fulfilled: (response) => {
                          const callerIdName =
                            phoneCall.callerIdName ||
                            response?.data?.FormattedPhone;
                          return (
                            <>
                              <div className="contact-name">{callerIdName}</div>
                              {/* TODO: since we have to push this to proudction we decided to comment this code out and once product realizes what they wanna do we will come back to this and fix it */}
                              {/* <div className="contact-name"> {response.data.DisplayName ? response.data.DisplayName : "Unknown Caller"} </div> */}
                              {!isPhoneNumber(callerIdName) && (
                                <div className="contact-number">
                                  {response?.data?.FormattedPhone}
                                </div>
                              )}
                            </>
                          );
                        },
                      })
                    ) : (
                      phoneCall.callerIdName &&
                      !phoneCall.person && (
                        <>
                          <div className="contact-name">
                            {phoneCall.callerIdName}
                          </div>
                          {!isPhoneNumber(phoneCall.callerIdName) && (
                            <div className="contact-number">
                              {formatNumberWithNationalCode(
                                phoneCall.phoneNumber
                              )}
                            </div>
                          )}
                        </>
                      )
                    )}
                  </>
                )}

                {phoneCall && phoneCall.isIncomingCall && callIsInTransit && (
                  <div className="transit-call-status">
                    <span>
                      {phoneCall.isCallConnecting
                        ? 'incoming call..'
                        : 'connecting...'}
                    </span>
                  </div>
                )}
                {!phoneCall.isIncomingCall && !phoneCall.isCallConnected && (
                  <div className="transit-call-status">
                    <span>
                      {phoneCall.isCallConnecting
                        ? 'calling...'
                        : 'connecting...'}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="btn-wrapper">
              {phoneCall && !phoneCall.isCallConnected && (
                <CallButtons
                  inbound={phoneCall.isIncomingCall}
                  lineActions={this}
                  isWebRTCConnected={isWebRTCConnected}
                />
              )}
              {phoneCall &&
                phoneCall.isCallConnected &&
                phoneCall.isMediaConnected && (
                  <CallActiveButtons
                    loggedInAccountId={loggedInAccountId}
                    phoneCallModel={phoneCall}
                    transferToConference={transferToConference}
                    muteCall={this.muteCall}
                    toggleDialPad={phoneCall.toggleDialPad}
                    holdCall={this.holdCall}
                    transferCall={this.transferCall}
                    endCall={this.endCall}
                    loadOrCreateConversationWithPost={
                      loadOrCreateConversationWithPost
                    }
                    postConferenceByConversationId={
                      postConferenceByConversationId
                    }
                    oldSession={oldSession}
                    loggedInUserActiveConferenceConversation={
                      loggedInUserActiveConferenceConversation
                    }
                    ConferenceIdGuid={conferenceIdGuid}
                    selectPeopleOrContactsFromConferenceRoom={
                      selectPeopleOrContactsFromConferenceRoom
                    }
                    conferenceRoomsIdAndNumbers={conferenceRoomsIdAndNumbers}
                  />
                )}
            </div>
          </div>
        }
        <audio
          key="KeyPressTone0"
          ref={(elem) => (phoneCall.keyPressTone0 = elem)}
          src={keypad0}
          id="KeyPressTone0-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone1"
          ref={(elem) => (phoneCall.keyPressTone1 = elem)}
          src={keypad1}
          id="KeyPressTone1-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone2"
          ref={(elem) => (phoneCall.keyPressTone2 = elem)}
          src={keypad2}
          id="KeyPressTone2-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone3"
          ref={(elem) => (phoneCall.keyPressTone3 = elem)}
          src={keypad3}
          id="KeyPressTone3-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone4"
          ref={(elem) => (phoneCall.keyPressTone4 = elem)}
          src={keypad4}
          id="KeyPressTone4-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone5"
          ref={(elem) => (phoneCall.keyPressTone5 = elem)}
          src={keypad5}
          id="KeyPressTone5-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone6"
          ref={(elem) => (phoneCall.keyPressTone6 = elem)}
          src={keypad6}
          id="KeyPressTone6-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone7"
          ref={(elem) => (phoneCall.keyPressTone7 = elem)}
          src={keypad7}
          id="KeyPressTone7-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone8"
          ref={(elem) => (phoneCall.keyPressTone8 = elem)}
          src={keypad8}
          id="KeyPressTone8-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTone9"
          ref={(elem) => (phoneCall.keyPressTone9 = elem)}
          src={keypad9}
          id="KeyPressTone9-audio"
          preload="auto"
        />
        <audio
          key="KeyPressTonePound"
          ref={(elem) => (phoneCall.keyPressTonePound = elem)}
          src={keypadPound}
          id="KeyPressTonePound-audio"
          preload="auto"
        />
        <audio
          key="KeyPressToneStar"
          ref={(elem) => (phoneCall.keyPressToneStar = elem)}
          src={keypadAsterisk}
          id="KeyPressToneStar-audio"
          preload="auto"
        />
      </div>
    );
  }
}
export default withRouter(observer(CallActive));
