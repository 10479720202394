import { TextLink } from 'Components/shared/TextLink';
import { IS_ELECTRON } from 'Constants/env';
import React from 'react';
import { ParserRule, ReactOutputRule } from 'simple-markdown';
import { electronOpenExternal } from 'Utils/electronHelpers';

export const buildAudioLink = (): ParserRule & ReactOutputRule => ({
  order: 1,
  match: (matchSrc) =>
    RegExp(/^(https?:\/\/.*\.(?:mp3|ogg|wav|flac).*)/).exec(matchSrc),
  parse: (capture) => ({ target: capture[0] }),
  react: (node, _output, state) => (
    <>
      <TextLink
        key={state.key + '_a'}
        testid={`markdownCompiler-linkAudio-${state.key}`}
        underline
        {...(IS_ELECTRON
          ? { 'data-ext-url': node.target, onClick: electronOpenExternal }
          : { href: node.target })}
      >
        {node.target}
      </TextLink>
      <br />
      <audio
        key={state.key}
        className={'inline-audio'}
        controls
        onError={(e) => (e.currentTarget.style.display = 'none')}
      >
        <source src={node.target} />
        Your browser does not support the audio element.
      </audio>
    </>
  ),
});
