import type { PinTextButtonProps } from './types';
import React from 'react';
import { Styled } from './PinTextButton.styles';

export const PinTextButton: React.FC<PinTextButtonProps> = ({
  isOwnMessage,
  pinnedBy,
  testid,
  togglePinMessage,
}) => {
  const [pinText, setPinText] = React.useState('');

  React.useEffect(() => setPinText(`Pinned by ${pinnedBy}`), [pinnedBy]);

  return (
    <Styled.PinBox
      onMouseEnter={() => setPinText('Click to unpin')}
      onMouseLeave={() => setPinText(`Pinned by ${pinnedBy}`)}
      onClick={togglePinMessage}
      $isOwnMessage={isOwnMessage}
      data-testid={`${testid}-buttonPinBox`}
    >
      <Styled.Icon size="mini" icon="pin-filled" />
      <Styled.BodyCopy ellipsis noMargin variant="subtitles">
        {pinText}
      </Styled.BodyCopy>
    </Styled.PinBox>
  );
};
