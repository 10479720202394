import styled, { css } from 'styled-components';
import { Input } from 'semantic-ui-react';

const Wrapper = styled.div`
  max-width: 600px;
  margin-bottom: 10px !important;
`;

const StyledInput = styled(Input)`
  & input {
    background-color: transparent !important;
    border-radius: 5px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ theme }) => css`
      border-color: ${theme.colors.border} !important;
      color: ${theme.colors.text} !important;
      &::placeholder {
        color: ${theme.colors.gray} !important;
      }
    `}
  }
`;

export const Styled = {
  Wrapper,
  Input: StyledInput,
};
