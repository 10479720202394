import styled from 'styled-components';
import { UserAvatar } from '../shared/UserAvatar';

const EntityProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledUserAvatar = styled(UserAvatar)`
  width: 80px;
  height: 80px;
  font-size: 18px;
`;

const DisplayName = styled.div`
  font-size: 22px;
  font-size: 1.75em;
  text-align: center;
  margin-top: 18px;
`;

export const Styled = {
  EntityProfileHeader,
  UserAvatar: StyledUserAvatar,
  DisplayName,
};
