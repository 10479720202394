import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const GridColumn = styled(Grid.Column)`
  max-width: unset !important;
  width: 1px !important;
  position: unset !important;
  flex: 1 0 auto;
`;

export const Styled = {
  GridColumn,
};
