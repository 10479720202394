import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import { SuccessScreenProps } from './interfaces';

const closeBtn = require('../../assets/images/close-btn.svg');
const successIcon = require('../../assets/images/success-icon.svg');

class SuccessScreenSource extends React.Component<SuccessScreenProps> {
  closeView = () => {
    const redirectTo =
      this.props.typeOfAddedAction === 'calendars'
        ? '/calendar/calendars'
        : '/addressBook/sources';
    this.props.navigate(redirectTo);
    this.props.person.setAddedNewSource({ show: false, sourceProvider: '' });
  };

  handleClick = () => {
    const newSource =
      this.props.person.allSources[this.props.person.allSources.length - 1];
    if (this.props.typeOfAddedAction === 'calendars') {
      this.props.navigate('/calendar/events');
      this.props.person.setAddedNewSource({ show: false, sourceProvider: '' });
      return;
    }
    newSource && this.props.showContactsBasedOnSource(newSource)();
    this.props.person.setAddedNewSource({ show: false, sourceProvider: '' });
  };

  render() {
    const { typeOfAddedAction } = this.props;
    return (
      <div className="success-screen">
        <img src={successIcon} />
        {this.props.alreadyAddedMessage ? (
          <div className="success-message">
            {this.props.alreadyAddedMessage}
          </div>
        ) : (
          <>
            <div className="success-message">
              You have successfully connected new <b>{this.props.provider}</b>{' '}
              account
            </div>
            <div className="wait-message">
              Your {typeOfAddedAction} are getting synchronised... This might
              take few minutes.
            </div>
          </>
        )}
        <img className="close-btn" src={closeBtn} onClick={this.closeView} />
        <Button
          onClick={this.handleClick}
          content={`VIEW ${typeOfAddedAction.toUpperCase()}`}
          primary
          className="link flex-grow-shrink"
        />
      </div>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(SuccessScreenSource)));
