import { Button } from 'Components/shared/Button';
import { Heading } from 'Components/shared/Heading';
import { Modal as SemanticModal } from 'semantic-ui-react';
import styled from 'styled-components';

const Modal = styled(SemanticModal)`
  padding: 70px 46px 56px 37px;
`;

const TextContainer = styled(Heading)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text} !important;
  text-align: center;
`;

const CloseButtonContainer = styled.div`
  position: absolute !important;
  top: 32px !important;
  right: 32px !important;
`;

const ActionButtonsContainer = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
`;

const CancelButton = styled(Button)`
  border: 1px solid #0099ad !important;
  margin-left: 24px !important;
`;

export const Styled = {
  Modal,
  TextContainer,
  CloseButtonContainer,
  ActionButtonsContainer,
  CancelButton
}