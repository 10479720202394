import type { ButtonManageSocialAccountProps } from './types';
import React from 'react';

import { Styled } from './index.styles';

export const ButtonManageSocialAccount: React.FC<
  ButtonManageSocialAccountProps
> = ({ action, disabled = false, icons, onClick, testid }) => (
  <Styled.Actions>
    <Styled.Action $disabled={disabled} {...{ onClick }} data-testid={testid}>
      <Styled.ActionText $disabled={disabled}>{action}</Styled.ActionText>
      <Styled.IconContainer>
        <Styled.ActionIcon
          icon={disabled ? icons?.disabled : icons?.enabled}
        ></Styled.ActionIcon>
      </Styled.IconContainer>
    </Styled.Action>
  </Styled.Actions>
);
