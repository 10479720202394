import type { ChatLayoutHeaderActionsProps } from './types';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { IconButton } from 'Components/shared/IconButton';
import { PopOverMenu } from 'Components/shared/PopOverMenu';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { placeVideoCallConference } from 'Utils/placeVideoCallConference';
import { ChatLayoutHeaderMoreOptions } from './ChatLayoutHeaderMoreOptions';
import { Styled } from './index.styles';

export const ChatLayoutHeaderActions: React.FC<ChatLayoutHeaderActionsProps> =
  observer(
    ({
      call,
      conversationId,
      isGroupChat,
      isAdHocGroupChat,
      isOwnConversation = false,
      personDetails,
      testid: parentTestid,
      video,
    }) => {
      const {
        conversation,
        phoneCall: { callWithPerson },
        ui: {
          listOfPinnedMessages,
          openedRightSidebarsOrder,
          removeFromOpenedRightSidebarsOrder,
          setOpenedRightSidebarsOrder,
        },
        person: { setShowPersonDetails },
      } = React.useContext<RootStoreProps>(MobXProviderContext);

      const { view, setView } = React.useContext(ChatLayoutContext);
      const testid = `${parentTestid}-chatLayoutHeaderActions`;

      const [showDropdown, setShowDropdown] = React.useState(false);

      const placeCall = () => {
        call.type === 'personId'
          ? callWithPerson(parseInt(call.value), undefined)
          : callWithPerson(undefined, call.value);

        pushToGTMDataLayer('placeCall', {
          internalUser: true,
          callDestination: call.value,
          conversationId,
        });
      };

      const isPinned = view === 'pinned-messages';
      const pinnedMessagesCounter = listOfPinnedMessages
        ?.get(conversationId)
        ?.filter(
          ({ systemEvent, call, conference }) =>
            !(systemEvent?.eventType || call?.text || conference?.displayName)
        )?.length;

      React.useEffect(() => {
        if (Number.isFinite(openedRightSidebarsOrder.get('sidebar-info'))) {
          if (personDetails) {
            setShowPersonDetails(personDetails.id, personDetails.type);
          }
          if (isGroupChat) {
            setShowPersonDetails(null, null);
            removeFromOpenedRightSidebarsOrder('sidebar-info');

            conversation.setChannelInfoDetails(conversationId, 'channel');
            setOpenedRightSidebarsOrder('sidebar-info');
          }
        }
      }, [openedRightSidebarsOrder, personDetails, isGroupChat]);

      return (
        <Styled.Wrapper>
          {conversation?.CurrentConversation?.isActiveParticipant && (
            <>
              {video && (
                <Styled.IconWrapper>
                  <IconButton
                    icon="video"
                    variant="secondary"
                    onClick={() =>
                      void placeVideoCallConference({
                        conversation,
                        conversationId,
                      })
                    }
                    disabled={{
                      title: 'Video conference unavailable',
                      condition: video?.disabled,
                    }}
                    size="medium"
                    testid={`${testid}-buttonVideo`}
                  />
                  <BodyCopy noMargin>Video</BodyCopy>
                </Styled.IconWrapper>
              )}
              {call && (
                <Styled.IconWrapper>
                  <IconButton
                    icon="call"
                    variant="secondary"
                    onClick={placeCall}
                    size="medium"
                    testid={`${testid}-buttonCall`}
                  />
                  <BodyCopy noMargin>Call</BodyCopy>
                </Styled.IconWrapper>
              )}
              {!!pinnedMessagesCounter && (
                <Styled.IconWrapper>
                  <IconButton
                    icon="pin"
                    variant="secondary"
                    active={isPinned}
                    onClick={() => setView(isPinned ? '' : 'pinned-messages')}
                    size="medium"
                    testid={`${testid}-buttonViewPinnedMessages`}
                    counter={pinnedMessagesCounter}
                  />
                  <BodyCopy noMargin>{isPinned ? 'Hide' : 'View'}</BodyCopy>
                </Styled.IconWrapper>
              )}
            </>
          )}

          {!isOwnConversation && (
            <PopOverMenu
              trigger={
                <Styled.IconWrapper>
                  <IconButton
                    icon="more"
                    variant="secondary"
                    onClick={() => setShowDropdown(!showDropdown)}
                    size="medium"
                    testid={`${testid}-buttonMore`}
                  />
                  <BodyCopy noMargin>More</BodyCopy>
                </Styled.IconWrapper>
              }
              content={
                <ChatLayoutHeaderMoreOptions
                  {...{
                    conversationId,
                    isGroupChat,
                    isAdHocGroupChat,
                    personDetails,
                    setShowDropdown,
                    testid,
                  }}
                />
              }
              show={showDropdown}
              onOutsideClick={() => setShowDropdown(false)}
              position="bottom right"
            />
          )}
        </Styled.Wrapper>
      );
    }
  );
