import closeBtn from 'Assets/images/close-btn.svg';
import ChannelEditObserver from 'Components/ChannelEdit';
import ChannelMembersObserver from 'Components/ChannelMembers'; // default export wrapped with `@observer`
import ChannelViewObserver from 'Components/ChannelView';
import CloseButton from 'Components/CloseButton';
import { isEmpty } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import { ConversationModel } from 'Models/index';
import * as React from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader, Message, Modal, Segment } from 'semantic-ui-react';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { resolveConversationPath } from 'Utils/routeNav';
import { ChannelProps, ChannelStoreProps } from './interfaces';

export const Channel: React.FC<ChannelProps> = observer(({ from }) => {
  const {
    conversation,
    participant,
    ui,
    person,
    phoneCall,
    notification,
    contact,
  } = useContext<ChannelStoreProps>(MobXProviderContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { channelConversationId, channelMode } = useParams();

  /** If `this.activeConversationId` is '0', this will contain the newly created `Conversation` */
  const [newConversation, setNewConversation] = useState<ConversationModel>();

  /** The currently active `Conversation`. If a new `Conversation` is being created, this will be '0', otherwise, the GUID of the `Conversation` */
  const [activeConversationId, setActiveConversationId] =
    useState<string>('-1');

  /** Whether this `Channel` container is in Edit mode (displaying `ChannelEdit`), or if false, View mode (displaying `ChannelView`)  */
  const [editing, setEditing] = useState<boolean>(false);

  const ActiveConversation = useMemo(() => {
    return activeConversationId === '0'
      ? newConversation
      : (conversation.conversationByIdMap.has(activeConversationId) &&
          conversation.conversationByIdMap.get(activeConversationId).case({
            fulfilled: (conv) => conv.data,
          })) ||
          undefined;
  }, [activeConversationId, newConversation, conversation]);

  const saveClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    // add to store
    // add participants to Participant store
    if (activeConversationId === '0') {
      // TODO: Real `Person` Ids
      const createConvPromise = conversation.createConversationPost(
        ActiveConversation?.unsavedTopic,
        ActiveConversation?.description,
        ActiveConversation?.grouping,
        ...participant.NewConversationParticipantPartials
      );
      return createConvPromise
        .then((resp) => {
          setActiveConversationId(resp.data.id as string);
          participant.resetNewConversationPersons();
          navigate(`/chat/conversations/${resp.data.id}/menu`);
          ui.setSelectedTopBarUsers(null);
          return resp;
        })
        .catch((e) => {
          return null;
        });
    } else {
      // Update existing Conversation
      const s = ActiveConversation;
      const unsavedTopic = s.unsavedTopic !== s.topic ? s.unsavedTopic : '';
      return conversation
        .updateConversationPatch(
          ActiveConversation.id,
          unsavedTopic,
          ActiveConversation.description
        )
        .then((resp) => {
          if (resp) {
            // Report `Channel` edit here, because `updateConversationPatch` can be used for either `OneOnOne` or `Channel` without distinction
            pushToGTMDataLayer('channelEdit', {
              conversationId: activeConversationId,
            });
            ui.setGroupModal(false);
          }
          return resp;
        })
        .catch((ex) => {
          if (ex.response.status === 304) {
            ui.setGroupModal(false);
          }
          return ActiveConversation;
        });
    }
  };

  const addChannelMember = (personId: number[]) => {
    if (
      channelMode === 'new' ||
      conversation.channelInfoDetails.channelMode === 'new'
    ) {
      personId.forEach((p) => participant.addPersonToNewConversation(p));
      return Promise.resolve(true);
    } else {
      return participant.createConversationParticipant(
        channelConversationId ||
          conversation.channelInfoDetails.channelConversationId,
        personId
      );
    }
  };

  const makeCall = (personId?: number, phone?: string) => {
    phoneCall.callWithPerson(personId, phone);
    resolveConversationPath(location.pathname, 'menu');

    if (personId) {
      conversation.loadOrCreateConversationWithPost(personId, undefined);
    } else {
      conversation.loadOrCreateConversationWithPost(undefined, phone);
    }
  };

  /**
   * Helper for descendants to resolve a pathname, conditionally including the `/conversations/{id}` if it was already present.
   * Use this so you don't have to pass `location` through the descendant tree
   */
  const resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(location.pathname, path);
  };

  const pushHistoryPath = (path: string) => {
    navigate(path);
  };

  const handleCloseButton = (from: 'group-info' | 'new-group', path?) => {
    if (from === 'group-info') {
      conversation.setChannelInfoDetails('0', 'new');
      conversation.clearNameTaken();
      ui.removeFromOpenedRightSidebarsOrder('sidebar-info');
    } else {
      navigate(path);
    }
  };

  const handleCloseGroupModal = () => {
    const { setGroupModal, setActiveConversationIdPinnMess } = ui;
    setGroupModal(false);
    setActiveConversationIdPinnMess('');
    conversation.clearNameTaken();
  };

  // componentWillMount
  const [mounted, setMounted] = useState(false);
  if (!mounted) {
    setMounted(true);
    if (
      channelMode === 'new' ||
      conversation.channelInfoDetails.channelMode === 'new' ||
      location.pathname.includes('channel/0/new')
    ) {
      setNewConversation(ConversationModel.NewChannel(person.loggedInPersonId));
      setActiveConversationId('0');
      participant.resetNewConversationPersons();
    } else {
      if (location.pathname.includes('chat')) {
        conversation.loadConversationByIdIfMissingGet(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        setActiveConversationId(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        // Load the `Participant`s in this `Conversation` if it is not already loading (or if it hasn't been loaded yet)
        participant.loadConversationParticipantsIfMissing(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
      }
    }

    setEditing(channelMode === 'new' || channelMode === 'edit');
  }

  // componentDidUpdate
  const mountedRef = useRef(false);
  useEffect(() => {
    if (mountedRef.current) {
      if (
        channelMode === 'new' ||
        conversation.channelInfoDetails.channelMode === 'new' ||
        location.pathname.includes('channel/0/new')
      ) {
        setNewConversation(
          ConversationModel.NewChannel(person.loggedInPersonId)
        );
        setActiveConversationId('0');
        participant.resetNewConversationPersons();
      } else {
        conversation.loadConversationByIdIfMissingGet(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        setActiveConversationId(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
        // Load the `Participant`s in this `Conversation` if it is not already loading (or if it hasn't been loaded yet)
        participant.loadConversationParticipantsIfMissing(
          channelConversationId ||
            conversation.channelInfoDetails.channelConversationId
        );
      }

      setEditing(channelMode === 'new' || channelMode === 'edit');
    } else {
      mountedRef.current = true;
    }
  }, [channelConversationId]);

  const channelMembersSharedProps = {
    addAxiosErrorNotification: notification.addAxiosErrorNotification,
    addNotification: notification.addNotification,
    addWebNotification: notification.addWebNotification,
    closeAllMessageCreatedWebNotifications:
      notification.closeAllMessageCreatedWebNotifications,
    closeWebNotification: notification.closeWebNotification,
    addChannelMember: addChannelMember,
    channelCandidates: participant.selectPersonsNotInConversation(
      channelConversationId ||
        conversation.channelInfoDetails.channelConversationId
    ),
    conversationId:
      channelConversationId ||
      conversation.channelInfoDetails.channelConversationId,
    getPerson: person.selectPersonById,
    makeCall: makeCall,
    pushHistoryPath: pushHistoryPath,
    removeChannelMembers: participant.removeParticipantsDelete,
    resolveConversationLinkPath: resolveConversationLinkPath,
    selectUnreadCounts: ui.selectConversationUnreadCounts,
    setConversationAndTotalUnreadCount: ui.setConversationAndTotalUnreadCount,
    updateMyLastReadMessage: participant.updateMyLastReadMessage,
  };

  if (
    activeConversationId !==
    conversation.channelInfoDetails.channelConversationId
  ) {
    setActiveConversationId(
      conversation.channelInfoDetails.channelConversationId
    );
  }

  const participantsByConversationId =
    participant.selectParticipantsByConversationId(
      channelConversationId ||
        conversation.channelInfoDetails.channelConversationId
    );

  return from === 'group-info' ? (
    <>
      <Segment
        id="channel-edit-view-wrap"
        className="no-vmargin no-hpadding flex-shrink"
        clearing
        basic
      >
        <CloseButton
          onClose={(from: 'group-info' | 'new-group', path: string) => {
            handleCloseButton(from, path);
          }}
          pathName={location.pathname}
          from={from || 'new-group'}
        />
        <ChannelViewObserver
          conversation={ActiveConversation}
          conversationId={activeConversationId}
          key="channel-view"
          loggedInAccountId={person.loggedInAccountId}
          loggedInUserActiveConferenceConversation={
            conversation.LoggedInUserActiveConferenceConversation
          }
          postConferenceByConversationId={
            conversation.postConferenceByConversationId
          }
          resolveConversationLinkPath={resolveConversationLinkPath}
          handleCloseButton={handleCloseButton}
          setGroupModal={ui.setGroupModal}
          setChannelInfoDetails={conversation.setChannelInfoDetails}
          setMoreActionOptions={ui.setMoreActionOptions}
          setOpeningGroupModalFrom={ui.setOpeningGroupModalFrom}
          loggedInPersonVideoFeature={person.personAvaliableFeatures.video}
        />
      </Segment>
      {channelMode !== 'new' &&
        conversation &&
        participantsByConversationId &&
        participantsByConversationId.case({
          pending: () => <Loader active indeterminate />,
          rejected: (reason) => (
            <Message
              visible
              error
              content={`Failed to load Conversation Participants: ${reason.response.data.message}`}
            />
          ),
          fulfilled: (resp) => (
            <ChannelMembersObserver
              key="channel-members-existing"
              {...channelMembersSharedProps}
              isNew={false}
              loggedInPersonId={person.loggedInPersonId}
              participants={resp.data.results}
              uiStore={ui}
              from={from}
              conversation={conversation}
              setShowPersonDetails={person.setShowPersonDetails}
              contact={contact}
              person={person}
              participantStore={participant}
              setDescription={ActiveConversation?.setDescription}
              saveClick={saveClick}
              conversationModel={ActiveConversation}
            />
          ),
        })}
    </>
  ) : (
    <Modal
      open={ui.groupModalOpen}
      onClose={handleCloseGroupModal}
      dimmer="blurring"
      className="group-modal"
      closeOnEscape={true}
      closeOnDimmerClick={true}
    >
      <Modal.Header>
        <div className="title">
          <span>
            {isEmpty(participantsByConversationId)
              ? 'Create Group'
              : 'Edit Group'}
          </span>
        </div>
        <img
          className="close-btn"
          src={closeBtn}
          onClick={handleCloseGroupModal}
        />
      </Modal.Header>
      <Modal.Content>
        <Segment
          id="channel-edit-view-wrap"
          className="no-vmargin no-hpadding flex-shrink"
          clearing
          basic
        >
          {conversation.channelInfoDetails.channelMode === 'new' ||
          channelMode === 'new' ||
          conversation.channelInfoDetails.channelMode === 'edit' ? (
            <ChannelEditObserver
              clearTopicNameTaken={conversation.clearNameTaken.bind(
                conversation
              )}
              conversation={ActiveConversation}
              isNew={channelMode === 'new'}
              key="channel-edit"
              saveClick={saveClick}
              topicNameIsTaken={conversation.topicNameIsTaken.bind(
                conversation
              )}
              topicNameTaken={conversation.topicNameTaken}
              conversationVm={conversation.conversationVm}
              setConversationVm={conversation.setConversationVm}
            />
          ) : (
            <ChannelViewObserver
              setMoreActionOptions={ui.setMoreActionOptions}
              conversation={ActiveConversation}
              conversationId={activeConversationId}
              key="channel-view"
              loggedInAccountId={person.loggedInAccountId}
              loggedInUserActiveConferenceConversation={
                conversation.LoggedInUserActiveConferenceConversation
              }
              postConferenceByConversationId={
                conversation.postConferenceByConversationId
              }
              resolveConversationLinkPath={resolveConversationLinkPath}
              handleCloseButton={handleCloseButton}
              setGroupModal={ui.setGroupModal}
              setChannelInfoDetails={conversation.setChannelInfoDetails}
              setOpeningGroupModalFrom={ui.setOpeningGroupModalFrom}
              loggedInPersonVideoFeature={person.personAvaliableFeatures.video}
            />
          )}
        </Segment>
        {conversation.channelInfoDetails.channelMode === 'edit' &&
          conversation &&
          participantsByConversationId &&
          participantsByConversationId.case({
            pending: () => <Loader active indeterminate />,
            rejected: (reason) => (
              <Message
                visible
                error
                content={`Failed to load Conversation Participants: ${reason.response.data.message}`}
              />
            ),
            fulfilled: (resp) => (
              <ChannelMembersObserver
                conversation={conversation}
                key="channel-members-existing"
                {...channelMembersSharedProps}
                isNew={false}
                loggedInPersonId={person.loggedInPersonId}
                participants={resp.data.results}
                uiStore={ui}
                from={from}
                setShowPersonDetails={person.setShowPersonDetails}
                contact={contact}
                person={person}
                participantStore={participant}
                setDescription={ActiveConversation?.setDescription}
                saveClick={saveClick}
                conversationModel={ActiveConversation}
              />
            ),
          })}
        {(channelMode === 'new' ||
          conversation.channelInfoDetails.channelMode === 'new') && (
          <ChannelMembersObserver
            conversation={conversation}
            key="channel-members-new"
            {...channelMembersSharedProps}
            isNew={true}
            loggedInPersonId={person.loggedInPersonId}
            participants={participant.NewConversationParticipantPartials}
            uiStore={ui}
            from={from}
            setShowPersonDetails={person.setShowPersonDetails}
            contact={contact}
            person={person}
            participantStore={participant}
            setDescription={ActiveConversation?.setDescription}
            saveClick={saveClick}
            conversationModel={ActiveConversation}
          />
        )}
      </Modal.Content>
    </Modal>
  );
});
