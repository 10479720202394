import { Banner } from 'Components/shared/Banner';
import { useNotificationStore } from 'Modules/notification/store/notification';
import React, { useCallback, useEffect } from 'react';
import { NOTIFICATION_CONFIG } from '../config';
import { NotificationStackPosition } from '../NotificationStack/index.types';
import { NotificationProps } from './index.types';

export interface NotificationItemProps {
  notification: NotificationProps;
  position: NotificationStackPosition;
}

export const Notification = ({
  notification,
  position = 'tc',
}: NotificationItemProps): React.ReactElement => {
  const removeNotify = useNotificationStore((state) => state.removeNotify);

  const handleClose = useCallback(() => {
    if (notification?.id) {
      removeNotify(position, notification.id);
    }
  }, [notification, position, removeNotify]);

  useEffect(() => {
    if (notification?.autoDismiss && notification?.autoDismiss > 0) {
      const timer = setTimeout(() => {
        handleClose();
      }, (notification.autoDismiss || NOTIFICATION_CONFIG.DEFAULT_DURATION) * 1000);

      return () => clearTimeout(timer);
    }
  }, [notification, handleClose]);

  if (!notification) {
    return null;
  }

  const showCloseButton =
    notification.dismissible === 'button' ||
    notification.dismissible === 'both' ||
    notification.dismissible === true;

  return (
    <Banner
      id={notification.id?.toString() || ''}
      type={notification.type || 'info'}
      title={notification.title || ''}
      message={notification.message || ''}
      action={notification.action}
      showCloseButton={showCloseButton}
      onClose={handleClose}
    >
      {notification.children}
    </Banner>
  );
};
