import type { ContextHeaderProps } from './interfaces';
import { ChatLayoutHeaderActionsRenderer } from 'Components/ChatLayoutHeader/ChatLayoutHeaderActions/ChatLayoutHeaderActionsRenderer';
import { ChatLayoutHeaderRenderer } from 'Components/ChatLayoutHeader/ChatLayoutHeaderRenderer';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_UI,
} from 'Constants/stores';
import withRouter from 'Hocs/WithRouter';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { resolveConversationPath } from 'Utils/routeNav';

export class ContextHeader extends React.Component<ContextHeaderProps> {
  constructor(props) {
    super(props);
  }

  toggleEmojiPicker = () => {
    const { ui } = this.props;
    ui.setEmojiPickerState({ open: false, editing: false });
  };

  componentWillMount() {
    const {
      participant,
      params: { conversationId },
    } = this.props;
    participant.loadConversationParticipantsIfMissing(conversationId);
  }

  componentDidUpdate(prevProps: ContextHeaderProps) {
    const {
      conversation,
      participant,
      params: { conversationId },
    } = this.props;
    const prevConvId = prevProps.params.conversationId;
    if (conversationId !== prevConvId) {
      conversation.loadConversationByIdIfMissingGet(prevConvId);
      participant.loadConversationParticipantsIfMissing(prevConvId);
    }
  }

  resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(this.props.location.pathname, path);
  };

  updateRoutes = (path) => {
    const { navigate, location } = this.props;
    const newPath = resolveConversationPath(location.pathname, path);
    navigate(newPath);
  };

  render() {
    const {
      params: { conversationId },
    } = this.props;

    return (
      <div
        id="context-header"
        className="flex-row flex-zero"
        onClick={this.toggleEmojiPicker}
      >
        <div data-private className="flex-grow-shrink" id="header-render-wrap">
          <ChatLayoutHeaderRenderer {...{ conversationId }} />
        </div>
        <div className="flex-shrink" id="context-actions-wrap">
          <ChatLayoutHeaderActionsRenderer {...{ conversationId }} />
        </div>
      </div>
    );
  }
}

export default inject(
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_PUSHER
)(withRouter(observer(ContextHeader)));
