
import { Icon } from 'Components/shared/Icon';
import { IconButton } from 'Components/shared/IconButton';
import styled, { css, keyframes } from 'styled-components';

const revealHideAnimation = keyframes`
  from {
    transform: translateY(20px);
    transform-origin: center bottom;
    border-bottom-width: 0px;
    border-radius: 20px;
    margin-bottom: -25px;
  }
  to {
    transform: translateY(0);
    border-bottom-width: 20px;
    border-radius: 10px;
    margin-bottom: -30px;
  }
`;

const Wrapper = styled.div<{ isVisible: boolean, canAnimate: boolean }>`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  grid-row: 1;
  grid-column: 1;
  padding: 0.6em 0.8em;
  background-color: #f4f4f4;
  border-bottom: 0px solid #f4f4f4;
  border-radius: 10px;
  margin-bottom: -25px;
  transform: translateY(20px);
  border-radius: 20px;


  ${({ canAnimate, isVisible }) => canAnimate && css`
    animation: ${isVisible ? css`
            ${revealHideAnimation} 500ms ease-out forwards
          ` : css`
            ${revealHideAnimation} 500ms ease-out reverse forwards
          `};
  `}
`;

const StyledIcon = styled(Icon)`
  margin-top: 1px;
  color: #333e48 !important;
`;

const CloseIconButton = styled(IconButton)`
  .ui.button {
    color: #333e48 !important;
  }
`;

const Text = styled.div`
  flex: 1;
  margin: 0 5px;
  font-size: 11px;
  line-height: ${({ theme }) => theme.sizes.xsmall};
  color: #333e48;
`;

export const Styled = {
  Icon: StyledIcon,
  CloseIconButton,
  Wrapper,
  Text,
};
