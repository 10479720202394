import { BodyCopy } from 'Components/shared/BodyCopy';
import { TextLink } from 'Components/shared/TextLink';
import styled from 'styled-components';

const MessageInputAreaDisabled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 61px;
  width: 100%;

  ::before {
    content: '';
    background-color: ${({ theme }) => theme.colorsV2.border};
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    margin-top: -61px;
  }
`;

const StyledBodyCopy = styled(BodyCopy)`
  text-align: center;
`;

const CenteredTextLink = styled(TextLink)`
  justify-content: center;
  font-size:13px!important; //because of a high hirarchy .less rule
  font-weight: 600;
`;

export const Styled = {
  MessageInputAreaDisabled,
  BodyCopy: StyledBodyCopy,
  CenteredTextLink,
};
