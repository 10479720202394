import type { SecondaryMenuOptionsProps } from './routing.types';

export const ROUTE_PATHS = {
  latestChat: {
    path: '/chat',
  },
  conversation: {
    path: '/chat/conversations/:conversationId/(.*)',
  },
  calls: {
    path: '/calls',
  },
  voicemail: {
    path: '/voicemail',
  },
  fax: {
    path: '/fax',
  },
  video: {
    path: '/video-app',
  },
  calendar: {
    path: '/calendar',
  },
  addressBook: {
    path: '/addressBook',
  },
  adHocGroup: {
    path: '/adHocGroup',
  },
  settings: {
    path: '/settings',
  },
  conversationWithEmail: {
    path: '/email',
  },
  directory: {
    path: '/directory',
  },
};

export const MAIN_TABS = [
  '',
  'addressBook',
  'calendar',
  'calls',
  'directory',
  'fax',
  'settings',
  'video-app',
  'voicemail',
];

export const SETTINGS_SUBPATHS = {
  PROFILE: '/settings/profile',
  GENERAL: '/settings/general',
  PHONE: '/settings/phone',
  VOICEMAILS: '/settings/voicemails',
  NOTIFICATIONS: '/settings/notifications',
  AUDIO_VIDEO: '/settings/audio-video',
  SOCIAL_ACCOUNTS: '/settings/social-accounts',
};

export const SETTINGS_MENU_OPTIONS: SecondaryMenuOptionsProps[] = [
  { disabled: false, to: SETTINGS_SUBPATHS.PROFILE, text: 'Profile' },
  { disabled: false, to: SETTINGS_SUBPATHS.GENERAL, text: 'General' },
  { disabled: true, to: SETTINGS_SUBPATHS.PHONE, text: 'Phone' },
  { disabled: true, to: SETTINGS_SUBPATHS.VOICEMAILS, text: 'Voicemail' },
  {
    disabled: false,
    to: SETTINGS_SUBPATHS.NOTIFICATIONS,
    text: 'Notifications',
  },
  { disabled: false, to: SETTINGS_SUBPATHS.AUDIO_VIDEO, text: 'Audio' },
  {
    disabled: false,
    featureSwitch: 'socialAccounts',
    to: SETTINGS_SUBPATHS.SOCIAL_ACCOUNTS,
    text: 'Social Accounts',
  },
];

export const CALLS_SUBPATHS = {
  ALL: '/calls/all',
  MISSED: '/calls/missed',
  OUTGOING: '/calls/outgoing',
  INCOMING: '/calls/incoming',
};

export const CALLS_SUBMENU_OPTIONS: SecondaryMenuOptionsProps[] = [
  { to: CALLS_SUBPATHS.ALL, text: 'All calls' },
  { to: CALLS_SUBPATHS.MISSED, text: 'Missed calls' },
  { to: CALLS_SUBPATHS.OUTGOING, text: 'Outgoing calls' },
  { to: CALLS_SUBPATHS.INCOMING, text: 'Incoming calls' },
];

export const CALENDAR_SUBPATHS = {
  EVENTS: '/calendar/events',
  CALENDARS: '/calendar/calendars',
};

export const CALENDAR_SUBMENU_OPTIONS: SecondaryMenuOptionsProps[] = [
  { to: CALENDAR_SUBPATHS.EVENTS, text: 'Events' },
  { to: CALENDAR_SUBPATHS.CALENDARS, text: 'Calendars' },
];

export const ADDRESS_BOOK_SUBPATHS = {
  CONTACT: '/addressBook/contact',
  CONTACTS: '/addressBook/contacts',
  SOURCES: '/addressBook/sources',
};

export const ADDRESS_BOOK_SUBMENU_OPTIONS: SecondaryMenuOptionsProps[] = [
  { to: ADDRESS_BOOK_SUBPATHS.CONTACTS, text: 'Contacts' },
  { to: ADDRESS_BOOK_SUBPATHS.SOURCES, text: 'Sources' },
];

export const VIDEO_SUBPATHS = {
  NEW_ROOM: '/video-app/newroom',
  PERSONAL_ROOM: '/video-app/room',
  HISTORY: '/video-app/history',
};

export const VIDEO_SUBMENU_OPTIONS: SecondaryMenuOptionsProps[] = [
  { to: VIDEO_SUBPATHS.PERSONAL_ROOM, text: 'My Personal Room' },
  { to: VIDEO_SUBPATHS.HISTORY, text: 'History' },
];
