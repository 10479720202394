import { BodyCopy } from 'Components/shared/BodyCopy';
import { IconButton } from 'Components/shared/IconButton';
import { TextButton } from 'Components/shared/TextButton';
import styled from 'styled-components';

const AttachmentPillWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 270px;
  margin-right: ${({ theme }) => theme.sizes.xxsmall};
  padding: ${({ theme }) => theme.sizes.xxsmall};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.sizes.small};
  background-color: ${({ theme }) => theme.colors.base};
`;

const FileExtension = styled(BodyCopy)`
  font-weight: 600;
  margin: 0 ${({ theme }) => theme.sizes.xxxsmall};
`;

const StyledTextButton = styled(TextButton)`
  margin-left: auto;
  padding-left: ${({ theme }) => theme.sizes.xxxsmall};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: -${({ theme }) => theme.sizes.xxxsmall};
  top: -${({ theme }) => theme.sizes.xxxsmall};
`;

export const Styled = {
  AttachmentPillWrapper,
  FileExtension,
  TextButton: StyledTextButton,
  IconButton: StyledIconButton,
};
