import cx from 'classnames';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { PersonModel, PhoneCallModel } from '../../models';
import { TransferDirectoryListItemProps } from './interfaces';

const BLIND_TRANSFER = 'Blind Transfer';
const WARM_TRANSFER = 'Warm Transfer';
const VOICEMAIL_TRANSFER = 'Transfer to Voicemail';

import favoritedStar from 'Assets/images/star-gray.svg';

interface ITransferCallDropdownProps {
  markup: any;
  number: number | string;
  phoneCall: PhoneCallModel;
  vmDisabled?: boolean;
}

export class TransferCallDropdown extends React.Component<
  ITransferCallDropdownProps,
  {}
> {
  handleChange = (e, { value }) => {
    e.persist();

    const { phoneCall } = this.props;
    const transferType = e.target.innerText;
    switch (transferType) {
      case BLIND_TRANSFER:
        phoneCall.blindTransfer(value);
        break;
      case WARM_TRANSFER:
        phoneCall.warmTransfer(value);
        break;
      case VOICEMAIL_TRANSFER:
        phoneCall.forwardToVoiceMail(value);
      default:
        break;
    }
  };

  render() {
    const { markup, number, vmDisabled } = this.props;
    const options = [
      { key: 1, text: BLIND_TRANSFER, value: number },
      { key: 2, text: WARM_TRANSFER, value: number, disabled: false },
      { key: 3, text: VOICEMAIL_TRANSFER, value: number, disabled: vmDisabled },
    ];

    return (
      <Dropdown
        trigger={markup}
        className="transfer-call-dropdown"
        options={options}
        icon={null}
        onChange={this.handleChange}
      />
    );
  }
}

export class TransferDirectoryListItem extends React.Component<
  TransferDirectoryListItemProps,
  { hovered: boolean }
> {
  onMouseOver = () => this.setState({ hovered: true });
  onMouseLeave = () => this.setState({ hovered: false });
  state = {
    hovered: false,
  };

  render() {
    const { hovered } = this.state;
    const {
      contact,
      conversationId,
      phoneCall,
      formatNumber,
      selectPersonPresenceStatus,
      favoriteContactsList,
      unknownUser,
      unknownUserNumber,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
    } = this.props;

    if (unknownUser && !isEmpty(unknownUserNumber)) {
      const unknownStatus = selectPersonPresenceStatus(0);
      const unknownUserMarkup = (
        <div className="number">{formatNumber(unknownUserNumber)}</div>
      );

      return (
        <li
          key={'unknown-user'}
          className={cx(
            'list-item-ih flex-row directory-list-item',
            { hovered },
            'pointer'
          )}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
        >
          <UserAvatar
            conversationGrouping="OneOnOne"
            conversationId={conversationId}
            presence={null}
            selectUnreadCounts={selectUnreadCounts}
            setConversationAndTotalUnreadCount={
              setConversationAndTotalUnreadCount
            }
            updateMyLastReadMessage={updateMyLastReadMessage}
          />
          {/* Unsaved Contacts */}
          <div className="content list-item-ih-content flex-grow-shrink">
            <div className="header">Unknown User</div>
            <TransferCallDropdown
              markup={unknownUserMarkup}
              phoneCall={phoneCall}
              number={unknownUserNumber}
              vmDisabled={true}
            />
          </div>
        </li>
      );
    }

    const src = contact.source;
    const presenceStatus = selectPersonPresenceStatus(src.id);
    const isVMDisabled = src.voicemailInboxId === 0;
    const officeNumMarkup = (
      <div className="number">
        {src.extensionNumber
          ? `OFFICE: ${src.extensionNumber}`
          : `OFFICE: ${src.phoneNumber}`}
      </div>
    );
    const mobileNumMarkup = (
      <div className="number">
        {!isEmpty(src.mobileNumber)
          ? `MOBILE: ${formatNumber(src.mobileNumber)}`
          : ''}
      </div>
    );

    return (
      <li
        key={contact.source.id}
        className={cx(
          'list-item-ih flex-row directory-list-item',
          { hovered },
          'pointer'
        )}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        <UserAvatar
          name={contact.source['DisplayName']}
          conversationId={conversationId}
          presence={presenceStatus}
          selectUnreadCounts={selectUnreadCounts}
          setConversationAndTotalUnreadCount={
            setConversationAndTotalUnreadCount
          }
          updateMyLastReadMessage={updateMyLastReadMessage}
        />
        <div className="content list-item-ih-content flex-grow-shrink">
          <div className="header">
            {src instanceof PersonModel
              ? src.firstName + ' ' + src.lastName
              : src.name}
          </div>
          <TransferCallDropdown
            markup={officeNumMarkup}
            phoneCall={phoneCall}
            number={
              src instanceof PersonModel ? `pr${src.id}` : src.phoneNumber
            }
            vmDisabled={src instanceof PersonModel ? isVMDisabled : true}
          />
          <TransferCallDropdown
            markup={mobileNumMarkup}
            phoneCall={phoneCall}
            number={src.mobileNumber}
            vmDisabled={true}
          />
        </div>
        {favoriteContactsList.includes(src.id) && (
          <div className="flex-shrink">
            <img className="tiny-star" src={favoritedStar} />
          </div>
        )}
      </li>
    );
  }
}

export default observer(TransferDirectoryListItem);
