import type {
  ConfirmationModalContextWrapperProps,
  ConfirmationModalMessagesProps,
  ConfirmationModalState,
} from './ConfirmationModalContextWrapper.types';
import { ConfirmationModal } from 'Components/shared/ConfirmationModal';
import * as React from 'react';

import { ConfirmationModalContext } from './ConfirmationModalContext';

const MODAL_MESSAGES: ConfirmationModalMessagesProps = {
  '': { textConfirm: '', textCancel: '', testid: '', description: '' },
  leaveGroup: {
    testid: 'leaveGroupModal',
    textConfirm: 'Leave',
    textCancel: 'Cancel',
    description: (
      <>
        Are you sure you want to leave the conversation? <br />
        You won&apos;t be able to send or receive new messages.
      </>
    ),
  },
  deleteMessage: {
    testid: 'deleteMessageModal',
    textConfirm: 'Delete',
    textCancel: 'Cancel',
    description: (
      <>
        Once deleted you can&apos;t recover this message. <br />
        Are you sure you want to continue?
      </>
    ),
  },
  deleteFileInMessage: {
    testid: 'deleteFileInMessageModal',
    textConfirm: 'Delete',
    textCancel: 'Cancel',
    description: 'Are you sure you want to delete this file?',
  },
};

export const ConfirmationModalContextWrapper: React.FC<
  ConfirmationModalContextWrapperProps
> = ({ children }) => {
  const [{ type, onConfirm }, setShow] = React.useState<ConfirmationModalState>(
    { type: '' }
  );

  return (
    <ConfirmationModalContext.Provider value={{ setShow }}>
      {children}
      <ConfirmationModal
        show={!!type}
        onClose={() => setShow({ type: '' })}
        onConfirm={() => {
          onConfirm?.();
          setShow({ type: '' });
        }}
        textConfirm={MODAL_MESSAGES[type]?.textConfirm}
        textCancel={MODAL_MESSAGES[type]?.textCancel}
        testid={`chatLayoutHeaderActions-${MODAL_MESSAGES[type]?.testid}`}
      >
        {MODAL_MESSAGES[type]?.description}
      </ConfirmationModal>
    </ConfirmationModalContext.Provider>
  );
};
