import type { AxiosResponse } from 'axios';
import { SEARCHABLE_TYPE } from 'Constants/enums';
import { API_ENDPOINTS, VIDEO_URL } from 'Constants/env';
import { uniq } from 'lodash';
import {
  action,
  computed,
  IObservableArray,
  observable,
  ObservableMap,
  runInAction,
  makeObservable,
} from 'mobx';
import { Contact } from 'Models/Contacts';
import { IPrivateConversationConference } from 'Models/ConversationModel';
import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';
import RRule, { RRuleSet, rrulestr } from 'rrule';
import { bugsnagClient } from 'Utils/logUtils';
import API from '../api';
import { PersonModel } from '../models';
import EventModel, {
  EventResponse,
  IEvent,
  IEventOrIUserEvents,
  IEventParticipants,
  IUserEvents,
  SupportedViews,
} from '../models/Calendar';
import { BaseStore } from './BaseStore';
import { RootStore } from './RootStore';

export class CalendarStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable
  personEventList: ObservableMap<{
    items: IUserEvents[];
    from: Moment;
    to: Moment;
  }> = observable.map();

  @observable
  calendarView: SupportedViews = 'week';

  @action
  setCalendarView = (view: SupportedViews) => (this.calendarView = view);

  @observable
  dateFromTo: { from: string; to: string } = {
    from: moment().startOf('week').toLocaleString(),
    to: moment().endOf('week').toLocaleString(),
  };

  @action
  setDateToFrom = (dates: { from: string; to: string }) =>
    (this.dateFromTo = dates);

  @observable
  allCheckedReferences: IObservableArray<IUserEvents> = observable.array();

  @observable
  allEvents: IObservableArray<IEvent> = observable.array();

  @observable
  allEventsAreLoaded = false;

  @observable
  loadingPersonsExtrContacts = false;

  @observable
  selectedEvent: IEvent = null;

  @action
  setSelectedEvent = (event: IEvent) => (this.selectedEvent = event);

  @observable
  openEventModal = false;

  @action
  setEventModal = (state: boolean) => (this.openEventModal = state);

  @observable
  pageNumber = 1;

  @action
  setPageNumber = (number: number) => (this.pageNumber = number);

  @observable
  searchQuery = '';

  @action
  setSearchQuery = (query: string) => (this.searchQuery = query);

  @observable
  successMessageRespond: {
    show: boolean;
    eventTitle: string;
    message: string;
  } = { show: false, eventTitle: '', message: '' };

  @action
  setSuccessMessageRespond = (status: {
    show: boolean;
    eventTitle: string;
    message: string;
  }) => (this.successMessageRespond = status);

  @observable
  pageSize = 20;

  @observable
  allExtrContacts: IObservableArray<Contact> = observable.array();

  @observable
  allPeople: IObservableArray<PersonModel> = observable.array();

  @observable
  mergedPeopleExtrContact: IObservableArray<Contact | PersonModel> =
    observable.array();

  @action
  clearAllData = () => {};

  @observable
  totalPersonHits = 0;

  @observable
  totalExtrContactsHits = 0;

  @computed
  get totalHits() {
    return this.totalPersonHits + this.totalExtrContactsHits;
  }

  @computed
  get hasMoreData() {
    return this.mergedPeopleExtrContact.length !== this.totalHits;
  }

  // Store longest recurring event duration to pad rrule dates calculation.
  // Some events may span multiple days and begin in one of the previous weeks.
  // That's why a padding needs to be added, otherwise the event might not be visible in the current time window.
  recurringEventsCalculationPadding = 0;

  getEventListArray() {
    const nestedEvents =
      (this.personEventList &&
        Array.from(this.personEventList as any, ([name, value]: any) => [
          ...value?.items,
        ])) ||
      [];
    return (
      (
        nestedEvents.map((eventObservable: any) => [...eventObservable]) as any
      ).flat() || []
    );
  }

  @action
  createEvent = async (event: IEvent) => {
    try {
      const eventResp: AxiosResponse<IEvent> = await API.post(
        API_ENDPOINTS.BaseEvents,
        event
      );
      if (eventResp.data) {
        this.eventCreatedSuccesfully(eventResp.data);
        return eventResp;
      }
      return null;
    } catch (err) {
      const errMsg = `Failed to create new Event`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'CalendarStore';
        event.addMetadata('custom', { function: 'createEvents' });
        event.request = err;
      });
      return null;
    }
  };

  mapToConferecingData = (conference: IPrivateConversationConference) => {
    return {
      url: conference.id,
      pin: conference.pin,
      phoneNumbers: conference.dialInInfos.map((phone) => phone.number),
      provider: 'BHive',
    };
  };

  createEventDescription = (
    conferenceName: string,
    conferenceId: string,
    creatorName: string
  ) => {
    return `'${creatorName} is inviting you to a B-hive Video meeting '${conferenceName}'. \nJoin meeting on: ${
      VIDEO_URL + conferenceId
    }'`;
  };

  @action
  loadEventById = async (eventId: number) => {
    try {
      const eventResp: AxiosResponse<IEvent> = await API.get(
        API_ENDPOINTS.EventById(eventId)
      );
      if (eventResp.data) {
        this.eventCreatedSuccesfully(eventResp.data);
        return eventResp;
      }
    } catch (err) {
      const errMsg = `Failed to load Event ${eventId}`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'CalendarStore';
        event.addMetadata('custom', { function: 'loadEventById' });
        event.request = err;
      });
    }
  };

  checkIfLoggedInStatusNo = (event: IEvent) => {
    const currentLoggedIn = event.participants?.find(
      (participant) =>
        participant.platformUserId?.toString() ===
        this.rootStore.personStore.loggedInPersonId.toString()
    );

    return currentLoggedIn && currentLoggedIn?.status === 'no';
  };

  @action
  loadEventsFromTo = async (
    dateFrom: string,
    dateTo: string,
    appendResults?: boolean
  ) => {
    try {
      this.allEventsAreLoaded = false;
      const eventResp: AxiosResponse<any> = await API.get(
        API_ENDPOINTS.eventFromTo(dateFrom, dateTo)
      );
      let data = eventResp.data?.items;
      if (eventResp.data) {
        if (appendResults) {
          data = uniq([...this.allEvents, ...data]);
        }
        this.loadEventsSuccess(data as IEvent[]);
        runInAction(() => (this.allEventsAreLoaded = true));
        return data;
      }
    } catch (err) {
      const errMsg = `Failed to load Event from ${dateFrom} to ${dateTo}`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'CalendarStore';
        event.addMetadata('custom', { function: 'loadEventsFromTo' });
        event.request = err;
      });
    }
  };

  @action
  deleteEventById = async (eventId: number) => {
    try {
      const eventResp: AxiosResponse<IEvent[]> = await API.delete(
        API_ENDPOINTS.EventById(eventId)
      );
      if (eventResp.status === 200) {
        this.handleRemoveSuccess(eventId);
        return true;
      }
    } catch (err) {
      const errMsg = `Failed to delete Event ${eventId}`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'CalendarStore';
        event.addMetadata('custom', { function: 'deleteEventById' });
        event.request = err;
      });
      return false;
    }
  };

  @action
  handleRemoveSuccess = (eventId: number) => {
    const filteredEvents = this.allEvents.filter(
      (event) => event.id !== eventId
    );
    this.allEvents = filteredEvents as any;
  };

  @action
  loadEventsSuccess = (events: IEvent[]) => {
    const listOfEvents: EventModel[] = [];

    this.recurringEventsCalculationPadding =
      this.getLargestRecurringEventDurationInMs(events);

    events.forEach((event) => {
      const eventModel = new EventModel(event);
      if (eventModel.recurrence) {
        const recurrences = this.generateRecurrences(
          eventModel,
          this.recurringEventsCalculationPadding
        );
        listOfEvents.push(...recurrences);
      } else {
        listOfEvents.push(eventModel);
      }
    });
    this.allEvents = observable.array(listOfEvents);
  };

  private generateRecurrences = (
    event: EventModel,
    padding: number
  ): EventModel[] => {
    const allReccuringDates: Date[][] = this.getReccuringEventDates(
      event,
      padding
    );
    const recurrences = this.calculateRecurrences(allReccuringDates, event);
    return recurrences;
  };

  private getLargestRecurringEventDurationInMs = (events: IEvent[]): number => {
    let largestDiffInMs = 0;
    events.forEach((event) => {
      if (!!event.recurrence && !!event.startDate && !!event.endDate) {
        const diffInMs = moment(event.endDate).diff(moment(event.startDate));
        if (diffInMs > largestDiffInMs) {
          largestDiffInMs = diffInMs;
        }
      }
    });
    return largestDiffInMs;
  };

  private calculateRecurrences = (dates: Date[][], event: EventModel) => {
    const [startDates, endDates] = dates;
    return startDates.reduce(
      (listOfEvents: EventModel[], date, index): EventModel[] => {
        const newCopy: EventModel = new EventModel({
          ...event,
          startDate: event.allDay
            ? moment(date).utc(true).toISOString()
            : date.toISOString(),
          recurrence: null,
          endDate: endDates[index]
            ? event.allDay
              ? moment(endDates[index]).utc(true).toISOString()
              : endDates[index].toISOString()
            : null,
          isReccuringEvent: true,
          id: event.id * Math.floor(Math.random() * Date.now()), //generate ID for reccuring events
          parentId: event.id,
        });
        listOfEvents.push(newCopy);
        return listOfEvents;
      },
      []
    );
  };

  @action
  handleEventRespond = (response: EventResponse, eventId: number) => {
    try {
      API.post(API_ENDPOINTS.respondEvent(eventId), {
        response,
      });
      return true;
    } catch (err) {
      const errMsg = `Failed to accept or decline an Event ${eventId}`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'CalendarStore';
        event.addMetadata('custom', { function: 'handleEventRespond' });
        event.request = err;
      });
      return false;
    }
  };

  @action
  eventCreatedSuccesfully = (event: IEvent) => {
    if (
      !this.allEvents.find((existingEvent) => existingEvent.id === event.id)
    ) {
      this.recurringEventsCalculationPadding =
        this.getLargestRecurringEventDurationInMs([...this.allEvents, event]);

      const eventModel = new EventModel(event);
      const eventsToAppend: EventModel[] = [];
      if (eventModel?.recurrence) {
        eventsToAppend.push(
          ...this.generateRecurrences(
            eventModel,
            this.recurringEventsCalculationPadding
          )
        );
      } else {
        eventsToAppend.push(eventModel);
      }

      this.allEvents = observable.array([...this.allEvents, ...eventsToAppend]);
      return true;
    }
  };

  // commented code is related to extrContacts, for now its not supported #DEBUGGER
  // if you are reading this 2 months from 13.12.2021 be free to remove it
  // if removing please make better naming for all related things
  @action
  getPersonsExtrContacts = async (
    query: string,
    searchableTypes: SEARCHABLE_TYPE[] = ['SearchableDetailsPerson'],
    pageSize = 20,
    pageNumber: number = this.pageNumber,
    appendResults = false
  ) => {
    this.loadingPersonsExtrContacts = true;
    //on user input, searchPageNum is default value
    const searchPageNum = query ? 1 : pageNumber;
    const mergedExtrPeople = [];
    try {
      const peopleResp = await API.get(
        API_ENDPOINTS.DirectorySearch(
          query,
          'firstName',
          pageSize,
          searchPageNum
        )
      );
      if (peopleResp.data.people?.length > 0) {
        const people: PersonModel[] = peopleResp.data.people;
        runInAction(() => (this.totalPersonHits = peopleResp.data.hits));
        const peopleList = this.handleSuccessPeople(people, appendResults);
        mergedExtrPeople.push(...peopleList);
      }
      this.mergedPeopleExtrContact = observable.array(mergedExtrPeople);
      this.loadingPersonsExtrContacts = false;
      return mergedExtrPeople;
    } catch (err) {
      this.loadingPersonsExtrContacts = false;
      return null;
    }
  };

  getEventByIdLocaly = (eventId: number) =>
    this.allEvents.find((item) => item.id === eventId);

  handleSuccessPeople = (listOfPeople, appendResult = false) => {
    let data = listOfPeople;
    if (appendResult) {
      data = [...this.allPeople, ...listOfPeople];
    }
    const poeple: PersonModel[] = data.map((item) => {
      const pers = PersonModel.FromResponseDto(item);
      pers['searchableType'] = 'SearchableDetailsPerson';
      return pers;
    });
    this.allPeople = observable.array(poeple);
    return poeple;
  };

  @action
  handleSuccessExtrContacts = async (
    extrContactsList,
    appendResult = false
  ) => {
    let data = extrContactsList;
    if (appendResult) {
      data = [...this.allExtrContacts, ...extrContactsList];
    }
    const newContactListResp = data.map(async (contact) => {
      if (contact.hasProfilePicture && !contact.pictureUrl) {
        return this.rootStore.personStore.loadContactProfilePic(contact);
      } else {
        return Contact.FromResponseDto(contact);
      }
    });
    const newContactList: Contact[] = await Promise.all(newContactListResp);
    this.allExtrContacts = observable.array(newContactList);
    return newContactList;
  };

  @action
  updateEvent = async (event: IEvent) => {
    try {
      const resp: AxiosResponse<IEvent> = await API.put(
        API_ENDPOINTS.EventById(event.id),
        event
      );
      if (resp) {
        const updatedEvent = new EventModel(resp.data);
        this.handleEventUpdatedSuccess(updatedEvent);
        return updatedEvent;
      }
    } catch (err) {
      const errMsg = `Failed to update an Event ${event.id}`;
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'CalendarStore';
        event.addMetadata('custom', { function: 'handleEventRespond' });
        event.request = err;
      });
      return false;
    }
  };

  @action
  handleEventUpdatedSuccess = (event: EventModel) => {
    const [index] = this.getIndexAndEventFromList(event, this.allEvents);
    const newEvents = [...this.allEvents];
    newEvents.splice(index, 1, event);
    this.allEvents = observable.array(newEvents);
  };

  getIndexAndEventFromList = (
    event: IEvent,
    eventList: IEvent[]
  ): [number, IEvent] => {
    const existingEvent = eventList.find((item) => item.id === event.id);
    const indexOfOldEvent = eventList.indexOf(existingEvent);
    return [indexOfOldEvent, existingEvent];
  };

  mapParticipantsToMatchIEvent = (
    ownerEmail: string,
    participants: (Contact | PersonModel)[],
    isDateChanged: boolean
  ) => {
    if (participants.length > 0) {
      return participants.map((participant) => {
        const email =
          participant instanceof PersonModel
            ? participant.email
            : participant.emails[0]
            ? participant.emails
            : '';
        const eventPerson = this.checkIsParticipantInEdit(participant.id);
        const isOwner = ownerEmail === email;
        return {
          email: email,
          id: this.selectedEvent ? eventPerson?.id || null : null,
          name:
            participant instanceof PersonModel
              ? participant.DisplayName
              : participant.DisplayName(),
          platformUserId: !isNaN(participant.id)
            ? Number(participant.id)
            : null,
          status: isDateChanged
            ? isOwner
              ? 'yes'
              : 'noreply'
            : eventPerson?.status,
        } as IEventParticipants;
      });
    }
    return [];
  };

  checkIsParticipantInEdit = (id: number) => {
    const { participants } = this.selectedEvent
      ? this.selectedEvent
      : { participants: [] };
    const participant =
      !isNaN(id) &&
      participants?.find(
        (participant) => participant.platformUserId === Number(id)
      );
    return participant || null;
  };

  getByIdPersonsExtrContacts = (participantIds: string[]) => {
    return this.mergedPeopleExtrContact?.filter((item) =>
      participantIds?.includes(item.id.toString())
    );
  };

  ifEmailInCreateExtrContact = (participantIds: string[]) => {
    return participantIds
      .map((id) => {
        const isEmail = this.rootStore.uiStore.validateEmail(id);
        const email = id;
        if (isEmail) {
          return {
            email: email,
            id: null,
            name: email,
            platformUserId: null,
          };
        }
      })
      .filter(Boolean);
  };

  checkIsOwnerLoggedIn = async (event: IEvent) => {
    const loggedInDetails = await this.rootStore.personStore.loginStatus;
    const splittedEmail = event?.owner?.match(/<(.*?)>/i);
    const cleanedEmail = splittedEmail?.length >= 1 ? splittedEmail[1] : null; // owner prop is in a form of <email> ( Google provider )
    return (
      cleanedEmail === loggedInDetails?.data?.email ||
      event?.owner === loggedInDetails?.data?.email
    );
  };

  getReccuringEventDates = (event: EventModel, paddingInMs: number) => {
    // ignore timezone conversion if event is marked as allDay as this may return incorrect start and end dates
    const rruleTimezoneSpecific = this.originalRRuleSetToTimezoneSpecific(
      event.recurrence.rrule,
      event.start,
      event.end,
      event.allDay ? null : event.recurrence.timezone
    );

    const eventStartDates = this.getStartDates(
      rruleTimezoneSpecific,
      paddingInMs
    );

    const eventDuration = moment.duration(
      moment(event.endDate).diff(moment(event.startDate))
    );
    const eventEndDates = this.getEndDates(eventStartDates, eventDuration);

    return [eventStartDates, eventEndDates];
  };

  private originalRRuleSetToTimezoneSpecific = (
    rrules: string[],
    startDate: Date,
    endDate?: Date,
    timezone?: string
  ): RRuleSet | RRule => {
    const rruleSet: RRuleSet | RRule = rrulestr(rrules.join('\n'));
    // convert dates to local timezone
    let exDatesLocal: string[] = [];
    if (rruleSet instanceof RRuleSet) {
      exDatesLocal = rruleSet
        .exdates()
        .map((date) => this.getDateString(date, timezone));
    }

    const startDateLocalRRuleString = this.getDateString(startDate, timezone);
    const until = rruleSet.options.until
      ? this.getDateString(rruleSet.options.until, timezone)
      : null;

    const rrulesLocal: string[] = [
      `DTSTART${
        timezone ? `;TZID=${timezone}` : ''
      }:${startDateLocalRRuleString}`,
    ];

    if (until) {
      rrulesLocal.push(rrules[0].replace(/UNTIL=\d+T\d+/i, `UNTIL=${until}`));
    } else {
      rrulesLocal.push(rrules[0]);
    }

    if (exDatesLocal?.length > 0) {
      rrulesLocal.push(`EXDATE:${exDatesLocal.join(',')}`);
    }

    return rrulestr(rrulesLocal.join('\n'));
  };

  private getStartDates = (
    rrule: RRuleSet | RRule,
    paddingInMs: number
  ): Date[] => {
    const fromDate = new Date(this.dateFromTo.from);
    fromDate.setMilliseconds(fromDate.getMilliseconds() - paddingInMs);

    const eventStartDatesUTC = rrule.between(
      fromDate,
      new Date(this.dateFromTo.to),
      true
    );

    const offsetMinutes = new Date().getTimezoneOffset();
    const eventStartDatesLocal = eventStartDatesUTC.map((date) =>
      moment(date).add(offsetMinutes, 'minutes').toDate()
    );

    return eventStartDatesLocal;
  };

  private getEndDates = (
    startDates: Date[],
    durationMilliseconds: moment.Duration
  ): Date[] => {
    return startDates.map((date) =>
      moment(date).clone().add(durationMilliseconds, 'milliseconds').toDate()
    );
  };

  private getDateString = (date: Date, timezone: string): string => {
    return timezone
      ? momentTz(date).tz(timezone).format('YYYYMMDDTHHmmss')
      : moment(date).format('YYYYMMDDTHHmmss');
  };

  isInBetween = (
    to: Moment,
    from: Moment,
    eventEndDateMoment: Moment,
    eventStartDateMoment: Moment
  ) => {
    const isEndBeforeEnd =
      to.toDate().getTime() < eventEndDateMoment.toDate().getTime();
    const isEndAfterStart =
      to.toDate().getTime() > eventStartDateMoment.toDate().getTime();
    const isStartAfterStart =
      from.toDate().getTime() > eventStartDateMoment.toDate().getTime();
    const isStartBeforeEnd =
      from.toDate().getTime() < eventEndDateMoment.toDate().getTime();
    const isStartBeforeStart =
      from.toDate().getTime() <= eventStartDateMoment.toDate().getTime();
    const isEndAfterEnd =
      to.toDate().getTime() >= eventEndDateMoment.toDate().getTime();
    return (
      (isEndAfterStart && isEndBeforeEnd) ||
      (isStartAfterStart && isStartBeforeEnd) ||
      (isStartBeforeStart && isEndAfterEnd)
    );
  };

  checkIsBusy = (
    listOfEvents: IEventOrIUserEvents[],
    from: Moment,
    to: Moment
  ) => {
    return listOfEvents.find((event) => {
      const eventStartDateMoment = moment(event.startDate);
      const eventEndDateMoment = moment(event.endDate);
      const isSameAsSelected =
        event.endDate === this.selectedEvent?.endDate &&
        event.startDate === this.selectedEvent?.startDate;
      return (
        !isSameAsSelected &&
        this.isInBetween(to, from, eventEndDateMoment, eventStartDateMoment)
      );
    });
  };

  @observable
  suggestion: { from: Moment; to: Moment } = null;

  @action
  setSuggestion = (from: Moment, to: Moment) =>
    (this.suggestion = { from, to });

  @action
  setSuggestionNull = () => (this.suggestion = null);

  @action
  setPersonsEventListNull = () => {
    this.personEventList = observable.map();
    this.alreadyCheckedEvents = [];
  };

  checkSuggestions = (
    suggestions,
    allEventsArray,
    event,
    refreshSuggestion?: boolean
  ) => {
    let newEventReference: IUserEvents = null;
    const validSuggestions = suggestions
      .map((suggestion) => {
        const { from, to } = suggestion;
        const today = moment();
        const noCheckedEvents = refreshSuggestion
          ? allEventsArray
          : allEventsArray.filter(
              (el) => !this.allCheckedReferences.includes(el)
            );
        const isSuggestionInThePast =
          to.toDate().getTime() < today.toDate().getTime() ||
          from.toDate().getTime() < today.toDate().getTime();
        const notAvailable = this.checkIsBusy(noCheckedEvents, from, to);
        if (
          notAvailable &&
          this.doesntContainEvent(notAvailable) &&
          (this.allCheckedReferences.length < allEventsArray.length ||
            refreshSuggestion)
        ) {
          newEventReference = notAvailable as IUserEvents;
        }
        return notAvailable || isSuggestionInThePast ? null : suggestion;
      })
      .filter(Boolean);
    if (newEventReference) {
      this.allCheckedReferences = observable.array([
        newEventReference,
        ...this.allCheckedReferences,
      ]);
    }
    return [validSuggestions, newEventReference];
  };

  doesntContainEvent = (event) =>
    event &&
    !this.allCheckedReferences.find((eventReference) => {
      return (
        eventReference.startDate === event.startDate &&
        eventReference.endDate === event.endDate
      );
    });

  alreadyCheckedEvents: { from: Moment; to: Moment }[] = [];
  calculateSuggestion = (from: Moment, to: Moment, event) => {
    const startDateMoment = moment(event.startDate);
    const endDateMoment = moment(event.endDate);
    const selectedDuration = moment.duration(to.diff(from));
    const diffMillisecs = selectedDuration.asMilliseconds();
    const suggestionPlus = endDateMoment
      .clone()
      .add(diffMillisecs, 'millisecond');
    const suggestionMinus = startDateMoment
      .clone()
      .subtract(diffMillisecs, 'millisecond');
    const suggestions = [
      { from: suggestionMinus, to: startDateMoment },
      { from: endDateMoment, to: suggestionPlus },
    ];
    const allEventsArray = [...this.getEventListArray()];
    const suggestionGood = this.checkIsSuggestionGood(
      allEventsArray,
      diffMillisecs,
      suggestions,
      from
    );
    if (suggestionGood) {
      return;
    }
    const [areSuggestionsValid, newEventReference] = this.checkSuggestions(
      suggestions,
      allEventsArray,
      event,
      true
    );
    if (areSuggestionsValid.length > 0) {
      this.setSuggestion(
        areSuggestionsValid[0]?.from,
        areSuggestionsValid[0]?.to
      );
      return;
    }
    this.getNextEventCalculate(newEventReference, allEventsArray, from, to);
  };

  getNextEventCalculate = (newEventReference, allEventsArray, from, to) => {
    if (!this.alreadyCheckedEvents.includes(newEventReference)) {
      this.alreadyCheckedEvents.push(newEventReference);
    } else {
      newEventReference = allEventsArray.find(
        (event) => !this.alreadyCheckedEvents.includes(event)
      );
      newEventReference && this.alreadyCheckedEvents.push(newEventReference);
    }
    newEventReference && this.calculateSuggestion(from, to, newEventReference);
  };

  checkIsSuggestionGood = (
    allEventsArray,
    diffMillisecs: number,
    newSuggestions: { from: Moment; to: Moment }[],
    fromRef: Moment
  ) => {
    //+1 minute because when we create suggestion, i am appening one minute to the start.
    const suggestionDuration =
      this.suggestion &&
      moment
        .duration(this.suggestion.to.diff(this.suggestion.from))
        .asMilliseconds() + 60000;
    const isSameDurationAsLastSuggestion = suggestionDuration === diffMillisecs;
    const isSameDay =
      this.suggestion &&
      fromRef.format('YYYY:MM:DD').toString() ===
        this.suggestion.from.format('YYYY:MM:DD').toString();
    if (isSameDurationAsLastSuggestion && isSameDay) {
      const isBusy = this.checkIsBusy(
        allEventsArray,
        this.suggestion.from,
        this.suggestion.to
      );
      this.suggestion && newSuggestions.push(this.suggestion);
      return !isBusy;
    }
    this.allCheckedReferences = observable.array();
    return false;
  };

  getPersonAvailability = async (
    person: PersonModel,
    from: Moment,
    to: Moment,
    allDaySelected?: boolean
  ) => {
    const alreadyFetched = this.personEventList.get(person?.id);
    const isDiffDateThanfetched =
      from.format('YYYY:MM:DD').toString() !==
        alreadyFetched?.from.format('YYYY:MM:DD').toString() ||
      to.format('YYYY:MM:DD').toString() !==
        alreadyFetched?.to.format('YYYY:MM:DD').toString();
    if (
      (person && !alreadyFetched) ||
      isDiffDateThanfetched ||
      allDaySelected
    ) {
      try {
        const resp = await API.get(API_ENDPOINTS.AvailabilityById(person.id), {
          params: {
            personId: person.id,
            from: from.clone().set('hours', 7).toLocaleString(),
            to: to.clone().endOf('day').toLocaleString(),
          },
        });
        if (resp.data) {
          this.personEventList.set(person.id, {
            items: resp.data.items,
            from: from,
            to: to,
          });
          const filterAllFromPast = this.filterEventsFromPast(resp.data.items);
          this.handleSuggestions(
            person,
            filterAllFromPast,
            from,
            to,
            allDaySelected
          );
        }
      } catch (e) {
        const errMsg = `Failed to get availability for person with personId: ${person.id}`;
        bugsnagClient.notify(errMsg, (event) => {
          event.severity = 'error';
          event.context = 'CalendarStore';
          event.addMetadata('custom', { function: 'getPersonAvailability' });
          event.request = e;
        });
        return null;
      }
    } else {
      const data = this.personEventList.get(person.id);
      const filterAllFromPast = this.filterEventsFromPast(data.items);
      this.handleSuggestions(
        person,
        filterAllFromPast,
        from,
        to,
        allDaySelected
      );
    }
    return person;
  };

  filterEventsFromPast = (events: IEventOrIUserEvents[]) => {
    const filteredEvents = events.filter(
      (event: IEvent | IUserEvents) =>
        moment(event.endDate).toDate().getTime() > moment().toDate().getTime()
    );
    return filteredEvents?.filter(Boolean);
  };

  handleSuggestions = (
    person: PersonModel,
    events: IEventOrIUserEvents[],
    from: Moment,
    to: Moment,
    allDaySelected: boolean
  ) => {
    const isBusy = this.checkIsBusy(events, from, to);
    if (isBusy && moment.duration(to.diff(from)).asHours() <= 24) {
      this.calculateSuggestion(from, to, isBusy);
    }
    person.availability = isBusy ? 'Busy' : 'Free';
    allDaySelected && this.setSuggestionNull();
  };
}
export default CalendarStore;
