import copyIcon from 'Assets/images/copy-icon-white.svg';
import downloadIcon from 'Assets/images/download-icon.svg';
import spinner from 'Assets/images/spinner.svg';
import iconDots from 'Assets/images/three-dots-vertical.svg';
import { STORE_CONVERSATION, STORE_UI } from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { RecordingStatus } from '../../../../../../models/VideoHistoryModel';
import { IRecordingVideo } from '../../../../../../models/VideoRecordings';
import { Styled } from './index.styles';
import { RecordingsProps } from './types';

const RecordingsObserver: React.FC<RecordingsProps> = ({
  conversation,
  ui,
  location,
  deleted,
  loadHistoryDetails,
  handleOpenRecordingLink,
}) => {
  const [loadingDeleteResp, setLoadingDeleteResp] = React.useState(false);
  const headers = ['START TIME', 'DURATION', 'FILESIZE', 'ACTIONS'];
  const recording = conversation.videoHistoryDetails?.data.recordings[0];
  const recordingStatus: any = RecordingStatus[recording?.status];
  const isAvailable = recordingStatus === RecordingStatus.Available;
  const isPending = recordingStatus === RecordingStatus.Processing;
  const isError = recordingStatus === RecordingStatus.Error;
  const hasData = conversation.videoHistoryDetails?.data?.recordings.length > 0;
  const isDeleted =
    recordingStatus === RecordingStatus.Deleted ||
    recordingStatus === RecordingStatus.DeletionPending;
  const canUndoDelete = recordingStatus === RecordingStatus.DeletionPending;
  const { personData, date, time } = deleted;

  const handeDownload = (recordingId: string) => () => {
    const historyId = parseInt(location.pathname.split('/').pop());
    const respRecordings = conversation.loadVideoRecordings(
      historyId,
      recordingId,
      false
    );
    void respRecordings.then((recordings: any) => {
      recordings.data.forEach((recording: IRecordingVideo) => {
        ui.handleDownloadWithLink(
          recording.downloadLink,
          `recording-${recordingId}`
        );
      });
    });
  };

  const toggleActionPopup = (e) => {
    e.stopPropagation();
    ui.setDeletePopupDisplay(!ui.deletePopupDisplay);
  };

  const handleDeleteAndUnDeleteRecording =
    (reqType: 'DELETE' | 'UNDELETE') => () => {
      let resp;
      if (!loadingDeleteResp) {
        setLoadingDeleteResp(true);
        if (reqType === 'DELETE') {
          resp = conversation.deleteVideoRecordings(
            conversation.videoHistoryDetails?.data.id,
            conversation.videoHistoryDetails?.data.recordings[0].recordingId
          );
        } else {
          resp = conversation.undoDeleteVideoRecordings(
            conversation.videoHistoryDetails?.data.id,
            conversation.videoHistoryDetails?.data.recordings[0].recordingId
          );
        }
        resp.then(() => {
          loadHistoryDetails();
          ui.setDeletePopupDisplay(false);
          setLoadingDeleteResp(false);
        });
      }
    };

  return (
    <Styled.RecordingsTableStyled>
      <Styled.PageTitleStyled> Recording </Styled.PageTitleStyled>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            {headers.map((text, index) => (
              <Table.HeaderCell
                key={text}
                textAlign={index === 3 ? 'right' : 'left'}
              >
                {text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isAvailable &&
            conversation.videoHistoryDetails?.data.recordings.map((value) => {
              const startTime = moment(value.startTime).format('hh:mm A');
              const duration = ui.formatTimeStamp(value.duration);
              return (
                <Table.Row key={value.recordingId}>
                  <Table.Cell>{startTime}</Table.Cell>
                  <Table.Cell>{duration}</Table.Cell>
                  <Table.Cell>{ui.bytesToSize(value.size)}</Table.Cell>
                  <Table.Cell textAlign="right" className="buttons">
                    <Styled.DownloadStyled
                      title="Download recording"
                      src={downloadIcon}
                      onClick={handeDownload(value.recordingId)}
                    />
                    <Styled.ActionPopupStyled>
                      <img src={iconDots} onClick={toggleActionPopup} />
                      {ui.deletePopupDisplay && (
                        <ul>
                          {conversation.videoHistoryDetails?.data.isAdmin ? (
                            <li
                              onClick={handleDeleteAndUnDeleteRecording(
                                'DELETE'
                              )}
                            >
                              Delete
                            </li>
                          ) : (
                            <li className="disabled-delete">Delete</li>
                          )}
                        </ul>
                      )}
                    </Styled.ActionPopupStyled>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          {isPending && (
            <Styled.PendingRecordStyled>
              <Table.Cell>
                <img src={spinner} className="spinner" />
                <b>Recording is being prepared.. </b> This may take a few
                minutes.
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell textAlign="right" className="no-hpadding" />
            </Styled.PendingRecordStyled>
          )}
          {isError && (
            <>
              <Styled.ErrorRecordStyled>
                <Table.Cell>
                  Error occurred while recoding, potential issues: recording is
                  too short or is without sound.
                </Table.Cell>
              </Styled.ErrorRecordStyled>
            </>
          )}
          {isDeleted && (
            <>
              <Styled.DeletedRecordStyled valign="middle">
                <Table.Cell colSpan={3}>
                  This recording has been deleted by{' '}
                  <b>{personData?.DisplayName}</b> on {date} at {time}.
                </Table.Cell>
                <Table.Cell textAlign="right" className="no-hpadding">
                  {conversation.videoHistoryDetails?.data.isAdmin &&
                    canUndoDelete &&
                    !loadingDeleteResp && (
                      <b
                        className="pointer"
                        onClick={handleDeleteAndUnDeleteRecording('UNDELETE')}
                      >
                        UNDO DELETE
                      </b>
                    )}
                </Table.Cell>
              </Styled.DeletedRecordStyled>
            </>
          )}
          {!hasData && !isPending && (
            <Table.Row>
              <Table.Cell>Meeting was not recorded</Table.Cell>
            </Table.Row>
          )}
          {hasData && (
            <Table.Row>
              <Styled.ShareCellStyled colSpan={4}>
                <Styled.ShareButtonStyled
                  id={`${
                    isDeleted || isPending || !recording?.recordingId
                      ? 'disabled'
                      : ''
                  }`}
                  className={`${
                    isDeleted || isPending || !recording?.recordingId
                      ? 'disabled'
                      : ''
                  }`}
                  content="SHARE RECORDING"
                  onClick={handleOpenRecordingLink(recording?.recordingId)}
                  icon={<img src={copyIcon} />}
                />
              </Styled.ShareCellStyled>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Styled.RecordingsTableStyled>
  );
};

export const Recordings = inject(
  STORE_CONVERSATION,
  STORE_UI
)(observer(RecordingsObserver));
