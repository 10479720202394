import type { CallLogsContextType } from './CallLogsContext';
import type { CallLogsContextWrapperProps } from './types';
import type { CallLogCategoryType } from 'Components/CallLogs/types';
import {
  CALL_LOGS_PER_PAGE,
  CALL_TYPES_CONTENT,
} from 'Components/CallLogs/constants';
import {
  mapImageToCallLogs,
  removeDuplicates,
} from 'Components/CallLogs/utils';
import { API_ENDPOINTS } from 'Constants/env';
import { DateTime } from 'luxon';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import API from '../../../api';
import { CallLogsContext } from './CallLogsContext';

export const CallLogsContextWrapper: React.FC<CallLogsContextWrapperProps> =
  observer(({ children, typeOfCallsId }) => {
    const {
      callLogsStore: {
        firstPageCallLogs,
        setFirstPageCallLogs,
        callLogPusherEvent,
        setLastSyncDate,
      },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const [callLogsLoading, setCallLogsLoading] = React.useState(false);
    const [shouldGetCallLogs, setShouldGetCallLogs] = React.useState(false);
    const [callLogs, setCallLogs] = React.useState<
      CallLogsContextType['callLogs']
    >([]);

    // Retriggers getCallLogs on pusher event
    React.useEffect(() => {
      setShouldGetCallLogs(true);
    }, [callLogPusherEvent?.id]);

    // Fetches and saves that typeOfCallsId's callLogs in cache
    React.useEffect(() => {
      !firstPageCallLogs[typeOfCallsId].length &&
        void getCallLogs(false, '', true);
    }, [typeOfCallsId]);

    const getCallLogs: CallLogsContextType['getCallLogs'] = async (
      appendResults,
      query,
      initialFetch
    ) => {
      try {
        setCallLogsLoading(true);

        // Here because of issue when user is offline, changes back, and goes back online
        const latestTypeOfCallId = window.location.pathname
          .split('/')
          .pop() as CallLogCategoryType;

        const callLogsResults = await API.get(
          API_ENDPOINTS.CallLogsSearch(
            CALL_LOGS_PER_PAGE,
            CALL_TYPES_CONTENT[latestTypeOfCallId].direction,
            CALL_TYPES_CONTENT[latestTypeOfCallId].disposition,
            query || '',
            appendResults ? callLogs.slice(-1)[0]?.id : null
          )
        );

        const callLogsWithImage = mapImageToCallLogs(callLogsResults.data.data);

        initialFetch &&
          setFirstPageCallLogs({
            ...firstPageCallLogs,
            [latestTypeOfCallId]: callLogsWithImage,
          });

        setCallLogs(
          appendResults
            ? removeDuplicates(callLogs, callLogsWithImage)
            : callLogsWithImage
        );

        if (!appendResults) setLastSyncDate(DateTime.now().toString());

        setCallLogsLoading(false);
      } catch (err) {
        throw new Error(err);
      }
    };

    const getCallLogsFromCache = () => {
      firstPageCallLogs[typeOfCallsId].length
        ? setCallLogs(firstPageCallLogs[typeOfCallsId])
        : void getCallLogs(false);
    };

    return (
      <CallLogsContext.Provider
        value={{
          callLogsLoading,
          setCallLogsLoading,
          shouldGetCallLogs,
          setShouldGetCallLogs,
          callLogs,
          getCallLogs,
          typeOfCallsId,
          getCallLogsFromCache,
        }}
      >
        {children}
      </CallLogsContext.Provider>
    );
  });
