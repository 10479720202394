import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';

export type UseMuteConversationProps = (props: {
  conversationId: string;
  currentPersonId?: string;
}) => {
  isMuted: boolean;
  conversationMuteToggle: () => Promise<void>;
};

export const useMuteConversation: UseMuteConversationProps = ({
  conversationId,
  currentPersonId,
}) => {
  const {
    conversation,
    ui: { selectIfConvMuted, setConvMuted },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const [isMuted, setIsMuted] = React.useState(false);

  React.useEffect(() => {
    const checkIsMuted = async () => {
      if (!currentPersonId || conversationId) {
        setIsMuted(selectIfConvMuted(conversationId));
        return;
      }
      const { data } = await conversation.loadOrCreateConversationWithPost(
        currentPersonId
      );
      setIsMuted(selectIfConvMuted(data.id?.toString()));
    };

    void checkIsMuted();
  }, [conversation, conversationId, currentPersonId, selectIfConvMuted]);

  const toggleMuteStatus = (conversationid: string) => {
    const value = selectIfConvMuted(conversationid);
    setConvMuted(conversationid, !value);
    return !value;
  };

  const conversationMuteToggle = async () => {
    if (!currentPersonId) {
      setIsMuted(toggleMuteStatus(conversationId));
      return;
    }

    const { data } = await conversation.loadOrCreateConversationWithPost(
      currentPersonId
    );
    setIsMuted(toggleMuteStatus(data.id?.toString()));
  };

  return { isMuted, conversationMuteToggle };
};
