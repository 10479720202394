import CloseButton from 'Components/CloseButton';
import EntityProfileObserver from 'Components/EntityProfile';
import { CONTACT_TYPE } from 'Constants/enums';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import * as React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { resolveConversationPath } from 'Utils/routeNav';
import withRouter from '../../hocs/WithRouter';
import { Styled } from './index.styles';
import { EntityProfileProps } from './interfaces';

export class EntityProfileContainer extends React.Component<
  EntityProfileProps,
  {}
> {
  backClick = () => {
    const { navigate } = this.props;
    navigate(-1);
  };

  componentDidUpdate(prevProps: EntityProfileProps) {
    const {
      contact,
      person,
      person: {
        showPersonDetails: { type, id },
      },
    } = this.props;
    const prevPropsId = prevProps.person.showPersonDetails.id;
    if (id !== prevPropsId) {
      if (type === 'person') {
        person.loadPersonByIdGetIfMissingGet(parseInt(id.toString(), 10));
      } else {
        contact.loadContactByPhoneNumber(id.toString());
      }
    }
  }

  /**
   * Helper for descendants to resolve a pathname, conditionally including the `/conversations/{id}` if it was already present.
   * Use this so you don't have to pass `location` through the descendant tree
   */
  resolveConversationLinkPath = (path: string) => {
    return resolveConversationPath(this.props.location.pathname, path);
  };

  makeCall = (personId?: number, phone?: string) => {
    const { phoneCall } = this.props;
    if (personId) {
      phoneCall.callWithPerson(personId, null);
    } else if (phone) {
      phoneCall.callWithPerson(null, phone);
    } else {
      throw new Error('Either Phone Number or Person Id is required.');
    }
  };

  messageUser = (
    personId: number,
    phoneNumber: string,
    contactType: CONTACT_TYPE
  ) => {
    const { conversation, navigate } = this.props;

    if (contactType === 'Person') {
      conversation
        .loadOrCreateConversationWithPost(personId)
        .then((resp) => navigate(`/chat/conversations/${resp.data.id}/menu`));
    } else {
      conversation
        .loadOrCreateConversationWithPost(null, phoneNumber)
        .then((resp) => navigate(`/chat/conversations/${resp.data.id}/menu`));
    }
  };

  closeInfoSidebar = () => {
    const { person, ui } = this.props;
    person.setShowPersonDetails(null, null);
    ui.removeFromOpenedRightSidebarsOrder('sidebar-info');
  };

  render() {
    const {
      person,
      contact,
      conversation,
      phoneCall,
      ui,
      location,
      params: { conversationId },
    } = this.props;
    const activeCall = phoneCall.AnyPhoneConnectionActive;
    const largeColumns = 4;
    const smallColumns = !activeCall && !ui.openTopbarDialpad ? 4 : 6;
    const params = {
      id: person.showPersonDetails.id?.toString(),
      type: person.showPersonDetails?.type,
    };
    if (ui.loadingConversationForSidebarInfo)
      return (
        <Styled.InfoSidebarStyled
          $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
          icon="labeled"
          widescreen={largeColumns}
          computer={largeColumns}
          desktop={largeColumns}
          tablet={smallColumns}
          mobile={smallColumns}
          className={`nogrow info-sidebar ${
            person.showPersonDetails.id ? 'visible' : 'hidden'
          }`}
        >
          <Loader active indeterminate />
        </Styled.InfoSidebarStyled>
      );

    if (params.type === 'person') {
      const personPbo = person.loadPersonByIdGetIfMissingGet(
        parseInt(params.id, 10)
      );
      return (
        <Styled.InfoSidebarStyled
          icon="labeled"
          widescreen={largeColumns}
          computer={largeColumns}
          desktop={largeColumns}
          tablet={smallColumns}
          mobile={smallColumns}
          className={`nogrow info-sidebar ${
            person.showPersonDetails.id ? 'visible' : 'hidden'
          }`}
          $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
        >
          {personPbo &&
            personPbo.case({
              pending: () => <Loader active indeterminate />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={`Failed to load Person ${params.id}: ${reason.response.data.message}`}
                />
              ),
              fulfilled: (personResp) => (
                <>
                  <CloseButton
                    onClose={this.closeInfoSidebar}
                    pathName={location.pathname}
                  />
                  <EntityProfileObserver
                    currentConversation={conversation.CurrentConversation}
                    loggedInUserActiveConferenceConversation={
                      conversation.LoggedInUserActiveConferenceConversation
                    }
                    loggedInAccountId={person.loggedInAccountId}
                    loggedInPersonId={person.loggedInPersonId}
                    loadOrCreateConversationWithPost={
                      conversation.loadOrCreateConversationWithPost
                    }
                    messageUser={this.messageUser}
                    makeCall={this.makeCall}
                    person={personResp.data}
                    conversationId={conversationId}
                    postConferenceByConversationId={
                      conversation.postConferenceByConversationId
                    }
                    resolveConversationLinkPath={
                      this.resolveConversationLinkPath
                    }
                    backClick={this.backClick}
                    selectPersonValueById={person.selectPersonValueById}
                    phoneCalls={phoneCall.phoneCalls}
                    uiStore={ui}
                    setIsAddingContact={person.setIsAddingContact}
                    getExtrContactByPhoneNumber={
                      person.getExtrContactByPhoneNumber
                    }
                    loggedInPersonVideoFeature={
                      person.personAvaliableFeatures.video
                    }
                    channelInfoDetails={conversation.channelInfoDetails}
                    allContactByPhone={person.allContactByPhone}
                  />
                </>
              ),
            })}
        </Styled.InfoSidebarStyled>
      );
    } else if (params.type === 'extrContact') {
      const extrContactsPbo = person.getAllContactsWithSameNum(params.id);
      const contactPbo =
        !extrContactsPbo &&
        params.id &&
        contact.loadContactByPhoneNumber(params.id);
      return extrContactsPbo ? (
        <Styled.InfoSidebarStyled
          icon="labeled"
          widescreen={largeColumns}
          computer={largeColumns}
          desktop={largeColumns}
          tablet={smallColumns}
          mobile={smallColumns}
          className={`nogrow info-sidebar ${
            person.showPersonDetails.id ? 'visible' : 'hidden'
          }`}
          $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
        >
          {extrContactsPbo &&
            extrContactsPbo.case({
              pending: () => <Loader active indeterminate />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={`Failed to load Person ${params.id}: ${reason.response.data.message}`}
                />
              ),
              fulfilled: (resp) => {
                const extrContacts = resp.data.items.map(
                  (item) => new Contact(item)
                );
                return (
                  <>
                    <CloseButton
                      onClose={this.closeInfoSidebar}
                      pathName={location.pathname}
                    />
                    <EntityProfileObserver
                      currentConversation={conversation.CurrentConversation}
                      loggedInUserActiveConferenceConversation={
                        conversation.LoggedInUserActiveConferenceConversation
                      }
                      loggedInAccountId={person.loggedInAccountId}
                      loggedInPersonId={person.loggedInPersonId}
                      loadOrCreateConversationWithPost={
                        conversation.loadOrCreateConversationWithPost
                      }
                      messageUser={this.messageUser}
                      makeCall={this.makeCall}
                      extrContacts={extrContacts}
                      conversationId={conversationId}
                      postConferenceByConversationId={
                        conversation.postConferenceByConversationId
                      }
                      resolveConversationLinkPath={
                        this.resolveConversationLinkPath
                      }
                      backClick={this.backClick}
                      selectPersonValueById={person.selectPersonValueById}
                      phoneCalls={phoneCall.phoneCalls}
                      uiStore={ui}
                      setEditContact={person.setEditContact}
                      setIsAddingContact={person.setIsAddingContact}
                      getExtrContactByPhoneNumber={
                        person.getExtrContactByPhoneNumber
                      }
                      loggedInPersonVideoFeature={
                        person.personAvaliableFeatures.video
                      }
                      channelInfoDetails={conversation.channelInfoDetails}
                      allContactByPhone={person.allContactByPhone}
                    />
                  </>
                );
              },
            })}
        </Styled.InfoSidebarStyled>
      ) : (
        <Styled.InfoSidebarStyled
          icon="labeled"
          widescreen={largeColumns}
          computer={largeColumns}
          desktop={largeColumns}
          tablet={smallColumns}
          mobile={smallColumns}
          className={`nogrow info-sidebar ${
            person.showPersonDetails.id ? 'visible' : 'hidden'
          }`}
          $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
        >
          {contactPbo &&
            contactPbo.case({
              pending: () => <Loader active indeterminate />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={`Failed to load Contact ${params.id}: ${reason.response.data.message}`}
                />
              ),
              fulfilled: (contactResp) => (
                <>
                  <CloseButton
                    pathName={location.pathname}
                    onClose={this.closeInfoSidebar}
                  />
                  <EntityProfileObserver
                    currentConversation={conversation.CurrentConversation}
                    loggedInUserActiveConferenceConversation={
                      conversation.LoggedInUserActiveConferenceConversation
                    }
                    loggedInAccountId={person.loggedInAccountId}
                    loggedInPersonId={person.loggedInPersonId}
                    messageUser={this.messageUser}
                    makeCall={this.makeCall}
                    contact={contactResp.data}
                    conversationId={conversationId}
                    postConferenceByConversationId={
                      conversation.postConferenceByConversationId
                    }
                    resolveConversationLinkPath={
                      this.resolveConversationLinkPath
                    }
                    backClick={this.backClick}
                    selectPersonValueById={person.selectPersonValueById}
                    phoneCalls={phoneCall.phoneCalls}
                    uiStore={ui}
                    setIsAddingContact={person.setIsAddingContact}
                    setEditContact={person.setEditContact}
                    getExtrContactByPhoneNumber={
                      person.getExtrContactByPhoneNumber
                    }
                    loggedInPersonVideoFeature={
                      person.personAvaliableFeatures.video
                    }
                    channelInfoDetails={conversation.channelInfoDetails}
                    allContactByPhone={person.allContactByPhone}
                  />
                </>
              ),
            })}
        </Styled.InfoSidebarStyled>
      );
    } else {
      const contactPbo =
        params.id && contact.loadContactByPhoneNumber(params.id);
      return (
        <Styled.InfoSidebarStyled
          icon="labeled"
          widescreen={largeColumns}
          computer={largeColumns}
          desktop={largeColumns}
          tablet={smallColumns}
          mobile={smallColumns}
          className={`nogrow info-sidebar ${
            person.showPersonDetails.id ? 'visible' : 'hidden'
          }`}
          $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
        >
          {contactPbo &&
            contactPbo.case({
              pending: () => <Loader active indeterminate />,
              rejected: (reason) => (
                <Message
                  visible
                  error
                  content={`Failed to load Contact ${params.id}: ${reason.response.data.message}`}
                />
              ),
              fulfilled: (contactResp) => (
                <>
                  <CloseButton
                    pathName={location.pathname}
                    onClose={this.closeInfoSidebar}
                  />
                  <EntityProfileObserver
                    currentConversation={conversation.CurrentConversation}
                    loggedInUserActiveConferenceConversation={
                      conversation.LoggedInUserActiveConferenceConversation
                    }
                    loggedInAccountId={person.loggedInAccountId}
                    loggedInPersonId={person.loggedInPersonId}
                    messageUser={this.messageUser}
                    makeCall={this.makeCall}
                    contact={contactResp.data}
                    conversationId={conversationId}
                    postConferenceByConversationId={
                      conversation.postConferenceByConversationId
                    }
                    resolveConversationLinkPath={
                      this.resolveConversationLinkPath
                    }
                    backClick={this.backClick}
                    selectPersonValueById={person.selectPersonValueById}
                    phoneCalls={phoneCall.phoneCalls}
                    uiStore={ui}
                    setIsAddingContact={person.setIsAddingContact}
                    setEditContact={person.setEditContact}
                    getExtrContactByPhoneNumber={
                      person.getExtrContactByPhoneNumber
                    }
                    loggedInPersonVideoFeature={
                      person.personAvaliableFeatures.video
                    }
                    channelInfoDetails={conversation.channelInfoDetails}
                    allContactByPhone={person.allContactByPhone}
                  />
                </>
              ),
            })}
        </Styled.InfoSidebarStyled>
      );
    }
  }
}

export default inject(
  STORE_MESSAGE,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_UI
)(withRouter(observer(EntityProfileContainer)));
