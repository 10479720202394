import { Button } from 'Components/shared/Button';
import styled from 'styled-components';

const JoinMeetingButton = styled(Button)`
  margin-top: 30px !important;
`

export const Styled = {
  JoinMeetingButton
}
