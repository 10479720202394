import { has, isEmpty } from 'lodash';
import { IS_ELECTRON } from '../constants/env';
import { pushToGTMDataLayer } from './analytics';

/**
 * Opens a link with the `data-ext-url` attribute in the OS Native browser, if in Electron.
 *
 * Otherwise, return `true` to defer to default event handler.
 *
 * @export
 * @param e A click event on an `<a>` element
 * @returns
 */
export function electronOpenExternal(e: React.MouseEvent<HTMLAnchorElement>) {
  if (IS_ELECTRON && has(window, 'shell')) {
    e.preventDefault();
    const extUrl = e.currentTarget.getAttribute('data-ext-url');
    if (!isEmpty(extUrl)) {
      console.debug('Electron opening data-ext-url', extUrl);
      (window as any).shell.openExternal(extUrl);
      pushToGTMDataLayer('linkClick');
    }
    return false;
  } else if (IS_ELECTRON && !has(window, 'shell')) {
    console.warn(
      'Not in Electron or window.shell is missing! Link could not be opened using shell.openExternal.'
    );
  }
  return true; // Defer to default click event handler
}
