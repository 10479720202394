import { isEmpty } from 'lodash';
import { observable, makeObservable } from 'mobx';
import { CONVERSATION_GROUPING } from '../constants/enums';

export interface IParticipantCreate {
  personId?: number;
  phone?: string;
}

export interface IParticipantModel extends IParticipantCreate {
  id: string;
  created?: string;
  readMessageId?: string;
  groupingType?: CONVERSATION_GROUPING;
  unreadMentionMessageId?: string;
}

export class ParticipantModel implements IParticipantModel {
  @observable
  public id: string;

  @observable
  public created?: string;

  @observable
  public readMessageId?: string;

  @observable
  public unreadMentionMessageId?: string;

  @observable
  public personId?: number;

  @observable
  public phone?: string;

  @observable
  groupingType?: CONVERSATION_GROUPING;

  constructor(
    id: string,
    personId?: number,
    phone?: string,
    created?: string,
    readMessageId?: string,
    groupingType?: CONVERSATION_GROUPING,
    unreadMentionMessageId?: string
  ) {
    makeObservable(this);
    if ((!personId && isEmpty(phone)) || (personId && !isEmpty(phone))) {
      throw new Error(
        'ParticipantModel requires either personId or phone, but not both. personId: ' +
          personId +
          ', phone: ' +
          phone
      );
    }
    this.id = id;
    this.personId = personId;
    this.phone = phone;
    this.created = created;
    this.readMessageId = readMessageId;
    this.groupingType = groupingType;
    this.unreadMentionMessageId = unreadMentionMessageId;
  }

  static FromResponseDto(responseDto: IParticipantModel) {
    return new ParticipantModel(
      responseDto.id,
      responseDto.personId,
      responseDto.phone,
      responseDto.created,
      responseDto.readMessageId,
      responseDto.groupingType,
      responseDto.unreadMentionMessageId
    );
  }
}
export default ParticipantModel;
