import { Icon } from 'Components/shared/Icon';
import { Dropdown } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const TopBarDropdown = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
`;

const TopBarDropdownContainer = styled.div`
  width: 100%;
  max-width: 625px;
  min-width: 0;
  position: relative;
  z-index: 101;
`;
const StyledDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.colors.base} !important;
  border: 1px solid ${({ theme }) => theme.colors.border} !important;
  border-radius: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 48px !important;
  padding-right: 35px !important;
  &.ui.selection.active.dropdown {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &.ui.multiple.dropdown > .label {
    background: ${({ theme }) => theme.colors.border};
    border-radius: 14px;
    padding: 5px 8px 5px 16px;
    margin: 0 5px 0 0;
    border: none;
    box-shadow: none;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.31em;
    font-weight: normal;
    i {
      width: 13px;
      height: 13px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjIxOTc0IDE3LjkzNDdDNC4wNzkwNCAxOC4wNzU0IDQgMTguMjY2MyA0IDE4LjQ2NTJDNCAxOC42NjQyIDQuMDc5MDQgMTguODU1IDQuMjE5NzQgMTguOTk1N0M0LjM2MDQ0IDE5LjEzNjQgNC41NTEyNiAxOS4yMTU1IDQuNzUwMjQgMTkuMjE1NUM0Ljk0OTIyIDE5LjIxNTUgNS4xNDAwNCAxOS4xMzY0IDUuMjgwNzQgMTguOTk1N0wxMS42MDc3IDEyLjY2ODdMMTcuOTM0NyAxOC45OTU3QzE4LjA3NTQgMTkuMTM2NCAxOC4yNjYzIDE5LjIxNTUgMTguNDY1MiAxOS4yMTU1QzE4LjY2NDIgMTkuMjE1NSAxOC44NTUgMTkuMTM2NCAxOC45OTU3IDE4Ljk5NTdDMTkuMTM2NCAxOC44NTUgMTkuMjE1NSAxOC42NjQyIDE5LjIxNTUgMTguNDY1MkMxOS4yMTU1IDE4LjI2NjMgMTkuMTM2NCAxOC4wNzU0IDE4Ljk5NTcgMTcuOTM0N0wxMi42Njg3IDExLjYwNzdMMTguOTk1NyA1LjI4MDc0QzE5LjEzNjQgNS4xNDAwNCAxOS4yMTU1IDQuOTQ5MjIgMTkuMjE1NSA0Ljc1MDI0QzE5LjIxNTUgNC41NTEyNiAxOS4xMzY0IDQuMzYwNDQgMTguOTk1NyA0LjIxOTc0QzE4Ljg1NSA0LjA3OTA0IDE4LjY2NDIgNCAxOC40NjUyIDRDMTguMjY2MyA0IDE4LjA3NTQgNC4wNzkwNCAxNy45MzQ3IDQuMjE5NzRMMTEuNjA3NyAxMC41NDY3TDUuMjgwNzQgNC4yMTk3NEM1LjE0MDA0IDQuMDc5MDQgNC45NDkyMiA0IDQuNzUwMjQgNEM0LjU1MTI2IDQgNC4zNjA0NCA0LjA3OTA0IDQuMjE5NzQgNC4yMTk3NEM0LjA3OTA0IDQuMzYwNDQgNCA0LjU1MTI2IDQgNC43NTAyNEM0IDQuODQ4NzYgNC4wMTk0MSA0Ljk0NjMyIDQuMDU3MTEgNS4wMzczNEM0LjA5NDgxIDUuMTI4MzcgNC4xNTAwNyA1LjIxMTA3IDQuMjE5NzQgNS4yODA3NEwxMC41NDY3IDExLjYwNzdMNC4yMTk3NCAxNy45MzQ3WiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K);
      background-size: 13px;
      background-repeat: no-repeat;
      margin: 0 0 -2px 4px;
      opacity: 1;
      &:before {
        display: none;
      }
    }
  }
`;

const SearchIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 10;
  color: #999 !important;
  ${({ icon }) =>
    icon === 'spinner' &&
    css`
      -webkit-animation: rotating 1.5s linear infinite;
      animation: rotating 1.5s linear infinite;
    `}
`;

export const Styled = {
  TopBarDropdown,
  TopBarDropdownContainer,
  Dropdown: StyledDropdown,
  SearchIcon,
};
