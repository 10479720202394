import type { SystemMessageProps } from './SystemMessageWrapper.types';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { SystemMessageWrapper } from './SystemMessageWrapper';
import { Styled } from './SystemMessageWrapper.styles';

export const SystemMessageGroupCreated: React.FC<SystemMessageProps> = observer(
  ({ message }) => {
    const {
      contact: { loadContactByPhoneNumber },
      person: { selectPersonById, loggedInPersonId },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const formattedAuthor =
      parseInt(message.systemEvent.reference) === loggedInPersonId
        ? 'You'
        : selectPersonById(parseInt(message.systemEvent.reference))?.case({
            fulfilled: ({ data }) => data.DisplayName,
          });

    const formattedParticipants =
      message.systemEvent?.payload?.participants?.map(
        ({ personId, phone }, index) => {
          const person =
            personId > 0
              ? selectPersonById(personId)
              : loadContactByPhoneNumber(phone);

          return person?.case({
            fulfilled: ({ data }) => (
              <span key={data.id}>
                {data.DisplayName || data.phoneNumber}
                {index <
                  message.systemEvent?.payload?.participants.length - 1 && ', '}
              </span>
            ),
          });
        }
      );

    return (
      <SystemMessageWrapper
        created={message.created}
        icon="three-users"
        title={<Styled.SemiBold>Group created</Styled.SemiBold>}
      >
        <BodyCopy noMargin>
          <Styled.SemiBold>Created by: </Styled.SemiBold>
          {formattedAuthor}
        </BodyCopy>
        <BodyCopy noMargin>
          <Styled.SemiBold>Participants: </Styled.SemiBold>
          {formattedParticipants}
        </BodyCopy>
      </SystemMessageWrapper>
    );
  }
);
