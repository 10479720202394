import { Channel as ChannelContainer } from 'Containers/Channel';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { VIDEO_SUBPATHS } from '../../constants/routing';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from '../../constants/stores';
import { ContextPanelProps } from '../../containers/ContextPanel/interfaces';
import withRouter from '../../hocs/WithRouter';
import { ContentArea } from '../shared/ContentArea';
import MyRoom from '../VideoComponent/VideoMenu/MyRoom';
import VideoHistory from '../VideoComponent/VideoMenu/VideoHistory';
import VideoHistoryDetails from '../VideoComponent/VideoMenu/VideoHistory/VideoHistoryDetails';
import NewRoom from './NewRoom';

class VideoPanel extends React.Component<ContextPanelProps> {
  render() {
    const { ui, params, person, conversation, calendar } = this.props;
    const cProps = { ui, person, conversation, calendar };

    return (
      <ContentArea>
        <Grid.Column className="video-panel">
          <ChannelContainer
            openGroupModal={ui.groupModalOpen}
            {...this.props}
            {...this.props.browserHistory}
          />
          <Routes>
            <Route path="room" element={<MyRoom {...cProps} />} />
            <Route path="newroom" element={<NewRoom {...cProps} />} />
            <Route path="history" element={<VideoHistory {...cProps} />} />
            <Route
              path="history/:videoHistoryId"
              element={<VideoHistoryDetails {...cProps} />}
            />
            <Route
              path="*"
              element={<Navigate to={VIDEO_SUBPATHS.PERSONAL_ROOM} replace />}
            />
          </Routes>
        </Grid.Column>
      </ContentArea>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(VideoPanel)));
