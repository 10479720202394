import React from 'react';
import { PhoneCallModel } from '../models';

export const generateAudioElement = (
  key: string,
  src: string,
  phoneCallModel: PhoneCallModel
) => (
  <audio
    key={`KeyPressTone${key}`}
    ref={(elem) => (phoneCallModel[`keyPressTone${key}`] = elem)}
    src={src}
    id={`KeyPressTone${key}-audio`}
    preload="auto"
  />
);
