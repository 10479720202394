import styled from 'styled-components';

const meetingTitleWidth = 'width: 100%;max-width: 0;';
const dateWidth = 'max-width: 100px;min-width: 100px;';
const timeWidth = 'max-width: 160px;min-width: 160px;';
const actionsWidth = `
max-width: 120px;min-width: 120px;
@media (max-width: 1023px) {
  max-width: 65px;min-width: 65px;
}`;

const cellPaddings = `
& > td, & > th {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
    padding-right: 10px;
  }
  &:nth-child(2),
  &:nth-child(3) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &:last-child {
    padding-left: 10px;
    padding-right: 0;
  }
}`;

const History = styled.div`
  &:hover {
    -ms-overflow-style: 4px;
    scrollbar-width: thin;
    scrollbar-color: #cccccc transparent;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #cccccc;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  & .infinite-scroll-component__outerdiv {
    display: contents;

    & .infinite-scroll-component {
      display: contents;
    }
  }
`;

const HistoryHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;
`;

const HistoryHeaderTitle = styled.span`
  font-size: 26px;
`;

const HistoryFilter = styled.div`
  display: flex;
  margin-left: auto;
`;

const HistoryFilterImage = styled.img`
  cursor: pointer;
  right: 20px;
  position: relative;
  width: 17px;
`;

const HistoryTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const TableHeader = styled.thead`
  display: block;

  -ms-overflow-style: 4px;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const TableHeaderFlexRow = styled.tr`
  ${cellPaddings}
`;

const TableHeaderCell = styled.th`
  text-align: left;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: @text-color-sidebar;

  .pointer {
    position: relative;
    left: 3px;
  }

  &:nth-child(1) {
    ${meetingTitleWidth}
  }

  &:nth-child(2) {
    ${dateWidth}
  }

  &:nth-child(3) {
    ${timeWidth}
  }

  &:nth-child(4) {
    ${actionsWidth}
  }
`;

const TableBody = styled.tbody`
  width: 100%;
  display: block;
  max-height: calc(100vh - 245px);
  overflow: auto;
  -ms-overflow-style: 4px;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #ccc transparent;
  scroll-behavior: auto;

  &:hover {
    overflow-y: auto;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const TableBodyFlexRow = styled.tr<{ isAdmin: boolean }>`
  height: 65px;
  max-height: 65px;
  align-items: flex-start;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;

  &:hover {
    background: white;
  }

  ${cellPaddings}

  ${({ isAdmin }) =>
    isAdmin ? 'cursor: pointer;' : 'background-color: #bec7d2 !important;'}
`;

const TableBodyMeetingTitleCell = styled.td`
  ${meetingTitleWidth}
`;

const TableBodyDateCell = styled.td`
  ${dateWidth}
`;

const TableBodyTimeCell = styled.td`
  ${timeWidth}
`;

const TableBodyActionsCell = styled.td`
  ${actionsWidth}
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;

  &:hover {
    background-color: white;
  }
`;

const RecordingWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-left: 8px;
`;

const RecordingIconWrapper = styled.span`
  width: 20px;
  height: 20px;
  border: 1px solid #0099ad;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1px;
`;

const RecordingIcon = styled.span`
  width: 12px;
  height: 12px;
  border: 1px solid #0099ad;
  border-radius: 50%;
  background: #0099ad;
`;

const Recording = styled.span`
  @media (max-width: 1023px) {
    display: none;
  }
`;

const ChevronWrapper = styled.div<{ isHovering?: boolean }>`
  display: flex;
  align-items: center;

  img {
    transform: rotate(0deg) !important;
  }
  ${({ isHovering }) =>
    isHovering
      ? `
      width: 37px;
      height: 37px;
      background-color: #0099ad;
      border-radius: 50%;
      cursor: pointer;
      justify-content: center;`
      : `padding-left: 14px;`}
`;

export const Styled = {
  TableHeader,
  History,
  HistoryHeader,
  HistoryHeaderTitle,
  HistoryFilter,
  HistoryFilterImage,
  HistoryTable,
  TableHeaderFlexRow,
  TableHeaderCell,
  TableBody,
  TableBodyFlexRow,
  TableBodyMeetingTitleCell,
  TableBodyDateCell,
  TableBodyTimeCell,
  TableBodyActionsCell,
  RecordingWrapper,
  RecordingIconWrapper,
  RecordingIcon,
  Recording,
  ChevronWrapper,
};
