import type { TextButtonProps } from './types';
import styled, { css } from 'styled-components';
import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

const BUTTON_VARIANTS: {
  [prop in TextButtonProps['variant']]: FlattenInterpolation<
    ThemeProps<DefaultTheme>
  >;
} = {
  primary: css<{ $disabled: boolean }>`
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.linkHover};
    }
    &:active {
      color: ${({ theme }) => theme.colors.linkClick};
    }
    ${({ $disabled }) =>
      $disabled &&
      css`
        cursor: not-allowed;
        &,
        &:hover,
        &:active {
          color: ${({ theme }) => theme.colors.linkDisabled};
        }
      `}
  `,
  secondary: css`
    color: ${({ theme }) => theme.colors.text};
  `,
  inverted: css`
    margin-left: 12px;
    margin-bottom: -2px;
    color: ${({ theme }) => theme.colors.base};
    border-bottom: 2px solid transparent;
    font-size: 15px;
    font-weight: bold;
    line-height: 15px;
    padding-top: 2px;
    transition: all 0.3s ease-in-out;
    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.base};
    }
  `,
};

const Button = styled.button<{
  $variant: TextButtonProps['variant'];
  $disabled: boolean;
}>`
  padding: 0;
  font: normal normal normal 13px/18px Open Sans;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  ${({ $variant }) => $variant && BUTTON_VARIANTS[$variant]}
`;

export const Styled = {
  Button,
};
