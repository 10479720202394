import { TextLink } from 'Components/shared/TextLink';
import { IS_ELECTRON } from 'Constants/env';
import React from 'react';
import { ParserRule, ReactOutputRule } from 'simple-markdown';
import { electronOpenExternal } from '../electronHelpers';

export const buildVideoLink = (): ParserRule & ReactOutputRule => ({
  order: 0,
  match: (matchSrc) =>
    RegExp(/^(https?:\/\/.*\.(?:mp4|ogg|webm).*)/).exec(matchSrc),
  parse: (capture) => ({ target: capture[0] }),
  react: (node, _output, state) => (
    <>
      <TextLink
        key={state.key + '_a'}
        testid={`markdownCompiler-linkVideo-${state.key}`}
        underline
        {...(IS_ELECTRON
          ? { 'data-ext-url': node.target, onClick: electronOpenExternal }
          : { href: node.target, target: '_blank', rel: 'noreferrer' })}
      >
        {node.target}
      </TextLink>
      <br />
      <video
        key={state.key}
        className="inline-video"
        controls
        onError={(e) => (e.currentTarget.style.display = 'none')}
      >
        <source src={node.target} />
        Your browser does not support the video element.
      </video>
    </>
  ),
});
