import type { AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import React from 'react';
import { IVideoHistoryDetails } from '../../../../../../models/VideoHistoryModel';

export class ShortHistory extends React.Component<{
  videoHistoryDetails: AxiosResponse<IVideoHistoryDetails>;
}> {
  handleShowDate = (startDate, endDate) => {
    const diffSec = endDate.diff(startDate, 'seconds');
    const [h, m, s] = moment
      .utc(diffSec * 1000)
      .format('H:m:s')
      .split(':');
    const duration = [];
    if (parseInt(h) > 0) {
      duration.push(
        <span key="duration-hours">
          {h} {h === '1' ? 'hour ' : 'hours '}
        </span>
      );
    }
    if (parseInt(m) > 0) {
      duration.push(
        <span key="duration-minutes">
          {m} {m === '1' ? 'minute ' : 'minutes '}
        </span>
      );
    }
    if (parseInt(s) > 0) {
      duration.push(
        <span key="duration-seconds">
          {s} {s === '1' ? 'second' : 'seconds'}
        </span>
      );
    }
    return duration;
  };

  render() {
    const { videoHistoryDetails } = this.props;
    const videoHistoryDetailsData = videoHistoryDetails?.data;
    const day = moment(videoHistoryDetailsData?.started).format(
      'dddd, MMMM DD, YYYY'
    );
    const timeOnCall = `${moment(videoHistoryDetailsData?.joinedAt).format(
      'hh:mm A'
    )} - ${moment(videoHistoryDetailsData?.completed).format('hh:mm A')}`;

    const endDate = moment(videoHistoryDetailsData?.completed);
    const startDate = moment(videoHistoryDetailsData?.joinedAt);
    const duration = this.handleShowDate(startDate, endDate);

    return (
      <div className="flex-justify-center first-container">
        <div className="subtitle">Meeting Details</div>
        <div className="flex-row short-hist-blocks">
          <span>Room: </span>
          <span data-private>{videoHistoryDetailsData?.displayName}</span>
        </div>
        <div className="flex-row short-hist-blocks">
          <span>Date: </span>
          <span>{day}</span>
        </div>
        <div className="flex-row short-hist-blocks">
          <span>Time: </span>
          <span>{timeOnCall}</span>
        </div>
        <div className="flex-row short-hist-blocks">
          <span>Duration: </span>
          <span>{duration}</span>
        </div>
      </div>
    );
  }
}
