import { isEmpty } from 'lodash';
import { now } from 'mobx-utils';
/**
 * Converts a TimeUUID v1 (ex. from Cassandra) into a timezoned `moment` instance representing the TimeUUID's value.
 *
 * This `moment` is zoned to `TIMEZONE_IDENTIFIER`
 * @param timeUUID A TimeUUID v1
 */
export function getISOStringFromTimeUUID(timeUUID: string) {
  const epochMs = getCorrectedMsFromUnixEpoch(timeUUID);
  // const epochSeconds = Math.round(epochMs / 1000);
  const parsedMoment = new Date(epochMs).toISOString();
  // console.debug('timeUUID', timeUUID, 'parsedMoment', parsedMoment.toISOString());
  return parsedMoment;
}

export function getCorrectedMsFromUnixEpoch(timeUUID: string) {
  if (!isEmpty(timeUUID)) {
    const uuid_arr = timeUUID.split('-'),
      time_str = [uuid_arr[2].substring(1), uuid_arr[1], uuid_arr[0]].join('');
    // Correct for Gregorian Calendar
    return (parseInt(time_str, 16) - 122192928000000000) / 10000;
  }
  return now('frame');
}
