import styled from 'styled-components';

const Banner = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 14px 19px;
  margin-bottom: 25px;
  background-color: #f95050;
  box-shadow: 0px 8.6px 13.7px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    margin: 0;
  }
  span {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    line-height: 15px;
    color: #fff;
    padding-top: 2px;
    margin-left: 12px;
    margin-bottom: -2px;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    &:hover {
      border-bottom-color: #fff;
    }
  }
`;

const BannerText = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin: 0;
`;

export const Styled = {
  Banner,
  BannerText,
};
