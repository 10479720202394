import type { DirectoryUserInfoProps } from './types';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import { PersonModel } from '../../../../models';
import { BodyCopy } from '../../../shared/BodyCopy';
import { Heading } from '../../../shared/Heading';
import { UserAvatar } from '../../../shared/UserAvatar';
import { Styled } from './index.styles';

export const DirectoryUserInfo: React.FC<DirectoryUserInfoProps> = observer(
  ({ data }) => {
    const {
      ui: { selectConversationUnreadCounts, selectPersonPresenceStatus },
    } = React.useContext<RootStoreProps>(MobXProviderContext);
    const { DisplayName } = PersonModel.FromResponseDto(data);

    return (
      <Styled.UserInfo>
        <UserAvatar
          name={DisplayName}
          selectUnreadCounts={selectConversationUnreadCounts}
          presence={selectPersonPresenceStatus(Number(data.id))}
        />
        <Styled.UserInfoContent>
          <Heading variant="h6" noMargin>
            {DisplayName}
          </Heading>
          <BodyCopy noMargin>
            {data.jobTitle}
            {data.jobTitle && data.department && ', '}
            {data.department}
          </BodyCopy>
        </Styled.UserInfoContent>
      </Styled.UserInfo>
    );
  }
);
