import type { SelectedEventProps } from './types';
import archiveIcon from 'Assets/images/bin.svg';
import closeBtn from 'Assets/images/c-close.svg';
import checkMarkIcon from 'Assets/images/check-mark.svg';
import declinedIcon from 'Assets/images/declined.svg';
import googleIcon from 'Assets/images/google-logo.svg';
import teamIcon from 'Assets/images/microsoft-team.svg';
import otherProviderIcons from 'Assets/images/other-providers.svg';
import timeIcon from 'Assets/images/time-icon.svg';
import groupIcon from 'Assets/images/user-grp.svg';
import whiteBoard from 'Assets/images/whiteboard.svg';
import zoomIcon from 'Assets/images/zoom-meeting.svg';
import { Button } from 'Components/shared/Button';
import { Heading } from 'Components/shared/Heading';
import DOMPurify from 'dompurify';
import { ConferenceProvider, EventResponse, IEvent } from 'Models/Calendar';
import moment, { Moment } from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { formatUserInitials } from 'Utils/formatUserInitials';
import { Styled } from './index.styles';

const formatDateTime = (date: Moment) =>
  date.format('dddd, MMMM DD ・ hh:mm A');
const formatDate = (date: Moment) => date.format('dddd, MMMM DD');
const formatTime = (date: Moment) => date.format('hh:mm A');
const isExternalProvider = (provider: ConferenceProvider) => {
  return provider !== 'BHive';
};

export const SelectedEvent = ({
  selectedEventData,
  checkIsOwnerLoggedIn,
  loggedInEmail,
  handleEventRespond,
  setSuccessMessageRespond,
  toogleEventPopup,
  setSelectedEvent,
  setEventModal,
  toggleRemoveModal,
  navigateVideoConferenceToSession,
  testId: parentTestId,
}: SelectedEventProps) => {
  const testId = `${parentTestId}-selectedEvent`;
  const [isOwnerLoggedInUser, setIsOwnerLoggedInUser] = useState(false);
  const toggleEventPopup = (e) => toogleEventPopup(null, e);
  const handleEditEvent = (e) => {
    toogleEventPopup(null, e);
    setSelectedEvent(selectedEventData);
    setEventModal(true);
  };

  const handleCheckIsOwnerLoggedIn = useCallback(async () => {
    const isOwnerLoggedIn = await checkIsOwnerLoggedIn(selectedEventData);

    setIsOwnerLoggedInUser(isOwnerLoggedIn);
  }, [checkIsOwnerLoggedIn, selectedEventData]);

  const joinEvent = (e) => {
    const { url } = selectedEventData.conferencing;
    navigateVideoConferenceToSession(url);
    toggleEventPopup(e);
  };

  const handleResponse = useCallback(
    (e) => {
      const possibleIds: EventResponse[] = ['yes', 'no', 'maybe', 'no-reply'];
      if (possibleIds.includes(e.target.id)) {
        const resp = handleEventRespond(
          e.target.id as EventResponse,
          selectedEventData.id
        );
        const validAnswer = ['yes', 'no'].includes(e.target.id);
        const message = e.target.id === 'yes' ? 'Accepted' : 'Declined';
        resp &&
          setSuccessMessageRespond({
            show: validAnswer && resp,
            eventTitle: selectedEventData.title,
            message,
          });
      }
      toggleEventPopup(e);
    },
    [
      handleEventRespond,
      selectedEventData,
      setSuccessMessageRespond,
      toggleEventPopup,
    ]
  );

  const removeEvent = useCallback(
    (event: IEvent) => (e) => {
      toggleEventPopup(e);
      toggleRemoveModal(event, e);
    },
    [toggleEventPopup, toggleRemoveModal]
  );

  const mapImageToProvider = (provider: ConferenceProvider) => {
    let image = otherProviderIcons;
    const providerLowerCase = provider.toLowerCase();
    if (
      provider === 'Google Meet' ||
      providerLowerCase.includes('google meet')
    ) {
      image = googleIcon;
    } else if (
      provider === 'Microsoft Teams' ||
      providerLowerCase.includes('microsoft teams')
    ) {
      image = teamIcon;
    } else if (
      provider === 'Zoom Meeting' ||
      provider.includes('zoom meeting')
    ) {
      image = zoomIcon;
    }
    return image;
  };

  const conferenceUrl = selectedEventData.conferencing?.url;
  const conferenceProvider = selectedEventData.conferencing?.provider;
  const loggedInUser = selectedEventData.participants?.find(
    (participant) => participant.email === loggedInEmail
  );

  const cleanDescription = DOMPurify.sanitize(
    selectedEventData.description || 'No description for this Event.'
  ).replace(/\n/g, '<br/>');

  useEffect(() => {
    handleCheckIsOwnerLoggedIn();
  }, [handleCheckIsOwnerLoggedIn]);

  return (
    <div
      className="calendar-event"
      id="calendar-event"
      onClick={toggleEventPopup}
    >
      <div className="screen selected-event">
        <div className="top-icons">
          {isOwnerLoggedInUser && (
            <img
              className="edit"
              id="edit"
              src={whiteBoard}
              onClick={handleEditEvent}
              data-testid={`${testId}-editButton`}
            />
          )}

          {isOwnerLoggedInUser && (
            <img
              className="remove"
              id="remove"
              src={archiveIcon}
              onClick={removeEvent(selectedEventData)}
              data-testid={`${testId}-removeButton`}
            />
          )}

          <img
            className="close"
            id="close"
            src={closeBtn}
            onClick={toggleEventPopup}
            data-testid={`${testId}-closeButton`}
          />
        </div>

        <Heading variant="h2">{selectedEventData.title}</Heading>

        <div
          data-private
          className="event-description"
          //TODO We need to address this in the future
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
        />
        {moment(selectedEventData.start).date() ===
        moment(selectedEventData.end).date() ? (
          <div className="start-date flex-row flex-align-items-center">
            <img src={timeIcon} />
            <span>
              {selectedEventData.allDay
                ? formatDate(moment(selectedEventData.start))
                : `${formatDateTime(moment(selectedEventData.start))}${
                    selectedEventData.start.getTime() ===
                    selectedEventData.end.getTime()
                      ? ''
                      : ` - ${formatTime(moment(selectedEventData.end))}`
                  }`}
            </span>
          </div>
        ) : (
          <>
            <div className="start-date flex-row flex-align-items-center">
              <img src={timeIcon} />
              <span>
                {selectedEventData.allDay
                  ? formatDate(moment(selectedEventData.start))
                  : formatDateTime(moment(selectedEventData.start))}
              </span>
            </div>
            <div className="end-date flex-row flex-align-items-center">
              <img src={timeIcon} />
              <span>
                {selectedEventData.allDay
                  ? formatDate(moment(selectedEventData.end))
                  : formatDateTime(moment(selectedEventData.end))}
              </span>
            </div>
          </>
        )}
        {selectedEventData.participants?.length > 0 && (
          <div
            className={`invited-people flex-row flex-align-items-center flex-wrap ${
              selectedEventData.participants?.length > 2 ? 'full-width' : ''
            }`}
          >
            <img src={groupIcon} />
            {selectedEventData.participants?.length > 2 && (
              <div className="guests">
                {selectedEventData.participants?.length} guests
              </div>
            )}
            {selectedEventData.participants.map((participant, index) => {
              const isStatusChoosen = ['yes', 'no'].includes(
                participant.status
              );

              return (
                <div
                  key={`item-${index}`}
                  className="flex-row participant flex-align-items-center"
                >
                  <span className="credentials">
                    {formatUserInitials(participant.name)}
                    {isStatusChoosen && (
                      <span
                        className={`status ${
                          participant.status === 'yes'
                            ? 'confirmed'
                            : 'declined'
                        }`}
                      >
                        <img
                          src={
                            participant.status === 'yes'
                              ? checkMarkIcon
                              : declinedIcon
                          }
                        />
                      </span>
                    )}
                  </span>
                  <span data-private key={participant.id}>
                    {`${participant.name || participant.email || ''}${
                      index < selectedEventData.participants.length - 1
                        ? ', '
                        : ''
                    }`}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <>
          {conferenceUrl && !isExternalProvider(conferenceProvider) && (
            <Styled.JoinMeetingButton
              onClick={joinEvent}
              id="join"
              variant="primary"
              testid={`${testId}-buttonJoin`}
              content={'Join Meeting'}
            />
          )}

          {loggedInUser?.status !== 'yes' &&
            loggedInUser?.status !== 'no' &&
            !selectedEventData.providerEventId && (
              <div className="footer flex-row flex-align-items-center">
                <span>Going?</span>
                <div className="buttons flex-row flex-align-items-center">
                  <Button
                    variant="secondary"
                    id="yes"
                    onClick={handleResponse}
                    testid={`${testId}-buttonAccept`}
                    content={'Accept'}
                  />

                  <Button
                    variant="secondary"
                    id="no"
                    onClick={handleResponse}
                    testid={`${testId}-buttonDecline`}
                    content={'Decline'}
                  />
                </div>
              </div>
            )}
        </>

        {conferenceUrl && isExternalProvider(conferenceProvider) && (
          <div className="footer flex-row flex-align-items-center">
            <img src={mapImageToProvider(conferenceProvider)} />
            <a target="blank" href={conferenceUrl}>
              {conferenceUrl}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
