import styled from 'styled-components';

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.base};
`;

export const Styled = {
  Avatar,
};
