import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
const loader = require('../../assets/images/loader.gif');

export const AppLoader = () => {
  return (
    <Grid textAlign="center" verticalAlign="middle" className="login">
      <Grid.Column style={{ maxWidth: 450 }} className="login-container">
        <Header as="h3" textAlign="center" className="being-loaded">
          <img src={loader} className="loader" />
          <br />
          Sweet experiences are being loaded.
        </Header>
      </Grid.Column>
    </Grid>
  );
};
