import { STORE_SEARCH } from 'Constants/stores';
import {
  CONVERSATION_DESCRIPTION_MAXLENGTH,
  CONVERSATION_TOPIC_MAXLENGTH,
  CONVERSATION_TOPIC_REGEXP,
} from 'Constants/validation';
import { debounce, get, isArray, isEmpty, uniqBy } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { createViewModel } from 'mobx-utils';
import * as React from 'react';

import {
  Button,
  Dropdown,
  DropdownProps,
  Grid,
  InputProps,
  Message,
  TextArea,
} from 'semantic-ui-react';
import type { SearchResponseData } from 'Stores/SearchStore.types';
import { formatUserInitials } from 'Utils/formatUserInitials';
import {
  ConversationModel,
  ParticipantModel,
  SearchResult,
} from '../../models';

import { ContactListSearchProps } from './interfaces';

let timeout;

/**
 * Wraps `<ContactList>` in a search container, which queries the server for Persons/Contacts if the input is populated.
 *
 * Used by `<ChannelMembers>` to list Persons/Contacts who can be added to a Channel.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ContactListSearch
 */
class ContactListSearchObserver extends React.Component<
  ContactListSearchProps,
  {
    hasMoreData: boolean;
    mandatoryFieldsAreEmpty: boolean;
    selectedUsers: string[] | string;
    personsFromTopBar: SearchResponseData;
    loading: boolean;
    allUsersSelected: any[];
    channelInfoDetails?: {
      channelConversationId: string;
      channelMode: string;
      channelType: string;
    };
    saveInProgress: boolean;
  }
> {
  @observable
  private searchInput = '';

  @action
  private setSearchInput = (searchInput: string) =>
    (this.searchInput = searchInput);

  @action
  onDescriptionChange = (e) =>
    (this.props.conversationVm.description = e.target.value);

  private get _DescriptionValid() {
    return (
      isEmpty(get(this.props.conversationVm, 'description', '')) ||
      get(this.props.conversationVm, 'description.length', 0) <=
        CONVERSATION_DESCRIPTION_MAXLENGTH
    );
  }

  private get _unsavedTopicValid() {
    return (
      get(this.props.conversationVm, 'unsavedTopic.length', 0) > 0 &&
      get(this.props.conversationVm, 'unsavedTopic.length', 0) <=
        CONVERSATION_TOPIC_MAXLENGTH &&
      CONVERSATION_TOPIC_REGEXP.test(
        get(this.props.conversationVm, 'unsavedTopic', '')
      )
    );
  }

  private get _unsavedTopicLength() {
    return get(this.props.conversationVm, 'unsavedTopic.length', 0) as number;
  }

  private get _topicValid() {
    return (
      get(this.props.conversationVm, 'topic.length', 0) > 0 &&
      get(this.props.conversationVm, 'topic.length', 0) <=
        CONVERSATION_TOPIC_MAXLENGTH &&
      CONVERSATION_TOPIC_REGEXP.test(
        get(this.props.conversationVm, 'topic', '')
      )
    );
  }

  private get _topicLength() {
    return get(this.props.conversationVm, 'topic.length', 0) as number;
  }

  directorySearchThrottled = debounce(
    this.props.search.getDirectorySearch,
    100,
    { leading: false }
  );

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      selectedUsers: [],
      hasMoreData: true,
      mandatoryFieldsAreEmpty: null,
      loading: false,
      personsFromTopBar: { hits: 0, results: [] },
      allUsersSelected: [],
      saveInProgress: false,
    };
  }

  async componentDidMount() {
    const { uiStore } = this.props;
    uiStore.setTopBarContactPageNumber(1);
    if (uiStore.selectedTopBarUsers?.length > 0) {
      const allPersonsPbo = await this.getAllPersonsBasedOnId();
      const allPersonsResp = await Promise.all(allPersonsPbo);
      const model: SearchResponseData = { hits: 0, results: [] };

      allPersonsResp.forEach((person) => {
        person.searchableType = 'SearchableDetailsPerson';
        model.results.push(new SearchResult(person, '', 0));
      });
      this.setState({ personsFromTopBar: model });
    }
  }

  componentWillMount() {
    const { loggedInPersonId, uiStore } = this.props;
    const { setConversationVm } = this.props;
    const newConversation: ConversationModel =
      ConversationModel.NewChannel(loggedInPersonId);
    setConversationVm(
      createViewModel(this.props.conversationModel || newConversation)
    );
    //call directorySearch, only if clicked on Edit Group
    if (uiStore.openingGroupModalFrom !== 'edit') {
      this.directorySearchThrottled('USERS');
    }
  }

  componentDidUpdate(prevProps: ContactListSearchProps) {
    const { participants, uiStore } = this.props;
    const participantsIds =
      participants &&
      this.getUniqueParticipants().map((participant) =>
        participant.personId.toString()
      );
    if (
      (participantsIds?.length > 0 || uiStore.selectedTopBarUsers) &&
      this.state.selectedUsers.length === 0
    ) {
      const merged =
        uiStore.selectedTopBarUsers &&
        uiStore.openingGroupModalFrom === 'top-bar'
          ? [...uiStore.selectedTopBarUsers, ...participantsIds]
          : [...participantsIds];
      this.setState({ selectedUsers: merged });
    }
  }

  getAllPersonsBasedOnId = async () => {
    const { uiStore, loadPersonByIdGetIfMissingGet } = this.props;
    return uiStore.selectedTopBarUsers
      .map(async (personId) => {
        const person = !isNaN(personId as any)
          ? await loadPersonByIdGetIfMissingGet(parseFloat(personId))
          : null;
        return person?.data;
      })
      .filter(Boolean);
  };

  getUniqueParticipants = () => {
    const { participants } = this.props;
    return uniqBy(participants, function (p) {
      return p.id?.toString() || p.personId?.toString();
    });
  };

  handleLoadContacts = async () => {
    const {
      uiStore: { handleLoadMoreContacts },
    } = this.props;
    if (!this.state.loading) {
      this.setState({ loading: true });
      const resp: boolean = await handleLoadMoreContacts(false, true);
      resp
        ? this.setState({ hasMoreData: false, loading: false })
        : this.setState({ loading: false });
    }
  };

  handleScrollDown = () => {
    timeout = setTimeout(() => {
      const element: any = document.querySelector(
        '#contact-list-search-wrap .menu'
      );
      if (element) {
        element.onscroll = (e: any) => {
          const bottom =
            e.target.scrollHeight -
            e.target.scrollTop -
            e.target.clientHeight -
            10;
          if (bottom <= 30 && this.state.hasMoreData) {
            this.handleLoadContacts();
          }
        };
      }
    }, 500);
    if (!this.state.hasMoreData) {
      clearTimeout(timeout);
    }
  };

  onSearchInputChange = (e, inputProps: InputProps) => {
    this.setSearchInput(inputProps.searchQuery);
    this.filter(inputProps);
  };

  filter = debounce(async (inputProps: InputProps) => {
    const { search } = this.props;
    await search.getDirectorySearch('USERS', inputProps.searchQuery);
  }, 300);

  handleOnChangeDropdown = (e, d: DropdownProps | any) => {
    const { setSelectedParticipants, search } = this.props;
    const directory = search.directoryMap?.['USERS'];
    const filteredResults = directory?.data?.results;
    const filteredResultsCheckbox = filteredResults?.filter(
      (person) =>
        !!person.label &&
        (d.value as string[]).includes(person?.source?.id?.toString())
    );
    const mergedPrevCurrent = uniqBy(
      [...this.state.allUsersSelected, ...filteredResultsCheckbox],
      (person) => person?.source?.id
    );
    this.setState({
      selectedUsers: d.value as string[],
      allUsersSelected: mergedPrevCurrent,
    });
    setSelectedParticipants(d.value);
    this.handleFocus();
    this.setSearchInput('');
  };

  handleFocus = () => {
    const { search } = this.props;
    document.querySelector<HTMLInputElement>('#inviteGroup input').focus();
    search.getDirectorySearch('USERS', '', 1, true);
  };

  handleCloseGroupModal = () => {
    this.props.uiStore.setGroupModal(false);
    this.props.clearNameTaken();
  };

  handleSaveMembers = (e) => {
    const {
      conversationId,
      isNew,
      topicNameTaken,
      removeChannelMembers,
      addChannelMember,
      participants,
      conversationVm,
      uiStore,
    } = this.props;
    const { selectedUsers } = this.state;

    e.preventDefault();
    const isInvalidTopic = isNew
      ? !this._unsavedTopicValid
      : !this._unsavedTopicValid || !this._topicValid;
    if (
      !selectedUsers ||
      (!conversationVm.unsavedTopic && !conversationVm.topic)
    ) {
      this.setState({ mandatoryFieldsAreEmpty: true });
    }
    if (!this._DescriptionValid || isInvalidTopic) {
      if (
        (!conversationVm.unsavedTopic && !conversationVm.topic) ||
        topicNameTaken
      ) {
        return;
      }
    }
    const oldUsers = new Set(participants.map((p) => p.personId.toString()));
    const newUsers = new Set(selectedUsers);

    // handle adding new users to the group
    const forCreate =
      isArray(selectedUsers) && selectedUsers.filter((u) => !oldUsers.has(u));
    if (forCreate.length) {
      addChannelMember(forCreate.map((u) => parseInt(u)));
    }

    // handle removing users from the group
    const forDelete = participants.filter(
      (u) => !newUsers.has(u.personId.toString())
    );
    if (forDelete.length) {
      removeChannelMembers(
        conversationId,
        // mapping id and personId in case one of these two is null or undefined
        forDelete.map((u) => u.id || `${u.personId}_`)
      );
    }
    if (selectedUsers) {
      this.commitViewModelChanges(null);
    }
  };

  onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Enter key
    if (e.keyCode === 13 && e.ctrlKey) {
      this.props.saveClick(e);
      this.commitViewModelChanges(e);
    }
  };

  commitViewModelChanges = (e) => {
    this.setState({ saveInProgress: true });
    const { setDescription, saveClick, conversationVm } = this.props;
    conversationVm.submit();
    setDescription(get(conversationVm, 'model.description', ''));
    void saveClick(e).then((resp) => {
      if (resp) {
        this.handleCloseGroupModal();
      }
      this.setState({ saveInProgress: false });
    });
  };

  transformToSearchResult = (participants: Partial<ParticipantModel>[]) =>
    participants.map(({ personId }) =>
      this.props.selectParticipantPersonInfo(personId)?.case({
        fulfilled: ({ data }) => ({
          key: personId.toString(),
          label: formatUserInitials(data.DisplayName),
          match: '',
          resultType: 'SearchableDetailsPerson',
          score: 0,
          text: data.DisplayName,
          value: personId.toString(),
          source: data,
        }),
      })
    );

  handleCloseMessCallPopup = (e) => {
    const { setSelectedParticipants } = this.props;
    if (e.which === 27) {
      this.setState({ selectedUsers: [] });
      this.setSearchInput('');
      setSelectedParticipants([]);
    }
  };

  render() {
    const {
      saveClick,
      conversationVm,
      search,
      loggedInPersonId,
      participants,
      isNew,
    } = this.props;
    const directory = search.directoryMap?.['USERS'];
    const { selectedUsers, mandatoryFieldsAreEmpty, loading, saveInProgress } =
      this.state;
    const isModelValid =
      this._DescriptionValid &&
      (this.props.isNew
        ? this._unsavedTopicValid
        : get(this.props.conversationVm, 'unsavedTopic', '') === null
        ? this._topicValid
        : this._unsavedTopicValid);
    const titleMessage =
      (this.props.isNew
        ? this._unsavedTopicLength
        : get(this.props.conversationVm, 'unsavedTopic', '') === null
        ? this._topicLength
        : this._unsavedTopicLength) === 0
        ? 'Please fill in the group name before saving'
        : !isModelValid
        ? 'Please fix the highlighted fields'
        : '';

    if (directory) {
      const participantsFromEditGrp = participants
        ? this.transformToSearchResult(participants).filter((item) => item)
        : [];
      const allPersons = directory.data.results;
      const allPersonsNoCurrent = allPersons.filter(
        (result) => result.source.id !== loggedInPersonId
      );
      const mergedTogetherEditAndAll = [
        ...participantsFromEditGrp,
        ...allPersonsNoCurrent,
        ...this.state.personsFromTopBar.results,
      ];
      const mergedResults = uniqBy(
        [...this.state.allUsersSelected, ...mergedTogetherEditAndAll],
        (person) => person?.source?.id
      );

      return (
        <div
          id="contact-list-search-wrap"
          className="flex-grow-shrink flex-column"
          style={{ minHeight: 0 }}
          onKeyDown={this.handleCloseMessCallPopup}
        >
          <Grid.Column
            className="no-hpadding flex-grow-shrink"
            inverted="true"
            verticalAlign="middle"
            size="large"
            selection
            id="contact-list"
          >
            {
              <Dropdown
                id="inviteGroup"
                onClick={this.handleFocus}
                placeholder="Add group member"
                fluid
                multiple={true}
                search
                loading={loading}
                searchQuery={this.searchInput}
                selection
                value={selectedUsers}
                options={mergedResults}
                onChange={this.handleOnChangeDropdown}
                onOpen={this.handleScrollDown}
                onSearchChange={this.onSearchInputChange}
              />
            }
            {saveClick && (
              <div className="ui form">
                <div id="groupDescription" className="group-modal-topics">
                  Group Description
                </div>
                <TextArea
                  placeholder="Description"
                  className="inverted"
                  onKeyUp={this.onKeyUp}
                  rows={3}
                  value={conversationVm?.description || ''}
                  onChange={this.onDescriptionChange}
                />
                {!this._DescriptionValid && (
                  <Message error visible>
                    <Message.Header content="Invalid Description" />
                    <Message.Content
                      content={`Conversation Description must contain no more than ${CONVERSATION_DESCRIPTION_MAXLENGTH} characters`}
                    />
                  </Message>
                )}
                {mandatoryFieldsAreEmpty && (
                  <Message error visible>
                    <Message.Header content="Required field" />
                    <Message.Content
                      content={`Group Name is required field.`}
                    />
                  </Message>
                )}
              </div>
            )}
            {saveClick && (
              <Grid.Row className="buttons-group-modal">
                <Button onClick={this.handleCloseGroupModal} content="CANCEL" />
                <Button
                  id="create-save-group"
                  className="primary"
                  content={
                    isEmpty(participants) ? 'CREATE GROUP' : 'SAVE CHANGES'
                  }
                  onClick={this.handleSaveMembers}
                  disabled={saveInProgress || !isModelValid}
                  loading={saveInProgress}
                  title={!saveInProgress && titleMessage}
                />
              </Grid.Row>
            )}
          </Grid.Column>
        </div>
      );
    }
    return null;
  }
}

export const ContactListSearch = inject(STORE_SEARCH)(
  observer(ContactListSearchObserver)
);
