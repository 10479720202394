import type { ChatListItemTextProps } from './types';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { Styled } from './index.styles';

export const ChatListItemText: React.FC<ChatListItemTextProps> = ({
  bold = false,
  emoji,
  isOwnChat = false,
  subtitle,
  title,
}) => (
  <Styled.TextWrapper data-private $bold={bold}>
    <Styled.TitleWrapper>
      <Styled.Heading variant="h5" noMargin ellipsis>
        {title}
      </Styled.Heading>
      {isOwnChat && (
        <Styled.OwnChatHeading variant="h5" noMargin>
          {'(You)'}
        </Styled.OwnChatHeading>
      )}
      {emoji?.title && (
        <Popup
          inverted
          trigger={<Styled.Emoji>{emoji.title}</Styled.Emoji>}
          content={emoji?.message || ''}
          position="top right"
        />
      )}
    </Styled.TitleWrapper>
    {subtitle && (
      <Styled.BodyCopy variant="paragraph" noMargin ellipsis>
        {subtitle}
      </Styled.BodyCopy>
    )}
  </Styled.TextWrapper>
);
