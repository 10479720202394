import type { CopyComponentProps } from './types';
import { Popup } from 'Components/shared/Popup';
import React from 'react';
import { Styled } from './index.styles';

const copiedIcon = require('../../../assets/images/copied-icon.svg');
const copyHoverIcon = require('../../../assets/images/copy-icon-hover.svg');
const copyIcon = require('../../../assets/images/copy-icon.svg');

export const CopyComponent: React.FC<CopyComponentProps> = ({
  copied: isCopied,
  handleCopy,
  hovered,
  link,
}) => {
  const [isHover, setIsHover] = React.useState(false);

  const isHovered = hovered || isHover;
  const textCopy = link
    ? isCopied
      ? 'URL is copied to clipboard'
      : 'Copy URL to clipboard'
    : isCopied
    ? 'Meeting info was copied to clipboard'
    : 'Copy Meeting info to clipboard';

  return (
    <Popup
      trigger={
        <Styled.CopyButton
          $isCopied={isCopied}
          onClick={handleCopy}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <img
            src={isCopied ? copiedIcon : isHovered ? copyHoverIcon : copyIcon}
          />
        </Styled.CopyButton>
      }
      content={textCopy}
      position="right center"
    />
  );
};
