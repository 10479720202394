import type { FilterBoxProps } from './types';
import { TESTID_FILTERBOX } from 'Constants/testids';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Styled } from './index.styles';

export const FilterBox: React.FC<FilterBoxProps> = ({
  loading,
  placeholder,
  sendInput,
  setPageNum,
  testid,
}) => {
  const location = useLocation();
  const [value, setValue] = React.useState('');
  const contactInputField = React.useRef(null);

  React.useEffect(() => {
    setValue('');
    contactInputField?.current?.focus();
  }, [location.pathname]);

  return (
    <Styled.Wrapper>
      <Styled.Input
        data-testid={testid + TESTID_FILTERBOX}
        fluid
        ref={contactInputField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
          setPageNum?.(1);
          sendInput(e.target.value);
        }}
        {...{ placeholder, loading, value }}
      />
    </Styled.Wrapper>
  );
};
