import { Heading } from 'Components/shared/Heading';
import styled from 'styled-components';

const LinkPreviewWrapper = styled.a`
  display: block;
  position: relative;
  width: 350px;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.sizes.xxsmall};
  background-color: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.sizes.xxxsmall};
`;

const TextWrapper = styled.div`
  padding: ${({ theme }) => `${theme.sizes.xxsmall} ${theme.sizes.xsmall}`};
`;

const Image = styled.img`
  max-height: 350px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.lightGray};
  width: 100%;
`;

const LinkIcon = styled.img`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 22px;
  height: 22px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.sizes.xxsmall};
`;

const LinkHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Styled = {
  LinkPreviewWrapper,
  TextWrapper,
  LinkIcon,
  Image,
  LinkHeading,
};
