import styled from 'styled-components';
import { Icon } from '../Icon';

const UserPresence = styled.div``;
const UnreadMessageCount = styled.div``;

const StyledIcon = styled(Icon)`
  width: 50%;
  max-width: 50%;
  height: 50%;
  max-height: 50%;
  position: absolute;
  bottom: -16%;
  right: -16%;
  z-index: 1;
`;
const AvailableIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.success}!important;
`;
const AwayIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.warning}!important;
`;
const BusyIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.danger}!important;
`;
const DNDIcon = styled(StyledIcon)`
  color: ${({ theme }) => theme.colors.danger}!important;
`;
const OfflineIcon = styled(StyledIcon)`
  color: #808080 !important;
`;

export const Styled = {
  UserPresence,
  UnreadMessageCount,
  AvailableIcon,
  AwayIcon,
  BusyIcon,
  DNDIcon,
  OfflineIcon,
};
