import moment from 'moment';

/**
 *
 * @param startDate date object or ISO string
 * @param endDate date object or ISO string
 * @returns
 */
export const isAllDayEvent = (
  startDate: Date | string,
  endDate?: Date | string
): boolean => {
  const startDateUtc = moment(startDate).utc();
  const endDateUtc = endDate ? moment(endDate).utc() : null;

  return (
    startDateUtc.hours() === 0 &&
    startDateUtc.minutes() === 0 &&
    startDateUtc.seconds() === 0 &&
    (!endDateUtc ||
      (endDateUtc.hours() === 0 &&
        endDateUtc.minutes() === 0 &&
        endDateUtc.seconds() === 0) ||
      (endDateUtc.hours() === 23 &&
        endDateUtc.minutes() === 59 &&
        endDateUtc.seconds() === 59))
  );
};

export const isAllDayCustomEvent = (endDate?: Date | string): boolean => {
  return (
    endDate &&
    (moment(endDate).format('LTS') === '11:59:59 PM' ||
      moment(endDate).format('LTS') === '11:59:00 PM')
  );
};
