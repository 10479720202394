import { NotificationStackPosition } from '../NotificationStack/index.types';
import { NotificationConfig } from './index.types';

export const NOTIFICATION_CONFIG = {
  DEFAULT_DURATION: 5,
  NOTIFICATION_WIDTH: 400,
  SCREEN_PADDING: 16,
  DEFAULT_GAP: 8,
} satisfies NotificationConfig;

export const POSITIONS = [
  'tl',
  'tc',
  'tr',
  'bl',
  'bc',
  'br',
] satisfies NotificationStackPosition[];
