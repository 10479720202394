
import headphonesIcon from 'Assets/images/headphones-icon.svg';
import micIcon from 'Assets/images/mic-icon.svg';
import { ErrorBanner } from 'Components/shared/ErrorBanner';
import { Heading } from 'Components/shared/Heading';
import { SettingsDropdown } from 'Components/shared/SettingsDropDown';
import { TextButton } from 'Components/shared/TextButton';
import { ToggleButton } from 'Components/shared/ToggleButton';
import {
  SELECTED_MICROPHONE,
  SELECTED_RING_DEVICE,
  SELECTED_SPEAKER,
} from 'Constants/localstorage';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ReactMic } from 'react-mic';
import { Grid } from 'semantic-ui-react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { stopAllStreams } from 'Utils/streamUtils';

export const AudioVideoSettings: React.FC = observer(() => {
  const testid = 'audioVideoSettings';
  const { phoneCall } = React.useContext<RootStoreProps>(MobXProviderContext);

  useEffect(() => {
    void phoneCall.refreshMicrophonePermissionStatus(null);
    phoneCall.checkSetDevice(SELECTED_MICROPHONE);
    phoneCall.checkSetDevice(SELECTED_SPEAKER);
    phoneCall.checkSetDevice(SELECTED_RING_DEVICE);
    navigator.mediaDevices.addEventListener('devicechange', () => {
      phoneCall.setIsThereDeviceChange(true);
      phoneCall.checkForIODevices();
    });
  }, []);

  const handleRefreshClick = (e) => {
    const element = e.target;
    void phoneCall.refreshMicrophonePermissionStatus(null);
    element.classList.add('blink_me');
    setTimeout(() => {
      element.classList.remove('blink_me');
    }, 1000);
  };

  const micOptLengh = phoneCall.microphoneOptions.length;
  const speakerOptLenght = phoneCall.speakerOptions.length;
  //on safari we cant change output/input device, the navigator.mediaDevices.enumerateDevices() returns us the list with empty values
  const allOptionsMic =
    micOptLengh < 2
      ? [
          {
            key: 'default',
            text: 'System Default Microphone Device',
            value: 'default',
          },
          ...phoneCall.microphoneOptions,
        ]
      : phoneCall.microphoneOptions;
  const allOptionsSpeakers =
    speakerOptLenght === 0
      ? [
          {
            key: 'default',
            text: 'System Default Speaker Device',
            value: 'default',
          },
          ...phoneCall.speakerOptions,
        ]
      : phoneCall.speakerOptions;
  const allOptionsRingtoneSpeakers =
    speakerOptLenght === 0
      ? [
          {
            key: 'default',
            text: 'System Default Ringtone Device',
            value: 'default',
          },
          ...phoneCall.speakerOptions,
        ]
      : phoneCall.speakerOptions;
  const defaultRingToneValue = allOptionsRingtoneSpeakers.find(
    (item) => item.value === phoneCall.theDefaultRingDevice
  )?.value;
  const defaultSoundValue = allOptionsSpeakers.find(
    (item) => item.value === phoneCall.theDefaultSpeaker
  )?.value;

  return (
    <Grid.Column>
      <Heading variant="h2">Audio</Heading>
      <SettingsDropdown
        label="Microphone"
        options={allOptionsMic}
        onChange={phoneCall.setTheMic}
        value={phoneCall.theDefaultMic}
        icon={micIcon}
        testid={`${testid}-dropdownMicrophone`}
      />
      {phoneCall.browserMicrophonePermissionState === 'denied' ? (
        <ErrorBanner
          show
          content="To make and receive calls, enable microphone access in your browser settings."
          action={
            <TextButton
              variant="inverted"
              testid={`${testid}-errorBanner-buttonRefresh`}
              onClick={handleRefreshClick}
            >
              Refresh
            </TextButton>
          }
        />
      ) : (
        <div className="settings-select flex-column">
          <span className="sub-headers settings-select--label">
            Test Microphone
          </span>
          <div className="flex-align-items-center flex-row">
            <ToggleButton
              onChange={() => phoneCall.toggleTestMicrophoneEnabled()}
              checked={phoneCall.testMicrophoneEnabled}
              testid={`${testid}-toggleButtonTestMicrophone`}
            />
            <ReactMic
              record={phoneCall.testMicrophoneEnabled}
              className="soundwaves"
              strokeColor="#0099AD"
              onStop={() => stopAllStreams()}
            />
          </div>
        </div>
      )}
      <SettingsDropdown
        label="Speakers"
        options={allOptionsSpeakers}
        onChange={phoneCall.setTheSpeaker}
        value={defaultSoundValue}
        icon={headphonesIcon}
        testid={`${testid}-dropdownSpeakers`}
      />
      <SettingsDropdown
        label="Ring options"
        options={allOptionsRingtoneSpeakers}
        onChange={phoneCall.setRingSpeaker}
        value={defaultRingToneValue}
        icon={headphonesIcon}
        testid={`${testid}-dropdownRingOptions`}
      />
    </Grid.Column>
  );
});
