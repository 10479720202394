import styled from 'styled-components';

const QuestionMarkContainer = styled.div`
  border: 1px solid black;
  border-radius: 50%;
  padding: 2.5px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -7px;
  left: 2px;
  cursor: help;
`;

const IconQuestionMark = styled.img`
  width: 92%;
`;

export const Styled = {
  QuestionMarkContainer,
  IconQuestionMark,
};
