import type { FeatureFlagProps } from './types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

export const FeatureFlag: React.FC<FeatureFlagProps> = ({
  flag,
  fallback,
  children,
}) => {
  const flags = useFlags();

  return <>{flags?.[flag] ? children : fallback}</>;
};
