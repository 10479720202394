import type { PersonStore, PersonStoreState } from 'Modules/person/index.types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState: PersonStoreState = {
  account: null,
  location: null,
  locationId: null,
  shouldAskForRating: false,
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  gravatarUrl: '',
  timeZone: '',
  mobileNumber: '',
  primaryLineId: 0,
  voicemailInboxId: 0,
  created: '',
  lines: [],
  inboundNumbers: [],
  package: 'Unknown',
  canReceiveSMS: null,
  personalConferenceUrl: '',
  jobTitle: '',
  profilePictureUrl: '',
  department: '',
  token: '',
  role: '',
  isAvailablePhoneNumber: null,
  campaignEnabled10Dlc: null,
};

const personStore = create(
  devtools<PersonStore>((set) => ({
    ...initialState,
    setIsAvailablePhoneNumber: (enabled) =>
      set({ isAvailablePhoneNumber: enabled }),
    setCampaignEnabled10Dlc: (enabled) =>
      set({ campaignEnabled10Dlc: enabled }),
  }))
);

export const usePersonStore = personStore;
export const setAvailabilityPhoneNumberSelector = (state: PersonStore) =>
  state.isAvailablePhoneNumber;
