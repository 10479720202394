import { ReplyBox } from 'Components/shared/MessageBubble/ReplyBox';
import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';

/**
 * Build a `SimpleMarkdown` `replyBox` rule.
 * @returns A `SimpleMarkdown` rule which renders a reply
 */
export const buildReplyBox = (references) =>
  ({
    order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
    // First we check whether a string matches
    match: (matchSrc) => /^@ref_{[\w\d-]*} /.exec(matchSrc),
    // Then parse this string into a syntax node
    parse: (capture) => ({ content: capture[0] }),
    react: (_node, _output, state) => (
      <ReplyBox key={state.key} reference={references[0]} />
    ),
  } as ParserRule & ReactOutputRule);
