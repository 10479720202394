import { MobXProviderContext } from 'mobx-react';
import type { IMessageReferences } from 'Models/MessageModel';
import * as React from 'react';

import { RootStoreProps } from 'Stores/RootStore.types';
import { compileEditorMarkdown } from './compileEditorMarkdown';
import { compileViewerMarkdown } from './compileViewerMarkdown';

export type useMarkdownCompilerProps = (
  messageId: string,
  references?: IMessageReferences[]
) => {
  compileViewerMarkdown: (content: string) => React.ReactNode;
  compileEditorMarkdown: (content: string) => string;
};

export const useMarkdownCompiler: useMarkdownCompilerProps = (
  messageId,
  references = []
) => {
  const {
    message: { loadLinkPreview },
    participant: { selectParticipantPersons },
    conversation: { CurrentConversation },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const participantPersons = selectParticipantPersons(
    CurrentConversation?.id || ''
  );

  const includeMentions =
    CurrentConversation?.grouping === 'Channel' ||
    CurrentConversation?.grouping === 'Group';

  const buildLinkPreview = (url) => {
    messageId && loadLinkPreview(url, CurrentConversation?.id || '', messageId);
  };

  return {
    compileViewerMarkdown: (content: string) =>
      compileViewerMarkdown({
        content,
        includeMentions,
        participantPersons,
        buildLinkPreview,
        references,
      }),
    compileEditorMarkdown: (content: string) =>
      compileEditorMarkdown({ content, participantPersons, includeMentions }),
  };
};
