import styled from 'styled-components';
import {
  FlexColumn,
  FlexRow,
} from '../../../../../../styles/theme/GlobalStyle';

const ConnectionInfoStyled = styled.div`
  margin-top: 25px;
  div {
    &:first-child {
      font-size: 16px;
      font-weight: 600;
    }
    &:last-child {
      margin-top: 17px;
    }

    .copy-icon {
      margin-left: 30px;
    }
  }
`;

const LinkStyled = styled.span`
  color: ${(props) => props.theme.colors.secondary};
`;

const CopyConnectionInfoStyled = styled.div`
  ${FlexColumn}
  margin-top: 1rem !important;

  span:not(.toggle-more-phones) {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const FlexRowSpanStyled = styled.span`
  ${FlexRow}
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.secondary};
`;

const MorePhonesStyled = styled.div`
  ${FlexColumn}
  margin-top: 0 !important;
`;

const ToggleMorePhonesStyled = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

const VideoUrlStyled = styled.span`
  ${FlexRow}
  word-break: normal;
  color: ${(props) => props.theme.colors.secondary};
`;

const MorePhonesContainerStyled = styled.div`
  ${FlexColumn}
`;

const DialInInfoStyled = styled.div`
  margin-top: 0 !important;
`;

export const Styled = {
  ConnectionInfoStyled,
  CopyConnectionInfoStyled,
  DialInInfoStyled,
  FlexRowSpanStyled,
  LinkStyled,
  MorePhonesContainerStyled,
  MorePhonesStyled,
  ToggleMorePhonesStyled,
  VideoUrlStyled,
};
