import styled from 'styled-components';

const RightHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const BetaRef = styled.a`
  margin-right: 10px;
`;

const BetaButton = styled.div`
  display: block;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.textDarkBackground};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.linkHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.linkClick};
  }
`;

const BetaIcon = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 5px;
  color: white;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    margin-right: 0;
  }
`;

const BetaText = styled.span`
  white-space: nowrap;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    display: none;

    ${BetaButton}:hover & {
      display: inline-block;
    }
  }
`;

export const Styled = {
  RightHeaderWrapper,
  BetaRef,
  BetaButton,
  BetaIcon,
  BetaText,
};
