import type { PopupProps } from './types';
import React from 'react';
import { Popup as SemanticPopup } from 'semantic-ui-react';
import { Styled } from './index.styles';

export const Popup: React.FC<PopupProps> = ({
  content,
  trigger,
  position = 'bottom left',
}) => (
  <SemanticPopup
    {...{ content, trigger, position }}
    popper={<Styled.Popper />}
    inverted
    size="mini"
  />
);
