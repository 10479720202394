import { FAST_EMOJIS } from 'Constants/assets';
import { possibleStatuses, STATE_PRESENCE } from 'Constants/enums';
import * as React from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Input,
  Modal,
} from 'semantic-ui-react';
import { QuestionHelper } from '../shared/QuestionHelper';
import { EmojiPicker } from './EmojiPicker';
import { FastEmojiList } from './FastEmojiList';
import { ModalMessageProps, ModalMessageState } from './interfaces';

import { ModalClearStatusButton } from './ModalClearStatusButton';
import { ModalCloseButton } from './ModalCloseButton';
/**
 * Custom Modal using Semantic UI Modal as base
 */

export class ModalMessageStatus extends React.Component<
  ModalMessageProps,
  ModalMessageState
> {
  constructor(props) {
    super(props);
    this.state = {
      showEmoji: false,
      activeEmoji: '',
      message: '',
      initialized: false,
      userStatus: null,
    };
  }

  componentDidUpdate(prevProps) {
    this.updateStatus(prevProps);
  }

  getUserStatus = () => {
    const { personStore, uiStore } = this.props;
    return uiStore.selectPersonPresenceStatus(personStore.loggedInPersonId)
      .state;
  };

  updateStatus = (prevProps) => {
    const { title, message } = this.props.uiStore.selectPersonMessageStatus(
      this.props.personStore.loggedInPersonId
    );
    const userStatus = this.getUserStatus();
    if (!this.state.initialized && userStatus !== 'OffLine') {
      const updateState: ModalMessageState = {
        initialized: true,
        userStatus: userStatus === 'OnCall' ? 'Active' : userStatus,
      };

      if (message) {
        updateState.message = message;
      }

      if (title) {
        updateState.activeEmoji = title;
      }
      this.setState(updateState);
    } else if (prevProps.open !== this.props.open) {
      userStatus &&
        this.setState({
          userStatus,
        });
    }
  };

  onEmojiClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  toggleShowMessageStatusEmojis = (e) => {
    const { showEmoji } = this.state;
    this.setState({ showEmoji: !showEmoji });
    e.preventDefault();
    e.stopPropagation();
  };

  handleUpdate = () => {
    const { uiStore } = this.props;
    const { activeEmoji, message } = this.state;

    uiStore.updateMessageStatus(
      !activeEmoji && message ? FAST_EMOJIS[0].emoji : activeEmoji,
      message
    );
    uiStore.updatePresence(this.state.userStatus || 'Active', true, '');
    this.handleCloseModal();
  };

  handleStatusUpdate = (e, d: DropdownProps) => {
    this.setState({ userStatus: d.value as STATE_PRESENCE });
  };

  handleCloseEmojiPopup = (prop: boolean) => () =>
    this.state.showEmoji && this.setState({ showEmoji: prop });

  handleClear = () => {
    this.setState({ showEmoji: null, activeEmoji: '', message: '' });
  };

  handleOnSelect = ({ native }: any) => {
    this.setState({ showEmoji: !this.state.showEmoji, activeEmoji: native });
  };

  toogleEmojiPicker = () => this.setState({ showEmoji: !this.state.showEmoji });

  handleFastEmojiPick =
    ({ emoji, message }) =>
    () => {
      this.setState({ activeEmoji: emoji, message: message });
    };

  handleInputChange = (e) => {
    this.setState({ message: e.target.value });
  };

  handleCloseModal = () => {
    this.props.onClose(null, null);
  };

  render() {
    const { open, size } = this.props;
    const testid = 'modalMessageStatus';

    return (
      <div
        className="custom-modal-bg-dim"
        style={{ display: open ? 'visible' : 'none' }}
      >
        <Modal
          {...{ size, open }}
          dimmer="blurring"
          closeOnDimmerClick
          centered
          onClose={this.handleCloseModal}
          onClick={this.handleCloseEmojiPopup(false)}
          className="message-status-modal"
        >
          <Modal.Header>
            <div className="title-modal-status-messages">
              Set Status
              <QuestionHelper
                {...{ testid }}
                content="Your status will be displayed to other users until you clear it."
              />
              <ModalCloseButton
                {...{ testid }}
                onClick={this.handleCloseModal}
              />
            </div>
            <div className="sub-title-modal">UPDATE YOUR STATUS</div>
            <div className="flex-row flex-align-items-center border-input">
              <EmojiPicker
                {...{ testid }}
                onEmojiClick={this.onEmojiClick}
                showEmoji={this.state.showEmoji}
                handleOnSelect={this.handleOnSelect}
                toogleEmojiPicker={this.toogleEmojiPicker}
                activeEmoji={this.state.activeEmoji}
                toggleShowMessageStatusEmojis={
                  this.toggleShowMessageStatusEmojis
                }
              />
              <Input
                className="flex-grow-shrink"
                placeholder="Out for lunch"
                data-testid={`${testid}-inputStatus`}
                value={this.state.message || ''}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="flex-row message-status-helper-container">
              <FastEmojiList
                {...{ testid }}
                activeEmoji={this.state.activeEmoji}
                handleFastEmojiPick={this.handleFastEmojiPick}
              />
              <ModalClearStatusButton
                {...{ testid }}
                onClick={this.handleClear}
                content="Clear status"
              />
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className="sub-title-modal">
              SET AVAILABILITY
              <QuestionHelper
                {...{ testid }}
                content="Your availability will be set to selected value as long as you don't set it back to “active”"
              />
            </div>
            <ul className="message-status-fast">
              <Dropdown
                data-testid={`${testid}-dropdownAvailability`}
                placeholder="Select an option"
                fluid
                selection
                value={this.state.userStatus || ''}
                options={possibleStatuses}
                onChange={this.handleStatusUpdate}
              />
            </ul>
            <div className="buttons-message-status">
              <Button
                content="CANCEL"
                onClick={this.handleCloseModal}
                data-testid={`${testid}-buttonCancel`}
              />
              <Button
                content="SAVE CHANGES"
                onClick={this.handleUpdate}
                data-testid={`${testid}-buttonSave`}
              />
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default ModalMessageStatus;
