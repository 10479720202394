import type { AxiosPromise, AxiosResponse } from 'axios';
import { CONVERSATION_GROUPING } from 'Constants/enums';
import {
  API_ENDPOINTS,
  BACKFILL_THROTTLE_MS,
  BV_ENV,
  EMPTY_TIMEUUID,
  IS_ELECTRON,
  TIMEZONE_IDENTIFIER,
  VIDEO_CALL_TIMEOUT,
} from 'Constants/env';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import { ILoadingOlderMessagesState } from 'Interfaces/components';
import { isArray, isEmpty } from 'lodash';
import {
  action,
  computed,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { createTransformer, fromPromise } from 'mobx-utils';
import type { IPromiseBasedObservable, IViewModel } from 'mobx-utils';
import { ContactModelBase } from 'Models/ContactModel';
import { LastScroll } from 'Models/ConversationLastScroll';
import type {
  IConversationConferenceModel,
  IConversationModel,
  INewConference,
  IPrivateConversationConference,
} from 'Models/ConversationModel';
import { ConversationModel, PersonModel } from 'Models/index';
import {
  IMessageDocument,
  IMessageModelConference,
  IMessageModelConferencePersonId,
} from 'Models/MessageModel';
import { IParticipantCreate, IParticipantModel } from 'Models/ParticipantModel';
import { IPinnedMessages } from 'Models/PinnedMessageModel';
import { IVideoHistory, IVideoHistoryDetails } from 'Models/VideoHistoryModel';
import { IRecordingVideo } from 'Models/VideoRecordings';
import moment from 'moment-timezone';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { configureConferencePopupWindow } from 'Utils/windowUtils';
import API from '../api';
import { Contact } from '../models/Contacts';
import { formatNumberWithNationalCode } from '../utils/phoneUtil';
import type { RootStore } from '.';
import { BaseStore } from './BaseStore';

export class ConversationStore extends BaseStore {
  /**
   * Maintain a map of `ConversationModel`s.
   *
   * This is the central property used to store and access any `ConversationModel`.
   *
   * The `ConversationModel`s are instantiated and contain observable & computed properties and actions.
   */
  @observable
  public conversationByIdMap = observable.map<
    string,
    IPromiseBasedObservable<AxiosResponseT<ConversationModel>>
  >();

  @observable
  public conversationByIdRecentHist = observable.map<
    string,
    IPromiseBasedObservable<AxiosResponseT<ConversationModel>>
  >();

  @observable
  public conferenceConversationByIdMap =
    observable.map<
      IPromiseBasedObservable<AxiosResponseT<ConversationModel>>
    >();

  @observable
  conversationVm: ConversationModel & IViewModel<ConversationModel>;

  /**
   * Id of the previous conversation
   * @observable
   * @type {string | undefined}
   */
  @observable
  previousConversation: string;

  /**
   * Function to set the previous conversation
   * @action
   * @param {string} previousConversationId
   */
  @action
  setPreviousConversation = (previousConversationId: string) =>
    (this.previousConversation = previousConversationId);

  @action
  setConversationVm = (
    model: ConversationModel & IViewModel<ConversationModel>
  ) => (this.conversationVm = model);

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /** Compute the current Conversation id based on the router value */
  @computed
  get CurrentConversation(): ConversationModel {
    // currentPath is needed to recompute when a store changes the url
    const currentConversationId = getCurrentConversationId(
      this.rootStore.routerStore.currentPath
    );
    if (currentConversationId === null) {
      return null;
    }
    const conv = this.selectConversationById(currentConversationId);
    if (conv === null || conv.state !== 'fulfilled') {
      return null;
    }
    return conv.value.data;
  }

  @observable
  ownerOfTheChat = '';

  @action
  setOwnerOfTheChat = (fullName: string) => (this.ownerOfTheChat = fullName);

  @observable
  public conversationLastScrollMap = observable.map<string, LastScroll>();

  @observable
  public isLoadingOlderMessagesMap = observable.map<
    string,
    ILoadingOlderMessagesState
  >();

  @observable
  public messageHistorybyConversationDateMap: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<IConversationModel>>
  > = null;

  // Load Conversations and Favorite Conversations (may be overlap, need to handle exclusivity in views)
  @observable public loadConversationsLazyStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<IConversationModel>>
  > = null;

  @observable
  public loadFavoriteConversationsLazyStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<IConversationModel>>
  > = null;

  @observable
  public loadOrCreateConversationWithStatus: IPromiseBasedObservable<
    AxiosResponseT<IConversationModel>
  > = null;

  @observable public createConversationPostLazyStatus: IPromiseBasedObservable<
    AxiosResponseT<IConversationModel>
  > = null;

  // Update Conversation & Participant `readMessageId`
  @observable
  public updateConversationPatchStatus: IPromiseBasedObservable<AxiosResponse> =
    null;

  @observable
  public updateParticipantLastReadMessagePatchStatus: IPromiseBasedObservable<AxiosResponse> =
    null;

  // Add/Remove Conversation to/from Favorites
  @observable
  public addConversationToFavoritesStatus: IPromiseBasedObservable<AxiosResponse> =
    null;

  @observable
  public removeConversationFromFavoritesStatus: IPromiseBasedObservable<AxiosResponse> =
    null;

  /** Select a a `IPromiseBasedObservable` representing a `Conversation` by its `id`, from `this.conversationByIdMap.get(conversationId)` */
  selectConversationById = createTransformer((conversationId: string) => {
    if (this.conversationByIdMap.has(conversationId)) {
      return this.conversationByIdMap.get(conversationId);
    } else if (this.conversationByIdRecentHist.has(conversationId)) {
      return this.conversationByIdRecentHist.get(conversationId);
    }
    return null;
  });

  @observable
  pageNumberVideoSearch: number;

  @action
  setPageNumberVideoSearch = (num: number) =>
    (this.pageNumberVideoSearch = num);

  @observable
  videoHistoryList: AxiosResponse<IVideoHistory>;

  @action
  setVideoHistoryList = (videoHistory: AxiosResponse<IVideoHistory>) =>
    (this.videoHistoryList = videoHistory);

  @observable
  videoHistoryDetails: AxiosResponse<IVideoHistoryDetails>;

  @action
  setVideoHistoryDetails = (
    videoHistoryDetails: AxiosResponse<IVideoHistoryDetails>
  ) => (this.videoHistoryDetails = videoHistoryDetails);

  @observable
  privateRoomNameTaken = false;

  @action
  setPrivateRoomNameTaken = (isTaken: boolean) =>
    (this.privateRoomNameTaken = isTaken);

  @observable
  privateRoomData: IPrivateConversationConference;

  @action
  setPrivateRoomInfo = (data) => (this.privateRoomData = data);

  @observable
  topicNameTaken = false;

  @observable
  showLinkModal = false;

  @action
  toogleLinkModal = () => (this.showLinkModal = !this.showLinkModal);

  @action
  topicNameIsTaken = () => {
    this.topicNameTaken = true;
  };

  @observable
  adHocGroupParticipants: (PersonModel | ContactModelBase)[];

  @action
  setAdHocGrouParticipants = (
    participants: (PersonModel | ContactModelBase)[]
  ) => (this.adHocGroupParticipants = participants);

  @action
  setActiveConversationError = (setError: boolean) => {
    this.activeConversationError = setError;
  };

  @observable
  activeConversationError = false;

  @observable
  channelInfoDetails: {
    channelConversationId: string;
    channelMode: string;
    channelType: string;
  } = {
    channelConversationId: '0',
    channelMode: 'new',
    channelType: 'person',
  };

  @action
  setChannelInfoDetails = (
    channelConversationId: string,
    channelMode: string,
    channelType = 'person'
  ) => {
    this.channelInfoDetails = {
      channelConversationId,
      channelMode,
      channelType,
    };
  };

  @action
  clearNameTaken = () => {
    this.topicNameTaken = false;
  };

  @observable
  listOfIncomingVideoCalls: Map<string, IMessageModelConferencePersonId> =
    new Map();

  @action
  addVideoConferenceToList = (
    conference: IMessageModelConference,
    personId: number
  ) => {
    const alreadyInList = this.listOfIncomingVideoCalls.has(conference.id);

    if (!alreadyInList) {
      this.listOfIncomingVideoCalls.set(conference.id, {
        ...conference,
        personId: personId,
      });

      this.addVideoConferenceTimeoutToList(
        conference.id,
        setTimeout(() => {
          this.removeVideoConferenceFromList(conference.id);
        }, VIDEO_CALL_TIMEOUT)
      );
    }
  };

  @observable
  videoConferenceTimeoutList: Map<string, ReturnType<typeof setTimeout>> =
    new Map();

  @action
  addVideoConferenceTimeoutToList = (
    conferenceId: string,
    timeout: ReturnType<typeof setTimeout>
  ) => {
    this.videoConferenceTimeoutList.set(conferenceId, timeout);
  };

  @action
  removeVideoConferenceTimeoutFromList = (conferenceId: string) => {
    const timeout = this.videoConferenceTimeoutList.has(conferenceId);
    if (timeout) {
      clearTimeout(this.videoConferenceTimeoutList.get(conferenceId));
      this.videoConferenceTimeoutList.delete(conferenceId);
    }
  };

  @action
  removeVideoConferenceFromList = (conferenceId: string) => {
    const conference = this.listOfIncomingVideoCalls.has(conferenceId);
    if (conference) {
      this.removeVideoConferenceTimeoutFromList(conferenceId);
      this.listOfIncomingVideoCalls.delete(conferenceId);
    }
  };

  @action
  setLoadOrCreateConversationWithStatus = (
    status: IPromiseBasedObservable<AxiosResponseT<IConversationModel>>
  ) => (this.loadOrCreateConversationWithStatus = status);

  /** Get an existing `LastScroll`, or if it's missing, construct a new instance of `LastScroll` */
  getOrConstructConversationLastScroll = (conversationId: string) => {
    let convLastScroll: LastScroll;
    if (!this.conversationLastScrollMap.has(conversationId)) {
      convLastScroll = new LastScroll();
      this.conversationLastScrollMap.set(conversationId, convLastScroll);
    } else {
      convLastScroll = this.conversationLastScrollMap.get(conversationId);
    }
    return convLastScroll;
  };

  @action
  assignLastScroll = (conversationId: string, ...ls: Partial<LastScroll>[]) => {
    const convLastScroll =
      this.getOrConstructConversationLastScroll(conversationId);
    if (convLastScroll.assignLastScroll(...ls)) {
      this.conversationLastScrollMap.set(conversationId, convLastScroll);
    }
    return convLastScroll;
  };

  @action
  setIsLoadingOlderMessages = (conversationId: string, isLoading: boolean) => {
    const nextStatus: ILoadingOlderMessagesState = {
      isLoading,
      lastLoadTime: null,
    };
    if (isLoading) {
      nextStatus.lastLoadTime = moment().tz(TIMEZONE_IDENTIFIER).toISOString();
    } else if (this.isLoadingOlderMessagesMap.has(conversationId)) {
      nextStatus.lastLoadTime =
        this.isLoadingOlderMessagesMap.get(conversationId).lastLoadTime;
    }
    this.isLoadingOlderMessagesMap.set(conversationId, nextStatus);
  };

  selectCanLoadOlderMessages = createTransformer((conversationId: string) => {
    // If there is no entry, assume we can load
    if (!this.isLoadingOlderMessagesMap.has(conversationId)) {
      return true;
    }
    const isLoadingStatus = this.isLoadingOlderMessagesMap.get(conversationId);
    const lastLoadDiff =
      isLoadingStatus.lastLoadTime !== null
        ? moment()
            .tz(TIMEZONE_IDENTIFIER)
            .diff(
              moment.tz(isLoadingStatus.lastLoadTime, TIMEZONE_IDENTIFIER),
              'ms'
            )
        : BACKFILL_THROTTLE_MS;
    return (
      !isLoadingStatus.isLoading &&
      (isLoadingStatus.lastLoadTime === null ||
        lastLoadDiff >= BACKFILL_THROTTLE_MS)
    );
  });

  /**
   * Returns a Promise, fulfilled when clearing data is complete
   */
  @action
  clearAllData = () => {
    const currConversationId = getCurrentConversationId();
    return new Promise<void>((resolve) => {
      if (
        !isEmpty(currConversationId) &&
        this.CurrentConversation?.isActiveParticipant
      ) {
        // If a Conversation is Active on logout/close, mark its latest Message as the lastReadMessageId (catches the case where another Conversation wasn't selected to mark the current one read)
        const convMessages =
          this.rootStore.messageStore.groupedMessagesByConversationMap.has(
            currConversationId
          )
            ? this.rootStore.messageStore.groupedMessagesByConversationMap.get(
                currConversationId
              )
            : null;
        if (!isEmpty(convMessages)) {
          console.debug(
            `Marking Message ${convMessages.NewestMessageId} as read for ${currConversationId}`
          );
          this.clearData();
          // Mark the Conversation's newest Message Id as read
          this.rootStore.participantStore
            .updateMyLastReadMessage(
              currConversationId,
              convMessages.NewestMessageId
            )
            .then(() => {
              resolve();
            });
        } else {
          console.warn(
            `Marking newest Message as read failed for ${currConversationId}`
          );
        }
      } else {
        this.clearData();
        resolve();
      }
    });
  };

  @action
  clearDataForPreferenceChange = () => {
    return new Promise<void>((resolve) => {
      this.clearData();
      resolve();
    });
  };

  @action
  clearData = () => {
    this.conversationByIdMap.clear();
    this.conversationByIdRecentHist.clear();
    this.loadConversationsLazyStatus = null;
    this.loadFavoriteConversationsLazyStatus = null;
    this.loadOrCreateConversationWithStatus = null;
    this.createConversationPostLazyStatus = null;
    this.updateConversationPatchStatus = null;
    this.updateParticipantLastReadMessagePatchStatus = null;
    this.addConversationToFavoritesStatus = null;
    this.removeConversationFromFavoritesStatus = null;
  };

  @computed
  get LoggedInUserActiveConferenceConversation() {
    const loggedInPersonId = this.rootStore.personStore.loggedInPersonId;
    const presenceStatus =
      this.rootStore.uiStore.loadPresenceIfMissing(loggedInPersonId);

    const listOfConvs = [];
    this.conversationByIdMap.forEach((cpbo) => {
      const conv = cpbo.case({
        fulfilled: (resp) => resp.data,
      });
      if (conv !== undefined) {
        listOfConvs.push(conv);
      }
    });

    /** Note: Currently going off `OnCall` presence state since there is no `OnConferenceVideo`.
     * Should change this check in future if new presence state is created for video (2-11-2019)
     * returns conversation model with both active conference/logged in user inside conference, or null if one not found
     */
    return (
      listOfConvs.find(function (conv) {
        if (
          !isNullOrUndefined(conv.activeConference) &&
          presenceStatus.state === 'OnCall' &&
          !isEmpty(presenceStatus.info) &&
          presenceStatus.info.toUpperCase().indexOf('voxeet'.toUpperCase()) >=
            0 &&
          conv.activeConference.sessionId === presenceStatus.info.split('/')[1]
        ) {
          return conv;
        }
      }) || null
    );
  }

  @computed
  get FavoriteConversationIds() {
    if (this.loadFavoriteConversationsLazyStatus !== null) {
      return observable.array(
        this.loadFavoriteConversationsLazyStatus.case({
          fulfilled: (fcs) => fcs.data.results.map((r) => r.id),
        }) || [],
        { deep: false }
      );
    }
    return observable.array<string>(null, { deep: false });
  }

  @computed
  get AllConversationIds() {
    if (this.conversationByIdMap !== null) {
      const conversationIdArr = observable.array(
        this.loadFavoriteConversationsLazyStatus.case({
          fulfilled: (fcs) => fcs.data.results.map((r) => r.id),
        }) || [],
        { deep: false }
      );

      conversationIdArr.push(
        ...observable.array(
          this.loadConversationsLazyStatus.case({
            fulfilled: (fcs) => fcs.data.results.map((r) => r.id),
          }) || [],
          { deep: false }
        )
      );
      return conversationIdArr;
    }
    return observable.array<string>(null, { deep: false });
  }

  @computed
  get FavoriteConversations() {
    return observable.array(
      this.FavoriteConversationIds.map((fcId) => {
        if (this.conversationByIdMap.has(fcId as string)) {
          return this.conversationByIdMap.get(fcId as string).case({
            fulfilled: (fcResp) => fcResp.data,
          });
        } else {
          return;
        }
      }).filter((x) => x !== undefined),
      { deep: false }
    );
  }

  @computed
  get AllConversations() {
    return observable.array(
      this.AllConversationIds.map((fcId) => {
        if (this.conversationByIdMap.has(fcId as string)) {
          return this.conversationByIdMap.get(fcId as string).case({
            fulfilled: (fcResp) => fcResp.data,
          });
        } else {
          return;
        }
      }).filter((x) => x !== undefined),
      { deep: false }
    );
  }

  @computed
  get FavoritePeopleIds() {
    const loggedInPersonId = this.rootStore.personStore.loggedInPersonId;
    const favoritePeopleIds = [];
    this.FavoriteConversations.forEach((convo) => {
      if (convo.grouping === 'OneOnOne' && convo.participants.length > 0) {
        convo.participants.forEach((p) => {
          if (p.hasOwnProperty('personId') && p.personId !== loggedInPersonId) {
            favoritePeopleIds.push(p.personId);
          }
        });
      }
    });
    return observable.array(favoritePeopleIds, { deep: false });
  }

  @computed
  get RecentConversations() {
    const nfConversations: ConversationModel[] = [];
    this.conversationByIdRecentHist.forEach((cpbo) => {
      const conv = cpbo?.case({
        fulfilled: (resp) => resp.data,
      });
      // Only return conversations with message history or of channel grouping type
      if (
        conv !== undefined &&
        ((conv.lastMessageId && !isEmpty(conv.lastMessageId)) ||
          conv.grouping === 'Channel' ||
          conv.grouping === 'Group')
      ) {
        nfConversations.push(conv);
      }
    });
    return observable.array(nfConversations, { deep: false });
  }

  @action
  removeFromRecentHistList = async (conversationId: string) => {
    if (conversationId === EMPTY_TIMEUUID) {
      console.warn('removeFromRecentHistList aborted on EMPTY_TIMEUUID');
      return null;
    }
    await this.rootStore.personStore.waitUntilLoggedIn();
    const removeConversationPbo = fromPromise<
      AxiosResponseT<ConversationModel>
    >(
      API.delete(
        API_ENDPOINTS.ConversationRemove(conversationId, 'LastMessage')
      )
    );
    removeConversationPbo.case({
      rejected: (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error removing Conversation'
        ),
      fulfilled: (resp) => this.removeLocalConversationFromList(resp.data.id),
    });
  };

  /** fetches a single `Conversation` from backend and returns it */
  @action
  fetchConversationById = async (conversationId: string) => {
    if (conversationId === EMPTY_TIMEUUID) {
      console.warn('loadConversationMessages aborted on EMPTY_TIMEUUID');
      return null;
    }
    await this.rootStore.personStore.waitUntilLoggedIn();

    return runInAction(() =>
      API.get(API_ENDPOINTS.ConversationById(conversationId)).then(
        (convResp) => convResp.data,
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error loading Conversation'
          )
      )
    );
  };

  /** Load a single `Conversation` into `this.conversationByIdMap` */
  @action
  loadConversationByIdGet = async (
    conversationId: string,
    markAsRecent = true
  ) => {
    if (conversationId === EMPTY_TIMEUUID) {
      console.warn('loadConversationMessages aborted on EMPTY_TIMEUUID');
      return null;
    }
    await this.rootStore.personStore.waitUntilLoggedIn();

    return runInAction(() => {
      const loadConversationPbo = fromPromise<
        AxiosResponseT<ConversationModel>
      >(API.get(API_ENDPOINTS.ConversationById(conversationId)));
      loadConversationPbo.then(
        (response) =>
          this.loadSingleConversationSuccess(response, markAsRecent),
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error loading Conversation'
          )
      );

      return loadConversationPbo.then((resp) => {
        const conv = resp.data;

        if (!conv?.id) {
          return null;
        }

        const convPbo = fromPromise.resolve({
          ...resp,
          data: ConversationModel.FromResponseDto(conv),
        });
        if (!this.conversationByIdMap.has(conversationId)) {
          this.conversationByIdMap.set(conv.id, convPbo);
          if (markAsRecent === true) {
            this.conversationByIdRecentHist.set(conv.id, conv.id as any);
          }
        }
        return (
          this.conversationByIdMap.get(conv.id) ||
          this.conversationByIdRecentHist.get(conv.id)
        );
      });
    });
  };

  /**
   * Ensures user is logged in before creating conference and redirecting to video conference window
   * @param conversationId ...
   * @param popupWindowRef (Optional) if provided, will use this `Window` ref instead of creating one. **Does nothing if IS_ELECTRON**
   */
  @action
  postConferenceByConversationId = (
    conversationId: string,
    popupWindowRef?: Window
  ) => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.post(API_ENDPOINTS.Conference, {
        conversationId,
        provider: 'bhive',
      }).then(
        (res: AxiosResponseT<IConversationConferenceModel>) => {
          pushToGTMDataLayer('joinVideoConference', {
            conversationId,
            videoConferenceSessionId: res.data.id,
          });
          this.openVideoPopup(res.data.id, popupWindowRef);
        },
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error Creating New Meeting room'
          )
      );
    });
  };

  @action
  postNewConference = (data: INewConference, popupWindowRef?: Window) => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.post(API_ENDPOINTS.Conference, data).then(
        (res: AxiosResponseT<any>) => {
          pushToGTMDataLayer('joinVideoConference', {
            conferenceId: res.data.id,
            videoConferenceSessionId: res.data.id,
          });
          if (data.shouldStart) {
            this.openVideoPopup(res.data.id, popupWindowRef);
          }
          return res;
        },
        (reason) => {
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error Creating New Meeting room'
          );
          return null;
        }
      );
    });
  };

  @action
  postConferencePrivateRoom = () => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.post(API_ENDPOINTS.ConferencePrivate).then(
        (res: AxiosResponseT<IPrivateConversationConference>) => {
          pushToGTMDataLayer('createdPrivateRoom', {
            id: res.data.id,
            videoConferenceSessionId: res.data.id,
          });
          this.setPrivateRoomInfo(res.data);
        },
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error Creating New Meeting room'
          )
      );
    });
  };

  createPinnedMessages = (conversationId: string, messageId: string) => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.post(API_ENDPOINTS.ConversationPinnedMess(conversationId), {
        messageId: messageId,
      }).then(
        (res: AxiosResponseT<IPinnedMessages>) => {
          pushToGTMDataLayer('Pinned message created', {
            conferenceId: res.data.id,
            videoConferenceSessionId: res.data.id,
          });
          return res;
        },
        (reason) => {
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error Creating New Pinned Message'
          );
          return undefined;
        }
      );
    });
  };

  getPinnedMessages = (conversationId: string) => {
    const pinnedMessages =
      this.rootStore.uiStore.listOfPinnedMessages.get(conversationId);
    if (pinnedMessages) {
      return pinnedMessages;
    }
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.get(API_ENDPOINTS.ConversationPinnedMess(conversationId)).then(
        (res: AxiosResponseT<IPinnedMessages[]>) => {
          pushToGTMDataLayer('Pinned message created', {
            conversationId: conversationId,
          });
          const pinnedDataMessages = res.data;
          runInAction(() =>
            this.rootStore.uiStore.listOfPinnedMessages.set(
              conversationId,
              pinnedDataMessages
            )
          );
          document.getElementById('message-input')?.click();
          return res;
        },
        (reason) => {
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error Getting Pinned Messages'
          );
        }
      );
    });
  };

  deletePinnedMessages = (conversationId: string, messageId: string) => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.delete(
        API_ENDPOINTS.ConversationPinnedMess(conversationId) + `/${messageId}`
      ).then(
        (res: AxiosResponseT<any>) => {
          pushToGTMDataLayer('Pinned message created', {
            conferenceId: res.data.id,
            videoConferenceSessionId: res.data.id,
          });
          return res;
        },
        (reason) => {
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error Deleting Pinned Message'
          );
          return undefined;
        }
      );
    });
  };

  @action
  updateConferencePrivateRoom = (roomName: string) => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      return API.put(API_ENDPOINTS.ConferencePrivate, {
        id: roomName,
        provider: 'bhive',
      }).then(
        (res: AxiosResponseT<IPrivateConversationConference>) => {
          pushToGTMDataLayer('updatedPrivateRoom', {
            id: res.data.id,
            videoConferenceSessionId: res.data.id,
          });
          this.setPrivateRoomNameTaken(false);
          this.setPrivateRoomInfo(res.data);
          return res;
        },
        (reason) => {
          const {
            status,
            data: { code },
          } = reason.response;
          if (status === 409) {
            if (code === 'Conflict') {
              this.setPrivateRoomNameTaken(true);
            } else if (code === 'InUse') {
              this.setActiveConversationError(true);
            }
            return reason;
          }
          if (reason.response.status === 500) {
            this.setActiveConversationError(true);
            return reason;
          }
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            reason.message
          );
          return reason;
        }
      );
    });
  };

  @action
  openVideoPopup = (conferenceId, popupWindowRef?: Window) => {
    const windowRef = !IS_ELECTRON
      ? popupWindowRef || configureConferencePopupWindow()
      : null;
    this.rootStore.uiStore.navigateVideoConferenceToSession(
      conferenceId,
      windowRef
    );
    // Check for active call here, if so, hang up. Cannot be in both active call and video conference
    if (!isNullOrUndefined(this.rootStore.phoneStore.ActivePhoneCall)) {
      this.rootStore.phoneStore.ActivePhoneCall.hangUp();
    }
    return windowRef;
  };

  /** Load a single `Conversation` into `this.conversationByIdMap` only if the key (`conversationId`) is missing */
  @action
  loadConversationByIdIfMissingGet = async (
    conversationId: string,
    markAsRecent = true
  ) => {
    if (!this.conversationByIdMap.has(conversationId)) {
      return this.loadConversationByIdGet(conversationId, markAsRecent);
    }
    return this.conversationByIdMap.get(conversationId);
  };

  /** Convert plain object `IConversationModel` response DTO into `ConversationModel` with observable properties and actions. */
  @action
  loadSingleConversationSuccess = (
    convResp: AxiosResponseT<IConversationModel>,
    markAsRecent = true
  ) => {
    /* Compensates for a bug where a non-existent conversationId returns 204 No Content from the API.
           OK to ignore since there is nothing we can do with the empty response (RP 2018-05-03) */
    if (convResp.status !== 204) {
      const conv = convResp.data;
      const convPbo = fromPromise.resolve({
        ...convResp,
        data: ConversationModel.FromResponseDto(conv),
      });
      this.conversationByIdMap.set(conv.id as string, convPbo);
      if (markAsRecent === true) {
        this.conversationByIdRecentHist.set(conv.id as string, convPbo);
      }
      // Only set unread counts if there isn't already a record for this Conversation
      if (
        conv.unreadCount > 0 &&
        !this.rootStore.uiStore.conversationUnreadCounts.has(conv.id as string)
      ) {
        // TODO: Once [BC-2019] is done and unread Mentions is a count (instead of boolean), use that count to initialize. (RP 2019-07-30)
        this.rootStore.uiStore.setConversationAndTotalUnreadCount(
          conv.id as string,
          conv.unreadCount,
          convPbo,
          conv.unreadMentionsCount
        );
      }
      // Eagerly load the `Participant`s for a "OneOnOne" `Conversation`
      if (conv.grouping === 'OneOnOne') {
        this.rootStore.participantStore.loadConversationParticipantsIfMissing(
          conv.id as string
        );
      }
    }
  };

  /** Load history of users conversations */
  @action
  loadConversationHistoryBasedOnDateGet = async (
    conversationCurrentDate: string,
    conversationSubDate: string
  ) => {
    await this.rootStore.personStore.waitUntilLoggedIn();

    return runInAction(() => {
      this.messageHistorybyConversationDateMap = fromPromise(
        API.get(
          API_ENDPOINTS.ConversationHistoryByDateRange(
            conversationCurrentDate,
            conversationSubDate
          )
        )
      );
      return this.messageHistorybyConversationDateMap.then(
        (resp) => this.loadConversationHistoryBasedOnDateSuccess(resp),
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error loading Conversations'
          )
      );
    });
  };

  @action
  loadConversationHistoryBasedOnDateSuccess = (
    resp: AxiosResponseT<ResultsCollection<IConversationModel>>
  ) => {
    const returnArray: ConversationModel[] = [];
    resp.data.results.forEach((conv) => {
      if (!this.conversationByIdMap.has(conv.id as string)) {
        this.conversationByIdMap.set(
          conv.id as string,
          fromPromise.resolve({
            ...resp,
            data: ConversationModel.FromResponseDto(conv),
          })
        );
      }
      returnArray.push(ConversationModel.FromResponseDto(conv));
      if (
        !this.rootStore.participantStore.participantByConvMap.has(
          conv.id.toString()
        )
      ) {
        this.rootStore.participantStore.loadConversationParticipants(
          conv.id.toString()
        );
      }
    });

    return returnArray;
  };

  @action
  loadVideoHistory = (limit: number, pageNum = 1, append: boolean) => {
    this.setPageNumberVideoSearch(pageNum);
    const skipNum = (pageNum - 1) * 20;
    const resp = API.get(
      API_ENDPOINTS.VideoHistory + limit + '&skip=' + skipNum
    );
    resp.then(
      (item) => {
        if (append) {
          item.data.results = [
            ...this.videoHistoryList.data.results,
            ...item.data.results,
          ];
        }
        this.setVideoHistoryList(item);
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading Conversations'
        )
    );
  };

  @action
  loadDetailsVideoHistory = (historyId: number) => {
    const resp = API.get(API_ENDPOINTS.VideoHistoryDetails + historyId);
    resp.then(
      (item) => {
        this.setVideoHistoryDetails(item);
        if (this.rootStore.uiStore.displayForbiddenScreen) {
          this.rootStore.uiStore.setDisplayForbiddenScreen(false);
        }
      },
      (reason) => {
        if (reason.response.status === 403) {
          this.rootStore.uiStore.setDisplayForbiddenScreen(true);
          return reason;
        }
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading Conversations'
        );
      }
    );
    return resp;
  };

  @action
  loadVideoRecordings = (
    historyId: number,
    recordingIds: string | string[],
    createSherableLink: boolean
  ) => {
    const formatedRecordingIds = isArray(recordingIds)
      ? recordingIds.map((ids) => ({ recordingId: ids }))
      : [{ recordingId: recordingIds }];
    const data = {
      recordings: formatedRecordingIds,
      provider: 'bhive',
      createSherableLink: createSherableLink,
    };

    const resp: AxiosPromise<IRecordingVideo> = API.post(
      API_ENDPOINTS.VideoRecordings(historyId),
      data
    );
    return resp.then(
      (recordings) => {
        return recordings;
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading Conversations'
        )
    );
  };

  @action
  undoDeleteVideoRecordings = (
    historyId: number,
    recordingIds: string | string[]
  ) => {
    const formatedRecordingIds = isArray(recordingIds)
      ? recordingIds.map((ids) => ({ recordingId: ids }))
      : [{ recordingId: recordingIds }];
    const data = { recordings: formatedRecordingIds, provider: 'bhive' };

    const resp: AxiosPromise<IRecordingVideo> = API.put(
      API_ENDPOINTS.VideoRecordings(historyId) + '/undelete',
      data
    );
    return resp.then(
      (recordings) => {
        return recordings;
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error while undeleting Video Record'
        )
    );
  };

  @action
  deleteVideoRecordings = (
    historyId: number,
    recordingIds: string | string[]
  ) => {
    const formatedRecordingIds = isArray(recordingIds)
      ? recordingIds.map((ids) => ({ recordingId: ids }))
      : [{ recordingId: recordingIds }];
    const data = { recordings: formatedRecordingIds, provider: 'bhive' };
    const resp: AxiosPromise<IRecordingVideo> = API.delete(
      API_ENDPOINTS.VideoRecordings(historyId),
      { data }
    );
    return resp.then(
      (recordings) => {
        return recordings;
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading Conversations'
        )
    );
  };

  @action
  loadConversationsGet = async () => {
    const {
      rootStore: { preferenceStore, notificationStore, personStore },
    } = this;
    await personStore.waitUntilLoggedIn();
    await preferenceStore.getExistingPreferenceData();

    return runInAction(() => {
      this.loadConversationsLazyStatus = fromPromise(
        API.get(
          API_ENDPOINTS.ConversationsQuery({
            Limit: 100,
            IncludeRemoved: true,
            ShowCallMessagesInChat:
              preferenceStore.preferences.showCallMessagesInChat,
          })
        )
      );
      this.loadConversationsLazyStatus.then(
        (resp) => this.loadConversationsGetSuccess(resp, true),
        (reason) =>
          notificationStore.addAxiosErrorNotification(
            reason,
            'Error loading Conversations'
          )
      );
      return this.loadConversationsLazyStatus;
    });
  };

  /**
   * Load the list of `Conversation`s if it hasn't been loaded, or (optionally) if it isn't pending.
   *
   * Returns `undefined` if no load was triggered
   *
   * @param loadIfNotPending If true, will trigger a load even if the data is present, as long as the Promise is not "pending"
   * @returns
   */
  @action
  loadConversationsConditionallyGet(loadIfNotPending: boolean) {
    if (
      this.loadConversationsLazyStatus === null ||
      (loadIfNotPending && this.loadConversationsLazyStatus.state !== 'pending')
    ) {
      return this.loadConversationsGet();
    }
  }

  @action
  loadFavoriteConversationsGet = async () => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    return runInAction(() => {
      this.loadFavoriteConversationsLazyStatus = fromPromise(
        API.get(API_ENDPOINTS.ConversationsFavorites)
      );
      this.loadFavoriteConversationsLazyStatus.then(
        this.loadConversationsGetSuccess, // Uses the same success action as "non-favorite" Conversations
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error loading favorite Conversations'
          )
      );
      return this.loadFavoriteConversationsLazyStatus;
    });
  };

  /**
   * Load the list of favorite `Conversation`s if it hasn't been loaded, or (optionally) if it isn't pending.
   *
   * Returns `undefined` if no load was triggered
   *
   * @param loadIfNotPending If true, will trigger a load even if the data is present, as long as the Promise is not "pending"
   * @returns
   */
  @action
  loadFavoriteConversationsConditionallyGet(loadIfNotPending: boolean) {
    if (
      this.loadFavoriteConversationsLazyStatus === null ||
      (loadIfNotPending &&
        this.loadFavoriteConversationsLazyStatus.state !== 'pending')
    ) {
      return this.loadFavoriteConversationsGet();
    }
  }

  @action
  reloadAndBackfillAllConversations = async () => {
    const {
      rootStore: { personStore, preferenceStore, participantStore },
    } = this;
    await personStore.waitUntilLoggedIn();
    await preferenceStore.getExistingPreferenceData();
    await Promise.all([
      this.loadConversationsGet(),
      this.loadFavoriteConversationsGet(),
    ]);
    // Intentionally not awaited, fire-and-forget
    return [
      participantStore.reloadParticipantsForAllConversations(),
      personStore.loadMessagesSinceNewestMessageForAllConversations(),
    ];
  };

  createConversationFromAdHoc = (
    participantIds: string[],
    rawMessageContent: string,
    sendAsSms?: boolean,
    uploadedFiles?: IMessageDocument[]
  ) => {
    const { messageStore, participantStore, conversationStore } =
      this.rootStore;

    return conversationStore
      .loadOrCreateAdHocGroup(participantIds, false)
      .then(async ({ data }) => {
        participantStore.resetNewConversationPersons();
        this.rootStore.routerStore.push(`/chat/conversations/${data.id}/menu`);
        await messageStore.createMessagePost(
          data.id as string,
          sendAsSms ? null : { text: rawMessageContent },
          sendAsSms ? { text: rawMessageContent } : null,
          'Group',
          uploadedFiles
        );
        return data;
      })
      .catch((e) => console.log(e.message));
  };

  /** Load or create a OneOnOne conversation with a `personId` or `phone` (mutually exclusive) */
  @action
  loadOrCreateConversationWithPost = (
    personId?: number | string,
    phone?: string,
    email?: string
  ) => {
    if (personId && phone && email) {
      throw Error(
        'loadOrCreateConversationWithPost accepts either personId or phone or email, but not both.'
      );
    }
    if (!personId && !phone && !email) {
      throw Error(
        'loadOrCreateConversationWithPost accepts either personId or phone or meial, one of which must be provided.'
      );
    }
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      this.setLoadOrCreateConversationWithStatus(
        fromPromise<AxiosResponseT<ConversationModel>>(
          API.post(API_ENDPOINTS.ConversationWith, {
            personId,
            phone,
            email,
          })
        )
      );

      this.loadOrCreateConversationWithStatus.then(
        this.loadSingleConversationSuccess,
        (reason) =>
          BV_ENV !== 'production' &&
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error loading Conversation'
          )
      );
      return this.loadOrCreateConversationWithStatus;
    });
  };

  isParsable = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  handleAdHocGroups = (participants: string[]) => {
    const personIds = participants?.map((participantId) => {
      if (!isNaN(participantId as any)) {
        return this.channelInfoDetails.channelType === 'phone'
          ? this.createParticipantForNumberGroup(participantId)
          : this.createParticipantForPersonGroup(participantId);
      }
    });
    if (
      !isEmpty(personIds) &&
      !personIds.some((item) => item === undefined) &&
      personIds.length > 1
    ) {
      this.setAdHocGrouParticipants(personIds);
      const adHocGroup = this.loadOrCreateAdHocGroup(participants, true);
      return adHocGroup.then((resp) => {
        if (resp.data) {
          this.rootStore.routerStore.push(
            `/chat/conversations/${resp.data.id}/menu`
          );
          return resp.data;
        }
      });
    }
  };

  createParticipantForNumberGroup = (value) => {
    return new ContactModelBase({
      id: value,
      name: value,
      phoneNumber: value,
      contactType: 'Unknown',
      created: '',
      searchableType: 'SearchableDetailsContact',
    });
  };

  createParticipantForPersonGroup = (participantId) => {
    const {
      personStore: { loadPersonByIdGetIfMissingGet },
    } = this.rootStore;
    const personPbo = loadPersonByIdGetIfMissingGet(parseInt(participantId));
    return personPbo?.case({
      fulfilled: (resp) => {
        return resp.data;
      },
    });
  };

  addProperName = (
    participantIds: Partial<IParticipantModel>[],
    from?: string
  ): string => {
    const participantData: (PersonModel | Contact)[] = participantIds?.map(
      ({ personId, phone }) => {
        if (
          this.isParsable(personId) &&
          personId !== 0 &&
          personId !== this.rootStore.personStore.loggedInPersonId
        ) {
          const personPbo =
            this.rootStore.personStore.loadPersonByIdGetIfMissingGet(personId);
          return personPbo?.case({
            fulfilled: (resp) => {
              return resp.data;
            },
          });
        } else if (phone) {
          const externalContact =
            this.rootStore.personStore.getExtrContactByPhoneNumber(phone);
          return externalContact;
        }
      }
    );

    const names = participantData?.map((participant, index) => {
      if (participant) {
        const name = `${participant.firstName} ${
          participant.lastName ? participant.lastName[0] : ' '
        }.`;
        return from !== 'channelHeader'
          ? name
          : typeof participant.DisplayName === 'function'
          ? participant.DisplayName()
          : participant.DisplayName;
      }
      return formatNumberWithNationalCode(participantIds[index].phone);
    });

    return names
      .filter((n) => !!n)
      .sort()
      .join(', ');
  };

  @action
  createDummyConversationAdHoc = (
    participants: Partial<IParticipantModel>[]
  ) => {
    const conv = new ConversationModel(
      'AdHocGroup',
      new Date().toISOString(),
      0,
      this.addProperName(participants),
      'Send the message in order to create AdHoc Group',
      'Group'
    );
    this.conversationByIdMap.set(conv.id, null);
    this.conversationByIdRecentHist.set(conv.id, null);
    this.rootStore.uiStore.setConversationAndTotalUnreadCount(
      conv.id,
      conv.unreadCount,
      null,
      conv.unreadMentionsCount
    );
  };

  // load or create add hoc group
  @action
  loadOrCreateAdHocGroup = (
    participantIds: string[],
    onlyGet: boolean
  ): Promise<AxiosResponseT<IConversationModel>> => {
    if (!participantIds) {
      throw Error(
        'You have to select two+ participants in order to create group'
      );
    }
    const formatedParticipantIds = this.mapIdsOrPhoneNumbers(participantIds);
    const data = {
      participants: formatedParticipantIds,
      getOnly: onlyGet,
    };
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      this.createConversationPostLazyStatus = fromPromise<
        AxiosResponseT<ConversationModel>
      >(API.post(API_ENDPOINTS.ConversationWithGroups, data));
      this.createConversationPostLazyStatus.then(
        (resp) => {
          if (resp.data.grouping === 'Group') {
            resp.data.topic = this.addProperName(
              resp.data?.participants.filter(
                (participant) =>
                  participant.personId !==
                  this.rootStore.personStore.loggedInPersonId
              )
            );
          }
          runInAction(() => {
            this.conversationByIdMap.set(
              resp.data.id as string,
              fromPromise.resolve({
                ...resp,
                data: ConversationModel.FromResponseDto(resp.data),
              })
            );
            this.conversationByIdRecentHist.set(
              resp.data.id as string,
              fromPromise.resolve({
                ...resp,
                data: ConversationModel.FromResponseDto(resp.data),
              })
            );
          });
          this.rootStore.participantStore.loadConversationParticipantsIfMissing(
            resp.data.id as string
          );
          pushToGTMDataLayer('adHoc channel created', {
            conversationId: resp.data.id,
          });
          if (!onlyGet) this.rootStore.uiStore.setSelectedTopBarUsers(null);
        },
        (error) => {
          if (error.response && error.response.status === 409) {
            // 409 Conflict
            this.rootStore.conversationStore.topicNameIsTaken();
          } else if (error.response && error.response.status === 404) {
            this.rootStore.routerStore.push(`/chat/adHocGroup`);
          } else {
            throw error;
          }
        }
      );
      return this.createConversationPostLazyStatus;
    });
  };

  mapIdsOrPhoneNumbers = (participants) => {
    return this.channelInfoDetails.channelType === 'person'
      ? participants.map((personId) => {
          return { personId: parseInt(personId) };
        })
      : participants.map((phone) => {
          return { phone };
        });
  };

  /**
   * Update a `Conversation`. Updateable fields are `topic` and `description`
   */
  @action
  updateConversationPatch = async (
    conversationId: string,
    topic?: string,
    description?: string
  ) => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    return runInAction(() => {
      const conv = this.conversationByIdMap.get(conversationId).case({
        fulfilled: (c) => c.data,
      });

      if (conv) {
        const patches = [];
        if (!isEmpty(topic) || !isEmpty(conv.topic)) {
          patches.push({
            op: 'replace',
            path: '/topic',
            value: topic || conv.topic,
          });
        } else {
          return null;
        }
        if (!isEmpty(description)) {
          patches.push({
            op: 'replace',
            path: '/description',
            value: description,
          });
        }
        this.updateConversationPatchStatus = fromPromise(
          API.patch(API_ENDPOINTS.ConversationById(conversationId), patches)
        );
        this.updateConversationPatchStatus.then(
          () => {
            conv.commitEdit();
            pushToGTMDataLayer('conversationEdit', {
              conversationId,
            });
          },
          (error) => {
            conv.revertEdit();
            if (error?.response?.status === 409) {
              this.rootStore.conversationStore.topicNameIsTaken();
              return;
            }

            if (error?.response?.status === 304) {
              // 304 Not Modified, topic name did not change, we can ignore this
              return;
            }

            throw error;
          }
        );
      } else {
        this.updateConversationPatchStatus = null;
      }
      // TODO: Global error message (RP 09/27/2017)
      return this.updateConversationPatchStatus;
    });
  };

  @action
  addConversationToFavoritesPatch = async (conversationId: string) => {
    await this.rootStore.personStore.waitUntilLoggedIn();

    return runInAction(() => {
      this.addConversationToFavoritesStatus = fromPromise(
        API.patch(API_ENDPOINTS.Preference, [
          {
            op: 'add',
            path: '/FavoriteConversationIds/-',
            value: conversationId,
          },
        ])
      );
      // Reload the list of Favorite `Conversation`s
      this.addConversationToFavoritesStatus.then(() => {
        pushToGTMDataLayer('conversationFavoriteToggle', {
          conversationId,
        });
        this.loadFavoriteConversationsGet();
      });
      return this.addConversationToFavoritesStatus;
    });
  };

  @action
  changeConvTopic = (participants) => {
    const otherParticipants = participants?.filter(
      (participant) =>
        parseInt(participant.id) !== this.rootStore.personStore.loggedInPersonId
    );
    return otherParticipants ? this.addProperName(otherParticipants) : '';
  };

  @action
  removeConversationFromFavoritesPatch = async (conversationId: string) => {
    await this.rootStore.personStore.waitUntilLoggedIn();

    return runInAction(() => {
      this.rootStore.preferenceStore
        .getExistingPreferenceData()
        .then((resp) => {
          return resp.favoriteConversationIds.findIndex(
            (c) => c === conversationId
          );
        })
        .then((conversationIndex) => {
          if (conversationIndex > -1) {
            this.removeConversationFromFavoritesStatus = fromPromise(
              API.patch(API_ENDPOINTS.Preference, [
                {
                  op: 'remove',
                  path: '/FavoriteConversationIds/' + conversationIndex,
                },
              ])
            );
            // Reload the list of Favorite `Conversation`s
            this.removeConversationFromFavoritesStatus.then(() => {
              pushToGTMDataLayer('conversationFavoriteToggle', {
                conversationId,
              });
              this.loadFavoriteConversationsGet();
            });
          } else {
            /* If the `Conversation` somehow couldn't be found in `loadFavoriteConversationsLazyStatus` (or is still loading), ;
             * clear the `IPromiseBasedObservable` tracking the `removeConversationFromFavoritesStatus` */
            this.removeConversationFromFavoritesStatus = null;
          }
          return Promise.resolve(null);
        });

      // TODO: Global error message (RP 09/27/2017)
      return this.removeConversationFromFavoritesStatus;
    });
  };

  @action
  updateParticipantLastReadMessagePatch = async (
    conversationId: string,
    participantId: string,
    messageId: string
  ) => {
    await this.rootStore.personStore.waitUntilLoggedIn();

    return runInAction(() => {
      const participant = this.rootStore.participantStore.participantByConvMap
        .get(conversationId)
        .case({
          fulfilled: (p) => p.data.results.find((x) => x.id === participantId),
        });

      if (participant !== undefined) {
        this.updateParticipantLastReadMessagePatchStatus = fromPromise(
          API.patch(
            API_ENDPOINTS.ConversationParticipantById(
              conversationId,
              participantId
            ),
            [
              {
                op: 'replace',
                path: '/readMessageId',
                value: messageId,
              },
            ]
          )
        );
        // Update the observable `readMessageId` property on the `Participant`
        this.updateParticipantLastReadMessagePatchStatus.then(() => {
          runInAction(() => (participant.readMessageId = messageId));
        });
      } else {
        /* If the `Participant` somehow couldn't be found in `this.rootStore.participantStore.participantByConvMap` (or is still loading),
         * clear the `IPromiseBasedObservable` tracking the `updateParticipantLastReadMessagePatchStatus` */
        this.updateParticipantLastReadMessagePatchStatus = null;
      }
      // TODO: Global error message (RP 09/27/2017)
      return this.updateParticipantLastReadMessagePatchStatus;
    });
  };

  @action
  createConversationPost = (
    topic: string,
    description: string,
    grouping: CONVERSATION_GROUPING,
    ...participants: IParticipantCreate[]
  ) => {
    return this.rootStore.personStore.waitUntilLoggedIn().then(() => {
      const firstPtc: IParticipantCreate = this.createFirstParticipant();
      const newConv = {
        description,
        grouping,
        participants: [firstPtc].concat(
          participants || ([] as IParticipantCreate[])
        ),
        topic,
      };
      this.createConversationPostLazyStatus = fromPromise(
        API.post(API_ENDPOINTS.Conversations, newConv)
      );
      this.createConversationPostLazyStatus.then(
        (resp) => {
          this.rootStore.uiStore.setGroupModal(false);
          runInAction(() => {
            this.conversationByIdMap.set(
              resp.data.id as string,
              fromPromise.resolve({
                ...resp,
                data: ConversationModel.FromResponseDto(resp.data),
              })
            );
            this.conversationByIdRecentHist.set(
              resp.data.id as string,
              fromPromise.resolve({
                ...resp,
                data: ConversationModel.FromResponseDto(resp.data),
              })
            );
          });
          this.rootStore.participantStore.loadConversationParticipantsIfMissing(
            resp.data.id as string
          );
          pushToGTMDataLayer(
            grouping === 'Channel' ? 'channelCreate' : 'oneOnOneCreate',
            {
              conversationId: resp.data.id,
            }
          );
        },
        (error) => {
          if (error.response && error.response.status === 409) {
            // 409 Conflict
            this.rootStore.conversationStore.topicNameIsTaken();
          } else {
            throw error;
          }
        }
      );
      return this.createConversationPostLazyStatus;
    });
  };

  createFirstParticipant = () => {
    return {
      personId: this.rootStore.personStore.loggedInPersonId,
    };
  };

  /**
   * Remove a `Conversation` from the store, as well as the related `Participant` and `Message` data from the respective stores.
   *
   * Intended to handle `ConversationLeave` push notifications
   */
  @action
  removeLocalConversationData = (conversationId: string) => {
    this.rootStore.messageStore.removeLocalConversationData(conversationId);
    this.rootStore.participantStore.removeLocalConversationData(conversationId);
    this.removeLocalConversationFromList(conversationId);
  };

  @action
  removeLocalConversationFromList = (conversationId: string) => {
    this.conversationByIdRecentHist.delete(conversationId);
    const currentConversationId = getCurrentConversationId();
    if (currentConversationId === conversationId) {
      this.rootStore.routerStore.push('/chat');
    }
    if (this.FavoriteConversationIds.includes(conversationId)) {
      this.loadFavoriteConversationsGet();
    }
  };

  redirectToWelcome = () => {
    const availableRoutes = [
      'video-app',
      'voicemail',
      'fax',
      'directory',
      'addressBook',
      'calendar',
      'settings',
    ];
    const shouldRedirect = !availableRoutes.some((route) =>
      window?.location.href.includes(route)
    );
    if (shouldRedirect) {
      this.rootStore.routerStore.push('/welcome');
    }
  };

  /**
   * Maps each `Conversation` into `this.conversationByIdMap` as `AxiosResponseT<ConversationModel>`.
   * Includes a shallow clone of the `AxiosResponseT` fields (except `data`) from `this.loadConversationsLazyStatus`
   */
  @action
  private loadConversationsGetSuccess = (
    resp: AxiosResponseT<ResultsCollection<IConversationModel>>,
    redirectIfEmpty?: boolean
  ) => {
    redirectIfEmpty &&
      resp.data.results.length === 0 &&
      this.redirectToWelcome();
    resp.data.results.forEach((conv) => {
      const convPbo = fromPromise.resolve({
        ...resp,
        data: ConversationModel.FromResponseDto(conv),
      });
      this.conversationByIdMap.set(conv.id as string, convPbo);
      this.conversationByIdRecentHist.set(conv.id as string, convPbo);
      if (conv.unreadCount > 0) {
        // TODO: Once [BC-2019] is done and unread Mentions is a count (instead of boolean), use that count to initialize. (RP 2019-07-30)
        this.rootStore.uiStore.setConversationAndTotalUnreadCount(
          conv.id as string,
          conv.unreadCount,
          convPbo,
          conv.unreadMentionsCount
        );
      } else {
        this.rootStore.uiStore.setConversationAndTotalUnreadCount(
          conv.id as string,
          0,
          convPbo,
          0
        );
      }

      // Eagerly load the `Participant`s for a "OneOnOne" `Conversation`
      if (conv.grouping === 'OneOnOne') {
        this.rootStore.participantStore.loadConversationParticipantsIfMissing(
          conv.id as string
        );
      }
    });
  };
}

export default ConversationStore;
