import { functions, isEqual, omit } from 'lodash';
import { action, observable, makeObservable } from 'mobx';

export interface ILastScroll {
  /** Distance in px between the top of the viewable area and the top of the scrollable area */
  scrollTop: number;
  /** Total height in px of the scrollable area */
  scrollHeight: number;
  /** Height in px of the viewable area, measured by the browser */
  clientHeight: number;
}

export class LastScroll implements ILastScroll {
  /** Distance in px between the top of the viewable area and the top of the scrollable area */
  @observable
  scrollTop = 0;

  /** Total height in px of the scrollable area */
  @observable
  scrollHeight = 0;

  /** Height in px of the viewable area, measured by the browser */
  @observable
  clientHeight = 0;

  /**
   * Assign the provided partial `ILastScroll`s into `this`. Assignment only occurs if the next computed values are different from the existing values.
   * @returns `boolean` indicating whether the assignment occurred.
   */
  @action
  assignLastScroll = (...ls: Partial<ILastScroll>[]) => {
    const candidateScroll = Object.assign({}, this, ...ls);
    if (
      !isEqual(
        omit(candidateScroll, functions(this)),
        omit(this, functions(this))
      )
    ) {
      Object.assign(this, ...ls);
      return true;
    }
    return false;
  };

  constructor() {
    makeObservable(this);
  }
}
