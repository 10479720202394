import type { IParticipantPerson } from 'Interfaces/participantPerson';
import type { IMessageReferences } from 'Models/MessageModel';
import React from 'react';
import {
  ReactOutputRule,
  Rules,
  defaultRules,
  parserFor,
  reactFor,
  ruleOutput,
} from 'simple-markdown';
import {
  buildAudioLink,
  buildCodeBlock,
  buildCodeSnippet,
  buildHeading,
  buildImageLink,
  buildInlineVideo,
  buildLinkText,
  buildMention,
  buildMentionKeyword,
  buildParagraph,
  buildRefLinkText,
  buildReplyBox,
  buildUnderline,
  buildVideoLink,
} from '.';


/**
 * Compile a Markdown formatted string to either React (or in the future, HTML, still working on that - RP 2018-11-18)
 *
 * @param content The input string to be compiled
 * @param includeMentions Whether to include rules for processing @Mentions (ex. only in `Channel` mode)
 * @param participantPersons An array of wrapped `ParticipantModel`s, linked with their `PersonModel`s, used for Mentions.
 * @param buildLinkPreview An `action` which should load the link preview for the Message model, given the URI.
 * @param references An array of references for processing replies.
 * @returns `React.ReactNode`
 */

export type compileViewerMarkdownProps = (props: {
  content: string;
  includeMentions: boolean;
  participantPersons?: IParticipantPerson[];
  buildLinkPreview?: (nodeTarget: string) => void;
  references: IMessageReferences[];
}) => React.ReactNode;

export const compileViewerMarkdown: compileViewerMarkdownProps = ({
  buildLinkPreview,
  content,
  includeMentions,
  participantPersons,
  references,
}) => {
  // if message contains no text to display
  if (!content) return null;

  const markDownRules = {
    ...defaultRules,
    underline: buildUnderline(),
    singleBacktick: buildCodeSnippet(),
    codeBlock: buildCodeBlock(),
    heading: buildHeading(),
    paragraph: buildParagraph(),
    link: buildLinkText(buildLinkPreview),
    reflink: buildRefLinkText(),
    inlineVideoRule: buildInlineVideo(),
    imageLinkRule: buildImageLink(),
    videoLinkRule: buildVideoLink(),
    audioLinkRule: buildAudioLink(),
    ...(references.length && { replyBoxRule: buildReplyBox(references) }),
    ...(includeMentions && {
      mentionRule: buildMention(participantPersons),
      mentionKeywordRule: buildMentionKeyword(),
    }),
  } as Rules<ReactOutputRule>;

  const markDownParse = parserFor(markDownRules);
  const markDownOutput = reactFor(ruleOutput(markDownRules, 'react'));
  // Many rules require content to end in \n\n to be interpreted as a block.
  const parseTree = markDownParse(content + '\n\n', { inline: true });
  return markDownOutput(parseTree);
};
