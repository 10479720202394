import { Icon } from 'Components/shared/Icon';
import styled from 'styled-components';
import { theme } from '~/styles/theme/Theme';

const Wrapper = styled.div`
  max-width: 250px;
  word-break: break-all;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
`;

const StatusBadge = styled(Wrapper)<{ $hasError?: boolean }>`
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 5px;
  background-color: ${theme.colors.base};
  text-indent: 5px;
  font-size: ${theme.sizes.xxsmall};
  line-height: 1.8;
  color: ${({ $hasError }) =>
    $hasError ? theme.colorsV2.danger : theme.colors.text};
`;

const LineSpinner = styled.div`
  width: 12px;
  height: 12px;
  border: 2px solid ${theme.colors.border};
  border-right: 2px solid ${theme.colors.primary};
  border-radius: 50%;
  animation: rotating 1.5s linear infinite;
  -webkit-animation: rotating 1.5s linear infinite;
`;

const StyledIcon = styled(Icon)<{ $hasError?: boolean }>`
  display: inline-flex;
  color: ${({ $hasError }) =>
    $hasError ? theme.colorsV2.danger : theme.colors.text} !important;
`;

export const Styled = {
  Wrapper,
  StatusBadge,
  LineSpinner,
  Icon: StyledIcon,
};
