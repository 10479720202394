import { Input, Popup } from 'semantic-ui-react';
import React, { useState } from 'react';
import {
  selectProperVideoIcon,
  selectProperVideoTooltipMessageForOneOnOnes,
} from 'Utils/videoConferenceUtils';

import CopyOff from 'Assets/images/copy-link-inactive.svg';
import cx from 'classnames';

const PersonalVideoMeetingInfo = ({
  loggedInPersonVideoFeature,
  loggedInPersonId,
  conferenceIsActive,
  userIsInActiveVideoConference,
  placeVideoCallConference,
  selectPersonValueById,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleFocus = (event) => {
    event.target.select();
  };

  const copyLinkAndShowTooltip = () => {
    const ele = document.createElement('input');
    document.body.appendChild(ele);
    ele.setAttribute(
      'value',
      selectPersonValueById(loggedInPersonId).data.personalConferenceUrl
    );
    ele.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    ele.style.position = 'absolute';
    ele.style.left = '-9999px';
    ele.select();
    document.execCommand('copy');
    document.body.removeChild(ele);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 5000);
  };

  return (
    <div className="copy-link-section">
      Video Conference URL:
      <div>
        <Input
          id="share-url"
          onFocus={handleFocus}
          placeholder="Something went wrong, Please Report"
          value={
            selectPersonValueById(loggedInPersonId).data.personalConferenceUrl
          }
          readOnly
        />
      </div>
      {showTooltip && (
        <div className="bubble-tip copy-link-tip">
          COPIED!
          <div className="anchor-popup" />
        </div>
      )}
      <div className="wrapper-share-link-row">
        <div className="wrapper-share-link-column">
          <Popup
            inverted
            position="bottom left"
            trigger={
              <button
                className="custom-button"
                onClick={() => placeVideoCallConference(loggedInPersonId)}
                disabled={
                  !loggedInPersonVideoFeature.enabled ||
                  loggedInPersonId === 9 ||
                  loggedInPersonId === 862
                }
              >
                <img
                  className={cx('small-icon', {
                    'disabled-video-icon':
                      !conferenceIsActive && userIsInActiveVideoConference,
                    'disabled-active-video-icon':
                      conferenceIsActive && userIsInActiveVideoConference,
                  })}
                  src={selectProperVideoIcon(null, null, null, null)}
                />
              </button>
            }
          >
            <div>
              <div>
                {selectProperVideoTooltipMessageForOneOnOnes(null, null)}
              </div>
            </div>
          </Popup>
        </div>
        <div className="wrapper-share-link-column">
          <Popup
            inverted
            position="bottom left"
            content="Copy Video Conference Link"
            trigger={
              <button
                className="custom-button"
                onClick={copyLinkAndShowTooltip}
              >
                <img
                  className="video-icon"
                  src={CopyOff}
                  onMouseOver={(e) => (e.currentTarget.src = `${CopyOff}`)}
                />
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalVideoMeetingInfo;
