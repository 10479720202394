import { Icon } from 'Components/shared/Icon';
import styled, { css } from 'styled-components';

const TextLink = styled.a<{ onClick?: () => void; $underline: boolean }>`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  font-size: 14px !important;
  word-break: break-word;

  color: ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover} !important;
  }

  &:active {
    color: ${({ theme }) => theme.colors.linkClick} !important;
  }

  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${({ $underline }) =>
    $underline &&
    css`
      &,
      &:hover,
      &:active {
        text-decoration: underline;
      }
    `}
`;

const StyledIcon = styled(Icon)<{ onClick?: () => void }>`
  &,
  &:hover,
  &:active {
    color: unset !important;
  }

  ${({ theme }) => `margin-right: ${theme.sizes.xxsmall};`}
`;

export const Styled = {
  TextLink,
  Icon: StyledIcon,
};
