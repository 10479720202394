import cx from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
 isPhoneNumber } from 'Utils/phoneUtil';
import {
  Button,
  Grid,
  Image,
  Loader,
  Message,
} from '../../../../node_modules/semantic-ui-react';
import { PersonModel } from '../../models';
import { UserAvatar } from '../shared/UserAvatar';
import { CallIncomingListItemProps } from './interfaces';
const endCallIcon = require('../../assets/images/icon-phonecalls.svg');

export class CallIncomingListItem extends React.Component<
  CallIncomingListItemProps,
  {}
> {
  state = {
    callerIdName: this.props.phoneCall.callerIdName,
  };

  rejectCall = () => {
    const { phoneCall } = this.props;
    phoneCall.reject();
  };

  async componentDidMount() {
    await this.setCallerIdName();
  }

  async setCallerIdName() {
    if (isPhoneNumber(this.props.phoneCall.callerIdName)) {
      const phoneNumNoPlus = formatNumberNoPlusIfUS(
        this.props.phoneCall.phoneNumber
      );
      const personModel = await this.props.getPersonFromDirectory(
        phoneNumNoPlus
      );
      if (personModel) {
        this.setState({
          callerIdName: `${personModel.firstName} ${personModel.lastName}`,
        });
      }
    }
  }

  render() {
    const {
      phoneCall,
      showMinified,
      getExtrContactByPhoneNumber,
      selectUnreadCounts,
    } = this.props;
    const phoneNumNoPlus = formatNumberNoPlusIfUS(phoneCall.phoneNumber);
    const extrContact = getExtrContactByPhoneNumber(phoneNumNoPlus);
    const avatarEl =
      phoneCall.person !== null ? (
        phoneCall.person.case({
          pending: () => <Loader indeterminate active />,
          rejected: (reason) => (
            <Message
              visible
              error
              content={
                'Error Loading Person: ' + reason.response?.data?.message
              }
            />
          ),
          fulfilled: (response) => {
            const person = response.data;
            const image = person.profilePictureUrl;
            return image?.startsWith('http') ? (
              <img
                src={image}
                className={`${
                  phoneCall?.isCallConnecting ? 'transit-call-image-border' : ''
                }`}
              />
            ) : (
              <UserAvatar
                name={extrContact?.DisplayName?.()}
                selectUnreadCounts={selectUnreadCounts}
              />
            );
          },
        })
      ) : extrContact ? (
        <>
          {extrContact.pictureUrl ? (
            <img src={extrContact.pictureUrl} className="profile-image" />
          ) : (
            <UserAvatar
              name={extrContact?.DisplayName?.()}
              selectUnreadCounts={selectUnreadCounts}
            />
          )}
        </>
      ) : phoneCall.contact !== null ? (
        phoneCall.contact.case({
          fulfilled: () => {
            return <UserAvatar selectUnreadCounts={selectUnreadCounts} />;
          },
        })
      ) : (
        <UserAvatar
          name={phoneCall.callerIdName || '?'}
          selectUnreadCounts={selectUnreadCounts}
        />
      );
    return (
      <div
        key={phoneCall.pickUpTime + Math.random() * 100}
        className={cx('call-incoming-list-item', 'call-item', {
          minified: showMinified,
          connecting: phoneCall.isCallConnecting,
        })}
      >
        {!showMinified && (
          <div className="contact-image-wrapper">{avatarEl}</div>
        )}
        <div className="contact-info">
          {extrContact && !phoneCall.person && (
            <>
              <div className="contact-name">{extrContact?.DisplayName?.()}</div>
              {extrContact.emails?.length > 0 && (
                <div className="contact-number">
                  {extrContact?.emails[0]?.email}
                </div>
              )}
              <div className="contact-number">
                {formatNumberWithNationalCode(phoneCall.phoneNumber)}
              </div>
            </>
          )}
          {phoneCall.person &&
            phoneCall.person.case({
              fulfilled: (response) => {
                const person = PersonModel.FromResponseDto(response.data);
                return (
                  <>
                    <div className="contact-name">
                      {response?.data?.firstName} {response?.data?.lastName}
                    </div>
                    <div className="contact-number">
                      {response?.data?.email}
                    </div>
                    <div className="contact-number">
                      {person?.lines ? person?.formatNumber() : ''}
                    </div>
                  </>
                );
              },
            })}
          {!extrContact &&
            phoneCall.contact &&
            phoneCall.contact.case({
              fulfilled: (response) => {
                const calledIdName =
                  this.state.callerIdName ||
                  phoneCall.callerIdName ||
                  response?.data?.FormattedPhone;
                return (
                  <>
                    <div className="contact-name">{calledIdName}</div>
                    {/* TODO: since we have to push this to proudction we decided to comment this code out and once product realizes what they wanna do we will come back to this and fix it */}
                    {/* <div className="contact-name"> {response.data.DisplayName ? response.data.DisplayName : "Unknown Caller"} </div> */}
                    {!isPhoneNumber(calledIdName) && (
                      <div className="contact-number">
                        {response?.data?.FormattedPhone}
                      </div>
                    )}
                  </>
                );
              },
            })}
          {!extrContact &&
            !phoneCall.contact &&
            !phoneCall.person &&
            phoneCall.callerIdName && (
              <div className="contact-name">
                {isPhoneNumber(phoneCall.callerIdName)
                  ? this.state.callerIdName
                  : phoneCall.callerIdName}
              </div>
            )}
          <div className="transit-call-status">
            <span>
              {phoneCall.isCallConnecting ? 'is calling you' : 'connecting...'}
            </span>
          </div>
        </div>
        <Grid.Column className="call-buttons width-76" textAlign="center">
          <div className="btn-wrapper">
            <Button onClick={this.rejectCall} circular className="end-call">
              <Button.Content visible>
                <Image src={endCallIcon} />
              </Button.Content>
            </Button>
            <span className="btn-text">Cancel</span>
          </div>
          <div className="btn-wrapper">
            <Button
              onClick={phoneCall.pickup}
              circular
              className="answer-call-btn"
            >
              <Button.Content visible>
                <Image
                  src={endCallIcon}
                  style={{ transform: 'rotateZ(-140deg)' }}
                />
              </Button.Content>
            </Button>
            <span className="btn-text">Answer</span>
          </div>
        </Grid.Column>
      </div>
    );
  }
}

export default observer(CallIncomingListItem);
