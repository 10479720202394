import type { BannerProps, BannerType } from './index.types';
import { Icon, IconDefinition } from 'Components/shared/Icon';
import React from 'react';
import { Styled } from './index.styles';

const icons: Record<BannerType, IconDefinition> = {
  success: 'success-filled',
  error: 'error-filled',
  warning: 'warning-filled',
  info: 'info-filled',
};

/**
 * Banner component to display messages with optional actions and close functionality.
 *
 * This component can be used:
 * - Inside other components to display messages to the user.
 * - Within a Notification system.
 *
 * @param {Object} props - The properties object.
 * @returns {React.ReactElement} The rendered Banner component.
 */
export const Banner = ({
  type,
  id,
  title = null,
  message = null,
  action,
  onClose,
  isFullWidth = false,
  showCloseButton = true,
}: BannerProps): React.ReactElement => {
  const icon = icons[type];

  return (
    <Styled.Container $type={type} id={id} $isFullWidth={isFullWidth}>
      <Styled.Wrapper>
        <Styled.Content>
          {icon && (
            <Styled.IconWrapper $type={type}>
              <Icon icon={icon} />
            </Styled.IconWrapper>
          )}
          <Styled.TextContainer>
            {title && <Styled.Title>{title}</Styled.Title>}
            {message && (
              <Styled.MessageContainer $isOnlyMessage={!title}>
                <Styled.Message>{message}</Styled.Message>
              </Styled.MessageContainer>
            )}
          </Styled.TextContainer>
        </Styled.Content>
        {showCloseButton && (
          <Styled.CloseButton onClick={onClose}>
            <Icon icon="cancel" />
          </Styled.CloseButton>
        )}
      </Styled.Wrapper>

      {action && (
        <Styled.FooterActions>
          {action && (
            <Styled.Action onClick={action.onClick}>
              {action.label}
            </Styled.Action>
          )}
        </Styled.FooterActions>
      )}
    </Styled.Container>
  );
};
