import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';
import { Styled } from './buildCodeSnippet.styles';

/**
 * creates a code snippet when matching for backticks: `example`
 */
export const buildCodeSnippet = (): ParserRule & ReactOutputRule => ({
  order: SimpleMarkdown.defaultRules.em.order - 0.5,
  // First we check whether a string matches
  match: (matchSrc) => /^(?!``)`([\s\S]+?)`/.exec(matchSrc),
  // Then parse this string into a syntax node
  parse: (capture, parse, state) => ({ content: parse(capture[1], state) }),
  react: (node, recurseOutput, state) => (
    <Styled.CodeSnippet key={state.key}>
      {recurseOutput(node.content, state)}
    </Styled.CodeSnippet>
  ),
});
