import type { Identity } from './types';
import { Auth0Context } from '@auth0/auth0-react';
import linkIcon from 'Assets/images/link.svg';
import { Button } from 'Components/shared/Button';
import { Heading } from 'Components/shared/Heading';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import { SocialAccountIdentities } from './SocialAccountsIdentities';

export const SocialAccounts: React.FC = observer(() => {
  const testid = 'socialAccountsSettings';
  const auth0 = React.useContext(Auth0Context);
  const { person, notification } =
    React.useContext<RootStoreProps>(MobXProviderContext);

  const [identities, setIdentities] = useState<null | Identity[]>(null);

  useEffect(() => {
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    try {
      const res = await person.getAuth0UserProfile(auth0, auth0.user.sub);
      setIdentities(res.data.identities);
    } catch (error) {
      error.response?.data?.message &&
        notification.addNotification(
          error.response.data.message,
          null,
          'error'
        );
    }
  };

  const linkSocialAccount = async () => {
    try {
      const res = await person.linkSocailAccount(auth0);
      setIdentities(res.data);
    } catch (error) {
      error.response.data.message &&
        notification.addNotification(
          error.response.data.message,
          null,
          'error'
        );
    }
  };

  const unlinkSocialAccount = async (source) => {
    try {
      const res = await person.unlinkSocialAccount(auth0, source);
      setIdentities(res.data);
    } catch (error) {
      error.response.data.message &&
        notification.addNotification(
          error.response.data.message,
          null,
          'error'
        );
    }
  };

  const auth0Identities = identities?.filter?.(
    (identity) => !!identity.isSocial
  );

  return (
    <div className="socialAccounts-container">
      <Grid.Column>
        <Heading variant="h2">Social Accounts</Heading>
        <p className="settings-description">
          You can connect your social account with your bhive account for easier
          and quicker login experience.
        </p>
      </Grid.Column>
      <Grid.Column>
        <div className="identity-list">
          <div className="existing-identity">Existing Social Accounts</div>
          <div className="identity-wrapper">
            <SocialAccountIdentities
              {...{ auth0Identities, unlinkSocialAccount }}
              mapProperLogoProvide={person.mapProperLogoProvide}
              testid={`${testid}-socialAccountIdentities`}
            />
          </div>
          <div className="identity-boxes flex-row">
            <Button
              variant="primary"
              id="link-social-account"
              icon={<img src={linkIcon} />}
              content="Link social account"
              onClick={linkSocialAccount}
              testid={`${testid}-buttonLinkSocialAccount`}
            />
          </div>
        </div>
      </Grid.Column>
    </div>
  );
});
