import type { ModalCloseButtonProps } from './interfaces';
import CloseBtn from 'Assets/images/close-btn.svg';
import * as React from 'react';
import { Button } from 'semantic-ui-react';

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({
  onClick,
  testid,
}) => (
  <Button
    {...{ onClick }}
    className="close-button"
    compact
    basic
    size="small"
    data-testid={`${testid}-buttonClose`}
  >
    <img className="close-icon" src={CloseBtn} />
  </Button>
);
