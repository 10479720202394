import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';

/**
 * creates an underline when matching for double underscores: __example__
 */
export const buildUnderline = (): ParserRule & ReactOutputRule => ({
  order: SimpleMarkdown.defaultRules.em.order - 0.5,
  // First we check whether a string matches
  match: (matchSrc) => /^__([\s\S]+?)__(?!_)/.exec(matchSrc),
  // Then parse this string into a syntax node
  parse: (capture, parse, state) => ({ content: parse(capture[1], state) }),
  react: (node, recurseOutput, state) => (
    <u key={state.key}>{recurseOutput(node.content, state)}</u>
  ),
});
