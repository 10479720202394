import { DropDownItemTopBar } from 'Components/TopBar/DropDownItemTopBar';
import { TopBarDropdownContextProps } from 'Components/TopBar/contexts/TopBarDropdownContext';
import type {
  ContactModel,
  ConversationModel,
  IContactDto,
  IConversationModel,
  IPersonModel,
  PersonModel,
  SearchResult,
} from 'Models/index';
import React from 'react';
import type { ConversationStore } from 'Stores/index';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { createArrayOfPersonIds } from '../helpers/createArrayOfPersonIds';

export type createConversationsOptionsProps = (
  allUsersButNotCurrent:
    | IPersonModel[]
    | {
        source: IPersonModel | IContactDto | IConversationModel;
        match: string;
        score: number;
      }[]
    | SearchResult<PersonModel | ConversationModel | ContactModel>[],
  changeConvTopic: ConversationStore['changeConvTopic'],
  isNumericSearch?: boolean,
  searchQuery?: string
) => TopBarDropdownContextProps['options'];

type PersonVariant = 'group' | 'internal-contact';

// Helper function to get the variant
const getVariant = (person: PersonModel): PersonVariant => {
  return person.searchableType === 'SearchableDetailsConversation'
    ? 'group'
    : 'internal-contact';
};

// Helper function to get the person's full name
const getPersonFullName = (person: PersonModel): string => {
  return person.firstName && person.lastName
    ? `${person.firstName} ${person.lastName}`
    : person.firstName || person.lastName || '';
};

// Helper function to get the name
const getName = (
  person,
  variant: PersonVariant,
  changeConvTopic: ConversationStore['changeConvTopic']
) => {
  if (variant === 'group') {
    return (
      (person.topic as string) ||
      changeConvTopic(createArrayOfPersonIds(person.participants))
    );
  }

  return (
    getPersonFullName(person) ||
    formatNumberWithNationalCode(person.mobileNumber) ||
    person.mobileNumber
  );
};

// Refactored createConversationsOptions function
export const createConversationsOptions: createConversationsOptionsProps = (
  allUsersButNotCurrent,
  changeConvTopic,
  isNumericSearch,
  searchQuery
) => {
  return (
    allUsersButNotCurrent?.map((personSearch) => {
      const person = personSearch.source
        ? personSearch.source
        : (personSearch as PersonModel);

      const variant = getVariant(person);
      const name = getName(person, variant, changeConvTopic);

      return {
        key: person.id.toString(),
        value: person.id.toString(),
        text: isNumericSearch ? searchQuery : name,
        variant,
        label: (
          <DropDownItemTopBar
            key={person.id}
            {...(variant === 'group'
              ? { convType: 'Channel' }
              : {
                  userInfo:
                    person.firstName || person.lastName
                      ? {
                          personId: person.id.toString(),
                          keyValue: person.id.toString(),
                        }
                      : {
                          phoneNumbers: [person.phoneNumber],
                          keyValue: person.phoneNumber,
                        },
                })}
            organization={person.department}
            ignoreName={!(person.firstName || person.lastName)}
            role={person.role}
            {...{ variant, name }}
          />
        ),
      };
    }) || []
  );
};
