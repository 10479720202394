import {
  useFloating,
  FloatingPortal,
  flip,
  offset,
  FloatingArrow,
  arrow,
  autoUpdate,
  useDismiss,
} from '@floating-ui/react';
import EmojiActive from 'Assets/images/emoji-active.svg';
import EmojiOnHover from 'Assets/images/emoji-hover.svg';
import EmojiInactive from 'Assets/images/emoji-inactive.svg';
import { Picker as EmojiPicker } from 'emoji-mart';
import React, { useRef } from 'react';
import { theme } from '~/styles/theme/Theme';
import { Styled } from './index.styles';
import { EmojiButtonProps } from './types';

export const EmojiButton: React.FC<EmojiButtonProps> = ({
  isOpen,
  onClose,
  onOpen,
  onChooseEmoji,
  relativePosition,
}) => {
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    transform: false,
    open: isOpen,
    onOpenChange: isOpen ? onClose : onOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({ fallbackStrategy: 'initialPlacement' }),
      arrow({
        element: arrowRef,
      }),
    ],
    placement: 'bottom-end',
  });

  useDismiss(context);

  const portalTarget = relativePosition
    ? document.getElementById('chat-list-wrapper')
    : document.getElementById('root');

  return (
    <Styled.EmojiWrapper
      className="emoji-wrapper"
      onKeyDown={(e) => {
        if (e.key !== 'Escape') return;
        onClose();
        e.preventDefault();
        e.stopPropagation();
      }}
      $relative={relativePosition}
      data-testid={`emojiWrapper`}
      ref={refs.setReference}
    >
      <FloatingPortal root={portalTarget}>
        <Styled.EmojiBox
          ref={refs.setFloating}
          data-testid={`emojiBox`}
          style={{ ...floatingStyles }}
          aria-hidden={!isOpen}
        >
          {isOpen && (
            <>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                fill={theme.colors.border}
              />
              <EmojiPicker
                onSelect={onChooseEmoji}
                autoFocus
                onClose={onClose}
              />
            </>
          )}
        </Styled.EmojiBox>
      </FloatingPortal>

      <Styled.EmojiIconWrapper
        onClick={(e) => {
          isOpen ? onClose() : onOpen();
          e.preventDefault();
          e.stopPropagation();
        }}
        data-testid={`emojiIconWrapper`}
      >
        <img
          src={isOpen ? EmojiActive : EmojiInactive}
          onMouseOver={(e) =>
            (e.currentTarget.src = isOpen ? EmojiActive : EmojiOnHover)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = isOpen ? EmojiActive : EmojiInactive)
          }
          data-testid={`emojiIcon`}
        />
      </Styled.EmojiIconWrapper>
    </Styled.EmojiWrapper>
  );
};
