import { InputDialpad } from 'Components/InputDialpad';
import TransferDirectoryListItem from 'Components/TransferDirectoryListItem';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { debounce, isEmpty } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { Message } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import PhoneNumberUtil from 'Utils/phoneUtil';
import phoneUtil from 'Utils/phoneUtil';
import { Styled } from './index.styles';

const keypadIcon = require('../../assets/images/btn-keypad-gray.svg');

export const TransferDirectory: React.FC = observer(() => {
  const [pageNumber, setPageNumber] = React.useState(0);
  const [maxContacts, setMaxContacts] = React.useState(0);
  const [searchString, setSearchString] = React.useState<string>('');

  const {
    conversation: { CurrentConversation, FavoritePeopleIds },
    search,
    phoneCall: { ActivePhoneCall },
    participant: { updateMyLastReadMessage },
    pusher,
    ui: {
      selectPersonPresenceStatus,
      selectConversationUnreadCounts,
      setConversationAndTotalUnreadCount,
    },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const conversationId = CurrentConversation?.id || '';

  React.useEffect(() => {
    const getContactSearchHit = async () => {
      const transferContacts = await search.getPersonContactSearch(
        'TRANSFER',
        '',
        1,
        false,
        ['SearchableDetailsPerson', 'SearchableDetailsContact']
      );

      if (transferContacts?.data?.hits)
        setMaxContacts(transferContacts.data.hits);
      setPageNumber(1);
    };

    void getContactSearchHit();

    return () => {
      if (pusher.isOnline) ActivePhoneCall.setTransferMode(false);
    };
  }, []);

  const isProperPhoneNumber = (number: string) => {
    const phoneNumber = number.replace(/[^0-9+]/g, '');
    return PhoneNumberUtil.isPossibleNumberString(phoneNumber, 'US');
  };

  const toggleDialPad = () => {
    ActivePhoneCall.toggleDialPad();
  };

  const cancelTransfer = () => {
    if (ActivePhoneCall.showDialPad) toggleDialPad();

    ActivePhoneCall.clearKeyPress();
    ActivePhoneCall.setTransferMode(false);
    ActivePhoneCall.setWarmTransfer(false);
  };

  const formatNumber = (num: string): number => {
    if (phoneUtil.isPossibleNumberString(num, 'US')) {
      const parsePhoneNumber = phoneUtil.parse(num, 'US');
      return +phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL);
    } else {
      return +num;
    }
  };

  const directory = search.directoryMap['TRANSFER'];
  if (!directory) return null;

  const validUnknownSearch =
    !isEmpty(directory.query) &&
    isProperPhoneNumber(directory.query) &&
    directory.data &&
    directory.data.hits === 0;

  const handleLoadMoreContacts = debounce(async () => {
    if (maxContacts === directory.data.results.length) return null;

    const nextPageNumber = pageNumber + 1;
    setPageNumber(nextPageNumber);
    await search.getPersonContactSearch(
      'TRANSFER',
      searchString,
      nextPageNumber,
      true,
      ['SearchableDetailsPerson', 'SearchableDetailsContact']
    );
  }, 500);

  const handleInputChange = (newSearchString: string) => {
    setSearchString(newSearchString);
  };

  return (
    <>
      <Styled.SearchDialpadWrapper>
        <Styled.ToggleDialpadWrapper>
          <Styled.Image src={keypadIcon} onClick={toggleDialPad} />
          <Styled.CancelText onClick={cancelTransfer}>Cancel</Styled.CancelText>
        </Styled.ToggleDialpadWrapper>
        <InputDialpad
          phoneCallModel={ActivePhoneCall}
          reset={() => setPageNumber(1)}
          searchString={searchString}
          onChange={handleInputChange}
        />
      </Styled.SearchDialpadWrapper>
      <Styled.TransferDirectory>
        <Styled.TransferDirectoryTitle>DIRECTORY</Styled.TransferDirectoryTitle>
        <Styled.Divider />
        <Styled.TransferDirectoryWrapper id="transfer-list">
          <Styled.InfiniteScroll
            dataLength={pageNumber * 20} //This is important field to render the next data
            next={handleLoadMoreContacts}
            hasMore
            loader={<React.Fragment />}
            // below props only if you need pull down functionality
            refreshFunction={handleLoadMoreContacts}
            pullDownToRefresh
            pullDownToRefreshThreshold={2}
            scrollableTarget="transfer-list"
          >
            <Styled.TransferDirectoryList>
              {validUnknownSearch ? (
                <TransferDirectoryListItem
                  conversationId={conversationId}
                  key={'unknown-number'}
                  unknownUser={true}
                  unknownUserNumber={directory.query}
                  phoneCall={ActivePhoneCall}
                  selectPersonPresenceStatus={selectPersonPresenceStatus}
                  selectUnreadCounts={selectConversationUnreadCounts}
                  setConversationAndTotalUnreadCount={
                    setConversationAndTotalUnreadCount
                  }
                  updateMyLastReadMessage={updateMyLastReadMessage}
                  formatNumber={formatNumber}
                />
              ) : directory?.data?.hits > 0 ? (
                directory.data.results.map((item) => (
                  <TransferDirectoryListItem
                    conversationId={conversationId}
                    key={item.source.id}
                    contact={item}
                    phoneCall={ActivePhoneCall}
                    favoriteContactsList={FavoritePeopleIds}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectConversationUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    updateMyLastReadMessage={updateMyLastReadMessage}
                    formatNumber={formatNumber}
                  />
                ))
              ) : !isEmpty(directory.query) &&
                !isProperPhoneNumber(directory.query) ? (
                <div>
                  <Message
                    className="no-results-message"
                    visible
                    content={`No results found for "${directory.query}"`}
                  />
                </div>
              ) : null}
            </Styled.TransferDirectoryList>
          </Styled.InfiniteScroll>
        </Styled.TransferDirectoryWrapper>
      </Styled.TransferDirectory>
    </>
  );
});
