import { RootStore } from 'Stores/RootStore';

export abstract class BaseStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  protected readonly rootStore: RootStore;

  /** Clear data ex. on logout. Can return `void` or a Promise indicating it's complete */
  abstract clearAllData: () => void | Promise<void>;
}
