import type { LinkPreviewProps } from './index.types';
import linkIcon from 'Assets/images/link-icon.png';
import { BodyCopy } from 'Components/shared/BodyCopy';
import { Heading } from 'Components/shared/Heading';
import { IS_ELECTRON } from 'Constants/env';
import { observer } from 'mobx-react';
import React from 'react';
import { electronOpenExternal } from 'Utils/electronHelpers';
import { Styled } from './index.styles';

export const LinkPreview: React.FC<LinkPreviewProps> = observer(
  ({ url, image, title, description }) =>
    url.toLowerCase().startsWith('http') ? (
      <Styled.LinkPreviewWrapper
        onClick={(e) =>
          IS_ELECTRON ? electronOpenExternal(e) : window.open(url)
        }
      >
        <Styled.LinkIcon src={linkIcon} />
        {image && <Styled.Image src={image} />}
        <Styled.TextWrapper>
          <Styled.LinkHeading variant="h4" ellipsis>
            {new URL(url).hostname.replace('www.', '')}
          </Styled.LinkHeading>
          {title && (
            <Heading variant="h6" ellipsis>
              {title}
            </Heading>
          )}
          {description && <BodyCopy noMargin>{description}</BodyCopy>}
        </Styled.TextWrapper>
      </Styled.LinkPreviewWrapper>
    ) : null
);
