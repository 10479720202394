import React from 'react';
import SimpleMarkdown, {
  HtmlOutputRule,
  ParserRule,
  ReactOutputRule,
} from 'simple-markdown';
import { Styled } from './buildMention.styles';

/**
 * A `SimpleMarkdown` `mentionKeyword` rule (returns React for display, and HTML for input)
 */
export const buildMentionKeyword = (): ParserRule &
  ReactOutputRule &
  HtmlOutputRule => ({
  order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
  match: (matchSrc) => RegExp(/^(@here)/).exec(matchSrc),
  parse: (capture) => ({ content: capture[0] }),
  react: (node, _output, state) => (
    <Styled.Mention key={state.key}>
      <>{node.content}</>
    </Styled.Mention>
  ),
  html: (node) =>
    `&nbsp;<div class="input-mention" mention-target="${node.content}" contenteditable="false">${node.content}</div>&nbsp;`,
});
