import moment from 'moment-timezone';
import { Event } from 'react-big-calendar';
import { isAllDayEvent, isAllDayCustomEvent } from '../utils/eventUtils';
export interface IEvent extends Event {
  id: number;
  owner: string;
  participants: IEventParticipants[];
  startDate: string;
  endDate: string;
  title: string;
  location: string;
  description: string;
  providerEventId?: string;
  visibility: string; //public private
  calendarId: number;
  iCalUid: string; //guid
  recurrence?: IRecurrence;
  conferencing: IConferencing;
  start: Date;
  end: Date;
  endTimezone?: string;
  readonly?: boolean;
  startTimezone?: string;
  isReccuringEvent?: boolean;
  parentId?: number;
}

export type ActionCalendarNotification =
  | 'Updated'
  | 'Created'
  | 'Deleted'
  | 'ParticipantRemoved';

export type IEventOrIUserEvents = IEvent | IUserEvents;
export interface IUserEvents {
  endDate: string;
  recurrence: any;
  startDate: string;
}

export interface IEventParticipantRemoved {
  eventId: number;
  participantId: number;
  platformAccountId: number;
}

export interface IConferencing {
  provider: ConferenceProvider; //WebEx
  url?: string;
  password?: string;
  pin?: string;
  meetingCode?: string;
  phoneNumbers?: string[];
}

export type SupportedViews = 'month' | 'week';
export type EventResponse = 'yes' | 'no' | 'maybe' | 'no-reply';
export type Scope = 'calendar' | 'contacts' | 'calendar.read_only';

export type ConferenceProvider =
  | 'Google Meet'
  | 'WebEx'
  | 'Zoom Meeting'
  | 'GoToMeeting'
  | 'Microsoft Teams'
  | 'BHive';

export interface IEventParticipants {
  id: number;
  email: string;
  status?: string; //noreply, etc
  platformUserId: number;
  comment?: string;
  name: string;
}

export interface IRecurrence {
  rrule: string[];
  timezone: string;
  startDate: string;
  startTimezone: null;
  status: string;
  title: string;
  visibility: null;
}
export class EventModel implements IEvent {
  id: number;
  owner: string;
  participants: IEventParticipants[];
  startDate: string;
  endDate: string;
  title: string;
  location: string;
  description: string;
  visibility: string; //public private
  calendarId: number;
  iCalUid: string; //guid
  recurrence?: IRecurrence;
  conferencing: IConferencing;
  start: Date;
  end: Date;
  providerEventId?: string; //if null -> its BHive
  endTimezone?: string;
  readonly?: boolean;
  startTimezone?: string;
  allDay: boolean;
  isReccuringEvent?: boolean;
  parentId?: number;
  constructor(dto: IEvent) {
    const isAllDay = dto.providerEventId
      ? isAllDayEvent(dto.startDate, dto.endDate)
      : isAllDayCustomEvent(dto.endDate);

    (this.id = dto.id),
      (this.owner = dto.owner),
      (this.participants = dto.participants),
      (this.startDate =
        !dto.endDate && isAllDay
          ? moment(dto.startDate).utc().startOf('day').toISOString()
          : dto.startDate),
      (this.endDate = !dto.endDate
        ? isAllDay
          ? moment(dto.startDate).utc().endOf('day').toISOString()
          : dto.startDate
        : dto.endDate),
      (this.title = dto.title),
      (this.location = dto.location),
      (this.description = dto.description),
      (this.visibility = dto.visibility),
      (this.calendarId = dto.calendarId),
      (this.iCalUid = dto.iCalUid),
      (this.recurrence = dto.recurrence),
      (this.conferencing = dto.conferencing),
      (this.start =
        isAllDay && dto.providerEventId
          ? moment(dto.startDate).utc().local(true).toDate()
          : new Date(dto.startDate)),
      (this.end =
        isAllDay && dto.providerEventId
          ? moment(this.endDate).utc().local(true).endOf('day').toDate()
          : new Date(this.endDate));
    this.providerEventId = dto.providerEventId;
    this.startTimezone = dto.startTimezone;
    this.endTimezone = dto.endTimezone;
    this.readonly = dto.readonly;
    this.allDay = isAllDay;
    this.isReccuringEvent = dto.isReccuringEvent;
    this.parentId = dto.parentId; // reference to parent, of reccuringEvent
  }
}

export const initialCreatedEvent: IEvent = {
  id: 0,
  owner: '',
  participants: [],
  startDate: null,
  endDate: null,
  title: '',
  location: '',
  description: '',
  visibility: null, //public private
  calendarId: 0,
  iCalUid: '', //guid
  conferencing: null,
  start: null,
  end: null,
};
export default EventModel;
