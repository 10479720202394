import { CALL_LOG_IMAGES, DIRECTIONS } from 'Components/CallLogs/constants';
import type { CallLogType } from 'Components/CallLogs/types';

type mapImageToCallLogsProps = (callLogs: CallLogType[]) => CallLogType[];

export const mapImageToCallLogs: mapImageToCallLogsProps = (
  callLogs: CallLogType[]
) =>
  callLogs?.map((callLog) => {
    const category =
      DIRECTIONS[`${callLog.direction}_${callLog.disposition}`.toLowerCase()];
    return { ...callLog, icon: CALL_LOG_IMAGES[category], category };
  }) || [];
