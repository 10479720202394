import closeIcon from 'Assets/images/c-close.svg';
import dragIcon from 'Assets/images/drag.svg';
import React from 'react';
import { IDraggableHeader } from './interfaces';

export const DraggableHeader = ({
  isActiveCall,
  onClose,
}: IDraggableHeader) => {
  return (
    <div className="DraggableHeader">
      <div className="DraggableHeader__left"></div>
      <div
        className="DraggableHeader__draggable"
        style={{
          backgroundImage: `url(${dragIcon})`,
        }}
      ></div>
      <div className="DraggableHeader__right">
        {!isActiveCall && (
          <img src={closeIcon} alt="Draggable Icon" onClick={() => onClose()} />
        )}
      </div>
    </div>
  );
};
