import * as React from 'react';
import { Message } from 'semantic-ui-react';
import { InfoMessageBannerProps } from './types';

export const InfoMessageBanner: React.FC<InfoMessageBannerProps> = ({
  show,
  content,
  testid,
}) =>
  show ? <Message visible info data-testid={testid} {...{ content }} /> : null;
