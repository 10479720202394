import { isEmpty } from 'lodash';
import { inject } from 'mobx-react';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { STORE_CONVERSATION, STORE_PERSON } from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import { Contact, emptyContact } from '../../models/Contacts';
import { ConversationStore, PersonStore } from '../../stores';
import type { WithRouterWrapperProps } from 'Interfaces/container';

interface ICreateChatWithEmailProps extends WithRouterWrapperProps {
  conversation: ConversationStore;
  person: PersonStore;
}
class CreateChatWithEmail extends React.Component<
  ICreateChatWithEmailProps,
  { showErrorPopup: boolean; email: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      showErrorPopup: false,
      email: '',
    };
  }

  async componentDidMount() {
    const { conversation, navigate, location } = this.props;
    const addressQuerySearch = location.search;
    const splittedInfo = addressQuerySearch?.split('=') || [];
    const emailAdress =
      splittedInfo && splittedInfo.length > 1 ? splittedInfo[1] : '';
    if (
      emailAdress &&
      !isEmpty(splittedInfo) &&
      splittedInfo[0].includes('address')
    ) {
      this.setState({ email: emailAdress });
      try {
        const resp = await conversation.loadOrCreateConversationWithPost(
          null,
          null,
          emailAdress
        );
        navigate(`/chat/conversations/${resp.data.id}/menu`);
      } catch (err) {
        this.handleErrorPopup();
        console.debug("Conversation coudn't be made.");
      }
    }
  }

  handleErrorPopup = () => this.setState({ showErrorPopup: true });
  handleGoToContact = () => {
    const { person, navigate } = this.props;
    const newExtrContact = new Contact({
      ...emptyContact,
      emails: [{ id: null, type: '', email: this.state.email }],
    });
    person.setIsAddingContact(true);
    person.setEditContact(newExtrContact);
    navigate(`/addressBook/contact`, { state: { from: '/email' } });
    this.setState({ showErrorPopup: false, email: '' });
  };

  handeGoToHome = () => this.props.navigate(`/chat`);

  render() {
    return (
      <div className="flex-grow padding-20 voicemails position-relative">
        <div className="flex-grow">
          {this.state.showErrorPopup && (
            <div className="error-box">
              <Icon name="close" circular={true} />
              <div className="error-text">Error</div>
              <div className="content">
                <div>
                  We coudn't find any account with provided email address:
                </div>
                <div>
                  <b>{this.state.email}</b>
                </div>
              </div>
              <div className="text-below">
                Choose your next action clicking on the buttons below.
              </div>
              <Button
                onClick={this.handleGoToContact}
                content="Add to contacts"
                primary
              />
              <Button
                onClick={this.handeGoToHome}
                content="Back to home"
                secondary
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default inject(
  STORE_CONVERSATION,
  STORE_PERSON
)(withRouter(CreateChatWithEmail));
