import type { ChatDividerProps } from './types';
import React from 'react';
import { formatDateGroup } from 'Utils/formatDateGroup';
import { Styled } from './index.styles';

export const ChatDivider: React.FC<ChatDividerProps> = ({ groupDate }) => (
  <Styled.DividerContainer>
    <Styled.DividerLine />
    <Styled.DividerText variant="paragraph">
      {formatDateGroup(groupDate)}
    </Styled.DividerText>
    <Styled.DividerLine />
  </Styled.DividerContainer>
);
