import { motion } from 'framer-motion';
import styled from 'styled-components';
import { NotificationStackPosition } from './index.types';

const getPositionStyles = (position: NotificationStackPosition) => {
  const positions = {
    tl: 'top: 80px; left: 10px;',
    tc: 'top: 16px; left: 50%; transform: translateX(-50%);',
    tr: 'top: 80px; right: 10px;',
    bl: 'bottom: 16px; left: 10px;',
    bc: 'bottom: 16px; left: 50%; transform: translateX(-50%);',
    br: 'bottom: 16px; right: 10px;',
  };

  return positions[position];
};

const Container = styled.div<{
  $position: NotificationStackPosition;
  $width: number;
}>`
  position: fixed;
  z-index: 9998;
  ${({ $position }) => getPositionStyles($position)}
  ${({ $position, $width }) =>
    $position.includes('c')
      ? `
    width: 100%;
    padding: 0 1rem;
    max-width: ${$width}px;
  `
      : `
    width: ${$width}px;
  `}
  pointer-events: none;
`;

const NotificationWrapper = styled.div<{ $gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap}px;
  pointer-events: auto;
  width: 100%;
`;

const NotificationItem = styled(motion.div)<{
  $position: NotificationStackPosition;
}>`
  width: 100%;
  pointer-events: auto;
`;

export const Styled = {
  Container,
  NotificationWrapper,
  NotificationItem,
};
