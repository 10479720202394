import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { NOTIFICATION_CONFIG } from '../config';
import { Notification } from '../NotificationItem';
import { useNotificationStore } from '../store/notification';
import { Styled } from './index.styles';
import { NotificationStackProps } from './index.types';

const getSlideAnimation = (position: string) => {
  const isTop = position.startsWith('t');
  const isLeft = position.endsWith('l');
  const isRight = position.endsWith('r');

  if (isLeft) return { x: -100, y: 0 };
  if (isRight) return { x: 100, y: 0 };
  return { x: 0, y: isTop ? -100 : 100 };
};

export const NotificationStack = ({
  position,
  gap = NOTIFICATION_CONFIG.DEFAULT_GAP,
  className,
  containerId,
}: NotificationStackProps): React.ReactElement | null => {
  const notifications = useNotificationStore(
    (state) => state.notifications.get(position) || []
  );

  if (notifications.length === 0) {
    return null;
  }

  const slideAnimation = getSlideAnimation(position);

  return (
    <Styled.Container
      id={containerId}
      className={className}
      $position={position}
      $width={NOTIFICATION_CONFIG.NOTIFICATION_WIDTH}
    >
      <Styled.NotificationWrapper $gap={gap}>
        <AnimatePresence mode="sync">
          {notifications.map((notification) => (
            <Styled.NotificationItem
              key={notification.id}
              layout="position"
              initial={{
                opacity: 0,
                ...slideAnimation,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
              }}
              exit={{
                opacity: 0,
                ...slideAnimation,
                transition: {
                  duration: 0.2,
                  ease: [0.4, 0, 0.2, 1],
                },
              }}
              transition={{
                duration: 0.3,
                ease: [0.4, 0, 0.2, 1],
              }}
              $position={position}
            >
              <Notification notification={notification} position={position} />
            </Styled.NotificationItem>
          ))}
        </AnimatePresence>
      </Styled.NotificationWrapper>
    </Styled.Container>
  );
};
