import type {
  CallLogCategoryType,
  CallLogDirectionType,
  CallLogDispositionType,
} from './types';
import IncomingCallIcon from 'Assets/images/incoming-call.svg';
import MissedCallIcon from 'Assets/images/missed-call.svg';
import OutgoingCallIcon from 'Assets/images/outgoing-call.svg';
import React from 'react';

export const TYPES_OF_CALLS: CallLogCategoryType[] = [
  'missed',
  'incoming',
  'outgoing',
  'all',
];

export type CallsTypesContentProps = {
  [prop in CallLogCategoryType]: {
    title: string;
    placeholder: string;
    emptySearchText: React.ReactNode;
    direction: CallLogDirectionType;
    disposition: CallLogDispositionType;
  };
};

export const CALL_TYPES_CONTENT: CallsTypesContentProps = {
  missed: {
    title: 'Missed calls',
    placeholder: 'Search missed calls',
    emptySearchText: <div>Way to go! You haven't missed any call!</div>,
    direction: 'inbound',
    disposition: 'missed',
  },
  incoming: {
    title: 'Incoming calls',
    placeholder: 'Search incoming call records',
    emptySearchText: <div>Bummer... You haven't received any call yet.</div>,
    direction: 'inbound',
    disposition: 'answered',
  },
  outgoing: {
    title: 'Outgoing calls',
    placeholder: 'Search outgoing call records',
    emptySearchText: (
      <>
        <div>This page is empty because you haven't called anyone yet.</div>
        <div>Go to your contacts list and call someone, anyone!</div>
      </>
    ),
    direction: 'outbound',
    disposition: null,
  },
  all: {
    title: 'All recent calls',
    placeholder: 'Search all call records',
    emptySearchText: (
      <>
        <div>Hmm... it seems you haven't placed or received any call yet.</div>
        <div>Nothing to show here.</div>
      </>
    ),
    direction: null,
    disposition: null,
  },
};

export const DIRECTIONS = {
  inbound_missed: 'missed',
  inbound_busy: 'incoming',
  inbound_answered: 'incoming',
  inbound_cancel: 'incoming',
  outbound_missed: 'outgoing',
  outbound_busy: 'outgoing',
  outbound_answered: 'outgoing',
  outbound_cancel: 'outgoing',
};

export const CALL_LOG_IMAGES = {
  missed: MissedCallIcon,
  incoming: IncomingCallIcon,
  outgoing: OutgoingCallIcon,
};

export const CALL_LOGS_PER_PAGE = 20;
