import { ObservableMap, autorun } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import type { IPinnedMessages } from 'Models/PinnedMessageModel';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';

const getPinnedMessage = (
  pinnedMessages: ObservableMap<string, IPinnedMessages[]>,
  conversationId: string,
  messageId: string
) => pinnedMessages.get(conversationId)?.find(({ id }) => id === messageId);

export const usePinnedStatus = (messageId: string, conversationId: string) => {
  const { ui, conversation, person } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const [pinnedBy, setPinnedBy] = React.useState<string>(null);

  React.useEffect(() => {
    const disposer = autorun(() => {
      const fetchPersonIfMessageIsPinned = async () => {
        const msg = getPinnedMessage(
          ui.listOfPinnedMessages,
          conversationId,
          messageId
        );
        setPinnedBy(
          (
            await person.selectPersonById(msg.pinnedByPersonId)
          )?.data.getDisplayName()
        );
      };

      if (getPinnedMessage(ui.listOfPinnedMessages, conversationId, messageId))
        void fetchPersonIfMessageIsPinned();
      else setPinnedBy(null);
    });

    return () => disposer();
  }, []);

  const togglePinMessage = async () => {
    if (pinnedBy) {
      const res = await conversation.deletePinnedMessages(
        conversationId,
        messageId
      );
      if (res?.status === 204) return setPinnedBy(null);
    }

    await conversation.createPinnedMessages(conversationId, messageId);
  };

  return { pinnedBy, togglePinMessage };
};
