import cx from 'classnames';
import CallIncomingListItem from 'Components/CallIncomingListItem';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CallIncomingListProps } from './interfaces';

export class CallIncomingList extends React.Component<
  CallIncomingListProps,
  {}
> {
  myRef;
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
  }

  render() {
    const { incomingCalls, activeCallExists, person, ui } = this.props;
    let callCount = 0;
    return (
      <ul
        className={cx('call-incoming-list', {
          'multiple-incoming-calls': incomingCalls.length >= 3,
        })}
      >
        {incomingCalls.length > 0 &&
          incomingCalls
            .slice()
            .reverse()
            .map((mp, index) => {
              callCount = callCount + 1;
              return (
                <CallIncomingListItem
                  phoneCall={mp}
                  key={`item-${index}`}
                  showMinified={activeCallExists || callCount > 1}
                  getExtrContactByPhoneNumber={
                    person.getExtrContactByPhoneNumber
                  }
                  selectUnreadCounts={ui.selectConversationUnreadCounts}
                  getPersonFromDirectory={person.getFromDirectory}
                />
              );
            })}
      </ul>
    );
  }
}

export default observer(CallIncomingList);
