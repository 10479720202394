import styled from 'styled-components';

const UserInfo = styled.div`
  display: flex;
`;

const UserInfoContent = styled.div`
  margin-left: ${({ theme }) => theme.sizes.xsmall};
`;

export const Styled = {
  UserInfo,
  UserInfoContent,
};
