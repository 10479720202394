import styled from 'styled-components';

const ListItem = styled.li`
  border-radius: 0px;
  padding: 10px 11px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #353535;
    color: ${({ theme }) => theme.colors.textDarkBackground};
  }
`;

const StatusTimerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const Status = styled.div`
  flex: 1;
  font-size: 11px;
  color: #bcbcbc;
  height: 18px;
  margin-bottom: 3px;
`;

const CallTimer = styled.div`
  font-size: 11px;
  color: #bcbcbc;
`;

const TextButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  justify-content: center;
`;

const Name = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Number = styled.div`
  font-size: 12px;
  color: #3bc597;
  line-height: 1em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BtnWrapper = styled.div`
  margin-left: 15px;
  align-items: center;
  justify-content: center;
`;

const HoldToggleBtn = styled.button`
  width: 35px;
  height: 35px;
  margin: 0;
  background-image: linear-gradient(#828282, #585858);
  background-color: #585858;
  position: relative;
  border: 0px;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  &.active {
    background-image: linear-gradient(#39bf9c, #104cf5);

    &:hover {
      background-image: linear-gradient(#104cf5, #39bf9c);
    }
  }

  &:hover {
    box-shadow: none;
    background-image: linear-gradient(#929292, #686868);
  }

  img {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -7px;
    margin-left: -8px;
    pointer-events: none;
  }
`;

export const Styled = {
  ListItem,
  StatusTimerWrapper,
  Status,
  CallTimer,
  TextButtonWrapper,
  TextWrapper,
  Name,
  Number,
  BtnWrapper,
  HoldToggleBtn,
};
