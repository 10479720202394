import { Input } from 'Components/shared/Input';
import { Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const CallIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  left: 4px;
  right: auto;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;

  &&::placeholder {
    color: #c0c0c0;
  }

  .transfer-call-dropdown {
    position: absolute;

    &.disabled {
      opacity: 1;
    }

    .menu {
      top: 34px;
      right: unset !important;
      left: auto !important;
    }
  }
`;

const StyledLoader = styled(Loader)`
  display: block;
  position: absolute;
  right: 9px;
  left: initial;
  top: 50%;
  transform: translateY(-50%);
`;

const SearchIcon = styled(Icon)`
  display: block;
  position: absolute;
  right: 5px;
  left: initial;
  top: 45%;
  transform: translateY(-50%);
`;

const StyledInput = styled(Input)`
  &&& input {
    padding-right: 30px !important;
    border-style: none;
    background-color: #808fa31a !important;
    color: #c0c0c0;

    &:focus {
      color: #c0c0c0;
      outline-width: 0;
    }
  }
`;

export const Styled = {
  CallIcon,
  InputWrapper,
  Input: StyledInput,
  Loader: StyledLoader,
  SearchIcon,
};
