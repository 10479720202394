/**
 * Returns the Top and Left offsets
 */
export function getElementAbsolutePosition(
  el: HTMLElement
): IElementAbsolutePosition {
  let el2 = el;
  let curtop = 0;
  let curleft = 0;
  let maxClientWidth = 0;
  let maxClientHeight = 0;
  do {
    curleft += el.offsetLeft - el.scrollLeft;
    curtop += el.offsetTop - el.scrollTop;
    el = el.offsetParent as HTMLElement;
    el2 = el2.parentNode as HTMLElement;
    while (el2 !== el) {
      curleft -= el2.scrollLeft;
      curtop -= el2.scrollTop;
      el2 = el2.parentNode as HTMLElement;
    }
    maxClientWidth = el.clientWidth;
    maxClientHeight = el.clientHeight;
  } while (el.offsetParent);
  return {
    top: curtop,
    left: curleft,
    maxAncestorClientWidth: maxClientWidth,
    maxAncestorClientHeight: maxClientHeight,
  };
}

export interface IElementAbsolutePosition {
  top: number;
  left: number;
  maxAncestorClientWidth: number;
  maxAncestorClientHeight: number;
}

export const isElementInViewport = (el: HTMLElement) => {
  if (!el) return null;

  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
