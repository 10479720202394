import moment from 'moment';

export const parseMessageDate = (date: string, simplified = false) => {
  const messageDate = moment(date);
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  const currentYearStart = moment().startOf('year');

  if (messageDate.isSame(today, 'day')) {
    return simplified ? 'Today' : messageDate.format('hh:mm A');
  }
  if (messageDate.isSame(yesterday, 'day')) {
    return simplified
      ? `Yesterday`
      : `Yesterday ${messageDate.format('hh:mm A')}`;
  }
  if (today.diff(messageDate, 'days') < 7) {
    return messageDate.format(simplified ? 'dddd' : 'dddd hh:mm A');
  }
  if (messageDate.isAfter(currentYearStart)) {
    return messageDate.format(simplified ? 'MMM DD' : 'MMM DD hh:mm A');
  }

  return messageDate.format(simplified ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm A');
};
