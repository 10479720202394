import { ConversationStore } from '../stores';

export type PlaceVideoCallConferenceProps = (props: {
  conversation: ConversationStore;
  currentPersonId?: string;
  conversationId: string;
}) => Promise<void>;

export const placeVideoCallConference: PlaceVideoCallConferenceProps = async ({
  conversation,
  currentPersonId = '',
  conversationId,
}) => {
  if (!currentPersonId) {
    await conversation.postConferenceByConversationId(conversationId);
    return;
  }

  const { data } = await conversation.loadOrCreateConversationWithPost(
    currentPersonId
  );
  await conversation.postConferenceByConversationId(data.id.toString());
};
