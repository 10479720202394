import { BV_ENV_LOCAL, BV_ENV_PRODUCTION } from 'Constants/env';
import {
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_PERSON,
} from 'Constants/stores';
import { IReactComponent } from 'Interfaces/classTypes';
import { get, has } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Accordion,
  Container,
  Header,
  Icon,
  Message,
  Segment,
} from 'semantic-ui-react';
import {
  ConversationStore,
  MessageStore,
  PhoneStore,
  UiStore,
} from 'Stores/index';
import { PersonStore } from 'Stores/PersonStore';
import { Subtract } from 'utility-types';
import { defaultErrorHandler } from 'Utils/defaultErrorHandler';
import { bugsnagClient } from 'Utils/logUtils';
export interface IWithErrorHandlerState {
  hasError: boolean;
  error?: any;
  errorInfo?: any;
  /** Controls the displayed accordion panel containing: error info including the `componentStack`, additional help text. Set to -1 (default) to close all. */
  activeIndex: number;
}

/** `props` injected into a Component wrapped in `withErrorHandler` */
export interface IWithErrorHandlerProps {
  /** (Injected) */
  person: PersonStore;
  message: MessageStore;
  conversation?: ConversationStore;
  browserHistory: any;
  /** Provided by `withErrorHandler`. Manually set an error state, short circuiting rendering for this Component to display an error message. */
  setError?: (error: any, info?: any) => void;
  /** Provided by `withErrorHandler`. Clear an existing error state. */
  clearError?: () => void;
  ui: UiStore;
  phoneStore: PhoneStore;
}
export type ErrorCallback<P extends IWithErrorHandlerProps> = (
  error: any,
  info: any,
  props: Subtract<P, IWithErrorHandlerProps>
) => void;
/** A Higher-Order Component which implements `componentDidCatch`, triggering display of an error Message (rather than a full app crash)  */
export const withErrorHandler = <P extends IWithErrorHandlerProps>(
  Component: IReactComponent<P>,
  errorCallback: ErrorCallback<P> = defaultErrorHandler
) => {
  class WithErrorHandler extends React.Component<P, IWithErrorHandlerState> {
    state = {
      hasError: false,
      error: null,
      errorInfo: null,
      // Open Detailed Error Info by default in non-production envs
      activeIndex: BV_ENV_PRODUCTION ? -1 : 0,
    };

    static displayName = `withErrorHandler(${Component.displayName})`;

    setError = (error: any, info: any) => {
      this.setState({ hasError: true, error, errorInfo: info });
      errorCallback(error, info, this.props);
    };

    clearError = () => {
      this.setState({
        hasError: false,
        error: null,
        errorInfo: null,
        activeIndex: -1,
      });
    };

    componentDidCatch(error, info) {
      this.setError(error, info);
      if (!BV_ENV_LOCAL) {
        bugsnagClient.notify(error, (event) => {
          event.severity = 'error';
          event.context = 'ErrorHandlerMixin';
          event.addMetadata('custom', { function: 'componentDidCatch' });
        });
      }
    }

    onAccordionClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndex } = this.state;
      const newIndex = activeIndex === index ? -1 : index;

      this.setState({ activeIndex: newIndex });
    };

    render() {
      if (this.state.hasError) {
        const { activeIndex, error, errorInfo } = this.state;
        let msg: string, title: string;
        if (has(error, 'response')) {
          msg = get(
            error.response,
            'data.message',
            error.message || 'Error Response from API'
          );
          title = `(${error.response.status} ${error.response.statusText})`;
        } else if (has(error, 'request')) {
          msg = error.message || 'Fatal Request Error';
          title = has(error, 'request')
            ? 'No Response from Server'
            : 'Malformed Request';
        } else {
          msg =
            error.message ||
            'A fatal error has occurred, please contact Customer Service';
          title = error.name || '';
        }
        return (
          <Container className="fatal-error-wrap">
            <Segment raised color="red">
              <Header
                as="h2"
                className="no-vmargin"
                color="red"
                textAlign="center"
                content="Oops! Something went wrong!"
                subheader="We're sorry for the inconvenience, please help us improve by reporting this issue so we can get it fixed ASAP!"
              />
              <Segment basic textAlign="center">
                <a href={`//${window.location.host}/`}>
                  Return to the Main Page
                </a>
              </Segment>
              <Accordion fluid styled>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.onAccordionClick}
                >
                  <Icon name="dropdown" /> Detailed Error Info
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <Message error>
                    <Message.Header>{title}</Message.Header>
                    <Message.Content>
                      <p>{msg}</p>
                      {errorInfo && has(errorInfo, 'componentStack') && (
                        <pre>{errorInfo.componentStack}</pre>
                      )}
                    </Message.Content>
                  </Message>
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Container>
        );
      }

      return (
        <Component
          {...this.props}
          setError={this.setError}
          clearError={this.clearError}
        />
      );
    }
  }

  return inject(
    STORE_PERSON,
    STORE_MESSAGE,
    STORE_CONVERSATION
  )(observer(WithErrorHandler));
};
