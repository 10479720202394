import { TextLink } from 'Components/shared/TextLink';
import { IS_ELECTRON } from 'Constants/env';
import React from 'react';
import SimpleMarkdown, { ParserRule, ReactOutputRule } from 'simple-markdown';
import { electronOpenExternal } from 'Utils/electronHelpers';

export const buildInlineVideo = (): ParserRule & ReactOutputRule => ({
  order: SimpleMarkdown.defaultRules.link.order - 0.5,
  match: (matchSrc) => {
    const LINK_HREF_AND_TITLE =
      '\\s*<?((?:[^\\s\\\\]|\\\\.)*?)>?(?:\\s+[\'"]([\\s\\S]*?)[\'"])?\\s*';
    return RegExp('^!VIDEO\\(' + LINK_HREF_AND_TITLE + '\\)').exec(matchSrc);
  },
  parse: (capture) => ({ target: capture[1] }),
  react: (node, _output, state) => (
    <>
      <TextLink
        key={state.key + '_a'}
        testid={`markdownCompiler-linkInlineVideo-${state.key}`}
        underline
        {...(IS_ELECTRON
          ? { 'data-ext-url': node.target, onClick: electronOpenExternal }
          : { href: node.target, target: '_blank', rel: 'noreferrer' })}
      >
        {node.target}
      </TextLink>

      <br />
      <video key={state.key} className="inline-video" controls>
        {' '}
        <source src={node.target} />
      </video>
    </>
  ),
});
