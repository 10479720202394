import { debounce } from 'lodash';
import { useState, useEffect } from 'react';

export const useWindowHeight = (): number => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = debounce(() => {
      setHeight(window.innerHeight);
    }, 500);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
};
