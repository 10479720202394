import styled from 'styled-components';

const VideoHistoryDetailsStyled = styled.div<{ $adjustWidth: boolean }>`
  @media (min-width: 1024px) {
    ${({ $adjustWidth }) =>
      $adjustWidth &&
      `
        width: calc(100% - 360px) !important;
      `};
  }
`;

export const Styled = {
  VideoHistoryDetailsStyled,
};
