import { Button } from 'Components/shared/Button';
import styled from 'styled-components';

const CalendarButton = styled(Button) <{
  $isActive: boolean;
}>`
  ${(props) => props.$isActive ? `
    background-color: ${props.theme.colors.primary} !important;
  ` : `
    background-color: ${props.theme.colors.border} !important;
    color: rgba(51, 62, 72, 1) !important;
    opacity: 0.6 !important;
  `}
`;

const TodayButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.border} !important;
  font-size: 10px !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #8d9ba8 !important;
  padding: 9px 17px 7px 17px !important;
  font-weight: 700 !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.base} !important;
    opacity: 0.7 !important;
  }
`;

const CloseButtonContainer = styled.div`
  margin-left: auto;
`;

const CreateButton = styled(Button)`
  width: 112px;
`;

export const Styled = {
  CloseButtonContainer,
  CreateButton,
  CalendarButton,
  TodayButton
};