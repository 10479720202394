import styled from 'styled-components';

const TopBarDropdownButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 13px;
  z-index: 5;
  justify-content: flex-end;
  display: flex;
`;

const CustomButton = styled.div`
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  margin: 0 0 0 5px;
  padding: 0;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const Styled = {
  TopBarDropdownButtonWrapper,
  CustomButton,
};
