import type { CallLogRowButtonsProps } from './types';
import { MoreButtonsMenuItem } from 'Components/CallLogs/MoreButtonsMenuItem';
import { IconButton } from 'Components/shared/IconButton';
import { PopOverMenu } from 'Components/shared/PopOverMenu';
import { CALL_STATE } from 'Constants/enums';
import { TESTID_BUTTONS, TESTID_CALL_LOGS } from 'Constants/testids';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { formatNumberNoPlusIfUS } from 'Utils/phoneUtil';

export const CallLogRowButtons: React.FC<CallLogRowButtonsProps> = observer(
  ({ personId, phoneNumber }) => {
    const {
      conversation: {
        loadOrCreateConversationWithPost,
        LoggedInUserActiveConferenceConversation,
        setChannelInfoDetails,
        postConferenceByConversationId,
      },
      person: {
        personAvaliableFeatures,
        getExtrContactByPhoneNumber,
        setShowPersonDetails,
        loadPersonByIdGetIfMissingGet,
        getLoadNewContact,
      },
      phoneCall,
      ui: {
        setOpenedRightSidebarsOrder,
        setLoadingConversationForSidebarInfo,
        setActiveConversationIdPinnMess,
      },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const [showMoreOptionsModal, setShowMoreOptionsModal] =
      React.useState(false);
    const [showCallOptionsModal, setShowCallOptionsModal] =
      React.useState(false);
    const [callState, setcallState] = React.useState<CALL_STATE>(
      CALL_STATE.Available
    );
    const navigate = useNavigate();
    const lines = getExtrContactByPhoneNumber(phoneNumber)?.phoneNumbers;

    React.useEffect(() => {
      let callState = CALL_STATE.Available;
      phoneCall.phoneCalls.forEach(({ callUri, isCallConnecting }) => {
        const elementPR = callUri?.split('@')[0].substring(2);
        if (elementPR === personId?.toString()) {
          callState = isCallConnecting
            ? CALL_STATE.Connecting
            : CALL_STATE.Connected;
        }
      });
      setcallState(callState);
    }, []);

    const handleOpenChat = () => {
      loadOrCreateConversationWithPost(
        personId,
        !personId && phoneNumber?.replace(/\D/g, '')
      ).then((resp) => {
        pushToGTMDataLayer('directoryMessageUser');
        navigate(`/chat/conversations/${resp.data.id}/menu`);
      });
    };

    const makeCall = () => {
      if (!personId && !phoneNumber)
        throw new Error('Either Phone Number or Person Id is required.');
      if (personId) {
        phoneCall.callWithPerson(personId, null);
      } else if (phoneNumber) {
        phoneCall.callWithPerson(null, phoneNumber);
      }
    };

    const makeVideoCall = () => {
      loadOrCreateConversationWithPost(personId, !personId && phoneNumber).then(
        (item) => {
          postConferenceByConversationId(item.data.id.toString());
        }
      );
    };

    const openInfoDetails = async () => {
      setLoadingConversationForSidebarInfo(true);
      !personId
        ? await handleSidebarInfoForNumber()
        : await handleSidebarInfoForPerson();
      setLoadingConversationForSidebarInfo(false);
      setChannelInfoDetails('0', 'new');
      setActiveConversationIdPinnMess('');
      setOpenedRightSidebarsOrder('sidebar-info');
      setShowMoreOptionsModal(false);
    };

    const handleSidebarInfoForNumber = async () => {
      const formatedPhoneNum = formatNumberNoPlusIfUS(phoneNumber);
      setShowPersonDetails(formatedPhoneNum, 'contact');
      const conv = await loadOrCreateConversationWithPost(
        null,
        formatedPhoneNum
      );
      let phoneNum = conv.data.participants[0].phone;
      await getLoadNewContact(phoneNum);
      const extrContact = getExtrContactByPhoneNumber(phoneNum);
      phoneNum =
        extrContact?.phoneNumbers[0]?.normalizedNumber?.replace(/\D/g, '') ||
        phoneNum ||
        phoneNumber;
      setShowPersonDetails(phoneNum, extrContact ? 'extrContact' : 'contact');
    };

    const handleSidebarInfoForPerson = async () => {
      await loadPersonByIdGetIfMissingGet(personId);
      setShowPersonDetails(personId, 'person');
    };

    const buttonSize = 'medium';
    const popupPosition = 'bottom center';

    return (
      <div className="flex-row flex-justify-end flex-align-items-center">
        {phoneNumber ? (
          <PopOverMenu
            trigger={
              <IconButton
                content="Start call"
                icon="call"
                onClick={() =>
                  lines?.length > 1
                    ? setShowCallOptionsModal(!showCallOptionsModal)
                    : makeCall()
                }
                disabled={{
                  title: 'You are currently on a call with this person',
                  condition: callState !== CALL_STATE.Available,
                }}
                testid={
                  TESTID_CALL_LOGS['ROW_BUTTONS'] + TESTID_BUTTONS['CALL']
                }
                {...{ popupPosition, size: buttonSize }}
              />
            }
            content={
              <div>
                {lines?.map(({ type, number }) => (
                  <MoreButtonsMenuItem
                    key={number}
                    textContent={`${type}: ${number}`}
                    testid={
                      TESTID_CALL_LOGS['ROW_BUTTONS'] +
                      `${TESTID_BUTTONS['CALL']}-${number}`
                    }
                    clickHandler={makeCall}
                    textClassname="call-menu-item"
                    setShowMoreOptionsModal={setShowMoreOptionsModal}
                  />
                ))}
              </div>
            }
            show={showCallOptionsModal}
            onOutsideClick={() => setShowCallOptionsModal(false)}
            position="bottom right"
          />
        ) : (
          <IconButton
            content="Start call"
            disabled={{
              title: 'You are currently on a call with this person',
              condition: callState !== CALL_STATE.Available,
            }}
            icon="call"
            onClick={makeCall}
            testid={TESTID_CALL_LOGS['ROW_BUTTONS'] + TESTID_BUTTONS['CALL']}
            {...{ popupPosition, size: buttonSize }}
          />
        )}
        <IconButton
          content="Start messaging"
          icon="chat"
          onClick={handleOpenChat}
          testid={TESTID_CALL_LOGS['ROW_BUTTONS'] + TESTID_BUTTONS['MESSAGE']}
          {...{ popupPosition, size: buttonSize }}
        />

        {!personId && phoneNumber ? (
          <IconButton
            content="View contact details"
            icon="info"
            onClick={openInfoDetails}
            testid={
              TESTID_CALL_LOGS['ROW_BUTTONS'] +
              TESTID_BUTTONS['CONTACT_DETAILS']
            }
            {...{ popupPosition, size: buttonSize }}
          />
        ) : (
          <PopOverMenu
            trigger={
              <IconButton
                content="More"
                icon="more"
                onClick={() => setShowMoreOptionsModal(!showMoreOptionsModal)}
                testid={
                  TESTID_CALL_LOGS['ROW_BUTTONS'] +
                  TESTID_BUTTONS['MORE_OPTIONS']
                }
                {...{ popupPosition, size: buttonSize }}
              />
            }
            content={
              <div className="more-action-buttons">
                <MoreButtonsMenuItem
                  textContent="Video call"
                  icon="video"
                  wrapperClassname={
                    !!LoggedInUserActiveConferenceConversation ||
                    !personAvaliableFeatures.video.enabled
                      ? 'cursor-not-allowed action-buttons'
                      : 'action-buttons'
                  }
                  testid={
                    TESTID_CALL_LOGS['ROW_BUTTONS'] +
                    TESTID_BUTTONS['VIDEO_CALL']
                  }
                  clickHandler={makeVideoCall}
                  disabled={{
                    title: 'Video conference unavailable',
                    condition:
                      !!LoggedInUserActiveConferenceConversation ||
                      !personAvaliableFeatures.video.enabled,
                  }}
                  setShowMoreOptionsModal={setShowMoreOptionsModal}
                />
                <MoreButtonsMenuItem
                  textContent="View contact details"
                  icon="info"
                  wrapperClassname="action-buttons"
                  testid={
                    TESTID_CALL_LOGS['ROW_BUTTONS'] +
                    TESTID_BUTTONS['CONTACT_DETAILS']
                  }
                  clickHandler={openInfoDetails}
                  setShowMoreOptionsModal={setShowMoreOptionsModal}
                />
              </div>
            }
            show={showMoreOptionsModal}
            onOutsideClick={() => setShowMoreOptionsModal(false)}
            position="bottom right"
          />
        )}
      </div>
    );
  }
);
